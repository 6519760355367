import Skeleton from "../../components/Skeleton";
import AppPageLayout from "../DocumentPage/app-page-layout";

const SubscriptionPageLoading = () => {
  return (
    <AppPageLayout title={"Абонамент - редакция"} className="history-page">
      <Skeleton count={10} />
    </AppPageLayout>
  );
};

export default SubscriptionPageLoading;
