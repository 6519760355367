import { useCallback } from "react";
import { FaLink } from "react-icons/fa";
import Collapse from "../../components/Collapse";
import Heading from "../../components/TableOfContents/Heading";
import TableOfContent from "../../components/TableOfContents/table-of-contents";
import TableOfContentActs from "../../components/TableOfContents/table-of-contents-acts";
import TableOfContentThesis from "../../components/TableOfContents/table-of-contents-thesis";
import { useEditorCollectionContext } from "../../contexts/editor-collection.context";
import {
  scrollToTargetAdjusted,
  scrollToTargetAdjustedInsideParent,
} from "../JudgementPage/judgmenet-view-page-metadata";
export type Mode = "view" | "edit";

const EditorCollectionPageNavigation = () => {
  const { activeId, setActiveId } = useEditorCollectionContext();
  const adjSetActiveId = useCallback(
    (activeId: Heading) => {
      setActiveId(activeId);
      const editor = document.querySelector(".ql-editor");
      const heading = activeId;
      if (heading) {
        if (editor) {
          scrollToTargetAdjustedInsideParent(document.querySelector(`#${heading.id}`)!);
        } else {
          document.querySelectorAll(".act-text .main-section:not(.h--0)").forEach((el) => {
            el.classList.add("h-0");
          });
          const selected = document.querySelector(`#${heading.id}`)!;
          selected.parentElement!.classList.remove("h-0");
          if (heading.level === 2) {
            window.scrollTo({ top: 0 });
          } else {
            scrollToTargetAdjusted(selected as HTMLElement);
          }
        }
      }
    },
    [setActiveId]
  );
  return (
    <div className="collapse-container">
      <TableOfContent activeId={activeId} setActiveId={adjSetActiveId} />

      <TableOfContentThesis activeId={activeId} setActiveId={adjSetActiveId} />
      <Collapse icon={<FaLink />} label={"Актове"} defaultExpanded={false}>
        <TableOfContentActs activeId={activeId} />
      </Collapse>
    </div>
  );
};

export default EditorCollectionPageNavigation;
