import { useCallback } from "react";
import { FormText } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm, UseFormSetError } from "react-hook-form";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import { PrimaryButton, SecondaryButton } from "../components/Button/button";
import { useAuth } from "../contexts/auth.context";
import {
  createPasswordResetRequest,
  CreatePasswordResetRequestInput,
} from "../services/password-reset-services";

type PasswordResetModalProps = {
  onClose: () => void;
};

const PasswordResetModal: React.FC<PasswordResetModalProps> = ({ onClose }) => {
  const { handleSubmit, register, setError, formState, clearErrors } =
    useForm<CreatePasswordResetRequestInput>();
  const { hasSuccessCallback, isLoginCancelable } = useAuth();
  const intl = useIntl();

  const onSubmit = useCallback(
    async (data: CreatePasswordResetRequestInput) => {
      try {
        await createPasswordResetRequest(data);
        toast.info(intl.formatMessage({ id: "password-reset.modal.toasts.submit.success" }));
        onClose();
      } catch (e: any) {
        handleApiErrors(e, setError, intl);
      }
    },
    [setError, intl, onClose]
  );

  useTitle(intl.formatMessage({ id: "password-reset.modal.header" }));

  return (
    <>
      <Modal
        show={true}
        centered
        animation={false}
        keyboard
        onEscapeKeyDown={onClose}
        onHide={onClose}
        backdrop={isLoginCancelable ? true : "static"}
        size="lg"
        fullscreen="sm-down"
      >
        <Modal.Header>
          <FormattedMessage id="password-reset.modal.header" />
        </Modal.Header>
        <Modal.Body>
          {hasSuccessCallback && (
            <Alert variant="primary">
              Достъпът до избраната функционалност изисква вход с потребителско име и парола.
            </Alert>
          )}
          <Form autoComplete="off" noValidate>
            <Form.Group controlId="email">
              <Form.Label>
                <FormattedMessage id="password-reset.modal.form.fields.email" />*
              </Form.Label>
              <Form.Control type="text" {...register("email")} />
              {formState.errors.email ? (
                <FormText className="text-danger">{formState.errors.email.message}</FormText>
              ) : (
                <Form.Text className="text-muted">
                  Оперативния имейл, с който сте регистрирани в ЛЕКСЕБРА
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton messageId="button.cancel" onClick={onClose} />
          <PrimaryButton
            messageId="button.confirm"
            onClick={(e: any) => {
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
            disabled={formState.isSubmitting}
            submitting={formState.isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const handleApiErrors = (
  e: any,
  setError: UseFormSetError<CreatePasswordResetRequestInput>,
  intl: IntlShape
) => {
  if (e.status && e.status === 400) {
    (e.payload.subErrors || []).forEach((error: any) => {
      setError(error.field, {
        type: "manual",
        message: intl.formatMessage({
          id: `password-reset.modal.form.errors.${error.field}.${error.message}`,
        }),
      });
    });
  }
};

export default PasswordResetModal;
