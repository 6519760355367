import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton, SecondaryButton } from "../../components/Button/button";
import Select from "../../components/Select";
import ReferencedJudgementAct from "../../models/ReferencedJudgementAct.model";
import { SearchHit } from "../../models/SearchHit.model";
import { DocumentStatusBadge, useSuggestions } from "../../services/document-services";

type AddRelatedActModalProps = {
  append: (prov: ReferencedJudgementAct) => void;
  relatedAct?: ReferencedJudgementAct;
  setRelatedAct: (act?: ReferencedJudgementAct) => void;
};
type ModalMode = "hidden" | "add" | "edit";
const AddRelatedActModal: React.FC<AddRelatedActModalProps> = ({
  append,
  relatedAct,
  setRelatedAct,
}) => {
  const [selectedAct, setSelectedAct] = useState<SearchHit>();

  useEffect(() => {
    if (relatedAct) {
      setSelectedAct({
        id: relatedAct.id,
        text: relatedAct.title,
        status: relatedAct.status,
        category: "",
      });
    }
  }, [setSelectedAct, relatedAct]);

  const [mode, setMode] = useState<ModalMode>("hidden");

  return (
    <>
      <span onClick={() => setMode("add")}>
        <FormattedMessage id="button.add" />
      </span>
      {(mode === "add" || mode === "edit") && (
        <SelectAct
          onConfirm={() => {
            append({
              id: selectedAct!.id,
              title: selectedAct!.text,
              status: selectedAct!.status,
              referencesTo: [],
              referencesFrom: [],
            });
            setMode("hidden");
            setSelectedAct(undefined);
          }}
          mode={mode}
          setSelectedAct={setSelectedAct}
          selectedAct={selectedAct}
          relatedAct={relatedAct}
          onCancel={() => {
            setMode("hidden");
            setRelatedAct(undefined);
            setSelectedAct(undefined);
          }}
        />
      )}
    </>
  );
};

type SelectActProps = {
  selectedAct?: SearchHit;
  setSelectedAct: (act?: SearchHit) => void;
  relatedAct?: ReferencedJudgementAct;
  onConfirm: () => void;
  mode: ModalMode;
  onCancel: () => void;
};

const SelectAct: React.FC<SelectActProps> = ({
  selectedAct,
  setSelectedAct,
  onConfirm,
  mode,
  relatedAct,
  onCancel,
}) => {
  const intl = useIntl();
  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton messageId="button.confirm" onClick={onConfirm} disabled={!selectedAct} />
    </>
  );

  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue("");
  }, [setInputValue]);
  const options = useSuggestions(inputValue);

  return (
    <Modal
      show={true}
      centered
      size="xl"
      animation={false}
      className="modal-xxl"
      onEscapeKeyDown={onCancel}
    >
      <Modal.Header>
        <FormattedMessage id="add-related-act.modal.header" />
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Select
          onChange={(...event: any[]) => {
            setSelectedAct(event[0]);
          }}
          inputValue={inputValue}
          onInputChange={setInputValue}
          menuIsOpen={!!inputValue}
          value={selectedAct ? [selectedAct] : []}
          components={{
            DropdownIndicator: null,
          }}
          inputStyles={{ singleValue: { width: "90%", textOverflow: "ellipsis" } }}
          options={options}
          filterOption={() => true}
          isMulti={false}
          isClearable
          getOptionValue={(value: any) => value.text || value.title}
          getOptionLabel={(value: any) => (
            <>
              {value.text || value.title}
              <span style={{ position: "absolute", right: "5px" }}>
                <DocumentStatusBadge status={value.status} />
              </span>
            </>
          )}
          onKeyDown={(e: any) => {
            e.stopPropagation();
            if (e.key === "Escape") {
              onCancel();
            }
          }}
          placeholder={intl.formatMessage({ id: "add-related-act.modal.select-act.placeholder" })}
          isDisabled={mode === "edit"}
          autoFocus
        />
      </Modal.Body>

      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

export default AddRelatedActModal;
