import React from "react";
import img_questions from "../../assets/images/thumbnails/questions.png";
import "./sample-search-cards.scss";

type SampleSearchCardsProps = {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onSearch: (text: string) => void;
};

const SampleSearchCards: React.FC<SampleSearchCardsProps> = ({ setInputValue, onSearch }) => {
  const cards = [
    "Връщане на кредит, уговорен в чуждестранна валута",
    "Присъждане на законна лихва за забава при недействителен потребителски кредит",
    "Кога настъпва преклузията за възражението на ответника по иск за трансформация на лично имущество, че дарителят на ищеца (позоваващият се на трансформацията съпруг) не е разполагал с имуществото, което се твърди да е дарил?",
    "Прекъсва ли погасителната давност отхвърления отрицателен установителен иск за вземане, срещу който ответникът е направил само възражение по основателността?",
  ];

  const handleCardClick = (text: string) => {
    setInputValue(text);
    onSearch(text);
  };

  return (
    <div className="sample-search-cards">
      {cards.map((text, index) => (
        <div key={index} className="search-card" onClick={() => handleCardClick(text)}>
          <img src={img_questions} alt="Questions Thumbnail" className="card-thumbnail" />
          <div className="card-title">{text}</div>
        </div>
      ))}
    </div>
  );
};

export default SampleSearchCards;
