/* eslint-disable no-underscore-dangle */
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Skeleton from "../../components/Skeleton";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { useSummaryContext } from "../../contexts/summary.context";
import { judgementActHref } from "../../services/judgement-act-services";

const RelatedActsTab = () => {
  const { summary } = useSummaryContext();
  const { setSelectedActId } = useSelectedActContext();

  const relatedActs = useMemo(
    () =>
      [
        ...summary.qas.map((qa, idx) =>
          (qa.relatedJudgementActs || []).map((act) => ({
            label: act.title,
            value: act.id,
            actDate: act.actDate,
          }))
        ),
      ]
        .flat()
        .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i),
    [summary]
  );

  const citedActs = relatedActs.filter(
    (item) => (summary.relatedJudgementAct.actDate || "").localeCompare(item.actDate || "") > 0
  );

  const citedByActs = relatedActs.filter(
    (item) => (summary.relatedJudgementAct.actDate || "").localeCompare(item.actDate || "") <= 0
  );

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  return (
    <>
      {relatedActs.length > 0 ? (
        <>
          {citedActs.length > 0 && (
            <div className="section">
              <span className="section-title">
                <FaArrowCircleRight />
                <FormattedMessage
                  id="judgement-act.page.labels.references-to"
                  defaultMessage="Цитира:"
                />
              </span>
              <ul>
                {citedActs.map((relatedAct) => (
                  <li key={relatedAct.value}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${relatedAct.value}`}>{relatedAct.label}</Tooltip>
                      }
                    >
                      <Link
                        to={judgementActHref(relatedAct.value)}
                        onClick={(ev) => {
                          if (!ev.ctrlKey && !ev.metaKey) {
                            ev.preventDefault();
                            setSelectedActId(relatedAct.value);
                          }
                        }}
                        className={`link inline-link ${
                          !isMobile ? "text-overflow-ellipsis" : ""
                        } d-inline-block`}
                      >
                        {relatedAct.label}
                      </Link>
                    </OverlayTrigger>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {citedByActs.length > 0 && (
            <div className="section">
              <span className="section-title">
                <FaArrowCircleLeft />
                <FormattedMessage
                  id="judgement-act.page.labels.referenced-from"
                  defaultMessage="Цитиран от:"
                />
              </span>
              <ul>
                {citedByActs.map((relatedAct) => (
                  <li key={relatedAct.value}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${relatedAct.value}`}>{relatedAct.label}</Tooltip>
                      }
                    >
                      <Link
                        className={`link inline-link ${
                          !isMobile ? "text-overflow-ellipsis" : ""
                        } d-inline-block`}
                        to={judgementActHref(relatedAct.value)}
                        onClick={(ev) => {
                          if (!ev.ctrlKey && !ev.metaKey) {
                            ev.preventDefault();
                            setSelectedActId(relatedAct.value);
                          }
                        }}
                      >
                        {relatedAct.label}
                      </Link>
                    </OverlayTrigger>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <div style={{ marginLeft: "10px" }}>Няма свързани актове</div>
      )}
    </>
  );
};

export const RelatedActsTabLoading = () => {
  return <Skeleton count={5} />;
};

export default RelatedActsTab;
