/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { ListGroup, Nav, Offcanvas } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useDeviceSelectors } from "react-device-detect";
import {
  FaBookOpen,
  FaCog,
  FaHistory,
  FaLock,
  FaRegUserCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { RiSpyFill } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ConnectUser, hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import { releaseRunAs } from "../../services/auth.service";

const UserMenu = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  if (isMobileOnly) {
    return <UserMenuMobile />;
  }
  return (
    <ConnectUser>
      {({ setCurrentUser, currentUser }) => (
        <Dropdown align="end">
          <Dropdown.Toggle as={CustomToggle} align="end">
            {currentUser.initials}
            {currentUser.runAs && (
              <RiSpyFill style={{ verticalAlign: "bottom", fontSize: "0.8rem" }} />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu} className="avatar-menu">
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => navigate("/user/profile")}>
              <FaCog /> <FormattedMessage id="user.menu.item.profile" />
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/user/history/today")}>
              <FaHistory /> <FormattedMessage id="user.menu.item.opened-doc.history" />
            </Dropdown.Item>
            {currentUser.runAs && (
              <Dropdown.Item
                onClick={async () => {
                  try {
                    setCurrentUser(await releaseRunAs());
                    toast.info(
                      intl.formatMessage({ id: "user.menu.toasts.release-run-as.success" })
                    );
                    window.location.reload();
                  } catch (e) {
                    console.log(e);
                    toast.error(
                      intl.formatMessage({ id: "user.menu.toasts.release-run-as.error" })
                    );
                  }
                }}
              >
                <RiSpyFill /> <FormattedMessage id="user.menu.item.relase-run-as" />
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={async () => {
                setCurrentUser(null);
                navigate("/");
                toast.info(intl.formatMessage({ id: "user.menu.toasts.logout.success" }));
              }}
              disabled={currentUser.runAs}
            >
              <FaSignOutAlt className={`${currentUser.runAs ? "text-muted" : ""}`} />{" "}
              <FormattedMessage id="user.menu.item.logout" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ConnectUser>
  );
};

const UserMenuMobile = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  useBodyScrollLock(opened);
  return (
    <ConnectUser>
      {({ setCurrentUser, currentUser }) => (
        <>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setOpened(true);
            }}
            className="nav-link"
            style={{ fontSize: "1.4rem", color: "var(--app-navbar-light-link)" }}
          >
            <div className="link-body">
              <FaRegUserCircle />
              <span className="nav-link-label">Профил</span>
            </div>
          </a>
          <Offcanvas
            show={opened}
            onHide={() => setOpened(false)}
            placement="bottom"
            backdrop
            id="user-menu-modal"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {currentUser.firstName} {currentUser.lastName}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ListGroup variant="flush">
                <ListGroup.Item
                  onClick={() => navigate("/user/profile")}
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <FaCog />
                  <FormattedMessage id="user.menu.item.profile" />
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => navigate("/user/history/today")}
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <FaHistory /> <FormattedMessage id="user.menu.item.opened-doc.history" />
                </ListGroup.Item>
                {currentUser.runAs && (
                  <ListGroup.Item
                    onClick={async () => {
                      try {
                        setCurrentUser(await releaseRunAs());
                        toast.info(
                          intl.formatMessage({ id: "user.menu.toasts.release-run-as.success" })
                        );
                        window.location.reload();
                      } catch (e) {
                        console.log(e);
                        toast.error(
                          intl.formatMessage({ id: "user.menu.toasts.release-run-as.error" })
                        );
                      }
                    }}
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <RiSpyFill /> <FormattedMessage id="user.menu.item.relase-run-as" />
                  </ListGroup.Item>
                )}

                <Visible when={hasPrivilege(Privileges.EDIT_USERS)}>
                  <ListGroup.Item
                    className="d-flex align-items-center"
                    onClick={() => navigate("/admin/users")}
                  >
                    <div className="link-body">
                      <FaLock /> Администрация
                    </div>
                  </ListGroup.Item>
                </Visible>

                <Visible when={hasPrivilege(Privileges.VIEW_COLLECTIONS)}>
                  <ListGroup.Item
                    className="d-flex align-items-center"
                    onClick={() => navigate("/collections")}
                  >
                    <div className="link-body">
                      <FaBookOpen /> Колекции
                    </div>
                  </ListGroup.Item>
                </Visible>

                <ListGroup.Item
                  className={`d-flex align-items-center ${currentUser.runAs ? "text-muted" : ""}`}
                  style={{ gap: "5px" }}
                  onClick={async () => {
                    if (!currentUser.runAs) {
                      setCurrentUser(null);
                      navigate("/");
                      toast.info(intl.formatMessage({ id: "user.menu.toasts.logout.success" }));
                    }
                  }}
                >
                  <FaSignOutAlt /> <FormattedMessage id="user.menu.item.logout" />
                </ListGroup.Item>
              </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </ConnectUser>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Nav.Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="avatar">{children}</div>
      <IoIosArrowDown />
    </Nav.Link>
  );
});

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        data-bs-popper="static"
      >
        <div className="p">
          <FormattedMessage id="user.menu.item.current-user" />
          <ConnectUser>
            {({ currentUser }) => (
              <div
                className="fw-bold"
                style={{ whiteSpace: "nowrap" }}
              >{`${currentUser.firstName} ${currentUser.lastName}`}</div>
            )}
          </ConnectUser>
        </div>
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

export default UserMenu;
