import { Offcanvas } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import BulletinQuickViewPage from "../BulletinPage/bulletin-quick-view-page";
import JudgementQuickViewPage from "../JudgementPage/judgement-quick-view-page";
import NewsArticleQuickViewPage from "../NewsArticlePage/news-article-quick-view-page";
import ProvisionQuickViewPage from "../SummaryViewPage/provision-quick-view-page";
import SummaryQuickViewPage from "../SummaryViewPage/summary-quick-view-page";

const JudgementActQuickView = () => {
  const { selectedActId, setSelectedActId } = useSelectedActContext();

  useBodyScrollLock(!!selectedActId);
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  return (
    <>
      {(selectedActId || []).map(({ actId: id, uuid }, idx) => {
        return (
          <Offcanvas
            id={`quick-view-drawer-${uuid}`}
            key={uuid}
            show={!!id}
            onHide={() => setSelectedActId(undefined)}
            backdrop
            scroll
            placement={isMobileOnly ? "bottom" : "end"}
            className={`quick-view-drawer-${idx} ${isMobileOnly ? "mobile-view" : ""}`}
            backdropClassName={`quick-view-drawer-overlay-${id}`}
            restoreFocus={false}
          >
            <Offcanvas.Body>
              {id?.startsWith("summary:") ? (
                <SummaryQuickViewPage
                  summaryId={id.substring(8)}
                  onClose={() => setSelectedActId(undefined)}
                />
              ) : id?.startsWith("provision:") ? (
                <ProvisionQuickViewPage
                  provisionId={id.substring(10)}
                  onClose={() => setSelectedActId(undefined)}
                />
              ) : id?.startsWith("article:") ? (
                <NewsArticleQuickViewPage
                  articleId={id.substring(8)}
                  onClose={() => setSelectedActId(undefined)}
                />
              ) : id?.startsWith("bulletin:") ? (
                <BulletinQuickViewPage
                  bulletinId={id.substring(9)}
                  onClose={() => setSelectedActId(undefined)}
                />
              ) : (
                <JudgementQuickViewPage
                  id={uuid}
                  actId={id!}
                  onClose={() => setSelectedActId(undefined)}
                />
              )}
            </Offcanvas.Body>
          </Offcanvas>
        );
      })}
    </>
  );
};

export default JudgementActQuickView;
