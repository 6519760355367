import "./skeleton.css";

type SkeletonProps = {
  count?: number;
  style?: SkeletonStyleProps;
  height?: string | number;
};

type SkeletonStyleProps = {
  baseColor?: string;
  highlightColor?: string;

  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  inline?: boolean;

  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;

  duration?: number;
  direction?: "ltr" | "rtl";
  enableAnimation?: boolean;
};

const Skeleton: React.FC<SkeletonProps> = ({ count = 1, style, height }) => {
  return (
    <>
      {Array.from(Array(count).keys()).map((num) => (
        <span className="skeleton" key={num} style={{ ...style, height }}>
          &zwnj;
        </span>
      ))}
    </>
  );
};

export default Skeleton;
