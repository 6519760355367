import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { FaRegClone } from "react-icons/fa";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import LabelValue from "../../components/label-value";
import PreloadingLink from "../../components/PreloadingLink";
import { useViewEditPageContext } from "../../contexts/view-edit-page.context";
import { SubscriptionBeneficiary } from "../../models/SubscriptionBeneficiary.model";
import SubscriptionBeneficiaryStatus from "../../models/SubscriptionBeneficiaryStatus.enum";
import { prefetchUser } from "../../services/user-services";
import AddBeneficiaryModal, { SubscriptionBeneficiaryModalMode } from "./add-beneficiary.modal";

const BeneficiariesTable: React.FC<{
  value: SubscriptionBeneficiary[];
  append: (data: SubscriptionBeneficiary) => void;
  remove: (idx: number) => void;
  replace: (idx: number, data: SubscriptionBeneficiary) => void;
  prefix: string;
}> = ({ value, append, remove, replace, prefix }) => {
  const intl = useIntl();

  const [mode, setMode] = useState<SubscriptionBeneficiaryModalMode>("hidden");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<SubscriptionBeneficiary>();
  const { mode: pageMode } = useViewEditPageContext();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success(`Кодът за регистрация бе копиран в клипборда`);
      },
      () => {
        toast.success(`Грешка при копиране в клипборда`);
      }
    );
  };

  return (
    <Tabs activeKey={"0"} className={`sub-section-tabs ${pageMode === "edit" ? "editable" : ""}`}>
      <Tab
        eventKey="0"
        disabled
        title={<FormattedMessage id="edit-subscription.form.beneficiaries" />}
      >
        {value.length === 0 ? (
          <span style={{ padding: "12px" }}>
            <FormattedMessage id="edit-subscription.form.beneficiaries.no-data" />
          </span>
        ) : (
          <Table responsive style={{ width: "100%" }} className="sub-section-table">
            <colgroup>
              <col span={1} />
              {/* <col span={1} style={{ width: "87px" }} /> */}
            </colgroup>

            <tbody>
              {[...value].map((v, i) => (
                <tr key={i}>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedBeneficiary(v);
                      setMode("edit");
                    }}
                  >
                    <div className="d-flex w-100">
                      <span>
                        {v.registrationCode}
                        <span>
                          &nbsp;
                          <FaRegClone
                            onClick={async (e: any) => {
                              e.stopPropagation();
                              await copyToClipboard(v.registrationCode);
                            }}
                          />
                        </span>

                        {v.email && (
                          <LabelValue
                            label={intl.formatMessage({
                              id: "subcription-edit-page.labels.beneficiaries.user",
                            })}
                          >
                            {v.userId ? (
                              <PreloadingLink
                                to={`/admin/users/${v.userId}`}
                                state={{ title: v.firstName + " " + v.lastName }}
                                preload={(cache) => prefetchUser(v.userId, cache)}
                              >
                                {v.email}({v.firstName} {v.lastName})
                              </PreloadingLink>
                            ) : (
                              <>
                                {v.email}({v.firstName} {v.lastName})
                              </>
                            )}
                          </LabelValue>
                        )}
                        {v.confirmedOn ? (
                          <LabelValue
                            label={intl.formatMessage({
                              id: "subcription-edit-page.labels.beneficiaries.confirmedOn",
                            })}
                          >
                            <FormattedDate
                              value={v.confirmedOn}
                              year="numeric"
                              month="long"
                              day="numeric"
                              hour="numeric"
                              minute="2-digit"
                            />
                          </LabelValue>
                        ) : v.activatedOn ? (
                          <LabelValue
                            label={intl.formatMessage({
                              id: "subcription-edit-page.labels.beneficiaries.activatedOn",
                            })}
                          >
                            <FormattedDate
                              value={v.activatedOn}
                              year="numeric"
                              month="long"
                              day="numeric"
                              hour="numeric"
                              minute="2-digit"
                            />
                          </LabelValue>
                        ) : (
                          <LabelValue
                            label={intl.formatMessage({
                              id: "subcription-edit-page.labels.beneficiaries.createdOn",
                            })}
                          >
                            <FormattedDate
                              value={v.createdOn}
                              year="numeric"
                              month="long"
                              day="numeric"
                              hour="numeric"
                              minute="2-digit"
                            />
                          </LabelValue>
                        )}
                      </span>
                      <span className="ms-auto">
                        <Badge
                          bg={
                            v.status === SubscriptionBeneficiaryStatus.CONFIRMED
                              ? "success"
                              : v.status === SubscriptionBeneficiaryStatus.PENDING_CONFIRMATION
                              ? "warning"
                              : "danger"
                          }
                        >
                          <FormattedMessage id={`subscription-beneficiary.status.${v.status}`} />
                        </Badge>
                      </span>
                    </div>
                  </td>
                  {/* <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        const idx = value.findIndex(
                          (existingBeneficiary) => existingBeneficiary.id === v.id
                        );
                        remove(idx);
                      }}
                      title={intl.formatMessage({ id: "button.remove" })}
                      className="remove-link"
                    >
                      <FaTimes />
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Tab>
      {
        /*pageMode === "edit"*/ 1 > 2 && (
          <Tab
            eventKey="-1"
            title={
              <AddBeneficiaryModal
                append={(beneficiary) => {
                  const idx = value.findIndex(
                    (existingBeneficiary) => existingBeneficiary.id === beneficiary.id
                  );
                  if (idx > -1) {
                    replace(idx, { ...beneficiary });
                  } else {
                    append(beneficiary);
                  }
                }}
                beneficiary={selectedBeneficiary}
                setSelectedBeneficiary={setSelectedBeneficiary}
                mode={mode}
                setMode={setMode}
                prefix={prefix}
              />
            }
          />
        )
      }
    </Tabs>
  );
};

export default BeneficiariesTable;
