import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

export type SelectedActRef = {
  actId: string;
  uuid: string;
};

export type SelectedActContextType = {
  selectedActId?: SelectedActRef[];
  setSelectedActId: (actId?: string) => void;
};

export const SelctedActContext = createContext<SelectedActContextType>({
  selectedActId: [],
  setSelectedActId: () => {},
});

export const SelctedActContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [selectedActId, setSelectedActId] = useState<SelectedActRef[]>();

  const select = useCallback((id?: string) => {
    if (id) {
      setSelectedActId((oldVal) => {
        const newVal = [...(oldVal || [])];
        newVal.unshift({ actId: id, uuid: uuid() });
        return newVal;
      });
    } else {
      setSelectedActId((oldVal) => {
        const newVal = [...(oldVal || [])];
        newVal.shift();
        return newVal.length ? newVal : undefined;
      });
    }
  }, []);

  useEffect(() => {
    const listener = (event: Event) => {
      select((event as any).detail.id);
    };
    document.addEventListener("selectedAct", listener);

    return () => document.removeEventListener("selectedAct", listener);
  }, [select]);

  return (
    <SelctedActContext.Provider
      value={{
        selectedActId: selectedActId,
        setSelectedActId: select,
      }}
    >
      {children}
    </SelctedActContext.Provider>
  );
};

export const useSelectedActContext = () => useContext(SelctedActContext);

export default SelctedActContextProvider;
