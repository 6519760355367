import React from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useNavigate } from "react-router";
import { Link, LinkProps } from "react-router-dom";
import { Cache, useSWRConfig } from "swr";
import { Privileges, useAuth, useProtectedAction } from "../../contexts/auth.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";

const PreloadingLink: React.FC<
  React.PropsWithoutRef<LinkProps & React.RefAttributes<HTMLAnchorElement>> & {
    preload: (cache: Cache<any>) => Promise<any>;
    event?: "onMouseEnter" | "onClick";
    showBar?: boolean;
    protect?: boolean;
  }
> = ({ preload, onClick, showBar = true, event = "onClick", protect = true, ...props }) => {
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const progress = useLoadingProgress();
  const protectedAction = useProtectedAction(!protect);
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  const { isLoginSessionActive, hasPrivilige } = useAuth();
  const restrictedAccess = !isLoginSessionActive() || !hasPrivilige(Privileges.VIEW_DOCUMENTS);

  const ev = isMobile || restrictedAccess ? "onClick" : event;
  return (
    <Link
      {...props}
      {...{
        [ev]: protectedAction(async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (!e.ctrlKey && !e.metaKey) {
            e.preventDefault();
            const isOnClick = ev === "onClick";

            isOnClick && progress.start();

            try {
              await preload(cache);
            } finally {
              isOnClick && progress.complete();
            }
            if (isOnClick && onClick) {
              onClick(e);
            }
            isOnClick && navigate(props.to as string, { state: props.state });
          }
        }),
      }}
    >
      {props.children}
    </Link>
  );
};

export default PreloadingLink;
