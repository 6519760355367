/* eslint-disable no-underscore-dangle */
import { useMemo } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaBars } from "react-icons/fa";
import { useIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import Collapse from "../../components/SimpleCollapse";
import Skeleton from "../../components/Skeleton";
import DocumentPageLayout, { DocumentPageLayoutProps } from "../DocumentPage/document-page-layout";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import SummaryPageNavigationLoading from "../SummaryPage/summary-page-navigation-loading";
import "./summary-view-page.scss";

const SummaryViewPageLoading: React.FC<DocumentPageLayoutProps> = (props) => {
  return (
    <DocumentPageLayout {...props} navigation={<SummaryPageNavigationLoading mode={props.mode} />}>
      <h5>
        <Skeleton count={1} />
      </h5>
      <h6>
        <Skeleton count={1} />
      </h6>
      <Row>
        <Col md={5}>
          <ProvisionsCardLoading />
        </Col>
        <Col md={7}>
          <MainInfoCardLoading />
        </Col>
      </Row>

      <QuestionCardLoading />
    </DocumentPageLayout>
  );
};

export const SummaryQuickViewPageLoading: React.FC<{ onClose: () => void }> = (props) => {
  const id = useMemo(() => uuid(), []);
  return (
    <DocumentQuickViewLayout onClose={props.onClose} id={id}>
      <div className="w-100">
        <h5>
          <Skeleton count={1} />
        </h5>
        <h6>
          <Skeleton count={1} />
        </h6>
        <Row>
          <Col md={5}>
            <ProvisionsCardLoading />
          </Col>
          <Col md={7}>
            <MainInfoCardLoading />
          </Col>
        </Row>

        <QuestionCardLoading />
      </div>
    </DocumentQuickViewLayout>
  );
};

const ProvisionsCardLoading = () => (
  <Card className="info-card">
    <Card.Body>
      <Skeleton count={5} />
    </Card.Body>
  </Card>
);

const MainInfoCardLoading = () => (
  <Card className="info-card">
    <Card.Body>
      <Skeleton count={5} />
    </Card.Body>
  </Card>
);

const QuestionCardLoading = () => {
  const intl = useIntl();
  return (
    <>
      <Card className="highlight-card">
        <Card.Body>
          <Skeleton count={3} />
        </Card.Body>
      </Card>
      <br />
      <div className="filters-sidebar">
        <Collapse
          icon={<FaBars />}
          label={intl.formatMessage({ id: "view-summary.labels.arguments" })}
          defaultExpanded={false}
        >
          <div className="act-text">
            <Skeleton count={8} />
          </div>
        </Collapse>
        <Collapse
          icon={<FaBars />}
          label={intl.formatMessage({ id: "view-summary.labels.answer" })}
        >
          <div className="act-text">
            <Skeleton count={8} />
          </div>
        </Collapse>
        <Collapse
          icon={<FaBars />}
          label={intl.formatMessage({ id: "view-summary.labels.relatedJudgementActs" })}
          defaultExpanded={false}
        >
          <Skeleton count={4} />
        </Collapse>
      </div>
    </>
  );
};

export default SummaryViewPageLoading;
