import { InlineLinkButton } from "../../../components/Button/button";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import { isQuoted, unquote } from "../search-utils";

const ResultsTableEmpty = () => {
  const quoteQueryFilter = (idx: number, value: any[], onChange: (value: any[]) => void) => {
    const newValue = [...value];
    newValue[idx] = isQuoted(newValue[idx])
      ? newValue[idx].substring(1, newValue[idx].length - 1)
      : `"${newValue[idx]}"`;
    onChange(newValue);
  };

  return (
    <SearchFieldController
      name="query"
      render={({ field: { onChange, value } }) => {
        const idxToUnquote = findLastIndex(value, (v) => isQuoted(v));
        return (
          <>
            Няма резултати, съответстващи на критериите за търсене
            {value && idxToUnquote > -1 && (
              <>
                <br />
                Изпълнете търсене с{" "}
                <InlineLinkButton onClick={() => quoteQueryFilter(idxToUnquote, value, onChange)}>
                  {unquote(value[idxToUnquote])}
                </InlineLinkButton>{" "}
                (без кавички)
              </>
            )}
          </>
        );
      }}
    ></SearchFieldController>
  );
};

export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array?.length || 0;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export default ResultsTableEmpty;
