import Modal from "react-bootstrap/Modal";
import { ActionButton, SecondaryButton } from "../../components/Button/button";

type ConfirmModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  text: string;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({ onClose, onConfirm, title, text }) => {
  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onClose} />
      <ActionButton messageId="button.confirm" onClick={onConfirm} submitting={false} />
    </>
  );

  return (
    <Modal show={true} centered size="sm" onEscapeKeyDown={onClose} animation={false}>
      <Modal.Header style={{ alignItems: "center", padding: "0.6rem" }}>
        <div>{title}</div>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        {" "}
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
