/* eslint-disable react/jsx-props-no-spreading */
import Container from "react-bootstrap/Container";
import { VscMenu } from "react-icons/vsc";
import { FormattedMessage } from "react-intl";
import Collapse from "../../components/SimpleCollapse";
import { withAuth } from "../../contexts/auth.context";
import "../SearchPage/FiltersSidebar/filters-sidebar.css";
import FiltersSideBarLoading from "./filters-sidebar-loading";
import StandardFilters from "./standard-filters";

const FiltersSideBar = () => {
  return (
    <Container fluid className="filters-sidebar">
      <Collapse
        icon={<VscMenu />}
        label={<FormattedMessage id="filters-sidebar.filters" />}
        defaultExpanded
      >
        <StandardFilters />
      </Collapse>
    </Container>
  );
};

export default withAuth(FiltersSideBar, FiltersSideBarLoading);
