import { useCallback, useRef, useState } from "react";
import { Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { isMobileOnly, MobileOnlyView } from "react-device-detect";
import { FaAngleRight } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useSwipeable } from "react-swipeable";
import { useTitle } from "react-use";
import { PrimaryButton } from "../../components/Button/button";
import ScrollTopArrow from "../../components/ScrollTopArrow";
import NavigationDrawerContextProvider from "../../contexts/navigation-drawer.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import "./app-page-layout.scss";

export type AppPageLayoutProps = {
  title?: string;
  className?: string;
  navigation?: React.ReactNode;
  navigationDrawerHeader?: (setIsOpen: (isOpen: boolean) => void) => React.ReactNode;
  copyrighted?: boolean;
};

const AppPageLayout: React.FC<AppPageLayoutProps> = ({
  title,
  navigation,
  navigationDrawerHeader,
  className = "",
  copyrighted = false,
  children,
}) => {
  const intl = useIntl();
  const tabTitle = title || intl.formatMessage({ id: "loading.page" });
  useTitle(tabTitle);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const setOpened = useCallback(
    (opened) => {
      setIsOpen(opened);
      // if (isMobileOnly) {
      //   if (opened) {
      //     setTimeout(() => (ref.current!.style.display = "none"), 400);
      //   } else {
      //     ref.current!.style.display = "block";
      //   }
      // }
    },
    [setIsOpen]
  );

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => setOpened(true),
  });
  const navHandlers = useSwipeable({
    onSwipedLeft: (eventData) => setOpened(false),
  });

  useBodyScrollLock(isOpen);
  return (
    <Container fluid className={`app-page ${className}`}>
      <Row className="flex-nowrap">
        <Col xl={3} className="d-none d-xl-block app-page-navigation">
          {navigation}
        </Col>
        <Col xl={8} md={12} className="p-0  d-flex" ref={ref}>
          <div className="w-100 d-flex flex-column">
            <div className="w-100 d-flex">
              <Container fluid className="app-page-main-content m-0 me-1" {...handlers}>
                {children}
              </Container>
              <div id="page-map">
                <canvas id="map"></canvas>
              </div>
            </div>
            {copyrighted && (
              <OverlayTrigger
                placement="top"
                overlay={(props) => (
                  <Tooltip id="copyright-tooltip" {...props}>
                    Всички права върху извеждането, структурирането и оформлението на резюметата са
                    изключително притежание на Лексебра ООД.
                  </Tooltip>
                )}
              >
                <div className="text-muted copyright">
                  &copy; {new Date().getFullYear()} Лексебра ООД. Всички права запазени
                </div>
              </OverlayTrigger>
            )}
          </div>
        </Col>
        {navigation && (
          <NavigationDrawerContextProvider setIsOpen={setOpened}>
            {title !== "Търсене" && (
              <div className="drawer-handle d-xl-none" onClick={() => setOpened(true)}>
                <FaAngleRight className="open-drawer-icon" />
              </div>
            )}
            <Offcanvas
              id="navigation-drawer"
              show={isOpen}
              onHide={() => setOpened(false)}
              backdrop
              placement="start"
              scroll
              className={`d-block d-xl-none navigation-drawer ${isMobileOnly ? "mobile-view" : ""}`}
            >
              <Offcanvas.Header>
                <MobileOnlyView className="d-flex w-100">
                  {navigationDrawerHeader ? (
                    navigationDrawerHeader(setOpened)
                  ) : (
                    <PrimaryButton onClick={() => setOpened(false)} className="ms-auto me-auto">
                      Обратно към документа
                    </PrimaryButton>
                  )}
                </MobileOnlyView>
              </Offcanvas.Header>
              <Offcanvas.Body {...navHandlers}>{navigation}</Offcanvas.Body>
            </Offcanvas>
          </NavigationDrawerContextProvider>
        )}
        <ScrollTopArrow attachToWindow />
      </Row>
    </Container>
  );
};

export default AppPageLayout;
