import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { StatsActsPeriod } from "../../models/StatsActsPeriod.model";

const StatsActsEditorCareChart: React.FC<{
  monthStats?: StatsActsPeriod[];
  xAxisLabel: string;
}> = ({ monthStats, xAxisLabel }) => {
  const intl = useIntl();

  return (
    <>
      <h5 className="mt-5 text-center">
        <FormattedMessage id="stats.header.editor-care" />
      </h5>
      <div className="stats-container">
        {!monthStats && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="stats-loading-spinner"
          />
        )}
        <ResponsiveContainer width={"99%"} height={300}>
          <LineChart
            data={(monthStats || []).filter((m) => m.role === "item")}
            accessibilityLayer
            width={500}
            height={300}
            {...{
              overflow: "visible",
            }}
            margin={{
              top: 0,
              right: 30,
              left: 50,
              bottom: 10,
            }}
            style={{
              filter: !monthStats && "blur(3px)",
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey={(entry) => {
                return entry.type === "month"
                  ? dayjs(entry.period, "YYYY/MM").format("MMM")
                  : dayjs(entry.period, "YYYY/MM/DD").format("D");
              }}
            >
              <Label value={xAxisLabel} position="bottom" style={{ textAnchor: "middle" }} />
            </XAxis>
            <YAxis scale="sqrt">
              <Label
                angle={-90}
                value={intl.formatMessage({ id: "stats.label.count" })}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Legend verticalAlign="top" height={36} />

            <Line
              type="monotone"
              dataKey="stats.countFocusedSummaries"
              stroke="#edbf33"
              name={intl.formatMessage({ id: "stats.label.focused" })}
            />
            <Line
              type="monotone"
              dataKey="stats.countSummarized"
              stroke="#b33dc6"
              name={intl.formatMessage({ id: "stats.label.summarized" })}
            />
            <Line
              type="monotone"
              dataKey="stats.countHiglighted"
              stroke="#bdcf32"
              name={intl.formatMessage({ id: "stats.label.highlighted" })}
            />
            <Tooltip itemSorter={(item) => (item.value as number) * -1} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default StatsActsEditorCareChart;
