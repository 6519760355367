import dayjs from "dayjs";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { FileUpload, useFileUpload } from "use-file-upload";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { Subscription } from "../../models/Subscription.model";
import { Invoice } from "../../services/subscription-services";

type AttachInvoiceFormProps = {
  proforma?: boolean;
  period?: boolean;
  subscription: Subscription;
  onAttach: (
    data?: Invoice & {
      licenseCount: number;
      discount: number;
    }
  ) => void;
};

const AttachInvoiceForm: React.FC<AttachInvoiceFormProps> = ({
  subscription,
  onAttach,
  proforma = false,
  period = false,
}) => {
  const { register, watch, control } = useForm<
    Invoice & {
      licenseCount: number;
      discount: number;
    }
  >({
    defaultValues: {
      proforma,
      licenseCount: subscription.licenseCount,
      discount: subscription.discount,
      issueNumber: "",
      issueDate: new Date(),
    },
  });

  const [files, selectFiles] = useFileUpload();

  const formData = watch();

  return (
    <Form className="edit-form" autoComplete="off">
      <Row>
        <Col md="5">
          <Form.Group controlId="reference">
            <Form.Label>
              {proforma ? (
                <FormattedMessage id="attach-invoice.form.reference-proforma" />
              ) : (
                <FormattedMessage id="attach-invoice.form.reference" />
              )}
            </Form.Label>
            <Form.Control
              type="text"
              {...register("issueNumber")}
              onBlur={() => {
                if (!formData.issueNumber) {
                  onAttach(undefined);
                }
              }}
            />
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group controlId="amount">
            <Form.Label>
              <FormattedMessage id="attach-invoice.form.amount" />
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                {...register("amount")}
                onBlur={() => {
                  if (!formData.issueNumber) {
                    onAttach(undefined);
                  }
                }}
              />
              <InputGroup.Text>лв.</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="issueDate">
            <Form.Label>
              <FormattedMessage id="attach-invoice.form.issueDate" />
            </Form.Label>
            <div>
              <Controller
                name="issueDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DayPickerInput
                    value={value}
                    formatDate={(value, format) => dayjs(value).format(format)}
                    format={"DD.MM.YYYY"}
                    dayPickerProps={{
                      months: dayjs.months(),
                      weekdaysShort: dayjs.weekdaysMin(true),
                      weekdaysLong: dayjs.weekdays(true),
                    }}
                    onDayChange={onChange}
                    placeholder="дд.мм.гггг"
                    inputProps={{
                      className: "form-control",
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Form.Group controlId="licenseCount">
            <Form.Label>
              <FormattedMessage id="attach-invoice.form.licenseCount" />
            </Form.Label>
            <Controller
              name="licenseCount"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectSingle
                  options={Array.from({ length: 50 }, (_, i) => i + 1)}
                  getOption={(option) => ({
                    label: `${option}`,
                    value: option,
                  })}
                  value={value}
                  onChange={onChange}
                  isDisabled={!proforma || period}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="regularPrice">
            <Form.Label>
              <FormattedMessage id="generate-invoice.form.regularPrice" />
            </Form.Label>
            <Form.Control type="text" value={subscription.plan.price} readOnly />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="discount">
            <Form.Label>
              <FormattedMessage id="attach-invoice.form.discount" />
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control type="text" {...register("discount")} disabled={!proforma || period} />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form.Group controlId="file">
            <Form.Label>
              <FormattedMessage id="start-subscription.modal.form.file" />
            </Form.Label>
            <div>
              {files && <span className="me-2">{(files as FileUpload).name}</span>}
              <Button
                size="sm"
                disabled={!formData.issueNumber || !formData.amount}
                onClick={() =>
                  selectFiles({ accept: ".pdf", multiple: false }, async (selection) => {
                    const file = selection as FileUpload;
                    const contents = await toBase64(file.file);
                    onAttach({
                      ...formData,
                      contents,
                      fileName: file.name,
                    });
                  })
                }
              >
                <FormattedMessage id="button.choose-file" />
              </Button>
              {(!formData.issueNumber || !formData.amount) && (
                <div>За да можете да прикачите файл, моля попълнете полетата от формата.</div>
              )}
            </div>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default AttachInvoiceForm;
