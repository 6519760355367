import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { PasswordResetRequest } from "../models/PasswordResetRequest.model";
import PasswordResetRequestStatus from "../models/PasswordResetRequestStatus.enum";

const PASSWORD_RESET_REQUESTS_BASE_URL = "/password-reset-requests";

export type CreatePasswordResetRequestInput = {
  email: string;
};

export type UpdatePasswordResetRequestStatusInput = {
  status: PasswordResetRequestStatus;
  password: string;
  passwordConfirm: string;
};

export const usePasswordResetRequests = () =>
  useSearchRequest<PasswordResetRequest>(PASSWORD_RESET_REQUESTS_BASE_URL);

export const prefetchPasswordResetRequests = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(PASSWORD_RESET_REQUESTS_BASE_URL, searchParams, cache);

export const usePasswordResetRequest = (id?: string) => {
  const { data } = useRequest<EntityModel<PasswordResetRequest>>(
    id ? `${PASSWORD_RESET_REQUESTS_BASE_URL}/${id}` : null
  );
  return data;
};

export const fetchPasswordResetRequest = (id: string, validate: boolean) => {
  return get<EntityModel<PasswordResetRequest>>(
    `${PASSWORD_RESET_REQUESTS_BASE_URL}/${id}?validate=${validate}`
  );
};

export const prefetchPasswordResetRequest = (id: string, cache: Cache<any>) =>
  prefetch(`${PASSWORD_RESET_REQUESTS_BASE_URL}/${id}`, cache);

export const createPasswordResetRequest = async (data: CreatePasswordResetRequestInput) => {
  return await post(`${PASSWORD_RESET_REQUESTS_BASE_URL}`, data);
};

export const reCreatePasswordResetRequest = async (expiredId: string) => {
  const request = await fetchPasswordResetRequest(expiredId, false);
  return await post(`${PASSWORD_RESET_REQUESTS_BASE_URL}`, {
    email: request.user.email,
  });
};

export const updatePasswordResetRequestStatus = async (
  resetRequestId: string,
  input: UpdatePasswordResetRequestStatusInput
) => {
  const result = await put(`${PASSWORD_RESET_REQUESTS_BASE_URL}/${resetRequestId}/status`, input);
  mutate(`${PASSWORD_RESET_REQUESTS_BASE_URL}/${resetRequestId}`, () => result, false);
  return result;
};

export const passwordResetRequestHref = (id: string): string =>
  `${PASSWORD_RESET_REQUESTS_BASE_URL}/${id}`;
