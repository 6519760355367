import { useEffect, useState } from "react";
import { Form, Offcanvas, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";
import { useDeviceSelectors } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { FaDownload } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage, useIntl } from "react-intl";
import { ActionButton, InlineLinkButton } from "../../components/Button/button";
import { useProtectedAction } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import { ExportDocumentOptions } from "./document-collection-actions";
import "./export.scss";

const BTN_ID = "export-btn";

const ExportDocument: React.FC<{
  popover?: boolean;
  handleExport: (options?: ExportDocumentOptions) => void;
}> = ({ popover, handleExport }) => {
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { selectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();
  if (popover) {
    return (
      <Button
        className="inline-link"
        key="1"
        size="sm"
        variant="link"
        onClick={() => {
          (window.document.querySelector(`#${BTN_ID}`) as HTMLElement).click();
        }}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-export">
              {formatMessage({ id: "document-actions.exportPdf" })}
            </Tooltip>
          }
        >
          <span>
            <FaDownload />
          </span>
        </OverlayTrigger>
      </Button>
    );
  }
  return isMobileOnly ? (
    <ExportModal handleExport={handleExport} />
  ) : (
    <ExportPopover handleExport={handleExport} />
  );
};

const ExportModal: React.FC<{ handleExport: (options?: ExportDocumentOptions) => void }> = ({
  handleExport,
}) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  useBodyScrollLock(showModal);
  const protectedAction = useProtectedAction();
  const { selectedActId } = useSelectedActContext();
  return (
    <>
      <Button
        variant="link"
        size="sm"
        className={`inline-link ${BTN_ID}`}
        onClick={protectedAction(() => setShowModal(!showModal))}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-export">
              {formatMessage({ id: "document-actions.exportPdf" })}
            </Tooltip>
          }
        >
          <span>
            <FaDownload />
          </span>
        </OverlayTrigger>
      </Button>
      <Offcanvas
        show={showModal}
        onHide={() => setShowModal(false)}
        placement="bottom"
        backdrop
        id="export-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FormattedMessage id="document-actions.exportPdf" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ExportPanel opened={showModal} setOpened={setShowModal} handleExport={handleExport} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const ExportPopover: React.FC<{ handleExport: (options?: ExportDocumentOptions) => void }> = ({
  handleExport,
}) => {
  const { formatMessage } = useIntl();

  const [showPopover, setShowPopover] = useState(false);

  const { selectedActId } = useSelectedActContext();
  const popover = (
    <Popover
      id={selectedActId ? "export-popover-quick" : "export-popover"}
      className={`shadow generic-popover ${showPopover ? "" : "d-none"}`}
      popper={{
        strategy: selectedActId ? "absolute" : "fixed",
      }}
    >
      <Popover.Header className="d-flex">
        <FormattedMessage id="document-actions.exportPdf" />
        <InlineLinkButton onClick={() => setShowPopover(false)} className="ms-auto">
          <IoMdClose />
        </InlineLinkButton>
      </Popover.Header>
      <Popover.Body>
        <ExportPanel opened={showPopover} setOpened={setShowPopover} handleExport={handleExport} />
      </Popover.Body>
    </Popover>
  );

  const protectedAction = useProtectedAction();

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [34, 10],
              },
            },
          ],
        }}
        overlay={popover}
        show={true}
        rootClose
      >
        <Button
          variant="link"
          size="sm"
          id={BTN_ID}
          className={`inline-link ${BTN_ID}`}
          onClick={protectedAction(() => setShowPopover(!showPopover))}
        >
          <OverlayTrigger
            placement={selectedActId ? "bottom" : "top"}
            overlay={
              <Tooltip id="doc-action-export">
                {formatMessage({ id: "document-actions.exportPdf" })}
              </Tooltip>
            }
          >
            <span>
              <FaDownload />
            </span>
          </OverlayTrigger>
        </Button>
      </OverlayTrigger>
    </>
  );
};

const ExportPanel: React.FC<{
  opened: boolean;
  setOpened: (opened: boolean) => void;
  handleExport: (options?: ExportDocumentOptions) => void;
}> = ({ opened, setOpened, handleExport }) => {
  useHotkeys(
    "Escape",
    () => {
      setOpened(false);
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<{ includeUserAnnotations: boolean; includeEditorAnnotations: boolean }>({
    defaultValues: {
      includeUserAnnotations: false,
      includeEditorAnnotations: false,
    },
  });

  useEffect(() => {
    !opened && reset();
  }, [opened, reset]);

  return (
    <Form className="edit-form">
      <Form.Group controlId="includeUserAnnotations">
        <Form.Label>
          <FormattedMessage id="export.form.includeUserAnnotations" />
        </Form.Label>
        <Controller
          name="includeUserAnnotations"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Check type="switch" onChange={onChange} checked={value} autoComplete="off" />
          )}
        />
      </Form.Group>
      <Form.Group controlId="includeEditorAnnotations">
        <Form.Label>
          <FormattedMessage id="export.form.includeEditorAnnotations" />
        </Form.Label>
        <Controller
          name="includeEditorAnnotations"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Check
              type="switch"
              onChange={onChange}
              checked={value}
              autoComplete="off"
              autoFocus
            />
          )}
        />
      </Form.Group>

      <ActionButton
        onClick={handleSubmit(async (data) => {
          await handleExport(data);

          setOpened(false);
        })}
        submitting={isSubmitting}
      >
        <FormattedMessage id="button.confirm" />
      </ActionButton>
    </Form>
  );
};

export default ExportDocument;
