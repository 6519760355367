import Container from "react-bootstrap/Container";
import AppPageLayout from "./app-page-layout";
import "./document-page-layout.scss";
import "./document-page-navigation.scss";

export type EditPageLayoutProps = {
  id?: string;
  title?: string;
  defaultTitle?: string;
  className?: string;
  menu?: React.ReactNode;
  navigation?: React.ReactNode;
};

const EditPageLayout: React.FC<EditPageLayoutProps> = ({
  id,
  title,
  navigation,
  menu,
  className = "",
  children,
}) => {
  return (
    <AppPageLayout
      className={`document-page edit-page ${className}`}
      title={title}
      navigation={navigation}
    >
      <Container fluid className="m-0 document-page-main-content-header-container">
        <div className="document-page-main-content-header">
          <span className="h6">{title}&nbsp;</span>
          <div className="document-page-main-content-header-actions">{menu}</div>
        </div>
      </Container>

      <Container fluid className="m-0 document-page-main-content-body">
        {children}
      </Container>
    </AppPageLayout>
  );
};

export default EditPageLayout;
