import React from "react";
import { useLocation } from "react-router";

let scrollPositions: { [key: string]: number } = {};

export const ManageScroll = () => {
  const { pathname, state } = useLocation();

  React.useEffect(() => {
    const listener = () => {
      scrollPositions[pathname] = window.scrollY;
    };

    !state?.bypassScrollRestore && window.scrollTo(0, scrollPositions[pathname || 0]);
    if (pathname === "/search") {
      Object.keys(scrollPositions)
        .filter((key) => key.startsWith("/search/"))
        .forEach((key) => delete scrollPositions[key]);
    }
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, [pathname, state]);

  return null;
};
