import React, { createContext, useCallback, useContext, useState } from "react";

export type SummaryEditContextType = {
  selectedText?: string;
  setSelectedText: (selectedText?: string) => void;
  modeAddProvisionModal: ProvisionModalMode;
  setModeAddProvisionModal: (mode: ProvisionModalMode, selectedText?: string) => void;
};

export type ProvisionModalMode = "hidden" | "select" | "add" | "edit" | "delete";

export const SummaryEditContext = createContext<SummaryEditContextType>({
  setSelectedText: () => {},
  modeAddProvisionModal: "hidden",
  setModeAddProvisionModal: () => {},
});

export const SummaryEditContextProvider: React.FC<{}> = ({ children }) => {
  const [selectedText, setSelectedText] = useState<string>();
  const [modeAddProvisionModal, setModeAddProvisionModal] = useState<ProvisionModalMode>("hidden");
  return (
    <SummaryEditContext.Provider
      value={{
        selectedText,
        setSelectedText,
        modeAddProvisionModal,
        setModeAddProvisionModal: useCallback(
          (mode, text) => {
            setModeAddProvisionModal(mode);
            if (mode === "hidden") {
              setSelectedText(text);
            } else {
              setSelectedText(text || selectedText);
            }
          },
          [selectedText, setModeAddProvisionModal, setSelectedText]
        ),
      }}
    >
      {children}
    </SummaryEditContext.Provider>
  );
};

export const useSummaryEditContext = () => useContext(SummaryEditContext);

export default SummaryEditContextProvider;
