/* eslint-disable react/jsx-props-no-spreading */
import { Badge } from "react-bootstrap";
import { useIntl } from "react-intl";
import LabelValue from "../../components/label-value";
import { withAuth } from "../../contexts/auth.context";
import { Selection } from "../../hooks/useMultipleSelection";
import { PasswordResetRequest } from "../../models/PasswordResetRequest.model";
import PasswordResetRequestStatus from "../../models/PasswordResetRequestStatus.enum";
import {
  passwordResetRequestHref,
  usePasswordResetRequests,
} from "../../services/password-reset-services";
import ResultsTable from "./ResultsTable/results-table";
import ResultsTableHeader from "./ResultsTable/results-table-header";

const PasswordResetRequestsSearchResults = () => {
  const { formatMessage, formatDate } = useIntl();

  const results = usePasswordResetRequests();

  return (
    <ResultsTable
      results={results}
      header={() => (
        <ResultsTableHeader
          sortOptions={[
            {
              value: "createdOn",
              label: "Дата на създаване",
            },
          ]}
        />
      )}
      itemToSelection={itemToSelection}
      allowBulkLoad
      row={(item) => (
        <ResultsTable.ResultTableRow>
          {getPasswordResetRequestTitle(item)}
          <LabelValue
            label={formatMessage({ id: "password-reset-request-search-page.labels.user" })}
          >
            {`${item.user.email}(${item.user.firstName} ${item.user.lastName})`}
          </LabelValue>
          <LabelValue
            label={formatMessage({ id: "password-reset-request-search-page.labels.createdOn" })}
          >
            {formatDate(item.createdOn, {
              month: "long",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </LabelValue>
          {item.status === PasswordResetRequestStatus.PENDING && (
            <LabelValue
              label={formatMessage({ id: "password-reset-request-search-page.labels.expiresOn" })}
            >
              {formatDate(item.expiresOn, {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </LabelValue>
          )}
          {item.status === PasswordResetRequestStatus.USED && (
            <LabelValue
              label={formatMessage({ id: "password-reset-request-search-page.labels.usedOn" })}
            >
              {formatDate(item.usedOn, {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </LabelValue>
          )}
          <ResultsTable.RowIndicators item={item}>
            {item.expired ? (
              <Badge bg="secondary">
                {formatMessage({ id: `password-reset-request-search-page.labels.expired` })}
              </Badge>
            ) : (
              <Badge bg={item.status === PasswordResetRequestStatus.USED ? "success" : "warning"}>
                {formatMessage({
                  id: `password-reset-request.status.${item.status}`,
                })}
              </Badge>
            )}
          </ResultsTable.RowIndicators>
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const itemToSelection = (item: PasswordResetRequest): Selection => ({
  _id: item.id,
  title: getPasswordResetRequestTitle(item),
  href: passwordResetRequestHref(item.id),
});

const getPasswordResetRequestTitle = (item: PasswordResetRequest) => {
  return item.id;
};

export default withAuth(PasswordResetRequestsSearchResults);
