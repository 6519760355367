/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Card, Container } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router";
import { InlineLinkButton } from "../../components/Button/button";
import CarouselPreview from "../../components/Carousel/carousel-preview";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { DocumentStatusBadge } from "../../services/document-services";
import { useEditorCollections } from "../../services/editor-collection-services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import "./editor-collections.scss";

const EditorCollections = () => {
  const navigate = useNavigate();

  const { data } = useEditorCollections();

  return (
    <AppPageLayout title={"Колекции"} className="collections-page" navigation={<></>}>
      <Container fluid className="m-0">
        <div className="editor-collections-cards-container">
          {data?.map((page) => {
            return page?._embedded.items.map((collection) => (
              <Card
                onClick={() => navigate(`/collections/${collection.id}`)}
                className="search-card"
                key={collection.id}
              >
                <Card.Body>
                  <CarouselPreview
                    imageSrc={collection.imageData}
                    title={collection.title}
                    subTitle={collection.subTitle}
                    details={`Съставител: ${collection.publishedBy}`}
                  />
                  <DocumentStatusBadge status={collection.status} />
                </Card.Body>
              </Card>
            ));
          })}
          <Visible when={hasPrivilege(Privileges.EDIT_COLLECTIONS)}>
            <div className="w-100 d-flex flex-row-reverse">
              <InlineLinkButton onClick={() => navigate(`/collections/new`)} className="">
                Добави нова колекция
              </InlineLinkButton>
            </div>
          </Visible>
        </div>
      </Container>
    </AppPageLayout>
  );
};

export default EditorCollections;
