import useRequest from "../api";
import { StatsActsPeriod } from "../models/StatsActsPeriod.model";
import { StatsEditor } from "../models/StatsEditor.model";

export const useStatsActYear = (year: string) =>
  useRequest<StatsActsPeriod[]>(`/stats/acts/${year}`)?.data;

export const useStatsActMonth = (year: string, month: string) =>
  useRequest<StatsActsPeriod[]>(`/stats/acts/${year}/${month}`)?.data;

export const useStatsEditors = (from: string, to: string) =>
  useRequest<StatsEditor[]>(`/stats/editors?from=${from}&to=${to}`)?.data;
