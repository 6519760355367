import Badge from "react-bootstrap/Badge";
import { BiLike } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useProtectedAction } from "../../contexts/auth.context";
import {
  addUserReaction,
  delUserReaction,
  useUserReactions,
} from "../../services/document-services";
import "./document-like-action.scss";

type DocumentLikeActionProps = {
  id: string;
};
const DocumentLikeAction: React.FC<DocumentLikeActionProps> = ({ id }) => {
  const reactions = useUserReactions(id);
  const protectedAction = useProtectedAction();
  return (
    <>
      {reactions?.reactions.length ? (
        <Badge
          bg="light"
          text="secondary"
          onClick={() => delUserReaction(id, reactions?.reactions[0].id!)}
        >
          <div className="d-flex align-items-center like-button">
            <BiLike />
            <span>&nbsp;</span>
            <FormattedMessage id="document-actions.liked" />
            <span>&nbsp;</span>
            <Badge bg="primary">{reactions.count}</Badge>
          </div>
        </Badge>
      ) : (
        <Badge
          bg="light"
          text="secondary"
          onClick={protectedAction(() => {
            addUserReaction(id);
            toast.success("Благодарим Ви за обратната връзка.");
          })}
        >
          <div className="d-flex align-items-center like-button">
            <BiLike />
            <span>&nbsp;</span>
            <FormattedMessage id="document-actions.like" /> <span>&nbsp;</span>
            <Badge bg="primary">{reactions.count}</Badge>
          </div>
        </Badge>
      )}
    </>
  );
};

export default DocumentLikeAction;
