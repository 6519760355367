import { useState } from "react";
import { Form, Offcanvas, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";
import { useDeviceSelectors } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import { FaHighlighter } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage, useIntl } from "react-intl";
import { InlineLinkButton } from "../../components/Button/button";
import { useProtectedAction } from "../../contexts/auth.context";
import { useHighlightContext } from "../../contexts/highlight.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import "./highlight.scss";

const BTN_ID = "highlight-btn";

const HighlightDocument: React.FC<{
  popover?: boolean;
}> = ({ popover }) => {
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { selectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();
  if (popover) {
    return (
      <Button
        className="inline-link"
        key="1"
        size="sm"
        variant="link"
        onClick={() => {
          (window.document.querySelector(`#${BTN_ID}`) as HTMLElement).click();
        }}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-highlight">
              {formatMessage({ id: "document-actions.highlight" })}
            </Tooltip>
          }
        >
          <span>
            <FaHighlighter />
          </span>
        </OverlayTrigger>
      </Button>
    );
  }
  return isMobileOnly ? <HighlightModal /> : <HighlightPopover />;
};

const HighlightModal = () => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  useBodyScrollLock(showModal);
  const protectedAction = useProtectedAction();
  const { selectedActId } = useSelectedActContext();
  return (
    <>
      <Button
        variant="link"
        size="sm"
        className={`inline-link ${BTN_ID}`}
        onClick={protectedAction(() => setShowModal(!showModal))}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-highlight">
              {formatMessage({ id: "document-actions.highlight" })}
            </Tooltip>
          }
        >
          <span>
            <FaHighlighter />
          </span>
        </OverlayTrigger>
      </Button>
      <Offcanvas
        show={showModal}
        onHide={() => setShowModal(false)}
        placement="bottom"
        backdrop
        id="highlight-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FormattedMessage id="document-actions.highlight" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <HighlightPanel opened={showModal} setOpened={setShowModal} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const HighlightPopover = () => {
  const { formatMessage } = useIntl();

  const [showPopover, setShowPopover] = useState(false);

  const { selectedActId } = useSelectedActContext();
  const popover = (
    <Popover
      id={selectedActId ? "highlight-popover-quick" : "highlight-popover"}
      className={`shadow generic-popover ${showPopover ? "" : "d-none"}`}
      popper={{
        strategy: selectedActId ? "absolute" : "fixed",
      }}
    >
      <Popover.Header className="d-flex">
        <FormattedMessage id="document-actions.highlight" />
        <InlineLinkButton onClick={() => setShowPopover(false)} className="ms-auto">
          <IoMdClose />
        </InlineLinkButton>
      </Popover.Header>
      <Popover.Body>
        <HighlightPanel opened={showPopover} setOpened={setShowPopover} />
      </Popover.Body>
    </Popover>
  );

  const protectedAction = useProtectedAction();

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [104, 10],
              },
            },
          ],
        }}
        overlay={popover}
        show={true}
        rootClose
      >
        <Button
          variant="link"
          size="sm"
          id={BTN_ID}
          className={`inline-link ${BTN_ID}`}
          onClick={protectedAction(() => setShowPopover(!showPopover))}
        >
          <OverlayTrigger
            placement={selectedActId ? "bottom" : "top"}
            overlay={
              <Tooltip id="doc-action-highlight">
                {formatMessage({ id: "document-actions.highlight" })}
              </Tooltip>
            }
          >
            <span>
              <FaHighlighter />
            </span>
          </OverlayTrigger>
        </Button>
      </OverlayTrigger>
    </>
  );
};

const HighlightPanel: React.FC<{
  opened: boolean;
  setOpened: (opened: boolean) => void;
}> = ({ opened, setOpened }) => {
  useHotkeys(
    "Escape",
    () => {
      setOpened(false);
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const {
    includeEditorAnnotations,
    includeUserAnnotations,
    setIncludeEditorAnnotations,
    setIncludeUserAnnotations,
  } = useHighlightContext();

  return (
    <Form className="edit-form">
      <Form.Group controlId="includeUserAnnotations">
        <Form.Label>
          <FormattedMessage id="highlight.form.includeUserAnnotations" />
        </Form.Label>
        <Form.Check
          type="switch"
          onChange={(e) => setIncludeUserAnnotations(e.target.checked)}
          checked={includeUserAnnotations}
          autoComplete="off"
        />
      </Form.Group>
      <Form.Group controlId="includeEditorAnnotations">
        <Form.Label>
          <FormattedMessage id="highlight.form.includeEditorAnnotations" />
        </Form.Label>
        <Form.Check
          type="switch"
          onChange={(e) => setIncludeEditorAnnotations(e.target.checked)}
          checked={includeEditorAnnotations}
          autoComplete="off"
          autoFocus
        />
      </Form.Group>
    </Form>
  );
};

export default HighlightDocument;
