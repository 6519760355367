import { useEffect, useMemo, useState } from "react";
import { Form, InputGroup, Offcanvas, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";
import { useDeviceSelectors } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { FaRegStar } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { ActionButton, InlineLinkButton } from "../../components/Button/button";
import SimpleSelect from "../../components/SimpleSelect";
import { useProtectedAction } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import { UserDocument, UserDocumentType } from "../../models/UserDocument.model";
import { addToCollection, useUserDocuments } from "../../services/user-services";
import ResponsiveFilter from "../SearchPage/FiltersSidebar/responsive-filter";
import "./add-to-favourites.scss";
import { useDocumentActionsContext } from "./document-actions-container";

const BTN_ID = "add-to-favourites-btn";

const AddToFavourites: React.FC<{ popover?: boolean }> = ({ popover }) => {
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { formatMessage } = useIntl();
  const { selectedActId } = useSelectedActContext();
  if (popover) {
    return (
      <Button
        className="inline-link"
        key="1"
        size="sm"
        variant="link"
        onClick={() => {
          (window.document.querySelector(`#${BTN_ID}`) as HTMLElement).click();
        }}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-favourites">
              {formatMessage({ id: "document-actions.add-to-read-later" })}
            </Tooltip>
          }
        >
          <span>
            <FaRegStar />
          </span>
        </OverlayTrigger>
      </Button>
    );
  }

  return isMobileOnly ? <AddToFavouritesModal /> : <AddToFavouritesPopover />;
};

const AddToFavouritesModal = () => {
  const { formatMessage } = useIntl();
  const [showSaveAs, setShowSaveAs] = useState(false);
  useBodyScrollLock(showSaveAs);
  const protectedAction = useProtectedAction();
  const { selectedActId } = useSelectedActContext();
  return (
    <>
      <Button
        variant="link"
        size="sm"
        className={`inline-link ${BTN_ID}`}
        onClick={protectedAction(() => setShowSaveAs(!showSaveAs))}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-favourites">
              {formatMessage({ id: "document-actions.add-to-read-later" })}
            </Tooltip>
          }
        >
          <span>
            <FaRegStar />
          </span>
        </OverlayTrigger>
      </Button>
      <Offcanvas
        show={showSaveAs}
        onHide={() => setShowSaveAs(false)}
        placement="bottom"
        backdrop
        id="add-favourites-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FormattedMessage id="document-actions.add-to-read-later" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddToFavouritesPanel opened={showSaveAs} setOpened={setShowSaveAs} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const AddToFavouritesPopover = () => {
  const { formatMessage } = useIntl();

  const [showSaveAs, setShowSaveAs] = useState(false);

  const { selectedActId } = useSelectedActContext();
  const popover = (
    <Popover
      id={selectedActId ? "add-favourites-popover-quick" : "add-favourites-popover"}
      className={`shadow generic-popover ${showSaveAs ? "" : "d-none"}`}
      popper={{
        strategy: selectedActId ? "absolute" : "fixed",
      }}
    >
      <Popover.Header className="d-flex">
        <FormattedMessage id="document-actions.add-to-read-later" />
        <InlineLinkButton onClick={() => setShowSaveAs(false)} className="ms-auto">
          <IoMdClose />
        </InlineLinkButton>
      </Popover.Header>
      <Popover.Body>
        <AddToFavouritesPanel opened={showSaveAs} setOpened={setShowSaveAs} />
      </Popover.Body>
    </Popover>
  );

  const protectedAction = useProtectedAction();

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [74, 10],
              },
            },
          ],
        }}
        overlay={popover}
        show={true}
        rootClose
      >
        <Button
          variant="link"
          size="sm"
          id={BTN_ID}
          className={`inline-link ${BTN_ID}`}
          onClick={protectedAction(() => setShowSaveAs(!showSaveAs))}
        >
          <OverlayTrigger
            placement={selectedActId ? "bottom" : "top"}
            overlay={
              <Tooltip id="doc-action-favourites">
                {formatMessage({ id: "document-actions.add-to-read-later" })}
              </Tooltip>
            }
          >
            <span>
              <FaRegStar />
            </span>
          </OverlayTrigger>
        </Button>
      </OverlayTrigger>
    </>
  );
};

const AddToFavouritesPanel: React.FC<{ opened: boolean; setOpened: (opened: boolean) => void }> = ({
  opened,
  setOpened,
}) => {
  const { formatMessage } = useIntl();
  const { document } = useDocumentActionsContext();
  const location = useLocation();

  useHotkeys(
    "Escape",
    () => {
      setOpened(false);
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const documents = useUserDocuments();
  const groupedData = useMemo(() => {
    return documents.favourite
      ?.reduce((acc: any[], item) => {
        const category = item.category;
        const element = acc.filter((e) => e === category);
        if (!element[0]) {
          acc.push(category);
        }
        return acc;
      }, [])
      .sort((g1, g2) => {
        if (g1 === "За четене по-късно") {
          return -1;
        }
        if (g2 === "За четене по-късно") {
          return 1;
        }
        return g1.localeCompare(g2);
      });
  }, [documents.favourite]);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<UserDocument & { newCategory?: string }>({
    defaultValues: {
      category: "За четене по-късно",
      title: document?.title,
      href: location.pathname,
    },
  });

  useEffect(() => {
    !opened && reset();
  }, [opened, reset]);

  const { title, category, newCategory } = watch();

  return (
    <Form className="edit-form">
      <Form.Group controlId="title">
        <Form.Label>
          <FormattedMessage id="add-to-favourites.form.title" />
        </Form.Label>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Control type="text" onChange={onChange} value={value} autoComplete="off" />
          )}
        />
      </Form.Group>
      <Form.Group controlId="shortTitle">
        <Form.Label>
          <FormattedMessage id="add-to-favourites.form.subTitle" />
        </Form.Label>
        <Controller
          name="shortTitle"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Control
              type="text"
              onChange={onChange}
              value={value}
              autoComplete="off"
              autoFocus
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="category">
        <Form.Label>
          <FormattedMessage id="add-to-favourites.form.category" />
        </Form.Label>
        <Controller
          name="category"
          control={control}
          render={({ field: { onChange, value } }) =>
            category ? (
              <ResponsiveFilter>
                <SimpleSelect
                  onChange={(selection) => onChange(selection[0])}
                  value={value ? [value] : []}
                  options={groupedData.map((group) => group)}
                  getOption={(label) => ({ label, value: label })}
                  isMulti={false}
                  disableSearch={true}
                  isCreatable={true}
                  overrideStrings={{
                    createNew: formatMessage({ id: "save-search.form.category.create-new" }),
                  }}
                  placeholder={formatMessage({ id: "add-to-favourites.form.category" })}
                />
              </ResponsiveFilter>
            ) : (
              <></>
            )
          }
        />

        <Controller
          name="newCategory"
          control={control}
          render={({ field: { onChange, value } }) =>
            !category ? (
              <InputGroup>
                <Form.Control
                  type="text"
                  onChange={onChange}
                  value={value}
                  autoComplete="off"
                  autoFocus
                />
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    onChange("");
                    setValue("category", "За четене по-късно");
                  }}
                  title={formatMessage({ id: "summary-search-panel.button.close" })}
                >
                  <IoMdClose />
                </Button>
              </InputGroup>
            ) : (
              <></>
            )
          }
        />
      </Form.Group>

      <ActionButton
        onClick={handleSubmit(async (data) => {
          await addToCollection(
            [{ ...data, category: data.category || data.newCategory }],
            UserDocumentType.FAVOURITE,
            "За четене по-късно"
          );

          setOpened(false);
        })}
        disabled={!title || (!category && !newCategory)}
        submitting={isSubmitting}
      >
        <FormattedMessage id="button.confirm" />
      </ActionButton>
    </Form>
  );
};

export default AddToFavourites;
