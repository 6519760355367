import { EntityModel } from "hateoas-hal-types";
import { useMemo } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaExpand, FaExternalLinkAlt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
import Skeleton from "../../components/Skeleton";
import { useProtectedAction } from "../../contexts/auth.context";
import { Bulletin } from "../../models/Bulletin.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { useBulletin } from "../../services/bulletin-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import "./bulletin-quick-view-page.scss";
import { BulletinContent } from "./bulletin-view-page";
import "./bulletin-view-page.scss";

interface BulletinQuickViewPageProps {
  bulletinId: string;
  onClose: () => void;
}

const BulletinQuickViewPage: React.FC<BulletinQuickViewPageProps> = ({ bulletinId, onClose }) => {
  const bulletin = useBulletin(bulletinId);

  return bulletin ? (
    <BulletinViewForm bulletin={bulletin} onClose={onClose} />
  ) : (
    <BulletinQuickViewPageLoading onClose={onClose} />
  );
};

interface BulletinViewPageProps {
  bulletin: EntityModel<Bulletin>;
  onClose: () => void;
}

const BulletinViewForm: React.FC<BulletinViewPageProps> = ({ bulletin, onClose }) => {
  const id = useMemo(() => uuid(), []);
  return (
    <DocumentQuickViewLayout
      id={id}
      title={bulletin.title}
      className="bulletin-page"
      menu={<BulletinQuickViewPageActions bulletin={bulletin} onClose={onClose} />}
      onClose={onClose}
    >
      <div>
        <BulletinContent bulletin={bulletin} />
      </div>
    </DocumentQuickViewLayout>
  );
};

interface BulletinQuickViewPageActionsProps {
  bulletin: EntityModel<Bulletin>;
  onClose?: () => void;
}

const BulletinQuickViewPageActions: React.FC<BulletinQuickViewPageActionsProps> = ({
  bulletin,
  onClose,
}) => {
  const navigate = useNavigate();
  const protectedAction = useProtectedAction(bulletin.accessMode === DocumentAccessMode.FREE);
  const expandBulletinNewTab = protectedAction(() =>
    window.open(`/bulletins/${bulletin?.id}`, "_blank")
  );

  const expandBulletin = protectedAction(() => {
    navigate(`/bulletins/${bulletin.id}`);
    onClose && onClose();
  });
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const intl = useIntl();
  return (
    <DocumentActionsContainer
      document={{
        id: bulletin.id,
        title: bulletin.title,
      }}
      quickview
    >
      <DocumentAction
        handler={expandBulletin}
        icon={<FaExpand />}
        label={intl.formatMessage({ id: "judgement-act-actions.open-full-screen" })}
      />
      {!isMobileOnly && (
        <DocumentAction
          handler={expandBulletinNewTab}
          icon={<FaExternalLinkAlt />}
          label={intl.formatMessage({ id: "judgement-act-actions.open-new-tab" })}
        />
      )}
      <DocumentCloseAction handler={onClose} />
    </DocumentActionsContainer>
  );
};

const BulletinQuickViewPageLoading: React.FC<{ onClose: () => void }> = (props) => {
  const id = useMemo(() => uuid(), []);
  return (
    <DocumentQuickViewLayout onClose={props.onClose} id={id}>
      <div className="w-100">
        <Skeleton count={10} />
      </div>
    </DocumentQuickViewLayout>
  );
};

export default BulletinQuickViewPage;
