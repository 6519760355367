import useRequest, { mutate, post } from "../api";

export const shortenUrl = async (query: string) => {
  const url = await post("/url", query);
  await mutate(`/url/${url.key}`, url);
  return url.key;
};

export const useUrl = (key?: string | null) =>
  useRequest<{ url: string }>(key ? `/url/${key}` : null).data?.url;
