import React, { createContext, useContext } from "react";

export type NavigationDrawerContextType = {
  setIsOpen: (open: boolean) => void;
};

export const NavigationDrawerContext = createContext<NavigationDrawerContextType>({
  setIsOpen: () => {},
});

export const NavigationDrawerContextProvider: React.FC<NavigationDrawerContextType> = ({
  setIsOpen,
  children,
}) => {
  return (
    <NavigationDrawerContext.Provider value={{ setIsOpen }}>
      {children}
    </NavigationDrawerContext.Provider>
  );
};

export const useNavigationDrawerContext = () => useContext(NavigationDrawerContext);

export default NavigationDrawerContextProvider;
