import React, { createContext, useContext, useLayoutEffect, useMemo, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";

interface DocumentActionsContainerProps {
  quickview?: boolean;
  document?: { id: string; title: string };
}

const DocumentActionsContainer: React.FC<DocumentActionsContainerProps> = ({
  quickview = false,
  document,
  children,
}) => {
  const [show, setShow] = useState(false);
  useLayoutEffect(() => {
    setTimeout(() => setShow(true), quickview ? 1000 : 100);
  }, [setShow, quickview]);
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  return (
    <DocumentActionsContextProvider dropdown={false} document={document}>
      {children}
      {!isMobileOnly && (
        <OverlayTrigger
          trigger="click"
          placement="bottom-end"
          show={show}
          popperConfig={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
          overlay={
            <Popover
              id={`actions-panel-popover${quickview ? "-quickview" : ""}`}
              className={`shadow`}
              style={{ display: "none" }}
              body
              popper={{
                strategy: "fixed",
              }}
            >
              <div className="document-page-main-content-header-actions">
                {React.Children.map(children, (child) =>
                  React.isValidElement(child) ? React.cloneElement(child, { popover: true }) : child
                )}
              </div>
            </Popover>
          }
        >
          <Button style={{ width: 0, padding: 0, border: "none" }} />
        </OverlayTrigger>
      )}
    </DocumentActionsContextProvider>
  );
};

export type DocumentActionsContextType = {
  dropdown: boolean;
  document?: { id: string; title: string };
};

export const DocumentActionsContext = createContext<DocumentActionsContextType>({ dropdown: true });

export const DocumentActionsContextProvider: React.FC<DocumentActionsContextType> = ({
  dropdown,
  document,
  children,
}) => {
  return (
    <DocumentActionsContext.Provider
      value={useMemo(
        () => ({
          dropdown,
          document,
        }),
        [dropdown, document]
      )}
    >
      {children}
    </DocumentActionsContext.Provider>
  );
};

export const useDocumentActionsContext = () => useContext(DocumentActionsContext);

export default DocumentActionsContainer;
