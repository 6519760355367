import React, { createContext, useContext } from "react";

export type SummaryViewContextType = {
  toggleSection?: (section: string) => void;
  toggleAll?: (sections: string[]) => void;
};

export const SummaryViewContext = createContext<SummaryViewContextType>({});

export const SummaryViewContextProvider: React.FC<SummaryViewContextType> = ({
  toggleSection,
  toggleAll,
  children,
}) => {
  return (
    <SummaryViewContext.Provider
      value={{
        toggleSection,
        toggleAll,
      }}
    >
      {children}
    </SummaryViewContext.Provider>
  );
};

export const useSummaryViewContext = () => useContext(SummaryViewContext);

export default SummaryViewContextProvider;
