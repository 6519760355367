import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  getFlatDataFromTree,
  getNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree";
import {
  ActionButton,
  InlineLinkButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Button/button";
import TopicSelect from "../../components/TopicSelect/topic-select";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import RefData from "../../models/RefData.model";
import SummaryTopic from "../../models/SummaryTopic.model";
import {
  updateSummaryTopicsTree,
  useSummaryTopicsTree,
} from "../../services/summary-topic.services";

type ModalMode = "hidden" | "select" | "edit";

type AddTopicModalProps = {
  append: (topic: RefData) => void;
};

const AddTopicModal: React.FC<AddTopicModalProps> = ({ append }) => {
  const [selectedTopics, setSelectedTopics] = useState<SummaryTopic[]>([]);
  const [mode, setMode] = useState<ModalMode>("hidden");

  return (
    <>
      {mode === "hidden" && (
        <InlineLinkButton messageId="button.add" onClick={() => setMode("select")} />
      )}
      {mode === "select" && (
        <SelectTopic
          onSelect={() => {
            append(selectedTopics[0]);
            setMode("hidden");
            setSelectedTopics([]);
          }}
          setMode={setMode}
          setSelectedTopics={setSelectedTopics}
          selectedTopics={selectedTopics}
          onCancel={() => {
            setMode("hidden");
            setSelectedTopics([]);
          }}
        />
      )}
      {mode === "edit" && (
        <CreateOrEditTopic
          onCreate={(topic) => {
            setSelectedTopics([topic]);
            setMode("select");
          }}
          mode={mode}
          selectedTopic={selectedTopics[0]}
          onCancel={() => {
            setMode("select");
          }}
        />
      )}
    </>
  );
};

type SelectTopicProps = {
  selectedTopics: SummaryTopic[];
  onSelect: (topic: SummaryTopic) => void;
  setSelectedTopics: (topic: SummaryTopic[]) => void;
  setMode: (mode: ModalMode) => void;
  onCancel: () => void;
};

const SelectTopic: React.FC<SelectTopicProps> = ({
  selectedTopics,
  setSelectedTopics,
  setMode,
  onSelect,
  onCancel,
}) => {
  const buttons = (
    <>
      <Visible when={hasPrivilege(Privileges.EDIT_TOPICS)}>
        <PrimaryButton onClick={() => setMode("edit")}>
          <FormattedMessage id="add-topic.modal.edit-topic-tree" />
        </PrimaryButton>
      </Visible>

      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton
        messageId="button.confirm"
        onClick={onSelect}
        disabled={selectedTopics.length === 0}
      />
    </>
  );
  return (
    <>
      <Modal
        show={true}
        centered
        size="lg"
        className="modal-xxl"
        animation={false}
        onEscapeKeyDown={onCancel}
      >
        <Modal.Header>
          <FormattedMessage id="add-topic.modal.header" />
          <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
            {buttons}
          </div>
        </Modal.Header>
        <Modal.Body style={{ height: "400px", overflowY: "auto" }}>
          <TopicSelect
            checkedNodes={selectedTopics}
            setCheckedNodes={(newSelection) =>
              setSelectedTopics(newSelection.filter((n) => !selectedTopics.includes(n)))
            }
            autoFocus
          />
        </Modal.Body>

        <Modal.Footer>{buttons}</Modal.Footer>
      </Modal>
    </>
  );
};

type CreateOrEditTopicProps = {
  selectedTopic: SummaryTopic;
  onCreate: (topic: SummaryTopic) => void;
  mode: ModalMode;
  onCancel: () => void;
};

const CreateOrEditTopic: React.FC<CreateOrEditTopicProps> = ({
  selectedTopic,
  onCreate,
  mode,
  onCancel,
}) => {
  const intl = useIntl();

  const cancelHandler = () => {
    onCancel();
  };

  const summaryTopicsTree = useSummaryTopicsTree();

  const [tree, setTree] = useState<any>(summaryTopicsTree.tree);
  const [deletedNodes, setDeletedNodes] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const getNodeKey = ({ node }: { node: any }) => node.value;

  const confirmHandler = async () => {
    setSubmitting(true);
    try {
      await updateSummaryTopicsTree({
        updated: getFlatDataFromTree({ treeData: tree, getNodeKey, ignoreCollapsed: false }).map(
          (item: any) => ({
            id: Number.parseInt(item.node.value),
            parentId: item.parentNode ? Number.parseInt(item.parentNode.value) : undefined,
            rank: item.treeIndex,
            label: item.node.shortName,
          })
        ),
        deleted: deletedNodes.map((node) => Number.parseInt(node)),
      });
      cancelHandler();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        show={true}
        centered
        size="lg"
        className="modal-xxl"
        animation={false}
        onEscapeKeyDown={onCancel}
      >
        <Modal.Header>
          <FormattedMessage id="edit-topic-tree.modal.header" />
        </Modal.Header>
        <Modal.Body>
          <SortableTree
            treeData={tree}
            onChange={(tree: any) => {
              setTree(tree);
            }}
            getNodeKey={getNodeKey}
            onDragStateChanged={({ isDragging, draggedNode }) => {
              setIsDragging(isDragging);
            }}
            canNodeHaveChildren={(node) => (isDragging ? !!node.expanded : true)}
            onMoveNode={(args) => {
              const { prevPath, nextPath, node } = args;

              prevPath.pop();
              let newTree = tree;
              while (prevPath.length) {
                const prevNode = getNodeAtPath({
                  treeData: newTree,
                  path: prevPath,
                  getNodeKey,
                });
                newTree = changeNodeAtPath({
                  treeData: newTree,
                  path: prevPath,
                  getNodeKey,
                  newNode: {
                    ...prevNode?.node,
                    summariesCount: (prevNode! as any).node.summariesCount - node.summariesCount,
                  },
                });
                prevPath.pop();
              }

              newTree = changeNodeAtPath({
                treeData: newTree,
                path: nextPath,
                getNodeKey,
                newNode: { ...node, changed: true },
              });

              nextPath.pop();
              while (nextPath.length) {
                const nextNode = getNodeAtPath({
                  treeData: newTree,
                  path: nextPath,
                  getNodeKey,
                });
                newTree = changeNodeAtPath({
                  treeData: newTree,
                  path: nextPath,
                  getNodeKey,
                  newNode: {
                    ...nextNode?.node,
                    summariesCount: (nextNode! as any).node.summariesCount + node.summariesCount,
                  },
                });
                nextPath.pop();
              }
              setTree(newTree);
            }}
            generateNodeProps={({ node, path }) => {
              const canDeleteNode =
                (!node.children || node.children.length === 0) && !node.summariesCount;
              return {
                style: {
                  maxHeight: "50px",
                },
                handle: <FaMinusCircle />,
                title: (
                  <>
                    <input
                      style={{ fontSize: "0.9rem" }}
                      value={node.shortName}
                      size={30}
                      onChange={(event) => {
                        const name = event.target.value;

                        setTree(
                          changeNodeAtPath({
                            treeData: tree,
                            path,
                            getNodeKey,
                            newNode: {
                              ...node,
                              label: name,
                              shortName: name,
                              title: name,
                              changed: true,
                            },
                          })
                        );
                      }}
                    />
                  </>
                ),
                subtitle: (
                  <span
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5px",
                      width: "40px",
                      display: "inline-block",
                    }}
                  >
                    ({node.summariesCount})
                  </span>
                ),
                buttons: [
                  <FaPlusCircle
                    style={{
                      fontSize: "1.3rem",
                      marginRight: "5px",
                      color: "#005695",
                      cursor: "pointer",
                    }}
                    title={intl.formatMessage(
                      { id: "add-topic.modal.edit-topic-tree.add-topic-button.title" },
                      node
                    )}
                    onClick={() => {
                      const newTopicMsg = intl.formatMessage(
                        { id: "add-topic.modal.edit-topic-tree.new-topic-label" },
                        node
                      );
                      setTree(
                        addNodeUnderParent({
                          treeData: tree,
                          parentKey: path[path.length - 1],
                          expandParent: true,
                          getNodeKey,
                          newNode: {
                            title: newTopicMsg,
                            label: newTopicMsg,
                            shortName: newTopicMsg,
                            value: -new Date().getTime() + "",
                            summariesCount: 0,
                            changed: true,
                          },
                          addAsFirstChild: true,
                        }).treeData
                      );
                    }}
                  />,
                  canDeleteNode ? (
                    <FaMinusCircle
                      style={{
                        fontSize: "1.3rem",
                        marginRight: "5px",
                        color: "#005695",
                        cursor: "pointer",
                      }}
                      title={intl.formatMessage(
                        { id: "add-topic.modal.edit-topic-tree.remove-topic-button.title" },
                        node
                      )}
                      onClick={() => {
                        setTree(
                          removeNodeAtPath({
                            treeData: tree,
                            path,
                            getNodeKey,
                          })
                        );
                        if (Number.parseInt(node.value) > 0) {
                          setDeletedNodes([...deletedNodes, node.value]);
                        }
                      }}
                    />
                  ) : (
                    <FaMinusCircle
                      style={{
                        fontSize: "1.3rem",
                        marginRight: "5px",
                        color: "lightgray",
                      }}
                    />
                  ),
                ],
              };
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton
            messageId="button.cancel"
            onClick={cancelHandler}
            disabled={submitting}
          />
          <ActionButton onClick={confirmHandler} disabled={submitting} submitting={submitting}>
            <FormattedMessage id="button.confirm" />
          </ActionButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTopicModal;
