import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ImageUploader from "react-images-upload";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import CarouselPreview from "../../components/Carousel/carousel-preview";
import NotAuthorized from "../../components/NotAuthorized";
import CollectionRichTextEditor from "../../components/RichTextEditor/collection-rich-text-editor";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { ConnectUser, hasPrivilege, not, Privileges, Visible } from "../../contexts/auth.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { EditorCollection } from "../../models/EditorCollection.model";
import { useEditorCollection } from "../../services/editor-collection-services";
import { useUserNames } from "../../services/user-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import EditorCollectionEditPageActions from "./editor-collection-edit-page-actions";
import "./editor-collection-edit-page.scss";
import EditorCollectionPageNavigation from "./editor-collection-page-navigation";
import EditorCollectionViewPageLoading from "./editor-collection-view-page-loading";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
interface EditorCollectionEditPageProps {
  collection?: EntityModel<Optional<EditorCollection, "id">>;
}

const EditorCollectionEditPage = () => {
  const { collectionId } = useParams();
  const { state } = useLocation();
  const collection = useEditorCollection(collectionId || state?.sourceId);

  if (!collectionId && collection) {
    return (
      <EditorCollectionEditForm
        collection={{
          ...collection,
          id: undefined,
          title: "*КОПИЕ* " + collection?.title,
          status: DocumentStatus.DRAFT,
        }}
      />
    );
  }

  return collection || !collectionId ? (
    <EditorCollectionEditForm collection={collection} />
  ) : (
    <EditorCollectionViewPageLoading mode="edit" className="page-loading" />
  );
};

const EditorCollectionEditForm: React.FC<EditorCollectionEditPageProps> = ({ collection }) => {
  const serverData = collection;
  const intl = useIntl();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: serverData || {
      status: DocumentStatus.DRAFT,
      accessMode: DocumentAccessMode.PAID,
    },
  });

  const { control, watch } = methods;
  const [title, subTitle] = watch(["title", "subTitle"]);

  const lexebraEditors = useUserNames();

  return (
    <FormProvider {...methods}>
      <DocumentPageLayout
        title={collection?.title}
        defaultTitle={intl.formatMessage({ id: "editor-collection-edit.page.add-new-title" })}
        mode="edit"
        className="editor-collection-page"
        menu={<EditorCollectionEditPageActions />}
        navigation={<EditorCollectionPageNavigation />}
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="9">
              <Form.Group controlId="title">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.title" />
                </Form.Label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="status">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.status" />
                </Form.Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={Object.keys(DocumentStatus)}
                          getOption={(status) => ({
                            label: intl.formatMessage({ id: `document.status.${status}` }),
                            value: status,
                            disabled: !(
                              hasPrivilege(Privileges.EDIT_ALL_EDITOR_COLLECTIONS)(currentUser) ||
                              status === DocumentStatus.DRAFT ||
                              status === DocumentStatus.REVIEW
                            ),
                          })}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="9">
              <Form.Group controlId="subTitle">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.sub-title" />
                </Form.Label>
                <Controller
                  name="subTitle"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="accessMode">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.access-mode" />
                </Form.Label>
                <Controller
                  name="accessMode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={Object.keys(DocumentAccessMode)}
                          getOption={(accessMode) => ({
                            label: intl.formatMessage({ id: `document.access-mode.${accessMode}` }),
                            value: accessMode,
                          })}
                          value={value}
                          onChange={onChange}
                          isDisabled={
                            !hasPrivilege(Privileges.EDIT_ALL_EDITOR_COLLECTIONS)(currentUser)
                          }
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Form.Group controlId="publishedBy">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.published-by" />
                </Form.Label>
                <Controller
                  name="publishedBy"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control type="text" onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="editor">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.editor" />
                </Form.Label>
                <ConnectUser>
                  {({ currentUser }) => (
                    <Controller
                      name="editor"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectSingle
                          options={lexebraEditors}
                          getOption={(lexebraEditor) => ({
                            label: lexebraEditor.name,
                            value: lexebraEditor,
                          })}
                          value={value}
                          onChange={onChange}
                          isDisabled={not(hasPrivilege(Privileges.EDIT_ALL_EDITOR_COLLECTIONS))(
                            currentUser
                          )}
                        />
                      )}
                    />
                  )}
                </ConnectUser>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="start">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.publish-date" />
                </Form.Label>
                <div>
                  <Controller
                    name="publishDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value}
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format={"DD.MM.YYYY"}
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(true),
                          weekdaysLong: dayjs.weekdays(true),
                        }}
                        onDayChange={onChange}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Controller
                name="imageData"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Form.Group controlId="imageData">
                      <Form.Label>
                        <FormattedMessage id="edit-editor-collection.form.image-data" />
                        {value && (
                          <Button variant="link" onClick={() => onChange(null)}>
                            <FormattedMessage id="button.remove" />
                          </Button>
                        )}
                      </Form.Label>
                      {!value && (
                        <ImageUploader
                          withIcon={false}
                          buttonText={intl.formatMessage({
                            id: "edit-editor-collection.form.image-data.choose-file",
                          })}
                          onChange={(pictureFiles: any, pictureDataURLs: any) => {
                            onChange(pictureDataURLs[0]);
                          }}
                          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                          maxFileSize={307200}
                          singleImage
                          defaultImage={value}
                          label={intl.formatMessage({
                            id: "edit-editor-collection.form.image-data.allowed-files",
                          })}
                        />
                      )}
                      {value && (
                        <CarouselPreview imageSrc={value} title={title} subTitle={subTitle} />
                      )}
                    </Form.Group>
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="body">
                <Form.Label>
                  <FormattedMessage id="edit-editor-collection.form.contents" />
                </Form.Label>
                <Controller
                  name="contents"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    //skip first update as it is just to cleanup the custom blot contents
                    return <CollectionRichTextEditor defaultValue={value} onChange={onChange} />;
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </DocumentPageLayout>
    </FormProvider>
  );
};

const ProtectedEditorCollectionEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)} fallback={NotAuthorized}>
      <EditorCollectionEditPage />
    </Visible>
  );
};

export default ProtectedEditorCollectionEditPage;
