/* eslint-disable react/jsx-props-no-spreading */
import { useSearchCategory } from "../search-utils";
import {
  ActMonthFilter,
  ActOtherFilter,
  ActYearFilter,
  AllowedByCourtFilter,
  CaseAdminBodyFilter,
  CaseMatterFilter,
  CaseStatusFilter,
  CaseTypeFilter,
  ChamberFilter,
  CollapsibleFiltersPanel,
  CourtFilter,
  DocumentStatusFilter,
  EUActOtherFilter,
  isActsCategory,
  isAdministrativeActsCategory,
  isEuActsCategory,
  isNewsArticlesCategory,
  isQuestionsCategory,
  isSummariesCategory,
  JudgementTypeFilter,
  LatestActFilter,
  NewsArticleCategoryFilter,
  NewsArticleSectionFilter,
  PersonalCategoryFilter,
  ProceedingFilter,
  ReporterFilter,
  SummaryGroundOfAppealFilter,
  SummaryKeyProvisionFilter,
  SummaryOtherFilter,
  SummaryRelatedProvisionFilter,
} from "./filters";
import "./filters-sidebar.css";

const StandardFilters = () => {
  const searchCategory = useSearchCategory();

  return (
    <>
      {!isNewsArticlesCategory(searchCategory) && !isEuActsCategory(searchCategory) && (
        <SummaryFilters />
      )}
      {isEuActsCategory(searchCategory) && <EuJudgementActFilters />}
      {isNewsArticlesCategory(searchCategory) && <NewsArticleFilters />}
    </>
  );
};

const SummaryFilters = () => {
  const searchCategory = useSearchCategory();
  return (
    <>
      <JudgementTypeFilter />
      <CaseTypeFilter />
      <ProceedingFilter />
      {!isAdministrativeActsCategory(searchCategory) && <AllowedByCourtFilter />}
      {isAdministrativeActsCategory(searchCategory) && (
        <>
          <CaseStatusFilter />
          <CaseAdminBodyFilter />
          <CaseMatterFilter />
        </>
      )}
      {!isAdministrativeActsCategory(searchCategory) && <SummaryRelatedProvisionFilter />}
      {!isSummariesCategory(searchCategory) && <SummaryKeyProvisionFilter />}
      {!isAdministrativeActsCategory(searchCategory) && <SummaryGroundOfAppealFilter />}
      <LatestActFilter />
      {(isActsCategory(searchCategory) || isAdministrativeActsCategory(searchCategory)) && (
        <ActOtherFilter />
      )}
      <PersonalCategoryFilter />
      <CollapsibleFiltersPanel collapseHeight="0px">
        <hr />
        {(isSummariesCategory(searchCategory) ||
          isQuestionsCategory(searchCategory) ||
          isEuActsCategory(searchCategory)) && <CourtFilter />}
        <ChamberFilter />
        <ReporterFilter />
        <ActYearFilter />
        <ActMonthFilter />
        <SummaryOtherFilter />
        <DocumentStatusFilter show={false} />
      </CollapsibleFiltersPanel>
    </>
  );
};

const EuJudgementActFilters = () => {
  return (
    <>
      <JudgementTypeFilter />
      <CourtFilter />
      <ActYearFilter />
      <ActMonthFilter />
      <DocumentStatusFilter show={false} />
      <EUActOtherFilter />
      <PersonalCategoryFilter />
    </>
  );
};

const NewsArticleFilters = () => {
  return (
    <>
      <NewsArticleCategoryFilter />
      <NewsArticleSectionFilter />
      <DocumentStatusFilter show={false} />
      <PersonalCategoryFilter />
    </>
  );
};

export default StandardFilters;
