import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import ReactDragListView from "react-drag-listview";
import { FaBars, FaTimes } from "react-icons/fa";
import { useIntl } from "react-intl";

import { getProvisionFullTitle } from "../../services/ref-data-services";

import "./provisions.table.css";

const ProvisionsTable = ({
  //: React.FC<UseControllerReturn<EntityModel<Summary>, "provisions">> = ({
  field: { value, onChange },
  setSelectedForEdit,
}) => {
  const intl = useIntl();

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...value];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      onChange(data);
    },
    nodeSelector: "tr",
    handleSelector: ".handle",
  };

  return (
    <ReactDragListView {...dragProps}>
      <Table responsive style={{ width: "100%" }} className="provisions-table">
        <colgroup>
          <col span={1} style={{ width: "30px" }} />
          <col span={1} style={{ width: "100%" }} />
          <col span={1} style={{ width: "25px" }} />
        </colgroup>

        {value && value.length > 0 ? (
          <tbody>
            {value.map((v) => (
              <tr key={v.id}>
                <td>
                  <FaBars className="handle" style={{ cursor: "move" }} />
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => !(window.getSelection() + "") && setSelectedForEdit(v)}
                  title={getProvisionFullTitle(v)}
                >
                  {getProvisionFullTitle(v)}
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => onChange(value.filter((i) => i.id !== v.id))}
                    title={intl.formatMessage({ id: "button.remove" })}
                    className="remove-link"
                  >
                    <FaTimes />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tfoot>
            <tr>
              <td colSpan={2}>Липсва информация за тълкувани разпоредби.</td>
            </tr>
          </tfoot>
        )}
      </Table>
    </ReactDragListView>
  );
};

export default ProvisionsTable;
