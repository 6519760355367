import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import SummaryRichTextEditor from "../../components/RichTextEditor/summary-rich-text-editor";
import { Summary } from "../../models/Summary.model";

type OppinionsSectionProps = {
  idx: number;
  fieldName: "dissentingOpinions" | "editorialOpinions";
  defaultAuthor?: string;
};

const OppinionsSection: React.FC<OppinionsSectionProps> = ({
  idx,
  fieldName,
  defaultAuthor = "",
}) => {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray<Summary, any, "fieldId">({
    name: `qas.${idx}.${fieldName}`,
    keyName: "fieldId",
  });

  const watchFieldArray = useWatch({ control, name: `qas.${idx}.${fieldName}` });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const intl = useIntl();

  const [selection, setSelection] = useState("0");

  return (
    <Tabs
      activeKey={selection}
      onSelect={(key) => {
        if (key === "-1") {
          append({ author: defaultAuthor, text: "" });
        }
        setSelection(key === "-1" ? controlledFields.length + 1 + "" : key!);
      }}
      className="question-sub-tabs"
    >
      <Tab eventKey="0" disabled title={<FormattedMessage id={`edit-summary.form.${fieldName}`} />}>
        {controlledFields.length === 0 ? (
          <span>
            Липсва информация за <FormattedMessage id={`edit-summary.form.${fieldName}.label`} />
          </span>
        ) : (
          <span>
            {controlledFields.length}{" "}
            <FormattedMessage
              id={`edit-summary.form.opinion.${
                controlledFields.length > 1 ? "plural" : "singular"
              }.label`}
            />
          </span>
        )}
      </Tab>
      {controlledFields.map((opp, i) => (
        <Tab
          key={opp.fieldId}
          eventKey={i + 1 + ""}
          title={
            <>
              <span title={opp.author}>{opp.author || "Неизвестен автор"}</span>
              {selection === i + 1 + "" && (
                <FaTimes
                  style={{ marginLeft: "5px" }}
                  onClick={(e) => {
                    remove(i);
                    setSelection((i > 0 || controlledFields.length === 1 ? i : i + 1) + "");
                    e.stopPropagation();
                  }}
                  title={intl.formatMessage({ id: "button.remove" })}
                />
              )}
            </>
          }
        >
          <Row>
            <Col md={12}>
              <Form.Group controlId={`qas.${idx}.${fieldName}.${i}.author`}>
                <Form.Label>Автор</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={`${opp.author}`}
                  {...register(`qas.${idx}.${fieldName}.${i}.author`)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId={`qas.${idx}.${fieldName}.${i}.text`}>
                <Form.Label>Мнение</Form.Label>
                <Controller
                  name={`qas.${idx}.${fieldName}.${i}.text`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SummaryRichTextEditor
                      defaultValue={value}
                      height="150px"
                      id={`qas-${idx}-${fieldName}-${i}`}
                      expandHeight="400px"
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </Tab>
      ))}

      <Tab eventKey="-1" title="Добави" />
    </Tabs>
  );
};

export default OppinionsSection;
