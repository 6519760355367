import { useCallback, useState } from "react";

export type Selection = {
  _id: any;
  favouritesId?: string;
  title?: string;
  href?: string;
  hasAnnotations?: boolean;
  hasAiAnnotations?: boolean;
  hasEditorAnnotation?: boolean;
  hasSummary?: boolean;
};

export type UseMultipleSelectionReturn = {
  selectAll: () => void;
  selectSingle: (s: Selection) => void;
  selection: Map<string, Selection>;
  clearSelection: () => void;
  isAllSelected: boolean;
  clearAfterAction: (func: () => Promise<void>) => () => Promise<void>;
};

const useMultipleSelection = (allIds?: Selection[]): UseMultipleSelectionReturn => {
  const [selection, setSelection] = useState<Map<string, Selection>>(new Map());
  const [isAllSelected, setAllSelected] = useState<boolean>(false);

  const selectSingle = useCallback(
    (s: Selection) => {
      const newSeletion = new Map<string, Selection>(selection);
      newSeletion.has(s._id) ? newSeletion.delete(s._id) : newSeletion.set(s._id, s);
      setSelection(newSeletion);

      setAllSelected(!allIds?.map((e) => e._id)?.some((id) => !newSeletion.has(id!)));
    },
    [selection, setSelection, setAllSelected, allIds]
  );

  const selectAll = useCallback(() => {
    const newSeletion = new Map<string, Selection>();
    if (!isAllSelected) {
      allIds?.forEach((e) => newSeletion.set(e._id!, e));
    }
    setSelection(newSeletion);

    setAllSelected(!isAllSelected);
  }, [isAllSelected, setSelection, setAllSelected, allIds]);

  const clearSelection = useCallback(() => {
    setSelection(new Map());
    setAllSelected(false);
  }, [setSelection, setAllSelected]);

  const clearAfterAction = useCallback(
    (func: () => Promise<void>) => {
      return async () => {
        await func();
        clearSelection();
      };
    },
    [clearSelection]
  );

  return { selectAll, selectSingle, selection, clearSelection, isAllSelected, clearAfterAction };
};

export default useMultipleSelection;
