import dayjs from "dayjs";
import { Table } from "react-bootstrap";
import { FaCrown } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { InlineLink } from "../../components/Link/link";
import Skeleton from "../../components/Skeleton";
import { StatsActsPeriod } from "../../models/StatsActsPeriod.model";

const StatsActsTable: React.FC<{ monthStats?: StatsActsPeriod[]; periodLabel: string }> = ({
  monthStats,
  periodLabel,
}) => {
  return monthStats ? (
    <Table className="stats-table">
      <thead>
        <tr>
          <th />
          <th colSpan={4} scope="col">
            <FormattedMessage id="stats.header.acts-vks" />
          </th>
          <th colSpan={3} scope="col" className="bg-light border-start">
            <FormattedMessage id="stats.header.editor-care" />
          </th>
        </tr>
        <tr>
          <th>{periodLabel}</th>
          <th>
            <FormattedMessage id="stats.label.total" />
          </th>
          <th>
            <FormattedMessage id="stats.label.290" />
          </th>
          <th>
            <FormattedMessage id="stats.label.274.short" />
          </th>
          <th>
            <FormattedMessage id="stats.label.288.combined" />
          </th>
          <th className="bg-light border-start">
            <FormattedMessage id="stats.label.focused" />
          </th>
          <th className="bg-light">
            <FormattedMessage id="stats.label.summarized" />
          </th>
          <th className="bg-light">
            <FormattedMessage id="stats.label.highlighted" />
          </th>
        </tr>
      </thead>
      {monthStats
        .filter((e) => e.role === "item")
        .map((monthStat) => {
          return <StatRow monthStat={monthStat} monthStats={monthStats} />;
        })}
      <tfoot>
        {monthStats
          .filter((e) => e.role === "total")
          .map((monthStat) => {
            return <StatRow monthStat={monthStat} monthStats={monthStats} />;
          })}
      </tfoot>
    </Table>
  ) : (
    <Skeleton count={20} />
  );
};

const StatRow: React.FC<{ monthStat: StatsActsPeriod; monthStats: StatsActsPeriod[] }> = ({
  monthStat,
  monthStats,
}) => {
  const topFocused = Math.max(
    ...monthStats
      .filter((e) => e.role === "item" && e.stats?.countFocusedSummaries)
      .map((e) => e.stats!.countFocusedSummaries)
  );
  const top290 = Math.max(
    ...monthStats
      .filter((e) => e.role === "item" && e.stats?.count290)
      .map((e) => e.stats!.count290)
  );
  const topAllowed = Math.max(
    ...monthStats
      .filter((e) => e.role === "item" && e.stats?.count288All)
      .map((e) => Math.round(((e.stats?.count288Allowed || 0) / e.stats!.count288All) * 100))
  );
  const focusedChamp =
    !!topFocused &&
    monthStat.role !== "total" &&
    monthStat.stats?.countFocusedSummaries === topFocused;
  const a290Champ = !!top290 && monthStat.role !== "total" && monthStat.stats?.count290 === top290;

  const allowedChamp =
    !!topAllowed &&
    monthStat.stats?.count288All &&
    monthStat.role !== "total" &&
    Math.round(((monthStat.stats?.count288Allowed || 0) / monthStat.stats!.count288All) * 100) ===
      topAllowed;
  return (
    <tr key={monthStat.period}>
      <td className="capitalize-first">
        {monthStat.role === "total"
          ? "Общо"
          : monthStat.type === "month"
          ? dayjs(monthStat.period, "YYYY/MM").format("MMMM")
          : dayjs(monthStat.period, "YYYY/MM/DD").format("D")}
      </td>
      <td>
        <Stat count={monthStat.stats?.countAll} url={monthStat.urls?.countAll} type="acts" />
      </td>
      <td className={a290Champ ? "stats-champion" : ""}>
        <Stat count={monthStat.stats?.count290} url={monthStat.urls?.count290} type="acts" />
        <span>
          <FaCrown />
        </span>
      </td>
      <td>
        <Stat count={monthStat.stats?.count274} url={monthStat.urls?.count274} type="acts" />
      </td>
      <td className={`${allowedChamp ? "stats-champion" : ""}`}>
        <Stat
          count={monthStat.stats?.count288Allowed}
          url={monthStat.urls?.count288Allowed}
          type="acts"
        />
        /
        <Stat
          count={monthStat.stats?.count288All}
          url={monthStat.urls?.count288All}
          type="acts"
        />{" "}
        {monthStat.stats?.count288All ? (
          <em style={{ fontSize: "1rem", fontStyle: "normal" }}>
            (
            {Math.round(
              ((monthStat.stats?.count288Allowed || 0) / monthStat.stats.count288All) * 100
            )}
            %)
          </em>
        ) : null}{" "}
        <span>
          <FaCrown />
        </span>
      </td>
      <td className={`bg-light border-start ${focusedChamp ? "stats-champion" : ""}`}>
        <Stat
          count={monthStat.stats?.countFocusedSummaries}
          url={monthStat.urls?.countFocusedSummaries}
          type="summaries"
        />
        <span>
          <FaCrown />
        </span>
      </td>
      <td className="bg-light">
        <Stat
          count={monthStat.stats?.countSummarized}
          url={monthStat.urls?.countSummarized}
          type="acts"
        />
      </td>
      <td className="bg-light">
        <Stat
          count={monthStat.stats?.countHiglighted}
          url={monthStat.urls?.countHiglighted}
          type="acts"
        />
      </td>
    </tr>
  );
};

const Stat: React.FC<{ type: "acts" | "summaries"; count?: number; url?: string }> = ({
  type,
  count,
  url,
}) => {
  return url ? (
    <InlineLink to={`/search/${type}?q=${url}`} target="_blank">
      {count}
    </InlineLink>
  ) : (
    <>-</>
  );
};

export default StatsActsTable;
