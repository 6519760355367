import React from "react";
import { Badge } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { FormattedMessage, useIntl } from "react-intl";
import LabelValue from "../../components/label-value";
import { UserDevice, UserDeviceStatus } from "../../models/UserDеvice.model";

const DevicesTable: React.FC<{
  value: UserDevice[];
}> = ({ value }) => {
  const { formatMessage, formatDate } = useIntl();
  return (
    <Tabs activeKey={"0"} className={`sub-section-tabs`}>
      <Tab eventKey="0" disabled title={<FormattedMessage id="edit-user.form.devices" />}>
        {value.length === 0 ? (
          <span style={{ padding: "12px" }}>
            <FormattedMessage id="edit-user.form.devices.no-data" />
          </span>
        ) : (
          <Table responsive style={{ width: "100%" }} className="sub-section-table">
            <colgroup>
              <col span={1} />
            </colgroup>

            <tbody>
              {[...value].map((v, i) => (
                <tr key={i}>
                  <td>
                    <div className="d-flex w-100">
                      <span className="d-flex flex-column">
                        <span>
                          {v.visitorId}({v.deviceType})
                        </span>
                        <div className="d-flex" style={{ gap: "5px" }}>
                          <LabelValue
                            className="d-flex align-items-end"
                            label={formatMessage({
                              id: "user-edit-page.labels.devices.createdOn",
                            })}
                          >
                            {formatDate(v.createdOn, {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </LabelValue>
                          {v.inactivatedOn && (
                            <LabelValue
                              className="d-flex align-items-end"
                              label={formatMessage({
                                id: "user-edit-page.labels.devices.inactivatedOn",
                              })}
                            >
                              {formatDate(v.inactivatedOn, {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              })}
                            </LabelValue>
                          )}
                        </div>
                      </span>

                      <span className="ms-auto">
                        <Badge bg={v.status === UserDeviceStatus.ACTIVE ? "success" : "secondary"}>
                          {formatMessage({
                            id: `user-device.status.${v.status}`,
                          })}
                        </Badge>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Tab>
    </Tabs>
  );
};

export default DevicesTable;
