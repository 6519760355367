import "@recogito/recogito-js/dist/recogito.min.css";
import React from "react";
import { Button, ListGroup, Offcanvas, Overlay, Tooltip } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { FaClone, FaCommentAlt, FaEye, FaHighlighter, FaTag, FaTrash } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Privileges, useAuth } from "../../contexts/auth.context";
import { UserDocumentAnno } from "../../models/UserDocumentAnno.model";
import { UserDocumentAnnoScope } from "../../models/UserDocumentAnnoScope.enum";
import { delUserDocumentAnno } from "../../services/user-services";
import { getCategory, getFirstCommentText, getScope, toggleCanvas } from "./judgement-view-page";

export const SelectedAnnotationPopover: React.FC<{
  actId: string;
  recogito: any;
  target?: [any, HTMLElement];
  canChangeAnno: (anno: any) => boolean;
  closePopover: () => void;
  showEditPopover: () => void;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
}> = ({
  recogito,
  target,
  actId,
  closePopover,
  showEditPopover,
  canChangeAnno,
  updateAnnotations,
}) => {
  const auth = useAuth();
  if (isMobileOnly) {
    const annotation = target?.[0];
    const category = getCategory(annotation)?.value || "Без етикет";
    const scopeBody = getScope(annotation)?.value || UserDocumentAnnoScope.PRIVATE;
    const canEditSummaries = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
    const isCategorized = category !== "Без етикет";
    return (
      <Offcanvas
        show={!!target}
        onHide={closePopover}
        placement="bottom"
        backdrop
        id="annotation-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {category !== "Без етикет" ? (
              <>
                {category}
                {canEditSummaries && isCategorized && (
                  <div className="text-muted fs-6">
                    <FaEye />{" "}
                    {scopeBody === UserDocumentAnnoScope.PUBLIC
                      ? "Всички"
                      : scopeBody === UserDocumentAnnoScope.EDITOR
                      ? "Редактори"
                      : "Текущ потребител"}
                  </div>
                )}
              </>
            ) : (
              "Подчертан текст"
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SelectedAnnotationPopoverContentsMobile
            actId={actId}
            recogito={recogito}
            annotation={annotation}
            showEditPopover={showEditPopover}
            closePopover={closePopover}
            canChangeAnno={canChangeAnno}
            updateAnnotations={updateAnnotations}
          />
        </Offcanvas.Body>
      </Offcanvas>
    );
  }

  return target ? (
    <Overlay
      target={target[1]}
      show={true}
      placement="top"
      popperConfig={{
        strategy: "fixed",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -4],
            },
          },
        ],
      }}
    >
      {(props) => {
        return (
          <Tooltip id="annotation-tooltip" {...props}>
            <SelectedAnnotationPopoverContents
              actId={actId}
              recogito={recogito}
              annotation={target[0]}
              showEditPopover={showEditPopover}
              closePopover={closePopover}
              canChangeAnno={canChangeAnno}
              updateAnnotations={updateAnnotations}
            />
          </Tooltip>
        );
      }}
    </Overlay>
  ) : null;
};

export const SelectedAnnotationPopoverContents: React.FC<{
  actId: string;
  recogito: any;
  canChangeAnno: (anno: any) => boolean;
  closePopover: () => void;
  showEditPopover: () => void;
  annotation: any;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
}> = ({
  recogito,
  annotation,
  actId,
  closePopover,
  showEditPopover,
  canChangeAnno,
  updateAnnotations,
}) => {
  const auth = useAuth();

  const scopeBody = getScope(annotation)?.value || UserDocumentAnnoScope.PRIVATE;
  const canEditSummaries = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const category = getCategory(annotation)?.value || "Без етикет";
  const isCategorized = category !== "Без етикет";

  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  return (
    <div className="d-flex align-items-center">
      {canEditSummaries && isCategorized && (
        <span>
          <FaEye />{" "}
          {scopeBody === UserDocumentAnnoScope.PUBLIC
            ? "Всички"
            : scopeBody === UserDocumentAnnoScope.EDITOR
            ? "Редактори"
            : "Текущ потребител"}{" "}
          |
        </span>
      )}
      {isCategorized && (
        <span>
          <FaTag /> {`${category}`}
        </span>
      )}
      {!isCategorized && (
        <Button
          variant="link"
          className="m-auto"
          onClick={async (e) => {
            e.stopPropagation();
            await navigator.clipboard.writeText(
              annotation.target.selector
                .filter((selector: any) => selector.type === "TextQuoteSelector")
                .map((selector: any) => selector.exact)[0]
            );
            closePopover();
            toast.info("Текстът бе копиран в клипборда");
          }}
        >
          {canEditSummaries ? (
            <FaClone title="Копирай подчертания текст" />
          ) : (
            <>
              <FaClone /> Копирай
            </>
          )}
        </Button>
      )}
      {!isCategorized && canChangeAnno(annotation) && (
        <Button
          variant="link"
          className="m-auto"
          onClick={async (e) => {
            closePopover();
            showEditPopover();
          }}
        >
          {canEditSummaries ? (
            <FaCommentAlt
              title="Бележка"
              style={{
                color: getFirstCommentText(annotation) ? "orange" : "white",
              }}
            />
          ) : (
            <>
              <FaCommentAlt
                style={{
                  color: getFirstCommentText(annotation) ? "orange" : "white",
                }}
              />{" "}
              Бележка
            </>
          )}
        </Button>
      )}

      {canChangeAnno(annotation) && (
        <Button
          variant="link"
          className="m-auto"
          onClick={async (e) => {
            const backendAnno = await delUserDocumentAnno(actId, encodeURIComponent(annotation.id));
            recogito.removeAnnotation(annotation);
            updateAnnotations(backendAnno);

            toggleCanvas(recogito, q);
            recogito.pagemap.redraw();
            closePopover();
          }}
        >
          {canEditSummaries ? (
            <FaHighlighter style={{ color: "orange" }} title="Премахни подчертаването" />
          ) : (
            <>
              <FaHighlighter style={{ color: "orange" }} /> Премахни подчертаването
            </>
          )}
        </Button>
      )}
    </div>
  );
};

export const SelectedAnnotationPopoverContentsMobile: React.FC<{
  actId: string;
  recogito: any;
  canChangeAnno: (anno: any) => boolean;
  closePopover: () => void;
  showEditPopover: () => void;
  annotation: any;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
}> = ({
  recogito,
  annotation,
  actId,
  closePopover,
  showEditPopover,
  canChangeAnno,
  updateAnnotations,
}) => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        onClick={async (e) => {
          e.stopPropagation();
          await navigator.clipboard.writeText(
            annotation.target.selector
              .filter((selector: any) => selector.type === "TextQuoteSelector")
              .map((selector: any) => selector.exact)[0]
          );
          closePopover();
          toast.info("Текстът бе копиран в клипборда");
        }}
      >
        <FaClone /> Копирай
      </ListGroup.Item>
      <ListGroup.Item
        onClick={async (e) => {
          closePopover();
          showEditPopover();
        }}
      >
        <FaCommentAlt /> Бележка
      </ListGroup.Item>
      {canChangeAnno(annotation) && (
        <ListGroup.Item
          onClick={async (e) => {
            const backendAnno = await delUserDocumentAnno(actId, encodeURIComponent(annotation.id));
            recogito.removeAnnotation(annotation);
            updateAnnotations(backendAnno);

            toggleCanvas(recogito, q);
            recogito.pagemap.redraw();
            closePopover();
          }}
        >
          <FaTrash /> Премахни подчертаването
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default SelectedAnnotationPopover;
