import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactDragListView from "react-drag-listview";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaClone, FaRegClone, FaTimes } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useAuth } from "../../contexts/auth.context";
import SummaryQaContextProvider from "../../contexts/summary-qa.context";
import { Summary } from "../../models/Summary.model";
import RelatedActsSection from "./summary-edit-page-acts";
import OppinionsSection from "./summary-edit-page-opinions";
import SummaryQaMain from "./summary-edit-page-qa-main";

export type SummaryQaTabSelection = {
  question: any;
  dissentingOpinions?: any;
  editorialOpinions?: any;
};

const SummaryQaTabs = () => {
  const { control } = useFormContext<Summary>();
  const { fields, append, remove, move } = useFieldArray<Summary, "qas", "fieldId">({
    name: "qas",
    keyName: "fieldId",
    control: control,
  });

  const lastQA = useWatch({ control, name: `qas.${fields.length - 1}` as any });

  const intl = useIntl();

  const { currentUser } = useAuth();

  const [selection, setSelection] = useState("0");

  const dragProps = {
    onDragEnd: (from: number, to: number) => {
      const realTo = Math.min(fields.length - 1, to);
      if (to > -1) {
        move(from, realTo);
        setSelection(realTo + "");
      }
    },
    nodeSelector: "li",
    ignoreSelector: "li:last-of-type",
    handleSelector: "button",
  };

  return (
    <ReactDragListView.DragColumn {...dragProps}>
      <div className="question-tabs">
        <Tabs
          // className="sticky-top"
          activeKey={selection}
          onSelect={(key) => {
            if (key === fields.length + "") {
              append({
                question: "",
                shortTitle: "",
                answer: "",
                arguments: "",
                dissentingOpinions: [],
                editorialOpinions: [],
                relatedJudgementActs: [],
                topics: [],
              });
            }
            setSelection(key!);
          }}
        >
          {fields.map((qa, i) => (
            <Tab
              key={qa.fieldId}
              tabClassName="question-tab"
              eventKey={i + ""}
              title={
                <span>
                  <span className="tab-title-text">
                    {fields.length > 1 ? `Въпрос ${i + 1}` : "Въпрос"}
                  </span>
                  {fields.length > 1 && i + "" === selection && (
                    <FaTimes
                      style={{ marginLeft: "5px" }}
                      onClick={(e) => {
                        remove(i);
                        setSelection((i === fields.length - 1 ? i - 1 : i) + "");
                        e.stopPropagation();
                      }}
                      title={intl.formatMessage({ id: "button.remove" })}
                    />
                  )}
                </span>
              }
            >
              <SummaryQaContextProvider>
                <SummaryQaMain i={i} qa={qa} active={i + "" === selection} />
                <Row>
                  <Col md={12}>
                    <OppinionsSection idx={i} fieldName="dissentingOpinions" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <OppinionsSection
                      idx={i}
                      fieldName="editorialOpinions"
                      defaultAuthor={currentUser?.firstName + " " + currentUser?.lastName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <RelatedActsSection idx={i} />
                  </Col>
                </Row>
              </SummaryQaContextProvider>
            </Tab>
          ))}

          <Tab
            eventKey={fields.length + ""}
            tabClassName="question-tab"
            title={
              <span>
                <span className="tab-title-text">+</span>
                {
                  <FaClone
                    title="Клонирай всичко"
                    style={{ float: "right" }}
                    onClick={(e) => {
                      append({
                        question: lastQA.question,
                        shortTitle: lastQA.shortTitle,
                        answer: lastQA.answer,
                        arguments: lastQA.arguments,
                        dissentingOpinions: lastQA.dissentingOpinions,
                        editorialOpinions: lastQA.editorialOpinions,
                        relatedJudgementActs: lastQA.relatedJudgementActs,
                        topics: lastQA.topics,
                        groundOfAppeal: lastQA.groundOfAppeal,
                        keyLegislation: lastQA.keyLegislation,
                      });

                      setSelection(fields.length + "");
                      e.stopPropagation();
                    }}
                  />
                }
                {
                  <FaRegClone
                    title="Клонирай Кратко заглавие, Мотиви, Водеща разпоредба и Основание за допускане"
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={(e) => {
                      append({
                        question: "",
                        shortTitle: lastQA.shortTitle,
                        answer: "",
                        arguments: lastQA.arguments,
                        dissentingOpinions: [],
                        editorialOpinions: [],
                        relatedJudgementActs: [],
                        topics: lastQA.topics,
                        groundOfAppeal: lastQA.groundOfAppeal,
                        keyLegislation: lastQA.keyLegislation,
                      });

                      setSelection(fields.length + "");
                      e.stopPropagation();
                    }}
                  />
                }
              </span>
            }
          />
        </Tabs>
      </div>
    </ReactDragListView.DragColumn>
  );
};

export default SummaryQaTabs;
