import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Rectangle,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useProtectedAction } from "../../contexts/auth.context";
import { StatsActsPeriod } from "../../models/StatsActsPeriod.model";

const BulletinActsChart: React.FC<{ monthStats?: StatsActsPeriod[]; date: string }> = ({
  monthStats,
  date,
}) => {
  const intl = useIntl();

  const protectedAction = useProtectedAction();

  const total = monthStats?.find((m) => m.role === "total");
  return (
    <>
      <h5 className="mt-3 text-center">
        <FormattedMessage
          id="stats.header.acts-vks.date"
          values={{
            from: dayjs(date, "DD.MM.YYYY").format("D MMMM YYYY"),
          }}
        />
      </h5>
      <div className="stats-container">
        {!total && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="stats-loading-spinner"
          />
        )}
        <ResponsiveContainer width={"99%"} height={300} onResize={console.log}>
          <BarChart
            data={
              total
                ? Object.entries(total.stats!).map((entry) => [
                    ...entry,
                    Object.entries(total.urls!).find((e) => e[0] === entry[0])?.[1],
                  ])
                : []
            }
            accessibilityLayer
            width={500}
            height={300}
            margin={{
              top: 0,
              right: 30,
              left: 10,
              bottom: 10,
            }}
            style={{
              filter: !total && "blur(3px)",
            }}
            {...{
              overflow: "visible",
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey={(entry) => {
                return intl.formatMessage({ id: `bulletin.stats.${entry[0]}` });
              }}
              tick={({ x, y, payload }) => {
                return (
                  <Text x={x} y={y + 4} textAnchor="middle" width={100} verticalAnchor="start">
                    {payload.value}
                  </Text>
                );
              }}
              interval={0}
            />
            <YAxis scale="sqrt">
              <Label
                angle={-90}
                value={intl.formatMessage({ id: "stats.label.count" })}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Bar
              dataKey={(entry) => entry[1]}
              fill="#005180"
              name={intl.formatMessage({ id: "stats.editor.label.summarized" })}
              activeBar={<Rectangle fill="gold" stroke="#005180" />}
              onClick={protectedAction((e) => {
                window.open(`/search/acts?q=${e.payload[2]}`, "_blank");
              })}
              barSize={40}
              style={{
                cursor: "pointer",
              }}
            >
              <LabelList dataKey={(entry) => (entry as any)[1]} position="top" />
            </Bar>
            <Tooltip />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default BulletinActsChart;
