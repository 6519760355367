import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { NewsArticle } from "../models/NewsArticle.model";

const NEWS_ARTICLES_BASE_URL = "/news-articles";

export const useNewsArticles = () => useSearchRequest<NewsArticle>(NEWS_ARTICLES_BASE_URL);

export const prefetchNewsArticles = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(NEWS_ARTICLES_BASE_URL, searchParams, cache);

export const useNewsArticle = (id?: string) => {
  const { data } = useRequest<EntityModel<NewsArticle>>(
    id ? `${NEWS_ARTICLES_BASE_URL}/${id}` : null
  );
  return data;
};

export const fetchNewsArticle = (id: string) => {
  return get<EntityModel<NewsArticle>>(`${NEWS_ARTICLES_BASE_URL}/${id}`);
};

export const prefetchNewsArticle = (id: string, cache: Cache<any>) =>
  prefetch(`${NEWS_ARTICLES_BASE_URL}/${id}`, cache);

export const saveNewsArticle = async (data: NewsArticle, articleId?: string) => {
  const result = (
    articleId
      ? await put(`${NEWS_ARTICLES_BASE_URL}/${articleId}`, data)
      : await post(`${NEWS_ARTICLES_BASE_URL}`, data)
  ) as EntityModel<NewsArticle>;
  mutate(`${NEWS_ARTICLES_BASE_URL}/${articleId}`, () => result, false);
  return result;
};

export const newsArticleHref = (id: string): string => `${NEWS_ARTICLES_BASE_URL}/${id}`;
