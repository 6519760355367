import { useEffect } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { InlineLink } from "../components/Link/link";
import SubscriptionStatus from "../models/SubscriptionStatus.enum";
import { useCurrentUser } from "../services/user-services";

const useRemindExpireSoon = () => {
  const currentUser = useCurrentUser();
  const intl = useIntl();
  useEffect(() => {
    if (
      currentUser?.latestSubscription?.status !== SubscriptionStatus.REQUESTED &&
      currentUser?.latestSubscription?.status !== SubscriptionStatus.PENDING_INITIAL_PAYMENT
    ) {
      if (currentUser?.currentSubscription?.status === SubscriptionStatus.EXPIRED) {
        toast.warn(
          <>
            Текущият Ви абонамент е изтекъл на{" "}
            {intl.formatDate(currentUser.currentSubscription?.expiresOn, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
            . <br />
            Можете да заявите подновяване на <InlineLink to="/user/profile">
              профилната
            </InlineLink>{" "}
            си страница.
          </>,
          { autoClose: false, toastId: "expired-toast" }
        );
      } else {
        currentUser?.currentSubscription?.expiresSoon &&
          runOncePerDay("expire_soon_last_reminded__at", () =>
            toast.warn(
              <>
                Текущият Ви абонамент изтича на{" "}
                {intl.formatDate(currentUser.currentSubscription?.expiresOn, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
                . <br />
                Можете да заявите подновяване от{" "}
                <InlineLink to="/user/profile">профилната</InlineLink> си страница.
              </>,
              { autoClose: false, toastId: "expire-soon-toast" }
            )
          );
      }
    } else {
      toast.dismiss("expire-soon-toast");
      toast.dismiss("expired-toast");
    }
  }, [currentUser, intl]);
};

function runOncePerDay(key: string, func: () => void) {
  var date = new Date().toLocaleDateString();

  if (localStorage.getItem(key) !== date) {
    localStorage.setItem(key, date);
    func();
  }
}

export default useRemindExpireSoon;
