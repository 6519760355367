import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaExpand, FaExternalLinkAlt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useProtectedAction } from "../../contexts/auth.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { printSummaryTitle, Summary } from "../../models/Summary.model";
import { fetchSummaryPdf } from "../../services/summary-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";

interface SummaryQuickViewPageActionsProps {
  summary: EntityModel<Summary>;
  onClose?: () => void;
}

const SummaryQuickViewPageActions: FunctionComponent<SummaryQuickViewPageActionsProps> = ({
  summary,
  onClose,
}) => {
  const navigate = useNavigate();
  const protectedAction = useProtectedAction(summary.accessMode === DocumentAccessMode.FREE);
  const expandSummary = protectedAction(() => {
    navigate(`/summaries/${summary.id}`);
    onClose && onClose();
  });
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  const expandSummaryNewTab = protectedAction(() =>
    window.open(`/summaries/${summary?.id}`, "_blank")
  );

  const intl = useIntl();
  return (
    <DocumentActionsContainer
      document={{
        id: summary.id,
        title: printSummaryTitle(summary),
      }}
      quickview
    >
      <DocumentAction
        handler={expandSummary}
        icon={<FaExpand />}
        label={intl.formatMessage({ id: "judgement-act-actions.open-full-screen" })}
      />
      {!isMobileOnly && (
        <DocumentAction
          handler={expandSummaryNewTab}
          icon={<FaExternalLinkAlt />}
          label={intl.formatMessage({ id: "judgement-act-actions.open-new-tab" })}
        />
      )}

      <DocumentCollectionActions
        showPrintExport
        dataToExport={async () => (await fetchSummaryPdf(summary.id)).data}
        accessMode={summary.accessMode}
      />
      <DocumentCloseAction handler={onClose} />
    </DocumentActionsContainer>
  );
};

export default SummaryQuickViewPageActions;
