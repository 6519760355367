import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  del,
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { JudgementAct, JudgementActDomain } from "../models/JudgementAct.model";
import { JudgementActReferences } from "../models/JudgementActReferences.model";
import RefData from "../models/RefData.model";
import { SearchHit } from "../models/SearchHit.model";
import { ExportDocumentOptions } from "../pages/DocumentPage/document-collection-actions";

const JUDGEMENT_ACTS_BASE_URL = "/judgement-acts";

export const judgementActHref = (id: string): string => `${JUDGEMENT_ACTS_BASE_URL}/${id}`;

export const useEuJudgementActs = () =>
  useSearchRequest<JudgementAct>(JUDGEMENT_ACTS_BASE_URL + "/eu");

export const prefetchEuJudgementActs = async (searchParams: URLSearchParams, cache: Cache<any>) =>
  await prefetchSearchRequest(JUDGEMENT_ACTS_BASE_URL + "/eu", searchParams, cache);

export const useJudgementActs = () =>
  useSearchRequest<JudgementAct>(JUDGEMENT_ACTS_BASE_URL + "/domestic");

export const prefetchJudgementActs = async (searchParams: URLSearchParams, cache: Cache<any>) =>
  await prefetchSearchRequest(JUDGEMENT_ACTS_BASE_URL + "/domestic", searchParams, cache);

export const useAdmJudgementActs = () =>
  useSearchRequest<JudgementAct>(JUDGEMENT_ACTS_BASE_URL + "/administrative");

export const useConstJudgementActs = () =>
  useSearchRequest<JudgementAct>(JUDGEMENT_ACTS_BASE_URL + "/constitutional");

export const useSupremeCourtudgementActs = () =>
  useSearchRequest<JudgementAct>(JUDGEMENT_ACTS_BASE_URL + "/supreme-court");

export const prefetchAdmJudgementActs = async (searchParams: URLSearchParams, cache: Cache<any>) =>
  await prefetchSearchRequest(JUDGEMENT_ACTS_BASE_URL + "/administrative", searchParams, cache);

export const prefetchConstJudgementActs = async (
  searchParams: URLSearchParams,
  cache: Cache<any>
) => await prefetchSearchRequest(JUDGEMENT_ACTS_BASE_URL + "/constitutional", searchParams, cache);

export const prefetchSupremeCourtJudgementActs = async (
  searchParams: URLSearchParams,
  cache: Cache<any>
) => await prefetchSearchRequest(JUDGEMENT_ACTS_BASE_URL + "/supreme-court", searchParams, cache);

export const fetchJudgementActs = async (query: string) => {
  const result = (await get(`${JUDGEMENT_ACTS_BASE_URL}/suggestions`, {
    query,
  })) as SearchHit[];
  return result;
};

export const useJudgementAct = (id?: string) => {
  const { data } = useRequest<EntityModel<JudgementAct>>(
    id ? `${JUDGEMENT_ACTS_BASE_URL}/${id}` : null
  );
  return data;
};

export const useJudgementActReferences = (id?: string) => {
  const { data } = useRequest<EntityModel<JudgementActReferences>>(
    id ? `${JUDGEMENT_ACTS_BASE_URL}/${id}/references` : null
  );
  return data;
};

export const fetchJudgementAct = (id: string) => {
  return get<EntityModel<JudgementAct>>(`${JUDGEMENT_ACTS_BASE_URL}/${id}`);
};

export const prefetchJudgementAct = (id: string, cache: Cache<any>) => {
  return prefetch(`${JUDGEMENT_ACTS_BASE_URL}/${id}`, cache);
};

export const fetchJudgementActPdf = (
  id: string,
  domain: JudgementActDomain,
  options?: ExportDocumentOptions
) => {
  return post(`${JUDGEMENT_ACTS_BASE_URL}/${getType(domain)}/${id}/pdf`, options);
};

export const fetchSummaryType = (id: string) => {
  return get<EntityModel<RefData>>(`${JUDGEMENT_ACTS_BASE_URL}/${id}/summary-type`);
};

const getType = (domain: JudgementActDomain) =>
  domain === JudgementActDomain.EU ? "eu" : "domestic";

export const extractValuesFromTitle = async (title: string, domain: JudgementActDomain) =>
  get(
    `${JUDGEMENT_ACTS_BASE_URL}/${getType(domain)}/builders/entity-from-title?title=${title}`
  ) as any;

export const buildTitleFromValues = async (
  data: JudgementAct,
  setValue: (key: any, value: any) => void
) =>
  post(
    `${JUDGEMENT_ACTS_BASE_URL}/${getType(data.domain)}/builders/title-from-entity`,
    data
  ) as any;

export const saveJudgementAct = async (data: JudgementAct, actId?: string) => {
  const type = getType(data.domain);
  const result = (
    actId
      ? await put(`${JUDGEMENT_ACTS_BASE_URL}/${type}/${actId}`, data)
      : await post(`${JUDGEMENT_ACTS_BASE_URL}/${type}`, data)
  ) as EntityModel<JudgementAct>;
  mutate(`${JUDGEMENT_ACTS_BASE_URL}/${type}/${actId}`, () => result, false);
  return result;
};

export const addTopicToAct = async (actId: string, topicId: string | number) => {
  await post(`${JUDGEMENT_ACTS_BASE_URL}/${actId}/topics/${topicId}`, {}, true);
};

export const removeTopicFromAct = async (actId: string, topicId: string | number) => {
  await del(`${JUDGEMENT_ACTS_BASE_URL}/${actId}/topics/${topicId}`, {}, true);
};
