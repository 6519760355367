import React, { useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { useDeviceSelectors } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { SearchApiResponse, UseSearchRequestReturn } from "../../../api";
import { InlineLinkButton, PrimaryButton } from "../../../components/Button/button";
import IndicatorIcons from "../../../components/IndicatorIcons";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import useMultipleSelection, {
  Selection,
  UseMultipleSelectionReturn,
} from "../../../hooks/useMultipleSelection";
import DocumentStatus from "../../../models/DocumentStatus.enum";
import { DocumentStatusBadge } from "../../../services/document-services";
import ResultsTableCheckbox from "./results-table-checkbox";
import ResultsTableEmpty from "./results-table-empty";
import ResultsTableLoading from "./results-table-loadng";
import "./results-table.scss";
export type ResultItem = {
  id: string;
  status?: DocumentStatus;
  highlights?: any;
};

interface ResultsTableProps<T extends ResultItem, R> {
  results: UseSearchRequestReturn<T>;
  itemToSelection: (item: R) => Selection;
  resultsExtractor?: (response?: SearchApiResponse<T>[]) => R[];
  header: (
    params: UseMultipleSelectionReturn
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;

  allowBulkLoad?: boolean;
  row: (item: R) => JSX.Element;
}

const ResultsTable = <T extends ResultItem, R extends any>({
  results,
  itemToSelection,
  header,
  allowBulkLoad = false,
  resultsExtractor = flattenPagedResponse,
  row,
}: ResultsTableProps<T, R>) => {
  const { data, size, setSize, totalCount, hasMore, isLoadingMore, totalPages, didYouMean } =
    results;

  const allIds = useMemo(
    () => resultsExtractor(data).map(itemToSelection),
    [data, itemToSelection, resultsExtractor]
  );
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  const methods = useMultipleSelection(allIds);

  return data ? (
    <div className="results-table-container">
      <Table borderless responsive size="sm" className="results-table">
        {header(methods)}
        <tbody>
          {totalCount === 0 && (
            <tr>
              <td colSpan={2} style={{ textAlign: "center" }}>
                <ResultsTableEmpty />
              </td>
            </tr>
          )}
          {didYouMean && didYouMean !== "NA" && (
            <tr>
              <td colSpan={2} className="info-content">
                <SearchFieldController
                  name="query"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        Може би имахте предвид&nbsp;
                        <InlineLinkButton
                          style={{ verticalAlign: "baseline" }}
                          onClick={() => {
                            onChange(
                              value.map((v, i) => (i === value.length - 1 ? didYouMean : v))
                            );
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: didYouMean!,
                            }}
                          />
                        </InlineLinkButton>
                      </>
                    );
                  }}
                ></SearchFieldController>
              </td>
            </tr>
          )}
          {resultsExtractor(data).map((item) => {
            const selectedItem = itemToSelection(item);
            return (
              <tr key={selectedItem._id}>
                {!isMobileOnly && (
                  <td>
                    <ResultsTableCheckbox
                      {...methods}
                      item={{
                        id: selectedItem._id,
                        title: selectedItem.title!,
                        hasEditorAnnotation: selectedItem.hasEditorAnnotation,
                        hasAnnotations: selectedItem.hasAnnotations,
                        hasAiAnnotations: selectedItem.hasAiAnnotations,
                        hasSummary: selectedItem.hasSummary,
                        favouritesId: selectedItem.favouritesId,
                      }}
                    />
                  </td>
                )}
                <td className="main-content">{row(item)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {hasMore &&
        (allowBulkLoad && size < 10 ? (
          <Dropdown as={ButtonGroup} className="load-more">
            <PrimaryButton
              submitting={isLoadingMore}
              messageId="button.load-more"
              onClick={() => setSize(size + 1)}
            />

            <Dropdown.Toggle split className="load-more" disabled={isLoadingMore} />

            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => setSize(Math.min(totalPages as number, 10))}>
                {totalPages && totalPages <= 10 ? "Зареди всички" : "Зареди 100"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <PrimaryButton
            submitting={isLoadingMore}
            messageId="button.load-more"
            onClick={() => setSize(size + 1)}
          />
        ))}
    </div>
  ) : (
    <ResultsTableLoading perRowCount={4} />
  );
};

type ResultsTableRowPartProps = {
  item: ResultItem;
};

const ResultTableRow: React.FC<any> = ({ children }) => (
  <>
    <div className="row-content">
      <div className="row-main-info">
        {getChildrenByType(children, ["RowIndicators", "RowHighlights"], true)}
      </div>
      {getChildrenByType(children, ["RowIndicators"])}
    </div>
    {getChildrenByType(children, ["RowHighlights"])}
  </>
);
ResultsTable.ResultTableRow = ResultTableRow;

const RowIndicators: React.FC<ResultsTableRowPartProps & { __TYPE?: string }> = ({
  item,
  children,
}) => (
  <div className="row-indicators">
    <div>
      <IndicatorIcons item={item} />
      <DocumentStatusBadge status={item.status} />
    </div>
    <div>{children}</div>
  </div>
);
RowIndicators.defaultProps = {
  __TYPE: "RowIndicators",
};
ResultsTable.RowIndicators = RowIndicators;

const RowHighlights: React.FC<ResultsTableRowPartProps & { __TYPE?: string }> = ({ item }) => (
  <>
    {Object.keys(item.highlights || {}).length > 0 && (
      <p className="row-highlights">
        {Object.keys(item.highlights).map((key) => {
          const value = (item.highlights as any)[key];
          return (
            <div>
              <em>
                (<FormattedMessage id={`hightlight.field.${key}`} />)
              </em>{" "}
              <span dangerouslySetInnerHTML={{ __html: `${value}` }} key={key} />
            </div>
          );
        })}
      </p>
    )}
  </>
);
RowHighlights.defaultProps = {
  __TYPE: "RowHighlights",
};
ResultsTable.RowHighlights = RowHighlights;

function flattenPagedResponse<T extends ResultItem>(response?: SearchApiResponse<T>[]) {
  return (response?.map((page) => page?._embedded.items).flat(2) || []) as any[];
}

const getChildrenByType = (children: React.ReactNode, types: string[], negate = false) =>
  React.Children.toArray(children).filter((child) =>
    negate
      ? types.indexOf(typeOfComponent(child)) === -1
      : types.indexOf(typeOfComponent(child)) !== -1
  );

export const typeOfComponent = (component: any) =>
  component?.props?.__TYPE ||
  component?.type?.toString().replace("Symbol(react.fragment)", "react.fragment") ||
  undefined;

export default ResultsTable;
