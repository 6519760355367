export type UserDevice = {
  visitorId: string;
  status: UserDeviceStatus;
  deviceType: string;
  createdOn: Date;
  inactivatedOn: Date;
};

export enum UserDeviceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
