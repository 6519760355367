import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import parse from "html-react-parser";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import CarouselPreview from "../../components/Carousel/carousel-preview";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { NewsArticle } from "../../models/NewsArticle.model";
import { useNewsArticle } from "../../services/news-article-services";
import DocumentLikeAction from "../DocumentPage/document-like-action";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import { renderTextWithProvisionsAndActs } from "../SummaryViewPage/renderTextWithProvisions";
import NewsArticlePageNavigation from "./news-article-page-navigation";
import NewsArticlePageActions from "./news-article-view-page-actions";
import NewsArticleViewPageLoading from "./news-article-view-page-loading";
import "./news-article-view-page.scss";
interface NewsArticleViewPageProps {
  article: EntityModel<NewsArticle>;
}

const NewsArticleViewPage = () => {
  const { articleId } = useParams();
  const article = useNewsArticle(articleId);

  return article ? (
    <NewsArticleViewForm article={article} />
  ) : (
    <NewsArticleViewPageLoading mode="view" className="page-loading" />
  );
};

const NewsArticleViewForm: React.FC<NewsArticleViewPageProps> = ({ article }) => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();
  return (
    <DocumentPageLayout
      id={article.id}
      title={article.title}
      status={article.status}
      mode="view"
      accessMode={article.accessMode}
      className="news-article-page"
      menu={<NewsArticlePageActions article={article} />}
      navigation={<NewsArticlePageNavigation article={article} />}
    >
      <CarouselPreview
        imageSrc={article.imageData}
        title={article.title}
        subTitle={article.subTitle}
      />

      {!!article.publishedBy && (
        <div className="text-muted published-by">
          <DocumentLikeAction id={article.id} />
          <span className="date">
            <FormattedMessage
              id="view-news-article.publish-info"
              values={{
                publishedBy: article.publishedBy,
                publishedOn: intl.formatDate(dayjs(article.publishDate, "YYYY-MM-DD").toDate(), {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                }),
              }}
            />
          </span>
        </div>
      )}
      <div className="act-text">
        {parse(article.contents, {
          replace: (node) => {
            if ((node as any).data) {
              return renderTextWithProvisionsAndActs(
                (node as any).data,
                [],
                article.relatedJudgementActs,
                setSelectedActId
              );
            }
          },
        })}
      </div>
    </DocumentPageLayout>
  );
};

export default NewsArticleViewPage;
