import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Subscription } from "../../models/Subscription.model";
import BeneficiariesTable from "./beneficiaries.table";

const BeneficiariesSection = () => {
  const { control, setValue, watch } = useFormContext<Subscription>();
  const { fields, append, remove } = useFieldArray<Subscription, any, "fieldId">({
    name: "beneficiaries",
    keyName: "fieldId",
    control,
  });

  const watchFieldArray = useWatch({ control, name: "beneficiaries" as any });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const prefix = watch("prefix");

  return controlledFields.length > 0 ? (
    <BeneficiariesTable
      value={controlledFields}
      append={append}
      remove={remove}
      replace={(i, item) => {
        setValue(`beneficiaries.${i}` as any, item);
      }}
      prefix={prefix}
    />
  ) : null;
};

export default BeneficiariesSection;
