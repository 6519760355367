/* eslint-disable react/prop-types */

/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo } from "react";
import { FaCaretDown } from "react-icons/fa";
import ReactSelect, { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import "./select.css";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown />
    </components.DropdownIndicator>
  );
};

const Select = ({ shadowed = false, hint = "", inputStyles = {}, ...props }) => {
  const selectStyles = useMemo(() => buildStyles(inputStyles, shadowed), [inputStyles, shadowed]);

  const theme = useCallback(
    (theme) => ({
      ...theme,
    }),
    []
  );

  const { components: inputComponents, getControlLabel } = props;
  const MultiValue = getControlLabel
    ? (props) => {
        return <components.MultiValue {...props}>{getControlLabel(props)}</components.MultiValue>;
      }
    : components.MultiValue;

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {!hint || props.selectProps.inputValue ? props.children : hint}
      </components.NoOptionsMessage>
    );
  };

  const SelectComponent = props.loadOptions
    ? props.isCreatable
      ? AsyncCreatableSelect
      : AsyncSelect
    : ReactSelect;
  return (
    <SelectComponent
      noOptionsMessage={() => "Няма резултати"}
      loadingMessage={() => "Зареждане..."}
      placeholder={"Изберете..."}
      {...props}
      formatCreateLabel={(value) => {
        return `Добавяне на "${value}"`;
      }}
      styles={selectStyles}
      menuPortalTarget={document.body}
      components={{
        MultiValue,
        NoOptionsMessage,
        DropdownIndicator,
        ...inputComponents,
      }}
      theme={theme}
    />
  );
};

const buildStyles = (inputStyles, shadowed) => {
  return {
    dropdownIndicator: (base, { isDisabled }) => ({
      ...base,
      backgroundColor: isDisabled
        ? "var(--doc-page-edit-form-background-readonly)"
        : "var(--components-select-filter-dropdownIndicator-background)",
      color: "var(--components-select-filter-dropdownIndicator)",
      "&:hover": {
        color: "var(--components-select-filter-dropdownIndicator-hover)",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "var(--components-select-filter-indicatorSeparator-background)",
    }),

    placeholder: (base) => ({
      ...base,
      color: "var(--search-page-filters-form-control-placeholder)",
      fontStyle: "italic",
    }),

    singleValue: (base) => ({
      ...base,
      ...(inputStyles.singleValue || {}),
      color: "var(--components-select-filter-singleValue)",
      fontStyle: "italic",
    }),

    multiValue: (base) => ({
      ...base,
      borderRadius: "2px",
      marginTop: 0,
      marginBottom: 0,
      background: "var(--components-select-filter-multiValue-background)",
    }),

    multiValueLabel: (base) => ({
      ...base,
      padding: 0,
    }),

    option: (base, { isFocused, isDisabled }) => ({
      ...base,
      backgroundColor:
        isFocused && !isDisabled
          ? "var(--components-select-filter-option-background-enabled)"
          : "transparent",
      color: isDisabled
        ? "var(--components-select-filter-option-disabled)"
        : isFocused
        ? "var(--components-select-filter-option-focused)"
        : "var(--components-select-filter-option-disabled)",
      cursor: isDisabled ? "normal" : "pointer",
      paddingTop: "5px",
      paddingBottom: "5px",
      textDecoration: isDisabled ? "line-through" : "none",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),

    container: (provided, state) => ({
      ...provided,
      padding: 0,
      height: "fit-content",
      zIndex: 4,
    }),
    control: (provided, { isDisabled }) => ({
      ...provided,
      minHeight: "fit-content",
      height: "fit-content",
      borderColor: "var(--components-select-filter-control-border)",
      boxShadow: shadowed ? "2px 2px 3px var(--components-select-shadow)" : "none",
      "&:hover": {
        borderColor: "var(--components-select-filter-control-border-hover)",
      },
      cursor: "pointer",
      backgroundColor: isDisabled
        ? "var(--doc-page-edit-form-background-readonly)"
        : "var(--components-select-filter-control-background)",
      // marginLeft: "10px",
      // marginRight: "10px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      "& div": {
        padding: "5px",
        alignSelf: "stretch",
        alignItems: "center",
        borderRadius: "5px",
      },
    }),
    input: (provided, state) => ({
      ...provided,
      height: "21px",
    }),
  };
};

export const RefDataSelect = (props) => (
  <Select
    getOptionValue={(option) => option.id}
    getOptionLabel={(option) => option.name}
    getControlLabel={(props) =>
      props.selectProps.value.length > 1 ? props.data.shortName || props.data.name : props.data.name
    }
    {...props}
  />
);

export default Select;
