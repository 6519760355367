enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  REQUESTED = "REQUESTED",
  PENDING_INITIAL_PAYMENT = "PENDING_INITIAL_PAYMENT",
  OVERDUE_PAYMENT = "OVERDUE_PAYMENT",
  DEACTIVATED = "DEACTIVATED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}

export default SubscriptionStatus;
