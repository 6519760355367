import { EntityModel } from "hateoas-hal-types";
import parse, { domToReact } from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import TruncateMarkup, { TruncateProps } from "react-truncate-markup";
import { InlineLinkButton } from "../../components/Button/button";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { Bulletin } from "../../models/Bulletin.model";
import { useBulletin, useBulletinStats } from "../../services/bulletin-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import { scrollToTargetAdjusted } from "../JudgementPage/judgmenet-view-page-metadata";
import BulletinActsChart from "./bulletin-acts-chart";
import BulletinPageNavigation from "./bulletin-page-navigation";
import BulletinPageActions from "./bulletin-view-page-actions";
import BulletinViewPageLoading from "./bulletin-view-page-loading";
import "./bulletin-view-page.scss";
interface BulletinViewPageProps {
  bulletin: EntityModel<Bulletin>;
}

const BulletinViewPage = () => {
  const { bulletinId } = useParams();
  const bulletin = useBulletin(bulletinId);
  const navigate = useNavigate();
  useEffect(() => {
    if (bulletin && !bulletinId) {
      navigate(`/bulletins/${bulletin.id}`);
    }
  }, [bulletin, bulletinId, navigate]);

  return bulletin && bulletinId ? (
    <BulletinViewForm bulletin={bulletin} />
  ) : (
    <BulletinViewPageLoading mode="view" className="page-loading" />
  );
};

const BulletinViewForm: React.FC<BulletinViewPageProps> = ({ bulletin }) => {
  const stats = useBulletinStats(bulletin.id);

  return (
    <DocumentPageLayout
      id={bulletin.id}
      title={bulletin.title}
      status={bulletin.status}
      mode="view"
      accessMode={bulletin.accessMode}
      className="bulletin-page"
      menu={<BulletinPageActions bulletin={bulletin} />}
      navigation={<BulletinPageNavigation bulletin={bulletin} />}
    >
      <BulletinActsChart monthStats={stats} date={bulletin.publishDate} />
      <BulletinContent bulletin={bulletin} />
    </DocumentPageLayout>
  );
};

export const BulletinContent: React.FC<{ bulletin: EntityModel<Bulletin> }> = ({ bulletin }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setSelectedActId } = useSelectedActContext();
  useEffect(() => {
    ref.current?.querySelectorAll("a").forEach((link) => {
      const anchor = link as HTMLAnchorElement;
      anchor.onclick = () => {
        const prefix = anchor.href.indexOf("summaries") !== -1 ? "summary:" : "";
        setSelectedActId(prefix + anchor.href.substring(anchor.href.lastIndexOf("/") + 1));
        return false;
      };
    });
  }, [setSelectedActId]);
  return (
    <>
      <p id="b-disclaimer">
        <em>
          * Бюлетинът има за свой основен фокус актовете на Гражданска и Търговска колегия на
          Върховния касационен съд.
        </em>
      </p>
      <div className="act-text" ref={ref}>
        {parse(
          bulletin.contents.replaceAll(
            /(<h2.*?)(?=(<h2)|$)/gs,
            "<div class='main-section'>$1</div>"
          ),
          {
            replace,
          }
        )}
      </div>
    </>
  );
};

const TruncatePart: React.FC<TruncateProps> = ({ children, ...props }) => {
  const [shouldTruncate, setShouldTruncate] = useState(true);

  return shouldTruncate ? (
    <TruncateMarkup
      lines={4}
      tokenize="words"
      ellipsis={
        <span style={{ float: "right" }} className="show-more-btn">
          <InlineLinkButton onClick={() => setShouldTruncate(false)}>
            покажи повече
          </InlineLinkButton>
        </span>
      }
    >
      <div>{children}</div>
    </TruncateMarkup>
  ) : (
    <div>
      {children}{" "}
      <InlineLinkButton onClick={() => setShouldTruncate(true)} style={{ float: "right" }}>
        покажи по-малко
      </InlineLinkButton>
    </div>
  );
};

const replace = (node: any) => {
  // join answers <p>
  if ((node as any).attribs?.["class"] === "bp-answer") {
    if ((node as any).next?.attribs?.["class"] === "bp-answer") {
      return <></>;
    } else {
      const nodes = [];
      let curr = node as any;
      while (curr?.attribs?.["class"] === "bp-answer") {
        nodes.push(
          <>
            <span className="d-block mt-1" />
            <p className="bp-answer">{domToReact(curr.children, { replace })}</p>
          </>
        );
        curr = curr.prev;
      }

      nodes.reverse();

      return <TruncatePart>{nodes}</TruncatePart>;
    }
  }

  // render not references [X]
  if ((node as any).data) {
    return renderTextWithNoteReference((node as any).data);
  }
};

const renderTextWithNoteReference = (text: string) => {
  let result: any[] = [text];

  const newResult: any[] = [];
  result.forEach((token: string) => {
    if (typeof token !== "string") {
      newResult.push(token);
    } else {
      let remaining = token;
      let provision_regex = new RegExp(/\[\d+\]/);
      let remaining_provision_match = remaining.match(provision_regex);
      while (remaining_provision_match) {
        const idx = remaining_provision_match.index ? remaining_provision_match.index : 0;
        const match = remaining_provision_match[0];
        newResult.push(remaining.substr(0, idx));
        newResult.push(
          <Button
            variant="link"
            size="sm"
            className="inline-link"
            style={{ display: "contents", fontSize: "80%" }}
            onClick={(е: any) => {
              const el =
                document.getElementsByClassName("preset-notes")[
                  parseInt(match.substring(1, match.length - 1)) - 1
                ];
              el && scrollToTargetAdjusted(el as HTMLElement);
            }}
          >
            {match}
          </Button>
        );
        remaining = remaining.substr(idx + match.length);
        remaining_provision_match = remaining.match(provision_regex);
      }
      newResult.push(remaining);
    }
  });
  return <>{newResult}</>;
};

export default BulletinViewPage;
