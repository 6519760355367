import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useSWRConfig } from "swr";
import { PrimaryButton } from "../../components/Button/button";
import { HelpLink, HELP_LINK_TIMELINE } from "../../components/HelpLink/help-link";
import IndicatorIcons from "../../components/IndicatorIcons";
import Skeleton from "../../components/Skeleton";
import {
  hasPrivilege,
  Privileges,
  useAuth,
  useProtectedAction,
  Visible,
} from "../../contexts/auth.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { useSearchContext } from "../../contexts/search.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { Summary } from "../../models/Summary.model";
import SummaryTopic from "../../models/SummaryTopic.model";
import { useSummariesTimeline } from "../../services/home-services";
import { prefetchSummary, summaryHref } from "../../services/summary-services";
import { shortenUrl } from "../../services/url-services";
import AccessIndicator from "./access-indicator";
import { PUBLISH_DATE_FORMAT } from "./focused-section";
import { TimelineTopicSelectProps } from "./timeline-topic-select";
import "./timeline.css";

const Timeline: React.FC<TimelineTopicSelectProps> = ({ selectedTopic }) => {
  const auth = useAuth();
  const { data, size, setSize, totalCount, hasMore } = useSummariesTimeline(selectedTopic);
  const isLoadingMore = size > 0 && data && typeof data[size - 1] === "undefined";
  const protectedAction = useProtectedAction();
  return data ? (
    <>
      {totalCount! > 0 && (
        <>
          <div className="timeline">
            <span className="timeline-now-title">
              <FormattedMessage id="home-page.sections.timeline.now-title" />
              <HelpLink articleId={HELP_LINK_TIMELINE} />
            </span>
          </div>
          <div className="timeline">
            <div>&nbsp;</div>
            <span className="timeline-now">
              <FormattedMessage id="home-page.sections.timeline.now" />
            </span>
          </div>{" "}
          <VerticalTimeline
            animate={false}
            className={`vertical-timeline-custom-line ${hasMore ? "vertical-timeline-more" : ""} ${
              !auth.hasPrivilige(Privileges.EDIT_SUMMARIES) ? "date-clickable" : ""
            }`}
          >
            {data?.map((page) => {
              return page?._embedded.items.map((summary) => (
                <TimelineElement summary={summary} selectedTopic={selectedTopic} />
              ));
            })}
          </VerticalTimeline>
        </>
      )}
      {hasMore && (
        <PrimaryButton
          submitting={isLoadingMore}
          messageId="button.load-more"
          onClick={protectedAction(() => setSize(size + 1))}
        />
      )}
      <div className="spacer" />
      {totalCount! === 0 && (
        <h5 className="no-data-message">
          <FormattedMessage id="home-page.sections.timeline.no-data" />
        </h5>
      )}
    </>
  ) : (
    <TimelineLoading />
  );
};

const TimelineElement: React.FC<{
  summary: EntityModel<Summary>;
  selectedTopic?: SummaryTopic;
}> = ({ summary, selectedTopic }) => {
  const intl = useIntl();
  const auth = useAuth();
  const showPublishDate = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const protectedAction = useProtectedAction(summary.accessMode === DocumentAccessMode.FREE);
  const navigate = useNavigate();
  const progress = useLoadingProgress();
  const { cache } = useSWRConfig();
  const { opened, addOpened } = useSearchContext();
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date={intl.formatDate(
        dayjs(
          showPublishDate ? summary.publishDate : summary.relatedJudgementAct.actDate,
          PUBLISH_DATE_FORMAT
        ).toDate(),
        {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }
      )}
      iconStyle={{
        background: "var(--home-page-focused-section-background)",
        color: "var(--home-page-background)",
      }}
      key={summary.id}
      onTimelineElementClick={protectedAction(async (e: any) => {
        if (
          (e.target as HTMLElement).classList.contains("vertical-timeline-element-date") &&
          !auth.hasPrivilige(Privileges.EDIT_SUMMARIES)
        ) {
          const date = dayjs(summary.relatedJudgementAct.actDate, PUBLISH_DATE_FORMAT).format(
            "DD.MM.YYYY"
          );
          const params = new URLSearchParams();
          params.append("actPeriod", `${date} - ${date}`);
          const q = await shortenUrl(params.toString());
          navigate(`/search/summaries?q=${q}`);
          return;
        }
      })}
    >
      <a
        href={summaryHref(summary.id)}
        className="deco-none w-100 h-100 position-absolute"
        onClick={protectedAction(async (e: any) => {
          if (!e.ctrlKey && !e.metaKey) {
            e.preventDefault();

            progress.start();
            try {
              await prefetchSummary(summary.id, cache);
            } finally {
              progress.complete();
            }
            navigate(summaryHref(summary.id), {
              state: { from: "/", callbackState: { selectedTopic } },
            });
            addOpened(summary.id);
          }
        })}
      >
        &nbsp;
      </a>
      <div
        className={`vertical-timeline-element-title fw-bold ${
          opened.indexOf(summary.id) > -1 ? "visited" : ""
        }`}
      >
        {summary.shortTitle}
        <AccessIndicator mode={summary.accessMode} />
      </div>
      {summary.relatedJudgementAct.title}
      {summary.reporters?.length > 0 && (
        <div>
          <span className="label">
            <FormattedMessage id="home-page.sections.timeline.reporter" />:{" "}
          </span>
          {summary.reporters?.join(", ")}
        </div>
      )}
      <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
        <div>
          <span className="label">
            <FormattedMessage id="home-page.sections.timeline.editor" />:{" "}
          </span>
          {summary.editor.name}
        </div>
      </Visible>
      <div className="vertical-timeline-line-indicators">
        <IndicatorIcons item={summary} />
      </div>
    </VerticalTimelineElement>
  );
};

const TimelineLoading = () => {
  const intl = useIntl();
  return (
    <>
      <div className="timeline">
        <span className="timeline-now">
          <FormattedMessage id="home-page.sections.timeline.now" />
        </span>
      </div>{" "}
      <VerticalTimeline animate={false} className="vertical-timeline-custom-line">
        {[0, 1, 2, 3, 4, 5].map((idx) => {
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={intl.formatDate(new Date(), {
                year: "numeric",
                month: "long",
                day: "2-digit",
              })}
              iconStyle={{
                background: "var(--home-page-focused-section-background)",
                color: "var(--home-page-background)",
              }}
              key={idx}
            >
              <Skeleton count={4} />
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </>
  );
};

export default Timeline;
