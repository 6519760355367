import memoizee from "memoizee";
import { useParams } from "react-router";
import useRequest, { laggy } from "../../api";
import { useSearchContext } from "../../contexts/search.context";
import RefData from "../../models/RefData.model";
import SummaryTopic from "../../models/SummaryTopic.model";
import {
  useActMonths,
  useActOtherFilters,
  useActYears,
  useAllowedByCourt,
  useCaseStatuses,
  useCaseTypes,
  useChambers,
  useCourts,
  useDocumentAccessModes,
  useDocumentSets,
  useDocumentStatuses,
  useEUActOtherFilters,
  useGroundOfAppeals,
  useJudgementActTypes,
  useLatestActFilters,
  useNewsArticleCategories,
  useNewsArticleSections,
  useOtherFilters,
  usePersonalCategories,
  useProceedings,
  useShowInHeader,
  useSummaryTypes,
} from "../../services/ref-data-services";
import { useSummaryTopics } from "../../services/summary-topic.services";

export type FiltersType = {
  [key: string]: RefData[];
  summaryTopic: SummaryTopic[];
} & { query: string[] };

export enum SearchCategory {
  SUMMARIES = "summaries",
  ACTS = "acts",
  ADM_ACTS = "adm-acts",
  CONST_ACTS = "const-acts",
  SC_ACTS = "sc-acts",
  EU_ACTS = "eu-acts",
  NEWS_ARTICLES = "news-articles",
  QUESTIONS = "questions",
  SEARCH = "search",
}

export const useSearchCategory = (): SearchCategory => {
  const { category } = useParams();
  return (category as SearchCategory) || SearchCategory.SEARCH;
};

export const useQuery = (): any => {
  const { search } = useSearchContext();
  return toObject(search);
};

const groupParamsByKey = (params: URLSearchParams) =>
  Array.from(params.entries()).reduce((acc: any, tuple) => {
    const [key, val] = tuple;
    if (acc.hasOwnProperty(key)) {
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        acc[key] = [acc[key], val];
      }
    } else {
      acc[key] = val;
    }

    return acc;
  }, {});

export const toObject = memoizee((search?: string) =>
  groupParamsByKey(new URLSearchParams(search))
);

export const useAvailableFilters = (explicitCategory?: string) => {
  const category = useSearchCategory();
  const searchCategory = explicitCategory || category;
  const { search } = useSearchContext();

  const queryParam = new URLSearchParams(search);
  queryParam.delete("sort");
  const { data } = useRequest<any>(
    `/${
      searchCategory === "acts"
        ? "judgement-acts/domestic"
        : searchCategory === "const-acts"
        ? "judgement-acts/constitutional"
        : searchCategory === "sc-acts"
        ? "judgement-acts/supreme-court"
        : searchCategory === "adm-acts"
        ? "judgement-acts/administrative"
        : searchCategory === "eu-acts"
        ? "judgement-acts/eu"
        : searchCategory === "news-articles"
        ? "news-articles"
        : searchCategory === "questions"
        ? "questions"
        : "summaries"
    }/available-filters?` + queryParam.toString(),
    (a) => a,
    { use: [laggy] }
  );
  return data || {};
};

export const isQuoted = (value: string) => value.startsWith('"') && value.endsWith('"');
export const containsQuote = (value: string) => value.indexOf('"') > -1;

export const unquote = (value: string) => value.substring(1, value.length - 1);

export const quoteIfAllowed = (value: string) =>
  containsQuote(value) || !isQuoteApplicable(value) ? value.trim() : `"${value.trim()}"`;

export const isQuoteApplicable = (value: string) => value.trim().indexOf(" ") > -1;

export const useFiltersCount = (): number => {
  const queryObject = useQuery();
  return (
    Object.keys(queryObject).filter((key) => key !== "sort").length +
    (queryObject.query
      ? Array.isArray(queryObject.query)
        ? (queryObject.query as string[]).length - 1
        : 0
      : 0)
  );
};

export const useFilters = (qo?: any): FiltersType => {
  let queryObject = useQuery();
  queryObject = qo || queryObject;

  const { data: judgementTypes } = useJudgementActTypes();
  const { data: summaryTypes } = useSummaryTypes();
  const { data: caseTypes } = useCaseTypes();
  const { data: courts } = useCourts();
  const { data: chambers } = useChambers();
  const { data: proceedings } = useProceedings();
  const { data: groundOfAppeals } = useGroundOfAppeals();
  const { data: topics } = useSummaryTopics();
  const { data: statuses } = useDocumentStatuses();
  const { data: accessModes } = useDocumentAccessModes();
  const { data: actYears } = useActYears();
  const { data: actMonths } = useActMonths();
  const { data: others } = useOtherFilters();
  const { data: othersAct } = useActOtherFilters();
  const { data: euOthersAct } = useEUActOtherFilters();
  const { data: latestActs } = useLatestActFilters();
  const { data: categories } = useNewsArticleCategories();
  const { data: sections } = useNewsArticleSections();
  const { data: showInHeader } = useShowInHeader();
  const { data: documentSets } = useDocumentSets();
  const { data: allowedByCourt } = useAllowedByCourt();
  const { data: personalCategory } = usePersonalCategories();
  const { data: caseStatuses } = useCaseStatuses();

  const refData = new Map<string, RefData[]>();
  refData.set("judgementType", judgementTypes!);
  refData.set("summaryType", summaryTypes!);
  refData.set("caseType", caseTypes!);
  refData.set("court", courts!);
  refData.set("chamber", chambers!);
  refData.set("proceeding", proceedings!);
  refData.set("allowedByCourt", allowedByCourt!);
  refData.set("personalCategory", personalCategory!);
  refData.set("groundOfAppeal", groundOfAppeals!);
  refData.set("summaryTopic", topics!);
  refData.set("status", statuses!);
  refData.set("accessMode", accessModes!);
  refData.set("documentSet", documentSets!);
  refData.set("actYear", actYears!);
  refData.set("actMonth", actMonths!);
  refData.set("other", others!);
  refData.set("otherAct", othersAct!);
  refData.set("otherAct", euOthersAct!);
  refData.set("latestActs", latestActs!);
  refData.set("category", categories!);
  refData.set("section", sections!);
  refData.set("showInHeader", showInHeader!);
  refData.set("caseStatus", caseStatuses!);
  refData.set(
    "reporter",
    (queryObject.reporter
      ? Array.isArray(queryObject.reporter)
        ? queryObject.reporter
        : [queryObject.reporter]
      : []
    ).map((value: string) => ({ value, label: value }))
  );
  refData.set(
    "editor",
    (queryObject.editor
      ? Array.isArray(queryObject.editor)
        ? queryObject.editor
        : [queryObject.editor]
      : []
    ).map((value: string) => ({ value, label: value }))
  );
  refData.set(
    "provision",
    (queryObject.provision
      ? Array.isArray(queryObject.provision)
        ? queryObject.provision
        : [queryObject.provision]
      : []
    ).map((value: string) => ({ value, label: value }))
  );
  refData.set(
    "relatedProvision",
    (queryObject.relatedProvision
      ? Array.isArray(queryObject.relatedProvision)
        ? queryObject.relatedProvision
        : [queryObject.relatedProvision]
      : []
    ).map((value: string) => ({ value, label: value }))
  );
  refData.set(
    "summaryShortTitle",
    (queryObject.summaryShortTitle
      ? Array.isArray(queryObject.summaryShortTitle)
        ? queryObject.summaryShortTitle
        : [queryObject.summaryShortTitle]
      : []
    ).map((value: string) => ({ value, label: value }))
  );
  refData.set(
    "qaShortTitle",
    (queryObject.qaShortTitle
      ? Array.isArray(queryObject.qaShortTitle)
        ? queryObject.qaShortTitle
        : [queryObject.qaShortTitle]
      : []
    ).map((value: string) => ({ value, label: value }))
  );

  const result: any = {};
  let incomplete = false;
  Object.keys(queryObject).forEach((key) => {
    let value: string[] = Array.isArray(queryObject[key]) ? queryObject[key] : [queryObject[key]];

    const mappedValue = value
      .map((token) =>
        refData.get(key)
          ? refData
              .get(key)
              ?.filter(
                (i) => (extractValue(i, "id") || extractValue(i, "value") || i) + "" === token
              )[0]
          : token
      )
      .filter((value) => !!value);
    result[key] = mappedValue;
    incomplete = incomplete || mappedValue.length === 0;
  });
  return incomplete ? {} : result;
};

export const useSort = (defaultSort?: { column: string; direction: string }) => {
  const queryObject = useQuery();
  const sort = queryObject.sort as string;
  if (sort) {
    const [column, direction] = sort.split(",");
    return { column, direction };
  }
  return defaultSort;
};

const extractValue = (value: any, property: string) =>
  typeof value !== "undefined" && typeof value[property] !== "undefined"
    ? value[property]
    : undefined;

export const buildNextSearchParams = (searchParam: URLSearchParams, data: any) => {
  const newSearchParam = new URLSearchParams(searchParam);
  Object.keys(data).forEach((key) => {
    const value = data[key];
    newSearchParam.delete(key);
    if (Array.isArray(value)) {
      if (value.length) {
        value
          .map((val) => extractValue(val, "id") || extractValue(val, "value") || val)
          .forEach((val) => {
            newSearchParam.append(key, val);
          });
      }
    } else if (value) {
      newSearchParam.append(
        key,
        extractValue(value, "id") || extractValue(value, "value") || value
      );
    }
  });
  const sortedParams = new URLSearchParams(
    Array.from(newSearchParam.entries()).sort((e1, e2) =>
      (e1[0] + "::" + e1[1]).localeCompare(e2[0] + "::" + e2[1])
    )
  );
  sortedParams.sort();
  return sortedParams;
};

export const getTabUrl = (location: { pathname: string; search: string }, category?: string) => {
  return location.pathname + "/../" + category + location.search;
};

export const displayCount = (count?: number) => ((count || 0) > 2000000 ? "2000000+" : count);
