/* eslint-disable no-underscore-dangle */
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Provision from "../../models/Provision.model";
import ReferencedJudgementAct from "../../models/ReferencedJudgementAct.model";
import { getProvisionFullTitle } from "../../services/ref-data-services";

export const renderTextWithProvisionsAndActs = (
  text: string,
  provisions: Provision[],
  referencedJudgementAct: ReferencedJudgementAct[],
  setSelectedActId: (actId?: string) => void
) => {
  const provision_conflicts = new Set();
  provisions.forEach((provision) => {
    let provision_root = provision.title;
    if (provision_root.indexOf("-") > 0) {
      provision_root = provision_root.substr(0, provision_root.indexOf("-"));
    }
    if (provision_root.indexOf("(") > 0) {
      provision_root = provision_root.substr(0, provision_root.indexOf("(")).trim();
    }
    let candidate_occurences = 0;
    provisions.forEach((candidate) => {
      if (candidate.title !== provision.title) {
        if (candidate.title.indexOf(provision_root) > -1) {
          provision_conflicts.add(candidate.title);
        }
      } else {
        candidate_occurences += 1;
      }
    });
    if (candidate_occurences > 1) {
      provision_conflicts.add(provision.title);
    }
  });

  let result: any[] = [text];

  referencedJudgementAct.forEach((act) => {
    const newResult: any[] = [];
    result.forEach((token: string) => {
      if (typeof token !== "string") {
        newResult.push(token);
      } else {
        let remaining = token;
        while (remaining.indexOf(act.title) > -1) {
          const idx = remaining.indexOf(act.title);
          newResult.push(remaining.substr(0, idx));
          newResult.push(
            <Button
              variant="link"
              size="sm"
              className="inline-link"
              style={{ display: "contents" }}
              key={act.id}
              onClick={() => {
                setSelectedActId(act.id);
              }}
            >
              {act.title}
            </Button>
          );
          remaining = remaining.substr(idx + act.title.length);
        }
        newResult.push(remaining);
      }
    });
    result = newResult;
  });

  [...provisions]
    .sort(
      (p1, p2) => (p2.sgYear ? 1 : -1) - (p1.sgYear ? 1 : -1) || p2.title.localeCompare(p1.title)
    )
    .forEach((provision) => {
      const newResult: any[] = [];
      result.forEach((token: string) => {
        if (typeof token !== "string") {
          newResult.push(token);
        } else {
          let remaining = token;
          let provision_regex = new RegExp(
            provision.title
              .replace(/[.*+?^${}|[\]\\]/g, "\\$&")
              .replaceAll(")", "\\))*")
              .replaceAll(" (", "(\\s*\\(")
              .replace(/-([0-9]{4})/g, "(-$1)*")
              .replaceAll(/["“”„]/g, ".") +
              (provision.title.indexOf("ред") > 0
                ? ""
                : "(\\s*\\(ред\\.(акция)* първоначална\\))*"),
            "u"
          );
          if (provision_conflicts.has(provision.title)) {
            const full_title = getProvisionFullTitle(provision);
            provision_regex = new RegExp(
              full_title.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").replaceAll(/["“”„]/g, "."),
              "u"
            );
          }
          let remaining_provision_match = remaining.match(provision_regex);
          while (remaining_provision_match) {
            const idx = remaining_provision_match.index ? remaining_provision_match.index : 0;

            newResult.push(remaining.substr(0, idx));
            newResult.push(
              <OverlayTrigger
                trigger="click"
                placement="auto"
                key={provision.id}
                rootClose
                overlay={
                  <Tooltip id={provision.id} className="provision-tooltip">
                    <strong>{getProvisionFullTitle(provision)}</strong>
                    {provision?.text && (
                      <div dangerouslySetInnerHTML={{ __html: provision?.text }} />
                    )}
                  </Tooltip>
                }
              >
                <span>
                  <Button
                    variant="link"
                    size="sm"
                    className="inline-link"
                    style={{ display: "contents" }}
                    onClick={(е: any) => е.preventDefault()}
                  >
                    {remaining.match(provision.title + "\\s*\\(ред\\.(акция)* първоначална\\)")
                      ? (provision.title + " (ред. първоначална)").replaceAll("  ", " ")
                      : provision_conflicts.has(provision.title)
                      ? getProvisionFullTitle(provision)
                      : provision.title}
                  </Button>
                </span>
              </OverlayTrigger>
            );
            remaining = remaining.substr(
              idx +
                (remaining_provision_match ? remaining_provision_match[0] : provision.title).length
            );
            remaining_provision_match = remaining.match(provision_regex);
          }
          newResult.push(remaining);
        }
      });
      result = newResult;
    });
  return <>{result}</>;
};

export const missingProvisions = (text: string, provisions: Provision[]) => {
  let missingProvisionsSet: Set<string> = new Set();
  const regex = /(чл\.)\s*[0-9]+/gi;
  const matches = text.match(regex);
  const provisionRegexList = provisions.map((provision) => {
    const escapedTitle = escapeRegExp(provision.title);
    return new RegExp(escapedTitle, "iu");
  });

  if (matches) {
    const contextLength = Math.max(...provisions.map((provision) => provision.title.length));

    matches.forEach((match) => {
      const matchIndex = text.indexOf(match);
      const startIndex = matchIndex;
      const endIndex = Math.min(text.length, matchIndex + match.length + contextLength);

      const contextAfterMatch = text.substring(startIndex, endIndex);

      if (!provisionRegexList.some((regex) => regex.test(contextAfterMatch))) {
        missingProvisionsSet.add(cleanContextAfterMatch(contextAfterMatch) + " ... ");
      }
    });
  }

  const missingProvisionsResult = Array.from(missingProvisionsSet);

  return missingProvisionsResult.length > 0 ? <>{missingProvisionsResult}</> : undefined;
};

export const missingActs = (text: string, referencedJudgementAct: ReferencedJudgementAct[]) => {
  let missingActsResult: any[] = [];
  const regex =
    /(тълкувателно)*\s*(решение|определение|разпореждане|постановление)\s*(№|(\s*от\s*[0-9]))/gi;
  const matches = text.match(regex);
  const actRegexList = referencedJudgementAct.map(
    (act) => new RegExp(act.title.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i")
  );

  if (matches) {
    const contextLength = Math.max(...referencedJudgementAct.map((act) => act.title.length));

    matches.forEach((match) => {
      const matchIndex = text.indexOf(match);
      const startIndex = matchIndex;
      const endIndex = Math.min(text.length, matchIndex + match.length + contextLength);

      const contextAfterMatch = text.substring(startIndex, endIndex);

      if (!actRegexList.some((regex) => regex.test(contextAfterMatch))) {
        missingActsResult.push(cleanContextAfterMatch(contextAfterMatch) + " ... ");
      }
    });
  }

  return missingActsResult.length > 0 ? <>{missingActsResult}</> : undefined;
};

const escapeRegExp = (str: string) => {
  return (
    str
      .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      //.replace(/§/g, ".")
      .replace(/\//g, ".")
  );
};

const cleanContextAfterMatch = (str: string) => {
  return str.replace("<p>", "").replace("</p>", "").replace("<em>", "").replace("</em>", "");
};
