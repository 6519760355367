/* eslint-disable react/jsx-props-no-spreading */
import { useIntl } from "react-intl";
import { PrimaryButton } from "../../components/Button/button";
import { ManageScroll } from "../../components/ManageScroll";
import { withActiveSubscrption } from "../../contexts/auth.context";
import DocumentStats from "../../models/DocumentStats";
import { useDocumentStats } from "../../services/document-services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import CurrentFilters from "./CurrentFilters";
import FiltersSideBar from "./FiltersSidebar/filters-sidebar";
import { displayCount, SearchCategory, useSearchCategory } from "./search-utils";
import SearchInput from "./SearchInput";
import SearchResults from "./SearchResults";

const SearchPage = () => {
  const intl = useIntl();
  const stats = useDocumentStats();
  const searchCategory = useSearchCategory();
  const count = getCount(searchCategory, stats);

  return (
    <AppPageLayout
      navigation={<FiltersSideBar />}
      navigationDrawerHeader={(setIsOpen) => (
        <PrimaryButton onClick={() => setIsOpen(false)}>
          Виж&nbsp;
          <b>{displayCount(count)}</b>
          &nbsp;{count && count > 1 ? "резултатa" : "резултат"}
        </PrimaryButton>
      )}
      title={intl.formatMessage({ id: "search-page.result-title" })}
      className="search-page"
    >
      <ManageScroll />
      <SearchInput />
      <CurrentFilters />
      <SearchResults />
    </AppPageLayout>
  );
};

const getCount = (searchCategory: SearchCategory, stats?: DocumentStats) =>
  searchCategory === SearchCategory.ACTS
    ? stats?.domesticActsCount.total
    : searchCategory === SearchCategory.EU_ACTS
    ? stats?.euActsCount.total
    : searchCategory === SearchCategory.ADM_ACTS
    ? stats?.administrativeActsCount.total
    : searchCategory === SearchCategory.CONST_ACTS
    ? stats?.constActsCount.total
    : searchCategory === SearchCategory.SC_ACTS
    ? stats?.supremeCourtActsCount.total
    : searchCategory === SearchCategory.SUMMARIES
    ? stats?.summariesCount.total
    : searchCategory === SearchCategory.NEWS_ARTICLES
    ? stats?.newsArticlesCount.total
    : searchCategory === SearchCategory.QUESTIONS
    ? stats?.questionsCount.total
    : 0;

export default withActiveSubscrption(SearchPage);
