import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { FaTimes } from "react-icons/fa";
import { RiChatQuoteFill, RiChatQuoteLine } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import Skeleton from "../../../components/Skeleton";
import { withActiveSubscrption } from "../../../contexts/auth.context";
import { prefetchDocumentStats } from "../../../services/document-services";
import {
  isQuoteApplicable,
  isQuoted,
  quoteIfAllowed,
  unquote,
  useFilters,
  useFiltersCount,
} from "../search-utils";
import CurrentFiltersLayout from "./current-filters-layout";

const CurrentFilters = () => {
  const filtersCount = useFiltersCount();
  return <CurrentFiltersLayout>{filtersCount > 0 && <Values />}</CurrentFiltersLayout>;
};

export const Values: React.FC<{ queryObject?: any }> = ({ queryObject }) => {
  const filters = useFilters(queryObject);

  return (
    <>
      {Object.keys(filters)
        .filter((key) => key !== "sort" && key !== "query")
        .map((key) => (
          <SearchFieldController
            name={key}
            key={key}
            preload={prefetchDocumentStats}
            render={({ field: { onChange, value, label } }) => {
              const renderValue = (filters[key] as any[])
                .map((v) => v.name || v.value || v)
                .join(", ");
              return (
                <Badge bg="light" className="ui-chip" title={renderValue as any}>
                  <span className="ui-chip-text">{`${label}: ${renderValue}`}</span>

                  <FaTimes onClick={() => onChange([])} />
                </Badge>
              );
            }}
          />
        ))}
      <SearchFieldController
        name="query"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value, label } }) => (
          <>
            {(value || []).map((term, i) => (
              <Badge key={term} bg="light" className="ui-chip">
                <span className="ui-chip-text" title={term as any}>
                  {`${label}: ${term}`}
                </span>
                {isQuoted(term) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="copy-documents-tooltip" {...props}>
                        <FormattedMessage id="current-filters.disable-exact-match-search" />
                      </Tooltip>
                    )}
                  >
                    <span>
                      <RiChatQuoteFill onClick={() => quoteQueryFilter(i, value, onChange)} />
                    </span>
                  </OverlayTrigger>
                ) : isQuoteApplicable(term) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="copy-documents-tooltip" {...props}>
                        <FormattedMessage id="current-filters.enable-exact-match-search" />
                      </Tooltip>
                    )}
                  >
                    <span>
                      <RiChatQuoteLine onClick={() => quoteQueryFilter(i, value, onChange)} />
                    </span>
                  </OverlayTrigger>
                ) : null}
                <FaTimes onClick={() => removeQueryFilter(i, value, onChange)} title="Затвори" />
              </Badge>
            ))}
          </>
        )}
      />
    </>
  );
};

export const ReadonlyValues: React.FC<{ queryObject?: any }> = ({ queryObject }) => {
  const filters = useFilters(queryObject);

  return (
    <>
      {Object.keys(filters)
        .filter((key) => key !== "sort" && key !== "query")
        .map((key) => (
          <SearchFieldController
            name={key}
            key={key}
            render={({ field: { label } }) => {
              const renderValue = Array.isArray(filters[key])
                ? (filters[key] as any[]).map((v) => v.name || v.value || v).join(", ")
                : filters[key];
              return (
                <Badge bg="light" className="ui-chip" title={renderValue as any}>
                  <span className="ui-chip-text">{`${label}: ${renderValue}`}</span>
                </Badge>
              );
            }}
          />
        ))}
      <SearchFieldController
        name="query"
        preload={prefetchDocumentStats}
        render={({ field: { label } }) => (
          <>
            {(filters.query || []).map((term, i) => (
              <Badge key={term} bg="light" className="ui-chip">
                <span className="ui-chip-text" title={term as any}>
                  {`${label}: ${term}`}
                </span>
              </Badge>
            ))}
          </>
        )}
      />
    </>
  );
};

const removeQueryFilter = (idx: number, value: any[], onChange: (value: any[]) => void) => {
  const newValue = [...value];
  newValue.splice(idx, 1);
  onChange(newValue);
};

const quoteQueryFilter = (idx: number, value: any[], onChange: (value: any[]) => void) => {
  const newValue = [...value];
  newValue[idx] = isQuoted(newValue[idx]) ? unquote(newValue[idx]) : quoteIfAllowed(newValue[idx]);
  onChange(newValue);
};

export default withActiveSubscrption(CurrentFilters, Skeleton);
