/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import { VscMenu } from "react-icons/vsc";
import { FormattedMessage } from "react-intl";
import ErrorBoundary from "../../../components/ErrorBoundary";
import {
  HelpLink,
  HELP_LINK_NAV_FILTERS,
  HELP_LINK_NAV_TOPICSEARCH,
} from "../../../components/HelpLink/help-link";
import Collapse from "../../../components/SimpleCollapse";
import {
  hasPrivilege,
  not,
  Privileges,
  Visible,
  withActiveSubscrption,
} from "../../../contexts/auth.context";
import { useSearchCategory } from "../search-utils";
import ExtendedFilters from "./extended-filters";
import { isActsCategory, isAdministrativeActsCategory, isEuActsCategory } from "./filters";
import FiltersSideBarLoading from "./filters-sidebar-loading";
import "./filters-sidebar.css";
import StandardFilters from "./standard-filters";
import TopicFilter from "./topic-filter";

type FiltersSideBarProps = {
  landingPage?: boolean;
};

const FiltersSideBar: FunctionComponent<FiltersSideBarProps> = ({ landingPage = false }) => {
  const searchCategory = useSearchCategory();
  const summariesCategory =
    searchCategory === "summaries" || searchCategory === "questions" || landingPage;

  return (
    <Container fluid className="filters-sidebar">
      <Collapse
        icon={<VscMenu />}
        label={
          <>
            <FormattedMessage id="filters-sidebar.filters" />
            <HelpLink articleId={HELP_LINK_NAV_FILTERS} />
          </>
        }
        defaultExpanded={!landingPage}
      >
        {!landingPage ? (
          <ErrorBoundary message="Възникна грешка при зареждането на филтрите">
            <Visible when={hasPrivilege(Privileges.SEARCH_EXTENDED_FILTERS)}>
              <ExtendedFilters />
            </Visible>
            <Visible when={not(hasPrivilege(Privileges.SEARCH_EXTENDED_FILTERS))}>
              <StandardFilters />
            </Visible>
          </ErrorBoundary>
        ) : (
          <span className="text-muted">Изберете категория, за да заредите филтрите</span>
        )}
      </Collapse>
      <div style={{ lineHeight: "1" }}>&nbsp;</div>
      {(summariesCategory ||
        isActsCategory(searchCategory) ||
        isEuActsCategory(searchCategory) ||
        isAdministrativeActsCategory(searchCategory)) && (
        <Collapse
          className="topic-filter"
          icon={<VscMenu />}
          label={
            <>
              <FormattedMessage id="filters-sidebar.topic.search" />
              <HelpLink articleId={HELP_LINK_NAV_TOPICSEARCH} />
            </>
          }
        >
          <ErrorBoundary message="Възникна грешка при зареждането на филтрите">
            <TopicFilter />
          </ErrorBoundary>
        </Collapse>
      )}
    </Container>
  );
};

export default withActiveSubscrption(FiltersSideBar, FiltersSideBarLoading);
