import Table from "react-bootstrap/Table";
import { useDeviceSelectors } from "react-device-detect";
import Skeleton from "../../../components/Skeleton";
import useMultipleSelection from "../../../hooks/useMultipleSelection";
import ResultsTableCheckbox from "./results-table-checkbox";
import ResultsTableHeader from "./results-table-header";
import "./results-table.scss";

const ResultsTableLoading: React.FC<{ count?: number; perRowCount?: number }> = ({
  count = 10,
  perRowCount = 2,
}) => {
  const methods = useMultipleSelection();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  return (
    <div className="results-table-container">
      <Table borderless responsive size="sm" className="results-table">
        <ResultsTableHeader {...methods} />
        <tbody>
          {Array.from({ length: count }, (_, i) => i + 1).map((idx) => (
            <tr key={idx}>
              {!isMobileOnly && (
                <td>
                  <ResultsTableCheckbox {...methods} />
                </td>
              )}
              <td>
                <Skeleton count={perRowCount} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ResultsTableLoading;
