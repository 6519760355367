/* eslint-disable react/jsx-props-no-spreading */
import Collapse from "@kunukn/react-collapse";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FormattedMessage, useIntl } from "react-intl";
import { ApiResponse } from "../../../api";
import { InlineLinkButton } from "../../../components/Button/button";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import SelectMultiple from "../../../components/SimpleSelect/select-multiple";
import { Privileges, useAuth } from "../../../contexts/auth.context";
import RefData from "../../../models/RefData.model";
import { prefetchDocumentStats } from "../../../services/document-services";
import {
  useActMonths,
  useActOtherFilters,
  useActsChambers,
  useActsProceedings,
  useActYears,
  useAdministrativeActsChambers,
  useAdministrativeActsProceedings,
  useAllowedByCourt,
  useCaseStatuses,
  useCaseTypes,
  useChambers,
  useCourts,
  useDocumentAccessModes,
  useDocumentSets,
  useDocumentStatuses,
  useDomesticCourts,
  useDomesticJudgementActTypes,
  useEUActOtherFilters,
  useEuCourts,
  useEuJudgementActTypes,
  useGroundOfAppeals,
  useJudgementActTypes,
  useLatestActFilters,
  useNewsArticleCategories,
  useNewsArticleSections,
  useOtherFilters,
  usePersonalCategories,
  useProceedings,
  useShowInHeader,
  useSummaryTypes,
} from "../../../services/ref-data-services";
import { useCurrentUser } from "../../../services/user-services";
import { displayCount, useAvailableFilters, useQuery, useSearchCategory } from "../search-utils";
import FilterPanel from "./filter-panel";
import "./filters-sidebar.css";
import ResponsiveFilter from "./responsive-filter";

export type RefDataFilterPanelProps = {
  filterName: string;
  labelKey: string;
  isMulti?: boolean;
  show?: boolean;
  useData: () => ApiResponse<RefData[]>;
  useFilters?: any;
  showSubLabel?: boolean;
  prefetch?: boolean;
  reverse?: boolean;
};

export const RefDataFilterPanel: FunctionComponent<RefDataFilterPanelProps> = ({
  filterName,
  labelKey,
  isMulti = true,
  useData,
  show = true,
  useFilters = useAvailableFilters,
  showSubLabel = false,
  prefetch = true,
  reverse = false,
}) => {
  const { data } = useData();

  const groupedData = useMemo(() => {
    const grouped = data
      ?.reduce((acc: any[], item) => {
        const category = item.category || "default";
        const element = acc.filter((e) => e.label === category);
        if (!element[0]) {
          acc.push({ label: category, options: [item] });
        } else {
          element[0].options.push(item);
        }
        return acc;
      }, [])
      .sort((g1, g2) => g1.label.localeCompare(g2.label));
    reverse && grouped?.reverse();
    return grouped?.length === 1
      ? (grouped[0].options as any[])
      : grouped
          ?.map((group) => [
            { value: group.label, label: group.label, _type: "group" },
            ...group.options,
          ])
          .flat(2);
  }, [data, reverse]);

  const availableFilters = useFilters();

  return show ? (
    <FilterPanel label={<FormattedMessage id={`filters-sidebar.filters.${labelKey}`} />}>
      <SearchFieldController
        name={filterName}
        preload={prefetch ? prefetchDocumentStats : undefined}
        render={({ field: { onChange, value, label } }) => {
          const selectValue = value
            .map((id: any) => data?.find((el) => el.id + "" === id))
            .filter((el) => !!el);
          return (
            <ResponsiveFilter>
              <SelectMultiple
                onChange={onChange}
                value={selectValue}
                options={groupedData}
                getOption={(o) =>
                  o._type === "group"
                    ? { ...o, className: "group-label", disabled: true }
                    : {
                        label:
                          o!.name +
                          (availableFilters?.[filterName] && availableFilters?.[filterName][o!.id]
                            ? ` (${displayCount(availableFilters?.[filterName][o!.id])})`
                            : ""),
                        value: o!.id,
                        subLabel: o!.shortName,
                        disabled:
                          availableFilters?.[filterName] && !availableFilters?.[filterName][o!.id],
                      }
                }
                placeholder={label!}
                ItemRenderer={
                  showSubLabel
                    ? ({ checked, option, onClick, disabled }: any) => (
                        <div
                          className={`item-renderer ${disabled && "disabled"} ${
                            (option as any).className || ""
                          }`}
                          onClick={(e) => {
                            onClick(e);
                            (document.activeElement as HTMLElement)?.blur();
                          }}
                        >
                          <span className="title">{option.label}</span>
                          <span className="sub-title">{option.subLabel}</span>
                        </div>
                      )
                    : undefined
                }
              />
            </ResponsiveFilter>
          );
        }}
      />
    </FilterPanel>
  ) : null;
};

export const ActDateFilter = () => {
  const ref = useRef<any>();
  const query = useQuery();
  const date = query["actPeriod"];
  useEffect(() => {
    ref.current.value = date || null;
  }, [date]);

  const [placeholder, setPlaceholder] = useState("За период");

  return (
    <FilterPanel label={<FormattedMessage id="filters-sidebar.filters.actPeriod" />}>
      <SearchFieldController
        name="actPeriod"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            type="text"
            placeholder={placeholder}
            ref={ref}
            onBlur={(e: any) => {
              if (
                /^\d\d\.\d\d\.\d\d\d\d\s?-?\s?(\d\d\.\d\d\.\d\d\d\d)?$/.test(e.target.value) ||
                !e.target.value
              ) {
                if (/^\d\d\.\d\d\.\d\d\d\d\s?-\s?$/.test(e.target.value)) {
                  onChange(e.target.value + "31.12.2050");
                } else if (/^\d\d\.\d\d\.\d\d\d\d\s?$/.test(e.target.value)) {
                  onChange(e.target.value + " - " + e.target.value);
                } else {
                  onChange(e.target.value);
                }
              } else {
                e.target.value = value;
              }
              setPlaceholder("За период");
            }}
            onFocus={() => {
              setPlaceholder("Напр.: 01.01.2019 - 01.01.2020");
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                if (
                  /^\d\d\.\d\d\.\d\d\d\d\s?-?\s?(\d\d\.\d\d\.\d\d\d\d)?$/.test(e.target.value) ||
                  !e.target.value
                ) {
                  if (/^\d\d\.\d\d\.\d\d\d\d\s?-\s?$/.test(e.target.value)) {
                    onChange(e.target.value + "31.12.2050");
                  } else if (/^\d\d\.\d\d\.\d\d\d\d\s?$/.test(e.target.value)) {
                    onChange(e.target.value + " - " + e.target.value);
                  } else {
                    onChange(e.target.value);
                  }
                }
              }
            }}
          />
        )}
      />
    </FilterPanel>
  );
};

export const CaseReferenceFilter = () => {
  const ref = useRef<any>();
  const query = useQuery();
  const date = query["caseReference"];
  useEffect(() => {
    ref.current.value = date || null;
  }, [date]);

  const intl = useIntl();

  return (
    <FilterPanel label={<FormattedMessage id="filters-sidebar.filters.caseReference" />}>
      <SearchFieldController
        name="caseReference"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <Form.Control
            type="text"
            placeholder={intl.formatMessage({
              id: "filters-sidebar.filters.caseReference",
            })}
            ref={ref}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                onChange(e.target.value);
              }
            }}
          />
        )}
      />
    </FilterPanel>
  );
};

export const JudgementTypeFilter = () => {
  const searchCategory = useSearchCategory();
  return (
    <RefDataFilterPanel
      filterName="judgementType"
      labelKey="judical-acts"
      useData={
        isSummariesCategory(searchCategory) || isQuestionsCategory(searchCategory)
          ? useJudgementActTypes
          : isDomesticCategory(searchCategory)
          ? useDomesticJudgementActTypes
          : useEuJudgementActTypes
      }
    />
  );
};

export const SummaryTypeFilter = () => (
  <RefDataFilterPanel filterName="summaryType" labelKey="summary-types" useData={useSummaryTypes} />
);

export const CaseTypeFilter = () => (
  <RefDataFilterPanel filterName="caseType" labelKey="case-types" useData={useCaseTypes} />
);

export const CourtFilter = () => {
  const searchCategory = useSearchCategory();
  return (
    <RefDataFilterPanel
      filterName="court"
      labelKey="courts"
      useData={
        isSummariesCategory(searchCategory) || isQuestionsCategory(searchCategory)
          ? useCourts
          : isDomesticCategory(searchCategory)
          ? useDomesticCourts
          : useEuCourts
      }
    />
  );
};

export const ChamberFilter = () => {
  const searchCategory = useSearchCategory();
  return (
    <RefDataFilterPanel
      filterName="chamber"
      labelKey="chambers"
      useData={
        isSummariesCategory(searchCategory) || isQuestionsCategory(searchCategory)
          ? useChambers
          : isActsCategory(searchCategory)
          ? useActsChambers
          : useAdministrativeActsChambers
      }
      reverse
    />
  );
};

export const ProceedingFilter = () => {
  const searchCategory = useSearchCategory();
  return (
    <RefDataFilterPanel
      filterName="proceeding"
      labelKey="proceedings"
      useData={
        isSummariesCategory(searchCategory) || isQuestionsCategory(searchCategory)
          ? useProceedings
          : isActsCategory(searchCategory)
          ? useActsProceedings
          : useAdministrativeActsProceedings
      }
      showSubLabel={true}
      reverse
    />
  );
};

export const CaseStatusFilter = () => {
  return (
    <RefDataFilterPanel filterName="caseStatus" labelKey="caseStatuses" useData={useCaseStatuses} />
  );
};

export const ActYearFilter = () => (
  <RefDataFilterPanel filterName="actYear" labelKey="actYears" useData={useActYears} />
);

export const ActMonthFilter = () => (
  <RefDataFilterPanel filterName="actMonth" labelKey="actMonths" useData={useActMonths} />
);

export const NewsArticleCategoryFilter = () => (
  <RefDataFilterPanel
    filterName="category"
    labelKey="categories"
    useData={useNewsArticleCategories}
  />
);

export const NewsArticleSectionFilter = () => (
  <RefDataFilterPanel filterName="section" labelKey="sections" useData={useNewsArticleSections} />
);

export const NewsArticleShowInHeaderFilter = () => (
  <RefDataFilterPanel filterName="showInHeader" labelKey="showInHeader" useData={useShowInHeader} />
);

export const DocumentStatusFilter: React.FC<{ show?: boolean }> = ({ show = true }) => (
  <RefDataFilterPanel
    filterName="status"
    labelKey="statuses"
    useData={useDocumentStatuses}
    show={show}
  />
);

export const DocumentAccessModeFilter: React.FC<{ show?: boolean }> = ({ show = true }) => (
  <RefDataFilterPanel
    filterName="accessMode"
    labelKey="accessModes"
    useData={useDocumentAccessModes}
    show={show}
  />
);

export const DocumentSetFilter = () => {
  const { hasPrivilige } = useAuth();
  const currentUser = useCurrentUser();
  return (
    <RefDataFilterPanel
      filterName="documentSet"
      labelKey="documentSets"
      useData={
        hasPrivilige(Privileges.VIEW_ALL_DOCUMENT_SETS)
          ? useDocumentSets
          : () => ({ data: currentUser?.documentSets || [] })
      }
    />
  );
};

export const AllowedByCourtFilter = () => (
  <RefDataFilterPanel
    filterName="allowedByCourt"
    labelKey="allowed-by-court"
    useData={useAllowedByCourt}
    useFilters={useAvailableFilters}
  />
);

export const PersonalCategoryFilter = () => (
  <RefDataFilterPanel
    filterName="personalCategory"
    labelKey="personal-categories"
    useData={usePersonalCategories}
    useFilters={useAvailableFilters}
  />
);

const mapValueToOptionV2 = (countsPerKey: any) => (key: string) => {
  return {
    value: key,
    label: `${key}` + (countsPerKey[key] ? ` (${displayCount(countsPerKey[key])})` : ""),
    disabled: !countsPerKey[key],
  };
};

const filterSelected = (value: any[]) => (key: any) => value.indexOf(key) === -1;

const sortAlphabetically = (v1: string, v2: string) => v1.localeCompare(v2);

export const ReporterFilter = () => {
  const availableFilters = useAvailableFilters();
  const intl = useIntl();
  return (
    <FilterPanel label={<FormattedMessage id="filters-sidebar.filters.reporters" />}>
      <SearchFieldController
        name="reporter"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              filterOptions={(options, input) => {
                return Object.keys(availableFilters?.reporter || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(availableFilters?.reporter || []));
              }}
              getOption={mapValueToOptionV2(availableFilters?.reporter || [])}
              placeholder={intl.formatMessage({
                id: "filters-sidebar.filters.reporters.placeholder",
              })}
              hint="Въведете име или част от име"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const CaseMatterFilter = () => {
  const availableFilters = useAvailableFilters();
  const intl = useIntl();
  return (
    <FilterPanel label={<FormattedMessage id="filters-sidebar.filters.caseMatter" />}>
      <SearchFieldController
        name="caseMatter"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              filterOptions={(options, input) => {
                return Object.keys(availableFilters?.caseMatter || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(availableFilters?.caseMatter || []));
              }}
              getOption={mapValueToOptionV2(availableFilters?.caseMatter || [])}
              placeholder={intl.formatMessage({
                id: "filters-sidebar.filters.caseMatter.placeholder",
              })}
              hint="Въведете текст"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const CaseAdminBodyFilter = () => {
  const availableFilters = useAvailableFilters();
  const intl = useIntl();
  return (
    <FilterPanel label={<FormattedMessage id="filters-sidebar.filters.caseAdminBody" />}>
      <SearchFieldController
        name="caseAdminBody"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              filterOptions={(options, input) => {
                return Object.keys(availableFilters?.caseAdminBody || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(availableFilters?.caseAdminBody || []));
              }}
              getOption={mapValueToOptionV2(availableFilters?.caseAdminBody || [])}
              placeholder={intl.formatMessage({
                id: "filters-sidebar.filters.caseAdminBody.placeholder",
              })}
              hint="Въведете текст"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const SummaryEditorFilter = () => {
  const availableFilters = useAvailableFilters();
  const intl = useIntl();
  return (
    <FilterPanel label={<FormattedMessage id="filters-sidebar.filters.editors" />}>
      <SearchFieldController
        name="editor"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={Object.keys(availableFilters?.editor || [])
                .filter(filterSelected(value))
                .sort(sortAlphabetically)}
              getOption={mapValueToOptionV2(availableFilters?.editor || [])}
              placeholder={intl.formatMessage({
                id: "filters-sidebar.filters.editors.placeholder",
              })}
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const SummaryKeyProvisionFilter = () => {
  const availableFilters = useAvailableFilters();
  const intl = useIntl();
  return (
    <FilterPanel
      label={
        <FormattedMessage
          id="filters-sidebar.filters.key-provisions"
          defaultMessage="Key provisions"
        />
      }
    >
      <SearchFieldController
        name="provision"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              getOption={mapValueToOptionV2(availableFilters?.provision || [])}
              closeOnSelection={false}
              hasSelectAll={true}
              filterOptions={(options, input) => {
                const tokens = input
                  .toLocaleLowerCase()
                  .trim()
                  .replace(/^член/i, "чл.") // член -> чл.
                  .replace(/чл([^.])/i, "чл.$1") // чл[^.] -> чл.
                  .replace(/чл\.([^\s])/i, "чл. $1") // чл. -> чл.[ ]
                  .replace(/([\d])([а-я])/i, "$1 $2") // 34ЗЗД -> 34 ЗЗД
                  .replace(/([а-я])([\d])/i, "$1 $2") // ЗЗД34 -> ЗЗД34
                  .replace(/[\u201c\u201d\u201e\u201f\u275d\u275e]/g, '"') //quotes
                  .replace(/[.*+?^${}|[\]\\]/g, "\\$&")
                  .split(/\s/)
                  .filter((token) => !!token);
                //Safari does not support lookbehind. we want to split by whitespace not preceeded by .(dot)
                const input_regexes_tmp: string[] = [];
                tokens.forEach((token) => {
                  const lastToken = input_regexes_tmp[input_regexes_tmp.length - 1];
                  if (lastToken && lastToken.endsWith(".")) {
                    input_regexes_tmp[input_regexes_tmp.length - 1] = lastToken + " " + token;
                  } else {
                    input_regexes_tmp.push(token);
                  }
                });
                const input_regexes = input_regexes_tmp.map((token) => new RegExp(token, "ui"));

                return Object.keys(availableFilters?.provision || [])
                  .filter(filterSelected(value))
                  .filter((value) =>
                    input_regexes.every((regex) =>
                      value
                        .toLowerCase()
                        .replace(/[\u201c\u201d\u201e\u201f\u275d\u275e]/g, '"')
                        .match(regex)
                    )
                  )
                  .sort((v1, v2) => {
                    const v1comp = v1
                      .trimStart()
                      .replace(/\d+/g, (match) => match.padStart(8, "0"));
                    const v2comp = v2
                      .trimStart()
                      .replace(/\d+/g, (match) => match.padStart(8, "0"));
                    return v1comp.localeCompare(v2comp);
                  })
                  .slice(0, 100)
                  .map(mapValueToOptionV2(availableFilters?.provision || []));
              }}
              hint="Въведете разпоредба като напр. чл. 32 ЗС."
              placeholder={intl.formatMessage({
                id: "filters-sidebar.filters.key-provisions.placeholder",
              })}
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const SummaryRelatedProvisionFilter = () => {
  const availableFilters = useAvailableFilters();
  const intl = useIntl();
  const altMarker = "(алт.)";
  return (
    <FilterPanel
      label={
        <FormattedMessage
          id="filters-sidebar.filters.related-provision"
          defaultMessage="Related provisions"
        />
      }
    >
      <SearchFieldController
        name="relatedProvision"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <InputGroup style={{ flexWrap: "nowrap" }}>
              {value.length > 0 && (
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip id="prov-tooltip" {...props}>
                      Приложи кумулативно филтриране
                    </Tooltip>
                  )}
                >
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: "var(--components-select-filter-control-background)",
                      borderColor: "var(--components-select-filter-control-border)",
                      padding: "0 6px 0 6px",
                    }}
                  >
                    <input
                      aria-label="Checkbox for following text input"
                      type="checkbox"
                      className="form-check-input"
                      checked={!value.some((v) => v === altMarker)}
                      onClick={(e: any) =>
                        onChange(
                          e.target.checked
                            ? value.filter((v) => v !== altMarker)
                            : [altMarker, ...value.filter((v) => v !== altMarker)]
                        )
                      }
                    />
                  </span>
                </OverlayTrigger>
              )}
              <SelectMultiple
                onChange={(v) => {
                  const altEnabled = value.some((v) => v === altMarker);
                  !altEnabled &&
                    (document.querySelector(".search-clear-button") as HTMLButtonElement)?.click();
                  onChange(altEnabled && v.length > 0 ? [altMarker, ...v] : v);
                  !altEnabled &&
                    setTimeout(() => {
                      (
                        document.querySelector(".select-panel .search input") as HTMLInputElement
                      )?.focus();
                    }, 100);
                }}
                value={value.filter((v) => v !== altMarker)}
                options={[]}
                getOption={mapValueToOptionV2(availableFilters?.relatedProvision || [])}
                hasSelectAll={true}
                closeOnSelection={false}
                filterOnEmpty={value.length > 0 && !value.some((v) => v === altMarker)}
                filterOptions={(options, input) => {
                  const altEnabled = value.some((v) => v === altMarker);
                  if (!input && !altEnabled && value.length > 0) {
                    return Object.keys(availableFilters?.relatedProvision || [])
                      .filter(filterSelected(value))
                      .sort((v1, v2) => {
                        const v1comp = v1
                          .trimStart()
                          .replace(/\d+/g, (match) => match.padStart(8, "0"));
                        const v2comp = v2
                          .trimStart()
                          .replace(/\d+/g, (match) => match.padStart(8, "0"));
                        return v1comp.localeCompare(v2comp);
                      })
                      .slice(0, 100)
                      .map(mapValueToOptionV2(availableFilters?.relatedProvision || []));
                  }
                  const tokens = input
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/^член/i, "чл.") // член -> чл.
                    .replace(/чл([^.])/i, "чл.$1") // чл[^.] -> чл.
                    .replace(/чл\.([^\s])/i, "чл. $1") // чл. -> чл.[ ]
                    .replace(/([\d])([а-я])/i, "$1 $2") // 34ЗЗД -> 34 ЗЗД
                    .replace(/([а-я])([\d])/i, "$1 $2") // ЗЗД34 -> ЗЗД34
                    .replace(/[\u201c\u201d\u201e\u201f\u275d\u275e]/g, '"') //quotes
                    .replace(/[.*+?^${}|[\]\\]/g, "\\$&")
                    .split(/\s/)
                    .filter((token) => !!token);
                  //Safari does not support lookbehind. we want to split by whitespace not preceeded by .(dot)
                  const input_regexes_tmp: string[] = [];
                  tokens.forEach((token) => {
                    const lastToken = input_regexes_tmp[input_regexes_tmp.length - 1];
                    if (lastToken && lastToken.endsWith(".")) {
                      input_regexes_tmp[input_regexes_tmp.length - 1] = lastToken + " " + token;
                    } else {
                      input_regexes_tmp.push(token);
                    }
                  });
                  const input_regexes = input_regexes_tmp.map((token) => new RegExp(token, "ui"));
                  return Object.keys(availableFilters?.relatedProvision || [])
                    .filter(filterSelected(value))
                    .filter((value) =>
                      input_regexes.every((regex) =>
                        value
                          .toLowerCase()
                          .replace(/[\u201c\u201d\u201e\u201f\u275d\u275e]/g, '"')
                          .match(regex)
                      )
                    )
                    .sort((v1, v2) => {
                      const v1comp = v1
                        .trimStart()
                        .replace(/\d+/g, (match) => match.padStart(8, "0"));
                      const v2comp = v2
                        .trimStart()
                        .replace(/\d+/g, (match) => match.padStart(8, "0"));
                      return v1comp.localeCompare(v2comp);
                    })
                    .slice(0, 100)
                    .map(mapValueToOptionV2(availableFilters?.relatedProvision || []));
                }}
                hint="Въведете разпоредба като напр. чл. 32 ЗС."
                placeholder={intl.formatMessage({
                  id: "filters-sidebar.filters.related-provision.placeholder",
                })}
              />
            </InputGroup>
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const SummaryGroundOfAppealFilter = () => (
  <RefDataFilterPanel
    filterName="groundOfAppeal"
    labelKey="ground-of-appeals"
    useData={useGroundOfAppeals}
    showSubLabel={true}
  />
);

export const SummaryOtherFilter = () => (
  <RefDataFilterPanel filterName="other" labelKey="others" useData={useOtherFilters} />
);

export const QuestionOtherFilter = () => (
  <RefDataFilterPanel filterName="other" labelKey="others" useData={useOtherFilters} />
);

export const ActOtherFilter = () => (
  <RefDataFilterPanel filterName="otherAct" labelKey="others" useData={useActOtherFilters} />
);

export const EUActOtherFilter = () => (
  <RefDataFilterPanel filterName="otherAct" labelKey="others" useData={useEUActOtherFilters} />
);

export const LatestActFilter = () => (
  <RefDataFilterPanel filterName="latestActs" labelKey="latestAct" useData={useLatestActFilters} />
);

type CollapsibleFiltersPanelProps = {
  collapseHeight: string;
  initiallyOpened?: boolean;
  openedLabel?: string;
  closedLabel?: string;
};

export const CollapsibleFiltersPanel: React.FC<CollapsibleFiltersPanelProps> = ({
  collapseHeight,
  initiallyOpened = false,
  children,
  openedLabel,
  closedLabel,
}) => {
  const searchCategory = useSearchCategory();
  const [isOpen, setOpen] = useState(initiallyOpened);

  useEffect(() => setOpen(initiallyOpened), [initiallyOpened, searchCategory]);

  const [calcCollapsedHeight, setCalcCollapsedHeight] = useState(collapseHeight);
  const [showButton, setShowButton] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <Collapse
        isOpen={isOpen}
        collapseHeight={calcCollapsedHeight}
        overflowOnExpanded
        onInit={({ state, style, node }) => {
          if (!initiallyOpened) {
            const ch = parseInt(collapseHeight.replace("px", ""));
            const height = Math.min(ch, ref.current!.scrollHeight);
            setCalcCollapsedHeight(height + "px");
            node.style.height = height + "px";
            if (height === ref.current!.scrollHeight) {
              setShowButton(false);
            }
          }
          setTimeout(
            () => (node.style.transition = "height 400ms cubic-bezier(0.4, 0, 0.2, 1)"),
            100
          );
        }}
      >
        <div ref={ref}>{children}</div>
      </Collapse>
      {showButton && (
        <div style={{ display: "grid" }}>
          <InlineLinkButton
            onClick={() => setOpen(!isOpen)}
            style={{ marginTop: "10px", placeSelf: "center" }}
          >
            {!isOpen
              ? closedLabel
                ? closedLabel
                : "Още филтри"
              : openedLabel
              ? openedLabel
              : "По-малко филтри"}
          </InlineLinkButton>
        </div>
      )}
    </>
  );
};

export const isQuestionsCategory = (searchCategory: string) => searchCategory === "questions";
export const isSummariesCategory = (searchCategory: string) => searchCategory === "summaries";
export const isEuActsCategory = (searchCategory: string) => searchCategory === "eu-acts";
export const isActsCategory = (searchCategory: string) =>
  searchCategory === "acts" || searchCategory === "const-acts" || searchCategory === "sc-acts";
export const isAdministrativeActsCategory = (searchCategory: string) =>
  searchCategory === "adm-acts";
export const isConstCourtActsCategory = (searchCategory: string) => searchCategory === "const-acts";
export const isSupremeCourtActsCategory = (searchCategory: string) => searchCategory === "sc-acts";

export const isDomesticCategory = (searchCategory: string) =>
  isActsCategory(searchCategory) ||
  isSummariesCategory(searchCategory) ||
  isAdministrativeActsCategory(searchCategory) ||
  isConstCourtActsCategory(searchCategory) ||
  isSupremeCourtActsCategory(searchCategory);

export const isDomesticActsCategory = (searchCategory: string) =>
  isActsCategory(searchCategory) ||
  isAdministrativeActsCategory(searchCategory) ||
  isConstCourtActsCategory(searchCategory) ||
  isSupremeCourtActsCategory(searchCategory);

export const isNewsArticlesCategory = (searchCategory: string) =>
  searchCategory === "news-articles";
export const isNonNewsArticleCategory = (searchCategory: string) =>
  isDomesticCategory(searchCategory) ||
  isAdministrativeActsCategory(searchCategory) ||
  isEuActsCategory(searchCategory);
