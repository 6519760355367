import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { SearchApiResponse, UseSearchRequestReturn } from "../../../api";
import { PrimaryButton } from "../../../components/Button/button";
import { Selection } from "../../../hooks/useMultipleSelection";
import ResultsTableEmpty from "./results-table-empty";
import ResultsTableLoading from "./results-table-loadng";
import "./results-table.scss";
export type ResultItem = {
  id: any;
};

interface ResultsTableProps<T extends ResultItem, R> {
  results: UseSearchRequestReturn<T>;
  itemToSelection: (item: R) => Selection;
  resultsExtractor?: (response?: SearchApiResponse<T>[]) => R[];
  header: () => React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;

  allowBulkLoad?: boolean;
  row: (item: R) => JSX.Element;
}

const ResultsTable = <T extends ResultItem, R extends any>({
  results,
  itemToSelection,
  header,
  allowBulkLoad = false,
  resultsExtractor = flattenPagedResponse,
  row,
}: ResultsTableProps<T, R>) => {
  const { data, size, setSize, totalCount, hasMore, isLoadingMore, totalPages } = results;

  return data ? (
    <div className="results-table-container">
      <Table borderless responsive size="sm" className="results-table">
        {header()}
        <tbody>
          {resultsExtractor(data).map((item) => {
            const selectedItem = itemToSelection(item);
            return (
              <tr key={selectedItem._id}>
                <td className="main-content">{row(item)}</td>
              </tr>
            );
          })}
          {totalCount === 0 && (
            <tr>
              <td colSpan={2} style={{ textAlign: "center" }}>
                <ResultsTableEmpty />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {hasMore &&
        (allowBulkLoad && size < 10 ? (
          <Dropdown as={ButtonGroup} className="load-more">
            <PrimaryButton
              submitting={isLoadingMore}
              messageId="button.load-more"
              onClick={() => setSize(size + 1)}
            />

            <Dropdown.Toggle split className="load-more" disabled={isLoadingMore} />

            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => setSize(Math.min(totalPages as number, 10))}>
                {totalPages && totalPages <= 10 ? "Зареди всички" : "Зареди 100"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <PrimaryButton
            submitting={isLoadingMore}
            messageId="button.load-more"
            onClick={() => setSize(size + 1)}
          />
        ))}
    </div>
  ) : (
    <ResultsTableLoading perRowCount={4} />
  );
};

type ResultsTableRowPartProps = {
  item: ResultItem;
};

const ResultTableRow: React.FC<any> = ({ children }) => (
  <>
    <div className="row-content">
      <div className="row-main-info">{getChildrenByType(children, ["RowIndicators"], true)}</div>
      {getChildrenByType(children, ["RowIndicators"])}
    </div>
  </>
);
ResultsTable.ResultTableRow = ResultTableRow;

const RowIndicators: React.FC<ResultsTableRowPartProps & { __TYPE?: string }> = ({ children }) => (
  <div className="row-indicators">
    <div>{children}</div>
  </div>
);
RowIndicators.defaultProps = {
  __TYPE: "RowIndicators",
};
ResultsTable.RowIndicators = RowIndicators;

function flattenPagedResponse<T extends ResultItem>(response?: SearchApiResponse<T>[]) {
  return (response?.map((page) => page?._embedded.items).flat(2) || []) as any[];
}

const getChildrenByType = (children: React.ReactNode, types: string[], negate = false) =>
  React.Children.toArray(children).filter((child) =>
    negate
      ? types.indexOf(typeOfComponent(child)) === -1
      : types.indexOf(typeOfComponent(child)) !== -1
  );

export const typeOfComponent = (component: any) =>
  component?.props?.__TYPE ||
  component?.type?.toString().replace("Symbol(react.fragment)", "react.fragment") ||
  undefined;

export default ResultsTable;
