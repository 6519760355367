import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton, SecondaryButton } from "../../components/Button/button";
import Select from "../../components/Select";
import JudgementActLink, { JudgementActLinkType } from "../../models/JudgementActLink.model";
import { SearchHit } from "../../models/SearchHit.model";
import { DocumentStatusBadge, useSuggestions } from "../../services/document-services";

type AddRelatedActModalProps = {
  append: (prov: JudgementActLink) => void;
  relatedAct?: JudgementActLink;
  setRelatedAct: (act?: JudgementActLink) => void;
};
type ModalMode = "hidden" | "add" | "edit";
const AddRelatedActModal: React.FC<AddRelatedActModalProps> = ({
  append,
  relatedAct,
  setRelatedAct,
}) => {
  const [selectedAct, setSelectedAct] = useState<SearchHit & { type: JudgementActLinkType }>();

  useEffect(() => {
    if (relatedAct) {
      setSelectedAct({
        id: relatedAct.id,
        text: relatedAct.act.title,
        status: relatedAct.act.status,
        category: "",
        type: relatedAct.type,
      });
    }
  }, [setSelectedAct, relatedAct]);

  const [mode, setMode] = useState<ModalMode>("hidden");
  const intl = useIntl();
  return (
    <>
      <span onClick={() => setMode("add")}>
        <FormattedMessage id="button.add" />
      </span>
      {(mode === "add" || mode === "edit") && (
        <SelectAct
          onConfirm={() => {
            append({
              id: selectedAct!.id,
              type: selectedAct!.type,
              act: {
                id: selectedAct!.id,
                title: selectedAct!.text,
                status: selectedAct!.status,
                referencesTo: [],
                referencesFrom: [],
              },
            });
            setMode("hidden");
            setSelectedAct(undefined);
          }}
          mode={mode}
          setSelectedAct={setSelectedAct}
          selectedAct={selectedAct}
          relatedAct={relatedAct}
          onCancel={() => {
            setMode("hidden");
            setRelatedAct(undefined);
            setSelectedAct(undefined);
          }}
        />
      )}
    </>
  );
};

type SelectActProps = {
  selectedAct?: SearchHit & { type: JudgementActLinkType };
  setSelectedAct: (act?: SearchHit & { type: JudgementActLinkType }) => void;
  relatedAct?: JudgementActLink;
  onConfirm: () => void;
  mode: ModalMode;
  onCancel: () => void;
};

const SelectAct: React.FC<SelectActProps> = ({
  selectedAct,
  setSelectedAct,
  onConfirm,
  mode,
  relatedAct,
  onCancel,
}) => {
  const intl = useIntl();
  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton messageId="button.confirm" onClick={onConfirm} disabled={!selectedAct} />
    </>
  );

  const [type, setType] = useState<JudgementActLinkType>(JudgementActLinkType.NONE);

  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue("");
  }, [setInputValue]);
  const options = useSuggestions(inputValue, "judgement-acts");

  return (
    <Modal
      show={true}
      centered
      size="xl"
      animation={false}
      className="modal-xxl"
      onEscapeKeyDown={onCancel}
    >
      <Modal.Header>
        <FormattedMessage id="judgement-act-add-related-act.modal.header" />
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={9}>
            <Select
              onChange={(...event: any[]) => {
                setSelectedAct({ ...event[0], type });
              }}
              inputValue={inputValue}
              onInputChange={setInputValue}
              menuIsOpen={!!inputValue}
              value={selectedAct ? [selectedAct] : []}
              components={{
                DropdownIndicator: null,
              }}
              inputStyles={{ singleValue: { width: "90%", textOverflow: "ellipsis" } }}
              options={options}
              filterOption={() => true}
              isMulti={false}
              isClearable
              getOptionValue={(value: any) => value.text || value.title}
              getOptionLabel={(value: any) => (
                <>
                  {value.text || value.title}
                  <span style={{ position: "absolute", right: "5px" }}>
                    <DocumentStatusBadge status={value.status} />
                  </span>
                </>
              )}
              onKeyDown={(e: any) => {
                e.stopPropagation();
                if (e.key === "Escape") {
                  onCancel();
                }
              }}
              placeholder={intl.formatMessage({
                id: "add-related-act.modal.select-act.placeholder",
              })}
              isDisabled={mode === "edit"}
              autoFocus
            />
          </Col>
          <Col md={3}>
            <Form.Select
              size="sm"
              value={type}
              disabled={!selectedAct}
              onChange={(e) => {
                setSelectedAct({ ...selectedAct!, type: e.target.value as JudgementActLinkType });
                setType(e.target.value as JudgementActLinkType);
              }}
            >
              {Object.keys(JudgementActLinkType).map((v) => (
                <option value={v} key={v}>
                  {intl.formatMessage({ id: `judgement-act.link.type.${v}` })}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

export default AddRelatedActModal;
