import { del, post } from "../api";
import { LoggedUser } from "../contexts/auth.context";

export const register = (username: string, email: string, password: string) => {
  return post("/auth/signup", {
    username,
    email,
    password,
  });
};

export const login = (
  username: string,
  password: string,
  visitorId?: string,
  visitorDetails?: string,
  code?: string
): Promise<LoggedUser> => {
  return post("/auth/signin", {
    username,
    password,
    visitorId,
    code,
  });
};

export const logout = async (): Promise<void> => {
  return post("/auth/signout", {}, true);
};

export const runAs = (username: string): Promise<LoggedUser> => {
  return post(`/auth/impersonation/${username}`, {});
};

export const releaseRunAs = async (): Promise<LoggedUser> => {
  return del("/auth/impersonation", {});
};
