/* eslint-disable react/jsx-props-no-spreading */
import { saveAs } from "file-saver";
import { Badge, Button } from "react-bootstrap";
import { FaDownload, FaEye } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import LabelValue from "../../components/label-value";
import PreloadingLink from "../../components/PreloadingLink";
import { withAuth } from "../../contexts/auth.context";
import { Selection } from "../../hooks/useMultipleSelection";
import { SubscriptionInvoice } from "../../models/SubscriptionInvoice.model";
import {
  formatInvoiceNumber,
  invoiceHref,
  prefetchSubscription,
  useInvoices,
} from "../../services/subscription-services";
import ResultsTable from "./ResultsTable/results-table";
import ResultsTableHeader from "./ResultsTable/results-table-header";

const InvoiceSearchResults = () => {
  const { formatMessage } = useIntl();

  const results = useInvoices();

  return (
    <ResultsTable
      results={results}
      header={() => (
        <ResultsTableHeader
          sortOptions={[
            {
              value: "creationDate",
              label: "Дата на създаване",
            },
          ]}
        />
      )}
      itemToSelection={itemToSelection}
      allowBulkLoad
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <strong>{formatInvoiceNumber(item)}</strong>
          <LabelValue label={formatMessage({ id: "user-search-page.labels.subscription" })}>
            <PreloadingLink
              to={`/admin/subscriptions/${item.subscription.id}`}
              state={{ title: item.subscription.code }}
              preload={(cache) => prefetchSubscription(item.subscription!.id, cache)}
            >
              {item.subscription.code}
            </PreloadingLink>{" "}
            ({item.subscription.plan.type.name})
          </LabelValue>
          <LabelValue label={formatMessage({ id: "user-search-page.labels.subscriber" })}>
            {item.subscription.subscriber.customerInfo.companyName ||
              item.subscription.subscriber.customerInfo.firstName +
                " " +
                item.subscription.subscriber.customerInfo.lastName}
          </LabelValue>
          <LabelValue label={formatMessage({ id: "user-search-page.labels.createdBy" })}>
            {item.createdBy}
          </LabelValue>

          <ResultsTable.RowIndicators item={item}>
            {item.proforma ? (
              <Badge bg="info" className="ms-1">
                <FormattedMessage id="user-search-page.labels.proforma" />
              </Badge>
            ) : (
              <Badge bg="danger" className="ms-1">
                <FormattedMessage id="user-search-page.labels.invoice" />
              </Badge>
            )}{" "}
            <Badge bg="secondary">{item.amount} лв.</Badge>
            <div className="d-flex">
              <Button
                className="ms-auto p-1"
                variant="link"
                onClick={() => {
                  const pdfWindow = window.open("");
                  pdfWindow &&
                    pdfWindow.document.write(
                      "<iframe width='100%' height='100%' src='" + item.contents + "'></iframe>"
                    );
                }}
                title={formatMessage({ id: "button.view" })}
              >
                <FaEye />
              </Button>
              <Button
                className="p-1"
                variant="link"
                onClick={() => {
                  saveAs(item.contents, item.fileName);
                }}
                title={formatMessage({ id: "button.download" })}
              >
                <FaDownload />
              </Button>
            </div>
          </ResultsTable.RowIndicators>
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const itemToSelection = (item: SubscriptionInvoice): Selection => ({
  _id: item.id,
  title: formatInvoiceNumber(item),
  href: invoiceHref(item.id),
});

export default withAuth(InvoiceSearchResults);
