import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";

type LoadingProgressProps = {
  start: () => void;
  complete: () => void;
};

const empty = {
  start: () => {},
  complete: () => {},
};

export const LoadingProgressContext = createContext<LoadingProgressProps>(empty);

export const LoadingProgressContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const ref = useRef<LoadingBarRef>(null);
  //const { search, pathname } = useLocation();
  const [started, setStarted] = useState<boolean>(false);

  useEffect(() => {
    if (started) {
      ref.current?.continuousStart(30, 200);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return () => ref.current?.complete();
    }
  }, [started]);

  return (
    <LoadingProgressContext.Provider
      value={
        ref.current
          ? {
              start: () => {
                setStarted(true);
              },
              complete: () => {
                setStarted(false);
              },
            }
          : empty
      }
    >
      <LoadingBar
        color="rgb(179, 8, 56)"
        ref={ref}
        containerClassName="loading-bar"
        waitingTime={500}
      />
      {children}
    </LoadingProgressContext.Provider>
  );
};

export const useLoadingProgress = () => useContext(LoadingProgressContext);

export default LoadingProgressContextProvider;
