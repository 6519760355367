import React from "react";
import LoginModal from "../pages/login.modal";

type GlobalErrorBoundaryProps = {
  message: string;
};

type GlobalErrorBoundaryState = {
  error?: any;
};

class GlobalErrorBoundary extends React.Component<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    return {
      error,
    };
  }

  render() {
    if (this.state.error instanceof UnauthorizedError) {
      return <LoginModal />;
    }
    return this.state.error ? <div /> : this.props.children;
  }
}

export class UnauthorizedError extends Error {
  constructor(...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthorizedError);
    }

    this.name = "UnauthorizedError";
  }
}

export class TooManyRequestsError extends Error {
  constructor(...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TooManyRequestsError);
    }

    this.name = "TooManyRequestsError";
  }
}

export class ForbiddenError extends Error {
  constructor(...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }

    this.name = "ForbiddenError";
  }
}

export class NotFoundError extends Error {
  constructor(...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    }

    this.name = "NotFoundError";
  }
}

export class BadRequestError extends Error {
  constructor(...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadRequestError);
    }

    this.name = "BadRequestError";
  }
}

export class NetworkError extends Error {
  constructor(...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError);
    }

    this.name = "NetworkError";
  }
}

export default GlobalErrorBoundary;
