import React from "react";

import Skeleton from "../../components/Skeleton";

import DocumentPageLayout, { DocumentPageLayoutProps } from "../DocumentPage/document-page-layout";
import "../view-page.scss";

const JudgementActViewPageLoading: React.FC<DocumentPageLayoutProps> = (props) => {
  return (
    <DocumentPageLayout {...props}>
      <Skeleton count={30} />
    </DocumentPageLayout>
  );
};

export default JudgementActViewPageLoading;
