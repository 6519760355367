import { useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import { saveJudgementAct } from "../../services/judgement-act-services";

const JudgementActEditPageActions = () => {
  const intl = useIntl();

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const navigate = useNavigate();

  const onSubmit = (shouldNavigate: boolean) => async (data: any) => {
    try {
      const saved = await saveJudgementAct(data, data.id);
      toast.success(
        intl.formatMessage(
          {
            id: data.id
              ? "judgement-act-edit.page.toasts.update.success"
              : "judgement-act-edit.page.toasts.add.success",
          },
          { title: data.title }
        )
      );
      setValue("id", saved.id);
      shouldNavigate && navigate(`/judgement-acts/${saved.id}`);
    } catch (e) {
      console.log(e);
      toast.error(
        intl.formatMessage({ id: "judgement-act-edit.page.toasts.error" }, { title: data.title })
      );
    }
  };

  useHotkeys(
    "ctrl+s,command+s",
    (e) => {
      e.preventDefault();
      handleSubmit(onSubmit(false))();
    },
    { enableOnContentEditable: true, enableOnTags: ["INPUT", "TEXTAREA"] }
  );

  return (
    <>
      <ActionButton
        onClick={handleSubmit(onSubmit(true))}
        disabled={isSubmitting}
        submitting={isSubmitting}
        messageId={"button.confirm"}
      />
      <SecondaryButton
        onClick={() => navigate(-1)}
        messageId={"button.cancel"}
        disabled={isSubmitting}
      />
    </>
  );
};

export default JudgementActEditPageActions;
