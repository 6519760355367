import { EntityModel } from "hateoas-hal-types";
import React, { createContext, useContext } from "react";
import { Summary } from "../models/Summary.model";

export type SummaryContextType = {
  summary: EntityModel<Summary>;
};

export const SummaryContext = createContext<SummaryContextType>({ summary: {} as any });

export const SummaryContextProvider: React.FC<SummaryContextType> = ({ summary, children }) => {
  return <SummaryContext.Provider value={{ summary }}>{children}</SummaryContext.Provider>;
};

export const useSummaryContext = () => useContext(SummaryContext);

export default SummaryContextProvider;
