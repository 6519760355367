import { EntityModel } from "hateoas-hal-types";
import { FaLink } from "react-icons/fa";
import { useIntl } from "react-intl";
import { InlineLinkButton } from "../../components/Button/button";
import Collapse from "../../components/SimpleCollapse";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { NewsArticle } from "../../models/NewsArticle.model";
export type Mode = "view" | "edit";

type NewsArticlePageNavigationProps = {
  article: EntityModel<NewsArticle>;
};
const NewsArticlePageNavigation: React.FC<NewsArticlePageNavigationProps> = ({ article }) => {
  return (
    <>
      <RelatedActsPanel article={article} />
    </>
  );
};

const RelatedActsPanel: React.FC<NewsArticlePageNavigationProps> = ({ article }) => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();
  return (
    <Collapse
      icon={<FaLink />}
      label={intl.formatMessage({ id: "view-summary.labels.relatedJudgementActs" })}
    >
      {article.relatedJudgementActs?.filter(
        (relatedAct) =>
          relatedAct.status !== DocumentStatus.GENERATED &&
          relatedAct.status !== DocumentStatus.DELETED
      ).length > 0 ? (
        <div className="section">
          {article.relatedJudgementActs
            ?.filter(
              (relatedAct) =>
                relatedAct.status !== DocumentStatus.GENERATED &&
                relatedAct.status !== DocumentStatus.DELETED
            )
            .map((relatedAct) => (
              <InlineLinkButton
                title={relatedAct.title}
                onClick={() => {
                  setSelectedActId(relatedAct.id);
                }}
                key={relatedAct.id}
              >
                {relatedAct.title}
              </InlineLinkButton>
            ))}
        </div>
      ) : (
        <div style={{ marginLeft: "10px" }}>Няма свързани актове</div>
      )}
    </Collapse>
  );
};

export default NewsArticlePageNavigation;
