import { Bulletin } from "./Bulletin.model";
import DocumentAccessMode from "./DocumentAccessMode.enum";
import DocumentStatus from "./DocumentStatus.enum";
import LexebraEditor from "./LexebraEditor.model";
import Provision from "./Provision.model";
import RefData from "./RefData.model";
import ReferencedJudgementAct from "./ReferencedJudgementAct.model";
import SummaryQa from "./SummaryQa.model";

export interface Summary {
  id: string;
  relatedJudgementAct: ReferencedJudgementAct;
  shortTitle: string;
  editor: LexebraEditor;
  reporters: string[];
  provisions: Provision[];
  status: DocumentStatus;
  accessMode: DocumentAccessMode;
  summaryType: RefData;
  qas: SummaryQa[];
  caseRelatedJudgementActs: ReferencedJudgementAct[];
  hasEditorialOpinion: boolean;
  hasDissentingOpinion: boolean;
  relatedToSpecialGroundOfAppeal: boolean;
  relatedToEuLegislation: boolean;
  linkWithEuLegsilation: boolean;
  hasRelatedCase: boolean;
  restrictedEdit: boolean;
  publishDate: string;
  comments: string;
  highlights: {
    question?: string;
    answer?: string;
    arguments?: string;
    dissentingOpinions?: string;
    editorialOpinions?: string;
  };
  documentSets: RefData[];
  mentions: number;
  bulletin?: Bulletin;
}

export const printSummaryShortTitle = (summary: { title: string }) => summary.title + " /Резюме/";

export const printSummaryTitle = (summary: Summary) =>
  summary.relatedJudgementAct.title + " /Резюме/";
