import Form from "react-bootstrap/Form";
import { UserIndicatorIcons } from "../../../components/IndicatorIcons/indicator-icons";
import { UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
import "./results-table-checkbox.scss";

type ResultsTableCheckboxProps = {
  item?: {
    id: string;
    favouritesId?: string;
    title: string;
    hasSummary?: boolean;
    hasEditorAnnotation?: boolean;
    hasAiAnnotations?: boolean;
    hasAnnotations?: boolean;
  };
} & UseMultipleSelectionReturn;

const ResultsTableCheckbox: React.FC<ResultsTableCheckboxProps> = ({
  item,
  selectSingle,
  selection,
}) => {
  return item ? (
    <Form.Group controlId={`chk-${item.id}`} className="checkbox">
      <Form.Check
        type="checkbox"
        label=""
        onChange={() =>
          selectSingle!({
            _id: item.id,
            title: item.title,
            href: `/summaries/${item.id}`,
          })
        }
        checked={selection!.has(item.id)}
      />
      <UserIndicatorIcons item={item} />
    </Form.Group>
  ) : (
    <Form.Group className="checkbox">
      <Form.Check type="checkbox" label="" />
    </Form.Group>
  );
};

export default ResultsTableCheckbox;
