import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { EditorCollection } from "../models/EditorCollection.model";

const EDITOR_COLLECTIONS_BASE_URL = "/editor-collections";

export const useEditorCollections = () =>
  useSearchRequest<EditorCollection>(EDITOR_COLLECTIONS_BASE_URL);

export const prefetchEditorCollections = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(EDITOR_COLLECTIONS_BASE_URL, searchParams, cache);

export const useEditorCollection = (id?: string) => {
  const { data } = useRequest<EntityModel<EditorCollection>>(
    id ? `${EDITOR_COLLECTIONS_BASE_URL}/${id}` : null
  );
  return data;
};

export const fetchEditorCollection = (id: string) => {
  return get<EntityModel<EditorCollection>>(`${EDITOR_COLLECTIONS_BASE_URL}/${id}`);
};

export const fetchEditorCollectionWord = (
  id: string,
  options?: {
    includeArguments: boolean;
    includeQuestionMetadata: boolean;
  }
) => {
  return post(`${EDITOR_COLLECTIONS_BASE_URL}/${id}/docx`, options);
};

export const prefetchEditorCollection = (id: string, cache: Cache<any>) =>
  prefetch(`${EDITOR_COLLECTIONS_BASE_URL}/${id}`, cache);

export const saveEditorCollection = async (data: EditorCollection, collectionId?: string) => {
  const result = (
    collectionId
      ? await put(`${EDITOR_COLLECTIONS_BASE_URL}/${collectionId}`, data)
      : await post(`${EDITOR_COLLECTIONS_BASE_URL}`, data)
  ) as EntityModel<EditorCollection>;
  mutate(`${EDITOR_COLLECTIONS_BASE_URL}/${collectionId}`, () => result, false);
  return result;
};

export const editorCollectionHref = (id: string): string => `${EDITOR_COLLECTIONS_BASE_URL}/${id}`;
