import Skeleton from "../../components/Skeleton";
import AppPageLayout from "../DocumentPage/app-page-layout";

const UserPageLoading = () => {
  return (
    <AppPageLayout title={"Потребител - редакция"} className="history-page">
      <Skeleton count={10} />
    </AppPageLayout>
  );
};

export default UserPageLoading;
