import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

const NotAuthorized = () => {
  return (
    <Container style={{ marginTop: "50px", textAlign: "center" }}>
      <Alert variant="danger">Нямате достъп до избраната функционалност</Alert>
    </Container>
  );
};

export const NotAuthorizedDocument = () => {
  return (
    <Container style={{ marginTop: "50px", textAlign: "center" }}>
      <Alert variant="danger">Нямате достъп до избрания документ</Alert>
    </Container>
  );
};

export const NotFoundDocument = () => {
  return (
    <Container style={{ marginTop: "50px", textAlign: "center" }}>
      <Alert variant="danger">Документът не е намерен</Alert>
    </Container>
  );
};

export const BadRequestDocument = () => {
  return (
    <Container style={{ marginTop: "50px", textAlign: "center" }}>
      <Alert variant="danger">Невалидна заявка</Alert>
    </Container>
  );
};

export default NotAuthorized;
