import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FaCheck, FaLink, FaPen, FaQuestionCircle } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useLocation } from "react-use";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import NotAuthorized from "../../components/NotAuthorized";
import RichTextEditor from "../../components/RichTextEditor";
import RefDataSelectSingle from "../../components/SimpleSelect/ref-data-select-single";
import SelectMultiple from "../../components/SimpleSelect/select-multiple";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { ConnectUser, hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import AllowedByCourt from "../../models/AllowedByCourt.enum";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { JudgementAct, JudgementActDomain } from "../../models/JudgementAct.model";
import {
  buildTitleFromValues,
  extractValuesFromTitle,
  useJudgementAct,
} from "../../services/judgement-act-services";
import {
  useCaseStatuses,
  useCaseTypes,
  useChambers,
  useCourts,
  useDocumentSets,
  useJudgementActTypes,
  useProceedings,
} from "../../services/ref-data-services";
import { useUserNames } from "../../services/user-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import JudgementActEditPageActions from "./judgement-edit-page-actions";
import ProvisionsSection from "./judgement-edit-page-provisions";
import TopicsSection from "./judgement-edit-page-topics";
import "./judgement-edit-page.scss";
import JudgementPageNavigation from "./judgement-page-navigation";
import JudgementActViewPageLoading from "./judgement-view-page-loading";
import RelatedActsTable from "./related-acts.table";

type JudgementEditPageProps = {
  act?: EntityModel<JudgementAct>;
};

const JudgementEditPage = () => {
  const { actId } = useParams();
  const act = useJudgementAct(actId);

  return act || !actId ? (
    <JudgementEditForm act={act} />
  ) : (
    <JudgementActViewPageLoading mode="edit" className="page-loading" />
  );
};

const JudgementEditForm: React.FC<JudgementEditPageProps> = ({ act }) => {
  const serverData = act;

  const intl = useIntl();

  const location = useLocation();

  const domain = location.state?.usr?.type || JudgementActDomain.DOMESTIC;

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: serverData || {
      status: DocumentStatus.DRAFT,
      accessMode: DocumentAccessMode.PAID,
      domain: domain,
      reporters: [],
      preliminaryRuling: false,
      topics: [],
      referencesFrom: [],
      referencesTo: [],
      provisions: [],
    },
  });

  const { control, register, setValue, getValues, watch } = methods;

  const { data: judgementTypes } = useJudgementActTypes();
  const { data: caseTypes } = useCaseTypes();
  const { data: caseStatuses } = useCaseStatuses();
  const { data: courts } = useCourts();
  const { data: chambers } = useChambers();
  const { data: proceedings } = useProceedings();

  const [toastId, setToastId] = useState<any>();
  const syncTitle = async () => {
    try {
      const parsedData = await buildTitleFromValues(getValues(), setValue);
      ["title"].forEach((key) => setValue(key as any, parsedData[key]));
      if (!toast.isActive(toastId)) {
        setToastId(
          toast.info(
            intl.formatMessage({ id: "judgement-act-edit.page.toasts.parse-from-values.success" })
          )
        );
      }
    } catch (e) {
      console.log(e);
      toast.error(
        intl.formatMessage({ id: "judgement-act-edit.page.toasts.parse-from-values.error" })
      );
    }
  };
  const onChangeTitleSync = (onChange: any) => {
    return (...event: any[]) => {
      onChange(...event);
      syncTitle();
    };
  };

  const [titleEditEnabled, setTitleEditEnabled] = useState(!act?.id);

  const isDomesticAct =
    getValues("domain") === JudgementActDomain.DOMESTIC ||
    getValues("domain") === JudgementActDomain.CONSTITUTIONAL ||
    getValues("domain") === JudgementActDomain.SUPREME_COURT;
  const isAdministrativeAct = getValues("domain") === JudgementActDomain.ADMINISTRATIVE;
  const isLocalAct = isDomesticAct || isAdministrativeAct;
  const isEuAct = getValues("domain") === JudgementActDomain.EU;

  const formData = watch();

  const updateValuesFromTitle = async (title: string) => {
    try {
      const parsedData = await extractValuesFromTitle(title, getValues("domain"));
      [
        "actType",
        "actDate",
        "actNumber",
        "euChamber",
        "chamber",
        "court",
        "caseType",
        "caseYear",
        "caseNumber",
        "caseReference",
        "caseWord",
      ].forEach((key) => setValue(key as any, parsedData[key]));
      toast.info(
        intl.formatMessage({ id: "judgement-act-edit.page.toasts.parse-from-title.success" })
      );
    } catch (e) {
      console.log(e);
      toast.error(
        intl.formatMessage({ id: "judgement-act-edit.page.toasts.parse-from-title.error" })
      );
    }
  };

  const lexebraEditors = useUserNames();
  const { data: documentSets } = useDocumentSets();

  return (
    <FormProvider {...methods}>
      <DocumentPageLayout
        title={serverData?.title}
        defaultTitle={intl.formatMessage({
          id: isEuAct
            ? "judgement-act-edit.page.add-new-title-eu"
            : "judgement-act-edit.page.add-new-title",
        })}
        mode="edit"
        className="judgement-act-page"
        menu={<JudgementActEditPageActions />}
        navigation={<JudgementPageNavigation act={formData || serverData} />}
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="9">
              <Form.Group controlId="title">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.title" />
                </Form.Label>

                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        onChange={onChange}
                        value={value}
                        readOnly={!titleEditEnabled}
                      />

                      <Button
                        className="noSelect"
                        onClick={() => {
                          setTitleEditEnabled(!titleEditEnabled);
                          if (titleEditEnabled) {
                            updateValuesFromTitle(value);
                          }
                        }}
                      >
                        {titleEditEnabled ? <FaCheck /> : <FaPen />}
                      </Button>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="status">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.status" />
                </Form.Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={Object.keys(DocumentStatus)}
                      getOption={(status) => ({
                        label: intl.formatMessage({ id: `document.status.${status}` }),
                        value: status,
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md="9">
              <Form.Group controlId="documentSets">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.documentSets" />
                </Form.Label>
                <Controller
                  name="documentSets"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectMultiple
                      options={documentSets}
                      getOption={(documentSet) => ({
                        label: documentSet.shortName,
                        value: documentSet.id,
                      })}
                      value={value}
                      onChange={onChange}
                      placeholder="Изберете..."
                      isDisabled
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="accessMode">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.access-mode" />
                </Form.Label>
                <Controller
                  name="accessMode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={Object.keys(DocumentAccessMode)}
                          getOption={(accessMode) => ({
                            label: intl.formatMessage({
                              id: `document.access-mode.${accessMode}`,
                            }),
                            value: accessMode,
                          })}
                          value={value}
                          onChange={onChange}
                          isDisabled={!hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)(currentUser)}
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <Form.Group controlId="actType">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.actType" />
                </Form.Label>
                <Controller
                  name="actType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RefDataSelectSingle
                      onChange={onChangeTitleSync(onChange)}
                      value={value}
                      isClearable
                      isDisabled={titleEditEnabled}
                      options={judgementTypes?.filter(
                        (c) => c.category === (isLocalAct ? "Български" : "Европейски")
                      )}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            {isLocalAct && (
              <Col md="2">
                <Form.Group controlId="actNumber">
                  <Form.Label>
                    <FormattedMessage id="edit-judgement.form.actNumber" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("actNumber")}
                    onBlur={syncTitle}
                    readOnly={titleEditEnabled}
                  />
                </Form.Group>
              </Col>
            )}
            <Col md="2">
              <Form.Group controlId="actDate">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.actDate" />
                </Form.Label>
                <div>
                  <Controller
                    name="actDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value ? dayjs(value, "YYYY/MM/DD").toDate() : undefined}
                        parseDate={(value, format) =>
                          value?.match(/\d\d\.\d\d\.\d\d\d\d/)
                            ? dayjs(value, format).toDate()
                            : undefined
                        }
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format="DD.MM.YYYY"
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(true),
                          weekdaysLong: dayjs.weekdays(true),
                        }}
                        onDayChange={async (value, modifier, input) => {
                          if (value) {
                            onChange(dayjs(value).format("YYYY/MM/DD"));
                            await syncTitle();
                          } else if (!input.getInput().value) {
                            onChange(null);
                            await syncTitle();
                          }
                        }}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                          disabled: titleEditEnabled,
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>
            {isLocalAct && (
              <Col md="4">
                <Form.Group controlId="reporters">
                  <Form.Label>
                    <FormattedMessage id="edit-judgement.form.reporters" />
                  </Form.Label>
                  <Controller
                    name="reporters"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Control
                        type="text"
                        defaultValue={value.join(", ")}
                        onKeyDown={(e) => {
                          console.log(e);
                          if ((e.ctrlKey || e.metaKey) && e.code === "KeyS") {
                            onChange(
                              (e.target as HTMLInputElement).value
                                .split(",")
                                .map((v: string) => v.trim())
                            );
                          }
                        }}
                        onBlur={(val: any) =>
                          onChange(val.target.value.split(",").map((v: string) => v.trim()))
                        }
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            {isLocalAct ? (
              <>
                <Col md="4">
                  <Form.Group controlId="caseType">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseType" />
                    </Form.Label>
                    <Controller
                      name="caseType"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RefDataSelectSingle
                          onChange={onChangeTitleSync(onChange)}
                          value={value}
                          isClearable
                          isDisabled={titleEditEnabled}
                          options={caseTypes}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group controlId="caseNumber">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseNumber" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("caseNumber")}
                      onBlur={syncTitle}
                      readOnly={titleEditEnabled}
                    />
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group controlId="caseYear">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseYear" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("caseYear")}
                      onBlur={syncTitle}
                      readOnly={titleEditEnabled}
                    />
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group controlId="caseYear">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseInternalId" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("caseInternalId")}
                      readOnly={titleEditEnabled}
                    />
                  </Form.Group>
                </Col>
              </>
            ) : (
              <>
                <Col md={isLocalAct ? 2 : 3}>
                  <Form.Group controlId="caseWord">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseType" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("caseWord")}
                      onBlur={syncTitle}
                      readOnly={titleEditEnabled}
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId="caseReference">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseReference" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("caseReference")}
                      onBlur={syncTitle}
                      readOnly={titleEditEnabled}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
          {isAdministrativeAct && (
            <Row>
              <>
                <Col md="4">
                  <Form.Group controlId="caseStatus">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseStatus" />
                    </Form.Label>
                    <Controller
                      name="caseStatus"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RefDataSelectSingle
                          value={value}
                          options={caseStatuses}
                          onChange={onChange}
                          isClearable
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="caseAdminBody">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseAdminBody" />
                    </Form.Label>
                    <Form.Control type="text" {...register("caseAdminBody")} />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="caseMatter">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.caseMatter" />
                    </Form.Label>
                    <Form.Control type="text" {...register("caseMatter")} />
                  </Form.Group>
                </Col>
              </>
            </Row>
          )}
          <Row>
            <Col md={isLocalAct ? 4 : 3}>
              <Form.Group controlId="court">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.court" />
                </Form.Label>
                <Controller
                  name="court"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RefDataSelectSingle
                      onChange={onChangeTitleSync(onChange)}
                      value={value}
                      isClearable
                      isDisabled={titleEditEnabled}
                      options={courts?.filter(
                        (c) =>
                          c.category ===
                            (isLocalAct ? "Български съдилища" : "Европейски съдилища") &&
                          (isEuAct ||
                            (isAdministrativeAct
                              ? c.name.startsWith("ВАС")
                              : !c.name.startsWith("ВАС")))
                      )}
                    />
                  )}
                />
                {/* {errors.court?.message} */}
              </Form.Group>
            </Col>
            <Col md={isLocalAct ? 4 : 5}>
              <Form.Group controlId="chamber">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.chamber" />
                </Form.Label>
                {isLocalAct ? (
                  <Controller
                    name="chamber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RefDataSelectSingle
                        onChange={onChangeTitleSync(onChange)}
                        value={value}
                        isClearable
                        isDisabled={titleEditEnabled}
                        options={chambers?.filter(
                          (c) => c.category === (isAdministrativeAct ? "ВАС" : "ВКС")
                        )}
                      />
                    )}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    {...register("euChamber")}
                    readOnly={titleEditEnabled}
                    onBlur={syncTitle}
                  />
                )}
              </Form.Group>
            </Col>
            {isLocalAct ? (
              <Col md="4">
                <Form.Group controlId="proceeding">
                  <Form.Label>
                    <FormattedMessage id="edit-judgement.form.proceeding" />
                  </Form.Label>
                  <Controller
                    name="proceeding"
                    control={control}
                    defaultValue={{
                      id: "10",
                      code: "OTHER",
                      shortName: "Друго",
                      name: "Друго",
                      rank: 10,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <RefDataSelectSingle
                        onChange={onChange}
                        value={value}
                        isClearable
                        options={proceedings?.filter(
                          (c) => c.category === (isAdministrativeAct ? "ВАС" : "ВКС")
                        )}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            ) : (
              <>
                <Col md="4">
                  <Form.Group controlId="preliminaryRuling" className="checkbox">
                    <Form.Label>
                      <FormattedMessage id="edit-judgement.form.preliminaryRuling" />
                    </Form.Label>
                    <div>
                      <Controller
                        name="preliminaryRuling"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SelectSingle
                            options={["true", "false"]}
                            getOption={(option) => ({
                              label: intl.formatMessage({ id: `boolean.${option}` }),
                              value: option,
                            })}
                            value={value + ""}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="src">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.src" />
                </Form.Label>
                <Controller
                  name="src"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputGroup className="mb-3">
                      <Form.Control type="text" onChange={onChange} value={value} />
                      <Button
                        className={`noSelect ${!value ? "disabled" : ""}`}
                        href={value}
                        target="_blank"
                        disabled={!value}
                      >
                        <FaLink />
                      </Button>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="caseSrc">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.caseSrc" />
                </Form.Label>
                <Controller
                  name="caseSrc"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputGroup className="mb-3">
                      <Form.Control type="text" onChange={onChange} value={value} />
                      <Button
                        className={`noSelect ${!value ? "disabled" : ""}`}
                        href={value}
                        target="_blank"
                        disabled={!value}
                      >
                        <FaLink />
                      </Button>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {isLocalAct ? (
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="annotatedBy">
                      <Form.Label>
                        <FormattedMessage id="edit-judgement.form.annotatedBy" />
                        <OverlayTrigger
                          placement="right"
                          overlay={(props) => (
                            <Tooltip id="annotationSource-tooltip" {...props}>
                              Попълва се автоматично при запазване
                            </Tooltip>
                          )}
                        >
                          <span>
                            <FaQuestionCircle className="text-muted ms-1" />
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <ConnectUser>
                        {({ currentUser }) => (
                          <Controller
                            name="annotatedBy"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <SelectSingle
                                options={lexebraEditors}
                                getOption={(lexebraEditor) => ({
                                  label: lexebraEditor.name,
                                  value: lexebraEditor,
                                })}
                                value={value}
                                isDisabled={
                                  !hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)(currentUser)
                                }
                                onChange={onChange}
                              />
                            )}
                          />
                        )}
                      </ConnectUser>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id="add-provision.modal.form.sgIssueNumber" />
                      </Form.Label>
                      <br />
                      <Form.Control type="text" {...register("sgIssueNumber")} />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id="add-provision.modal.form.sgYear" />
                      </Form.Label>
                      <br />
                      <Form.Control type="text" {...register("sgYear")} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="annotationSource">
                      <Form.Label>
                        <FormattedMessage id="edit-summary.form.annotationSource" />
                        <OverlayTrigger
                          placement="right"
                          overlay={(props) => (
                            <Tooltip id="annotationSource-tooltip" {...props}>
                              Попълва се само за анотации от външен източник
                            </Tooltip>
                          )}
                        >
                          <span>
                            <FaQuestionCircle className="text-muted ms-1" />
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <ConnectUser>
                        {({ currentUser }) => (
                          <Controller
                            name="annotationSource"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <AutoSizedTextArea onChange={onChange} value={value} minHeight={40} />
                            )}
                          />
                        )}
                      </ConnectUser>
                    </Form.Group>
                    <div className="mt-2">
                      <TopicsSection />
                    </div>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col md={8}>
                <span>&nbsp;</span>
              </Col>
            )}
            <Col md={4}>
              <Form.Group controlId="comments">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.comments" />
                </Form.Label>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} minHeight={200} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <ProvisionsSection title={act?.title || ""} />
            </Col>
            <Col md={4}>
              {isDomesticAct && (
                <Form.Group controlId="allowedByCourt">
                  <Form.Label>
                    <FormattedMessage id="edit-judgement.form.allowedByCourt" />
                  </Form.Label>
                  <Controller
                    name="allowedByCourt"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectSingle
                        options={Object.keys(AllowedByCourt)}
                        getOption={(allowedByCourt) => ({
                          label: intl.formatMessage({
                            id: `judgement-act.allowed-by-court.${allowedByCourt}`,
                          }),
                          value: allowedByCourt,
                        })}
                        value={value}
                        onChange={onChange}
                        isClearable
                      />
                    )}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <RelatedActsTable />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="body">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.contents" />
                </Form.Label>
                <Controller
                  name="contents"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor
                      defaultValue={value}
                      height="350px"
                      expandHeight="500px"
                      cleanupPastedContent
                      onChange={onChange}
                      id="contents"
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="annotation">
                <Form.Label>
                  <FormattedMessage id="edit-judgement.form.editorAnnotation" />
                </Form.Label>
                <Controller
                  name="editorAnnotation"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor
                      defaultValue={value}
                      height="200px"
                      expandHeight="350px"
                      cleanupPastedContent
                      onChange={onChange}
                      id="editorAnnotation"
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </DocumentPageLayout>
    </FormProvider>
  );
};

const ProtectedJudgementEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)} fallback={NotAuthorized}>
      <JudgementEditPage />
    </Visible>
  );
};

export default ProtectedJudgementEditPage;
