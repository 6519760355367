type LabelValueProps = {
  label: string | React.ReactNode;
  show?: boolean;
  className?: string;
};

const LabelValue: React.FC<LabelValueProps> = ({
  label,
  children,
  className = "",
  show = true,
}) => {
  return show ? (
    <div className={className}>
      <span className="flex-shrink-0 me-1 lv-label">
        {label}
        {typeof label === "string" && ":"}
      </span>
      {children}
    </div>
  ) : null;
};

export default LabelValue;
