/* eslint-disable react/jsx-props-no-spreading */
import RcCollapse from "@kunukn/react-collapse";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

type SimpleCollapseProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  className?: string;
  defaultExpanded?: boolean;
};

const SimpleCollapse: React.FC<SimpleCollapseProps> = ({
  icon,
  label,
  children,
  className = "",
  defaultExpanded = true,
}) => {
  const [isOpen, setOpen] = useState(defaultExpanded);

  return (
    <div className={`accordion ${className}`}>
      <Card>
        <Card.Header onClick={() => setOpen(!isOpen)}>
          <Container>
            {icon} {label}
            {isOpen ? (
              <FaAngleUp className="toggle-indicator" />
            ) : (
              <FaAngleDown className="toggle-indicator" />
            )}
          </Container>
        </Card.Header>
        <RcCollapse
          overflowOnExpanded
          isOpen={isOpen}
          transition="height 400ms cubic-bezier(0.4, 0, 0.2, 1)"
          onInit={() => setOpen(defaultExpanded)}
        >
          <Card.Body>{children}</Card.Body>
        </RcCollapse>
      </Card>
    </div>
  );
};

export default SimpleCollapse;
