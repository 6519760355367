import { v4 as uuid } from "uuid";

export const trimRange = (range) => {
  let quote = range.toString();
  let leadingSpaces = 0;
  let trailingSpaces = 0;

  // Count/strip leading spaces
  while (quote.substring(0, 1) === " ") {
    leadingSpaces += 1;
    quote = quote.substring(1);
  }

  // Adjust range
  if (leadingSpaces > 0) {
    range.setStart(range.startContainer, range.startOffset + leadingSpaces);
  }

  return range;
};

export const rangeToSelection = (range, containerEl) => {
  const rangeBefore = document.createRange();

  // A helper range from the start of the contentNode to the start of the selection
  rangeBefore.setStart(containerEl, 0);
  rangeBefore.setEnd(range.startContainer, range.startOffset);

  const quote = range.toString().trim();
  const start = rangeBefore.toString().length;

  return new Selection({
    selector: [
      {
        type: "TextQuoteSelector",
        exact: quote,
      },
      {
        type: "TextPositionSelector",
        start: start,
        end: start + quote.length,
      },
    ],
  }).toAnnotation();
};

/**
 * An "annotation in draft mode". Really the same
 * data structure, but as a separate class so we can
 * tell things apart properly.
 */
export class Selection {
  constructor(target, body) {
    this.underlying = {
      type: "Selection",
      body: body || [],
      target,
    };
  }

  get bodies() {
    return Array.isArray(this.underlying.body) ? this.underlying.body : [this.underlying.body];
  }

  selector = (type) => {
    const { target } = this.underlying;

    if (target.selector) {
      // Normalize to array
      const selectors = Array.isArray(target.selector) ? target.selector : [target.selector];

      return selectors.find((s) => s.type === type);
    }
  };

  toAnnotation = () => {
    const a = Object.assign({}, this.underlying, {
      "@context": "http://www.w3.org/ns/anno.jsonld",
      type: "Annotation",
      id: `#${uuid()}`,
    });

    return a;
  };
}
