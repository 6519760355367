import { FormattedMessage, useIntl } from "react-intl";
import LabelValue from "../../../components/label-value";
import PreloadingLink from "../../../components/PreloadingLink";
import { useSearchContext } from "../../../contexts/search.context";
import { Selection } from "../../../hooks/useMultipleSelection";
import { JudgementAct } from "../../../models/JudgementAct.model";
import { prefetchJudgementAct, useEuJudgementActs } from "../../../services/judgement-act-services";
import { fetchSummary } from "../../../services/summary-services";
import ResultsTable, { ResultItem } from "../ResultsTable/results-table";
import ResultsTableHeader from "../ResultsTable/results-table-header";
import { JudgementActsActionsMenu } from "./judgement-acts-results-table";

const EuJudgementActsResults = () => {
  const { formatMessage } = useIntl();

  const results = useEuJudgementActs();

  const { opened, addOpened } = useSearchContext();

  return (
    <ResultsTable
      results={results}
      header={(params) => (
        <ResultsTableHeader {...params} actions={<JudgementActsActionsMenu {...params} />} />
      )}
      itemToSelection={itemToSelection}
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <PreloadingLink
            to={judgementActHref(item)}
            state={{ title: item.title }}
            preload={(cache) => prefetchJudgementAct(item.id, cache)}
            onClick={() => addOpened(item.id)}
            className={opened.indexOf(item.id) > -1 ? "visited" : ""}
          >
            {item.title}
          </PreloadingLink>
          <LabelValue label={formatMessage({ id: "edit-judgement.form.preliminaryRuling" })}>
            {item.preliminaryRuling ? (
              <FormattedMessage id="label.yes" />
            ) : (
              <FormattedMessage id="label.no" />
            )}
          </LabelValue>

          {item.summaries?.length > 0 && (
            <LabelValue label={formatMessage({ id: "view-judgement.labels.summary" })}>
              {item.summaries!.map((summary) => (
                <PreloadingLink
                  to={`/summaries/${summary.id}`}
                  key={summary.id}
                  preload={() => fetchSummary(summary.id)}
                >
                  {summary.shortTitle || <FormattedMessage id="label.link" />}
                </PreloadingLink>
              ))}
            </LabelValue>
          )}
          <ResultsTable.RowIndicators item={item} />
          <ResultsTable.RowHighlights item={item} />
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const itemToSelection = (item: JudgementAct): Selection => ({
  _id: item.id,
  title: item.title,
  href: judgementActHref(item),
  hasAnnotations: item.hasAnnotations,
  hasSummary: item.summaries.length > 0,
});

const judgementActHref = (item: ResultItem): string => `/judgement-acts/${item.id}`;

export default EuJudgementActsResults;
