import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Accordion,
  Badge,
  Container,
  Form,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";
import { useDeviceSelectors } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { BiCategory } from "react-icons/bi";
import { FaAngleDown, FaAngleUp, FaBars, FaSave } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ActionButton, InlineLinkButton } from "../../../components/Button/button";
import { HelpLink, HELP_LINK_SAVESEARCH } from "../../../components/HelpLink/help-link";
import PreloadingLink from "../../../components/PreloadingLink";
import SimpleSelect from "../../../components/SimpleSelect";
import { useSearchContext } from "../../../contexts/search.context";
import useBodyScrollLock from "../../../hooks/useBodyScrollLock";
import { UserSearch } from "../../../models/UserSearch.model";
import { prefetchDocumentStats } from "../../../services/document-services";
import { addUserSearch, delUserSearch, useUserSearches } from "../../../services/user-services";
import { ReadonlyValues } from "../CurrentFilters/current-filters";
import ResponsiveFilter from "../FiltersSidebar/responsive-filter";
import { toObject, useFiltersCount } from "../search-utils";
import "./../../edit-page.scss";
import "./saved-searches.scss";

type ShowProps = {
  setShowSaveAs: (show: boolean) => void;
  setShowSaved: (show: boolean) => void;
  showSaveAs: boolean;
};

const SavedSearches = () => {
  const { pathname, search } = useLocation();
  const filterCount = useFiltersCount();
  const searches = useUserSearches();

  const [showSaved, setShowSaved] = useState(false);
  const [showSaveAs, setShowSaveAs] = useState(false);

  useHotkeys(
    "Escape",
    () => {
      setShowSaved(false);
      setShowSaveAs(false);
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const matchedSearch = searches.saved.find((s) => s.hash === pathname + search);

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  if (isMobileOnly) {
    return <SavedSearchesMobile />;
  }

  return (
    <>
      {filterCount > 0 &&
        (!matchedSearch ? (
          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            overlay={
              <Popover id="save-search-popover" className="shadow generic-popover">
                <Popover.Header className="d-flex">
                  Запази търсенето
                  <HelpLink articleId={HELP_LINK_SAVESEARCH} />
                  <InlineLinkButton onClick={() => setShowSaveAs(false)} className="ms-auto">
                    <IoMdClose />
                  </InlineLinkButton>
                </Popover.Header>
                <Popover.Body>
                  <SaveSearchPopoverContent
                    setShowSaveAs={setShowSaveAs}
                    setShowSaved={setShowSaved}
                    showSaveAs={showSaveAs}
                  />
                </Popover.Body>
              </Popover>
            }
            show={showSaveAs}
            rootClose
            onToggle={(show) => {
              setShowSaved(false);
              setShowSaveAs(show);
            }}
          >
            <Button variant="light" onClick={async () => {}} className={showSaveAs ? "active" : ""}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="save-search-tooltip">Запази търсенето</Tooltip>}
              >
                <span>
                  <FaSave />
                </span>
              </OverlayTrigger>
            </Button>
          </OverlayTrigger>
        ) : (
          <InputGroup.Text className="text-muted">
            <span>{matchedSearch.title}</span>
          </InputGroup.Text>
        ))}
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        overlay={
          <Popover id="saved-searches-popover" className="shadow generic-popover">
            <Popover.Header className="d-flex">
              Запазени търсения
              <InlineLinkButton onClick={() => setShowSaved(false)} className="ms-auto">
                <IoMdClose />
              </InlineLinkButton>
            </Popover.Header>
            <Popover.Body>
              <SavedSearchesPopoverContent
                setShowSaveAs={setShowSaveAs}
                setShowSaved={setShowSaved}
                showSaveAs={showSaveAs}
              />
            </Popover.Body>
          </Popover>
        }
        show={showSaved}
        rootClose
        onToggle={(show) => {
          setShowSaveAs(false);
          setShowSaved(show);
        }}
      >
        <Button variant="light" onClick={async () => {}} className={showSaved ? "active" : ""}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="saved-searches-tooltip">Запазени търсения</Tooltip>}
          >
            <span>
              <FaBars />
            </span>
          </OverlayTrigger>
        </Button>
      </OverlayTrigger>
    </>
  );
};

const SavedSearchesMobile = () => {
  const { pathname, search } = useLocation();
  const filterCount = useFiltersCount();
  const searches = useUserSearches();

  const [showSaved, setShowSaved] = useState(false);
  const [showSaveAs, setShowSaveAs] = useState(false);

  useBodyScrollLock(showSaved || showSaveAs);

  const matchedSearch = searches.saved.find((s) => s.hash === pathname + search);

  return (
    <>
      <Offcanvas
        show={showSaveAs}
        onHide={() => setShowSaveAs(false)}
        placement="bottom"
        backdrop
        id="save-search-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Запази търсенето</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SaveSearchPopoverContent
            setShowSaveAs={setShowSaveAs}
            setShowSaved={setShowSaved}
            showSaveAs={showSaveAs}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showSaved}
        onHide={() => setShowSaved(false)}
        placement="bottom"
        backdrop
        id="saved-searches-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Запазени търсения</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SavedSearchesPopoverContent
            setShowSaveAs={setShowSaveAs}
            setShowSaved={setShowSaved}
            showSaveAs={showSaveAs}
          />
        </Offcanvas.Body>
      </Offcanvas>
      {filterCount > 0 &&
        (!matchedSearch ? (
          <Button
            variant="light"
            onClick={() => {
              setShowSaved(false);
              setShowSaveAs(!showSaveAs);
            }}
            title="Запази търсенето"
            className={showSaveAs ? "active" : ""}
          >
            <FaSave />
          </Button>
        ) : (
          <InputGroup.Text className="text-muted">
            <span>{matchedSearch.title}</span>
          </InputGroup.Text>
        ))}

      <Button
        variant="light"
        onClick={() => {
          setShowSaveAs(false);
          setShowSaved(!showSaved);
        }}
        title="Запазени търсения"
        className={showSaved ? "active" : ""}
      >
        <FaBars />
      </Button>
    </>
  );
};

const SavedSearchesPopoverContent: React.FC<ShowProps> = ({ setShowSaveAs, setShowSaved }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const filterCount = useFiltersCount();
  const searches = useUserSearches();

  const groupedData = useMemo(() => buildGroupedData(searches.saved), [searches.saved]);

  return (
    <>
      {searches.saved.length ? (
        <Accordion defaultActiveKey="Без категория" flush>
          {groupedData.map((group) => (
            <Accordion.Item eventKey={group.label} key={group.label}>
              <Accordion.Header>
                <BiCategory />
                {group.label}
                <Badge>{group.items.length}</Badge>
                <FaAngleUp className="toggle-indicator up" />
                <FaAngleDown className="toggle-indicator down" />
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  {group.items.map((search: UserSearch) => (
                    <ListGroupItem
                      onClick={() => navigate(search.hash, { replace: true })}
                      key={search.id}
                    >
                      <div className="d-flex">
                        <PreloadingLink
                          to={search.hash}
                          preload={async (cache) =>
                            await prefetchDocumentStats(new URLSearchParams(search.query), cache)
                          }
                          onClick={() => setShowSaved(false)}
                        >
                          {search.title}
                        </PreloadingLink>
                        <IoMdClose
                          onClick={async () => {
                            try {
                              await delUserSearch(search.id!);
                              toast.success("Търсенето бе успешно премахнато");
                            } catch (e) {
                              console.log(e);
                              toast.error("Грешка при премахване на търсенето");
                            }
                          }}
                          className="ms-auto"
                          title={formatMessage({ id: "button.remove" })}
                        />
                      </div>
                      <div className="current-filters-values">
                        <ReadonlyValues queryObject={toObject(search.query)} />
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <Container fluid className="text-center m-2" style={{ fontSize: "1rem" }}>
          Нямате запазени търсения
          <br />
          {filterCount > 0 && (
            <>
              За да запазите текущото търсене натиснете{" "}
              <InlineLinkButton
                onClick={() => {
                  setShowSaveAs(true);
                  setShowSaved(false);
                }}
              >
                тук
              </InlineLinkButton>
            </>
          )}
        </Container>
      )}
    </>
  );
};

const SaveSearchPopoverContent: React.FC<ShowProps> = ({ setShowSaveAs, showSaveAs }) => {
  const { search: apiSearch } = useSearchContext();
  const { pathname, search } = useLocation();
  const { formatMessage } = useIntl();
  const searches = useUserSearches();

  const groupedData = useMemo(() => buildGroupedData(searches.saved), [searches.saved]);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<UserSearch & { newCategory?: string }>({
    defaultValues: {
      category: "Без категория",
    },
  });

  useEffect(() => {
    !showSaveAs && reset();
  }, [showSaveAs, reset]);

  const { title, category, newCategory } = watch();

  const ref = useRef<any>();

  useEffect(() => {
    ref.current && showSaveAs && setTimeout(() => ref.current.focus(), 100);
  }, [showSaveAs]);

  return (
    <Form className="edit-form">
      <Form.Group controlId="title">
        <Form.Label>
          <FormattedMessage id="save-search.form.title" />
        </Form.Label>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Control
              ref={ref}
              type="text"
              onChange={onChange}
              value={value}
              autoComplete="off"
              autoFocus
            />
          )}
        />
      </Form.Group>
      <Form.Group controlId="category">
        <Form.Label>
          <FormattedMessage id="save-search.form.category" />
        </Form.Label>
        <Controller
          name="category"
          control={control}
          render={({ field: { onChange, value } }) =>
            category ? (
              <ResponsiveFilter>
                <SimpleSelect
                  onChange={(selection) => onChange(selection[0])}
                  value={value ? [value] : []}
                  options={groupedData.map((group) => group.label)}
                  getOption={(label) => ({ label, value: label })}
                  isMulti={false}
                  disableSearch={true}
                  isCreatable={true}
                  overrideStrings={{
                    createNew: formatMessage({ id: "save-search.form.category.create-new" }),
                  }}
                  placeholder={formatMessage({ id: "save-search.form.category" })}
                />
              </ResponsiveFilter>
            ) : (
              <></>
            )
          }
        />

        <Controller
          name="newCategory"
          control={control}
          render={({ field: { onChange, value } }) =>
            !category ? (
              <InputGroup>
                <Form.Control
                  type="text"
                  onChange={onChange}
                  value={value}
                  autoComplete="off"
                  autoFocus
                />
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    onChange("");
                    setValue("category", "Без категория");
                  }}
                  title={formatMessage({ id: "summary-search-panel.button.close" })}
                >
                  <IoMdClose />
                </Button>
              </InputGroup>
            ) : (
              <></>
            )
          }
        />
      </Form.Group>
      <Form.Group controlId="filters">
        <Form.Label>
          <FormattedMessage id="save-search.form.filters" />
        </Form.Label>
        <div className="current-filters-values">
          <ReadonlyValues queryObject={toObject(apiSearch)} />
        </div>
      </Form.Group>
      <ActionButton
        onClick={handleSubmit(async (data) => {
          try {
            await addUserSearch({
              hash: pathname + search,
              query: apiSearch || "",
              title: data.title,
              category: data.category || data.newCategory!,
              persistent: true,
            });
            setShowSaveAs(false);

            toast.success("Търсенето бе успешно запазено");
          } catch (e) {
            console.log(e);
            toast.error("Грешка при запазване на търсенето");
          }
        })}
        disabled={!title || (!category && !newCategory)}
        submitting={isSubmitting}
      >
        <FormattedMessage id="button.confirm" />
      </ActionButton>
    </Form>
  );
};

const buildGroupedData = (saved: UserSearch[]) => {
  return saved
    ?.reduce((acc: any[], item) => {
      const category = item.category;
      const element = acc.filter((e) => e.label === category);
      if (!element[0]) {
        acc.push({ label: category, items: [item] });
      } else {
        element[0].items.push(item);
      }
      return acc;
    }, [])
    .sort((g1, g2) => g1.label.localeCompare(g2.label));
};

export default SavedSearches;
