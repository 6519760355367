import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import CheckboxTree from "react-checkbox-tree";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import RefData from "../../models/RefData.model";
import SummaryTopic from "../../models/SummaryTopic.model";
import {
  HierarchicalLabelValue,
  useSummaryTopicsTree,
} from "../../services/summary-topic.services";
import "./topic-select.css";

type TopicSelectProps = {
  checkedNodes: SummaryTopic[];
  setCheckedNodes: (nodes: SummaryTopic[]) => void;
  disabledNodes?: RefData[];
  countsPerTopic?: any;
  autoFocus?: boolean;
};

const TopicSelect: React.FC<TopicSelectProps> = ({
  checkedNodes = [],
  setCheckedNodes,
  disabledNodes = [],
  countsPerTopic,
  autoFocus = false,
}) => {
  const topics = useSummaryTopicsTree({ disabledTopics: disabledNodes, countsPerTopic });

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [filteredNodes, setFilteredNodes] = useState<any>([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    setFilteredNodes(topics.tree);
  }, [topics.tree]);

  const ref = useRef<any>();
  useEffect(() => {
    ref.current && autoFocus && setTimeout(() => ref.current.focus(), 100);
  }, [autoFocus]);

  useEffect(() => {
    setExpandedNodes(
      Array.from(
        new Set(
          checkedNodes
            .map((checkedNode) => topics.nodes.get(checkedNode.id as number)?.ancestors)
            .filter((item) => !!item)
            .flat()
        )
      ) as string[]
    );
  }, [checkedNodes, topics.nodes]);

  return (
    <>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Въведете тема..."
          autoComplete="off"
          value={filterText}
          ref={ref}
          onChange={(e) => {
            setFilterText(e.target.value);
            if (!e.target.value) {
              setFilteredNodes(topics.tree);
              setExpandedNodes([]);
              return;
            }
            if (e.target.value.length >= 3) {
              const filtered = filterTree(e.target.value, topics.tree);
              setFilteredNodes(filtered);
              setExpandedNodes(flattenTree(filtered));
            }
          }}
          style={{ marginTop: "10px", marginBottom: "10px", width: "94%" }}
          autoFocus={autoFocus}
        />
      </Form.Group>

      <CheckboxTree
        nodes={filteredNodes}
        checked={checkedNodes.map((node) => node.id + "")}
        expanded={expandedNodes!}
        onCheck={(nodes: string[]) =>
          setCheckedNodes(nodes.map((id) => topics.nodes.get(parseInt(id))!.ref))
        }
        onExpand={setExpandedNodes}
        noCascade
        icons={{
          expandClose: <FaCaretRight />,
          expandOpen: <FaCaretDown />,
        }}
      />
    </>
  );
};

const filterTree = (filterText: string, nodes: HierarchicalLabelValue<SummaryTopic>[]) => {
  const filterNodes = (
    filtered: HierarchicalLabelValue<SummaryTopic>[],
    node: HierarchicalLabelValue<SummaryTopic>
  ) => {
    const children = (node.children || []).reduce(filterNodes, []);

    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
      children.length
    ) {
      filtered.push({ ...node, children });
    }

    return filtered;
  };

  return nodes.reduce(filterNodes, []);
};

const flattenTree = (nodes: HierarchicalLabelValue<SummaryTopic>[]) => {
  const filterNodes = (
    filtered: HierarchicalLabelValue<SummaryTopic>[],
    node: HierarchicalLabelValue<SummaryTopic>
  ) => {
    (node.children || []).reduce(filterNodes, filtered);
    filtered.push(node);
    return filtered;
  };

  return nodes.reduce(filterNodes, []).map((n) => n.value);
};

export default TopicSelect;
