import React from "react";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import TopicSelect from "../../../components/TopicSelect/topic-select";
import SummaryTopic from "../../../models/SummaryTopic.model";
import { prefetchDocumentStats } from "../../../services/document-services";
import { useSummaryTopics } from "../../../services/summary-topic.services";
import { SearchCategory, useAvailableFilters, useSearchCategory } from "../search-utils";
import "./topic-filter.css";

const TopicFilter = () => {
  const filterName = "summaryTopic";
  const topics = useSummaryTopics();

  const availableFilters = useAvailableFilters();

  const searchCategory = useSearchCategory();
  return (
    <SearchFieldController
      name={filterName}
      preload={prefetchDocumentStats}
      render={({ field: { onChange, value } }) => {
        const checkedNodes = topics.data?.filter((el) => el.id + "" === value[0]) || [];
        return (
          <TopicSelect
            checkedNodes={checkedNodes}
            setCheckedNodes={async (nodes: SummaryTopic[]) => {
              const { navigate } = await onChange(
                { summaryTopic: nodes.map((cn) => cn.id + "").filter((n) => !value.includes(n)) },
                false
              );
              navigate(
                searchCategory === SearchCategory.QUESTIONS
                  ? "/search/questions"
                  : searchCategory === SearchCategory.ACTS
                  ? "/search/acts"
                  : searchCategory === SearchCategory.EU_ACTS
                  ? "/search/eu-acts"
                  : "/search/summaries"
              );
            }}
            disabledNodes={topics.data?.filter(
              (topic) =>
                availableFilters?.summaryTopic && !availableFilters.summaryTopic[topic.id + ""]
            )}
            countsPerTopic={availableFilters?.summaryTopic}
          />
        );
      }}
    />
  );
};

export default TopicFilter;
