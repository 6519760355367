import { Recogito } from "@recogito/recogito-js";
import "@recogito/recogito-js/dist/recogito.min.css";
import { EntityModel } from "hateoas-hal-types";
import parse from "html-react-parser";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { useLocation, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Privileges, useAuth } from "../../contexts/auth.context";
import HighlightContextProvider, { useHighlightContext } from "../../contexts/highlight.context";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { JudgementAct } from "../../models/JudgementAct.model";
import { UserDocumentAnno } from "../../models/UserDocumentAnno.model";
import { UserDocumentAnnoScope } from "../../models/UserDocumentAnnoScope.enum";
import { judgementActHref, useJudgementAct } from "../../services/judgement-act-services";
import { summaryHref } from "../../services/summary-services";
import { useCurrentUser, useUserDocumentAnno } from "../../services/user-services";
import DocumentLikeAction from "../DocumentPage/document-like-action";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import EditAnnotationNotePopover from "./edit-anotation-note";
import JudgementPageNavigation from "./judgement-page-navigation";
import JudgementViewPageActions from "./judgement-view-page-actions";
import JudgementActViewPageLoading from "./judgement-view-page-loading";
import "./judgement-view-page.scss";
import JudgementActMetadata, {
  filterAndSortTocAnnotations,
  filterPersonalMarkers,
} from "./judgmenet-view-page-metadata";
import pagemap from "./pagemap";
import RenderTextWithReferences from "./renderTextWithReferences";
import SelectedActTextPopover from "./selected-act-text-popover";
import SelectedAnnotationPopover from "./selected-annotation-popover";
const JudgementViewPage = () => {
  const { actId } = useParams();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const act = useJudgementAct(actId + (q ? `?q=${q}` : ""));
  const location = useLocation();

  return act ? (
    <HighlightContextProvider>
      <JudgementViewForm act={act} key={location.key} />
    </HighlightContextProvider>
  ) : (
    <JudgementActViewPageLoading mode="view" className="page-loading" />
  );
};

interface JudgementViewPageProps {
  act: EntityModel<JudgementAct>;
}

const JudgementViewForm: FunctionComponent<JudgementViewPageProps> = ({ act }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [recogito, setRecogito] = useState<any>();

  const currentUser = useCurrentUser();
  const auth = useAuth();
  const canEditAllSummaries = auth.hasPrivilige(Privileges.EDIT_ALL_SUMMARIES);
  const canEditSummaries = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);

  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  useEffect(() => {
    const r = new Recogito({
      content: "act-body",
      disableEditor: true,
      formatter: (anno: any) => {
        const noteText = getFirstCommentText(anno);
        return getCategory(anno)?.value !== "Без етикет"
          ? "categorized " +
              (getAiFlag(anno)?.value === "true" && canEditSummaries
                ? "ai-noted ai-pct-" + parseFloat(noteText) * 100
                : "")
          : "" + (noteText ? "noted" : "");
      },
    });
    r.readOnly = true;

    setRecogito(r);

    return () => r.destroy();
  }, [setRecogito]);

  const { includeEditorAnnotations, includeUserAnnotations } = useHighlightContext();
  const annotations = useUserDocumentAnno(act.id);
  const [recogitoAnnotation, setRecogitoAnnotations] = useState<UserDocumentAnno[] | undefined>();
  // const filteredAnnotation = useMemo(
  //   () =>
  //     recogitoAnnotationInState?.filter(
  //       (anno) =>
  //         (anno.scope === UserDocumentAnnoScope.PUBLIC && includeEditorAnnotations) ||
  //         (anno.scope === UserDocumentAnnoScope.PRIVATE && includeUserAnnotations)
  //     ),
  //   [recogitoAnnotation, includeEditorAnnotations, includeUserAnnotations]
  // );

  const [target, setTarget] = useState<[any, HTMLElement]>();
  const [hoverTarget, setHoverTarget] = useState<[any, HTMLElement]>();
  const [noteTarget, setNoteTarget] = useState<[any, HTMLElement]>();

  useEffect(() => {
    setRecogitoAnnotations(annotations);
  }, [setRecogitoAnnotations, annotations]);

  const canChangeAnno = useCallback(
    (anno: any) => {
      return (
        canEditAllSummaries ||
        !getScope(anno) ||
        getScope(anno).value === UserDocumentAnnoScope.PRIVATE ||
        recogitoAnnotation?.find((ranno) => ranno.id === anno.id)?.userId === currentUser?.id ||
        (canEditSummaries && getAiFlag(anno)?.value === "true")
      );
    },
    [canEditAllSummaries, recogitoAnnotation, currentUser?.id]
  );

  const onAnnoSelect = (e: any) => {
    let target = e.target as HTMLElement;
    if (window.getSelection()?.toString()) {
      return;
    }
    if (
      target.parentElement?.getAttribute &&
      target.parentElement?.getAttribute("data-id") &&
      target.classList.contains("categorized")
    ) {
      target = target.parentElement;
    }
    if (hoverTarget?.[1] !== target && target.getAttribute && target.getAttribute("data-id")) {
      const a = recogitoAnnotation?.find((item) => item.id === target.getAttribute("data-id"));
      if (a?.scope === UserDocumentAnnoScope.PRIVATE && !includeUserAnnotations) {
        return;
      }
      if (a?.scope === UserDocumentAnnoScope.PUBLIC && !includeEditorAnnotations) {
        return;
      }
      setHoverTarget([{ ...JSON.parse(a!.body) }, target]);
      Array.from(document.querySelectorAll(`span.r6o-selection`)).forEach((el) => {
        el.classList.remove("r6o-selection");
      });
      Array.from(document.querySelectorAll(`span.r6o-annotation[data-id='${a!.id}']`)).forEach(
        (el) => {
          el.classList.add("r6o-selection");
          recogito.pagemap.redraw();
        }
      );
      setTarget(undefined);
    } else {
      Array.from(document.querySelectorAll(`span.r6o-selection`)).forEach((el) => {
        el.classList.remove("r6o-selection");
        recogito.pagemap.redraw();
      });
      setHoverTarget(undefined);
    }
  };

  useEffect(() => {
    if (recogito && recogitoAnnotation) {
      recogito.setAnnotations(recogitoAnnotation.map((a) => JSON.parse(a.body)));
      setTimeout(() => {
        const canvas = document.querySelector("#map") as HTMLCanvasElement;
        toggleCanvas(recogito, q);
        if (recogito.pagemap) {
          recogito.pagemap.redraw();
        } else {
          recogito.pagemap = pagemap(canvas, {
            styles: {
              "div.act-text > p": "rgba(0,0,0,0.08)",
              "span.r6o-annotation.categorized": "rgba(0, 129, 204,1)",
              "span.r6o-selection.categorized": "rgba(0, 81, 128,1)",
              "span.r6o-annotation:not(.categorized)": "rgb(255,165,0,.7)",
              "span.r6o-selection:not(.categorized)": "rgba(255,165,0,1)",
              b: "rgba(134, 52, 235)",
            },
            back: "rgba(0,0,0,0.02)",
            view: "rgba(0,0,0,0.05)",
            drag: "rgba(0,0,0,0.10)",
            interval: null,
          });
          // return () => {
          //   icons.forEach((icon) => icon.parentElement?.removeChild(icon));
          // };
        }
        const icons: HTMLElement[] = [];
        const processedAnno = new Set();
        document
          .querySelectorAll(".note-icon")
          .forEach((icon) => icon.parentElement?.removeChild(icon));
        recogitoAnnotation?.forEach((anno) => {
          document.querySelectorAll(`.noted[data-id='${anno.id}']:not(:empty)`).forEach((span) => {
            if (span.innerHTML.trim().length === 0) {
              span.classList.add("d-none");
              return;
            }
            if (processedAnno.has(anno.id)) {
              return;
            }
            processedAnno.add(anno.id);
            const el = document.createElement("i");
            el.classList.add("note-icon");
            el.classList.add("far");
            el.classList.add("fa-comment-alt");
            el.onclick = (e) => {
              e.stopPropagation();
              e.preventDefault();
              setNoteTarget((target) => {
                const alreadySelected = target && target[1] === span;
                return alreadySelected ? undefined : [JSON.parse(anno.body!), span as HTMLElement];
              });
            };
            span.insertBefore(el, span.firstChild);

            const noteText = getFirstCommentText(JSON.parse(anno.body!));
            (span as HTMLSpanElement).dataset.probability = noteText;

            icons.push(el);
          });
          const processedAiAnno = new Set();
          document.querySelectorAll(`.ai-noted[data-id='${anno.id}']`).forEach((span) => {
            if (span.innerHTML.trim().length === 0) {
              span.classList.add("d-none");
              return;
            }
            if (processedAiAnno.has(anno.id)) {
              return;
            }
            processedAiAnno.add(anno.id);
          });
        });
        //setRecogitoAnnotations((annotations) => [...(annotations || [])]);
      }, 100);
    }
  }, [recogito, recogitoAnnotation, q]);

  useEffect(() => {
    if (noteTarget) {
      const icon = noteTarget[1].querySelector("i");
      if (icon) {
        icon.style.fontWeight = "900";
      }
    }
    return () => {
      if (noteTarget) {
        const icon = noteTarget[1].querySelector("i");
        if (icon) {
          icon.style.fontWeight = "400";
        }
      }
    };
  }, [noteTarget]);

  return (
    <DocumentPageLayout
      id={act.id}
      title={act.title}
      status={act.status}
      accessMode={act.accessMode}
      mode="view"
      className="judgement-act-page"
      menu={<JudgementViewPageActions act={act} />}
      navigation={
        <JudgementPageNavigation
          act={act}
          annotations={filterAndSortTocAnnotations(recogitoAnnotation)}
          markers={filterPersonalMarkers(recogitoAnnotation)}
        />
      }
    >
      <h5>
        {act.title}
        <DocumentLikeAction id={act.id} />
      </h5>
      {act?.status === DocumentStatus.GENERATED && (
        <Alert variant="warning">
          Актът е автоматично изтеглен. Възможно е да липсва докладчик или текстът да съвпада с
          други актове.
          {act?.comments?.length > 0 && (
            <>
              <br />
              Моля, проверете следните актове с подобно съдържание:{" "}
              {act?.comments?.match(/[^\s]*\s+0.[0-9]*/g)?.map((act_id_and_score) => (
                <>
                  <br />
                  <a
                    href={judgementActHref(
                      act_id_and_score.substring(0, act_id_and_score.indexOf(" "))
                    )}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {act_id_and_score.substring(0, act_id_and_score.indexOf(" ")) +
                      "  Score: " +
                      act_id_and_score.substring(act_id_and_score.indexOf(" "))}
                  </a>
                  <br />
                </>
              ))}
            </>
          )}
          {act?.summaries.length > 0 && (
            <>
              <br />
              Резюме на акта:{" "}
              {act?.summaries?.map((summary) => (
                <>
                  <Link key={summary.id} to={summaryHref(summary.id)}>
                    {summary.shortTitle}
                  </Link>
                  <br />
                </>
              ))}
            </>
          )}
        </Alert>
      )}
      <JudgementActMetadata
        act={act}
        annotations={filterAndSortTocAnnotations(recogitoAnnotation)}
      />
      <br />

      {/* <EditAnnotationPopover
        actId={act.id}
        recogito={recogito}
        target={target}
        closePopover={() => setTarget(undefined)}
      /> */}

      {noteTarget && (
        <EditAnnotationNotePopover
          actId={act.id}
          recogito={recogito}
          target={noteTarget}
          closePopover={() => setNoteTarget(undefined)}
          updateAnnotations={setRecogitoAnnotations}
        />
      )}

      <SelectedAnnotationPopover
        actId={act.id}
        recogito={recogito}
        target={hoverTarget}
        showEditPopover={() => setNoteTarget(hoverTarget)}
        closePopover={() => setHoverTarget(undefined)}
        canChangeAnno={canChangeAnno}
        updateAnnotations={setRecogitoAnnotations}
      />

      <SelectedActTextPopover
        actId={act.id}
        recogito={recogito}
        updateAnnotations={setRecogitoAnnotations}
        target={ref?.current || undefined}
        canAddAnno={
          canEditAllSummaries ||
          (canEditSummaries &&
            !recogitoAnnotation?.find((anno) => anno.userId !== currentUser?.id && !anno.ai))
        }
      />
      <div
        id="act-body"
        ref={ref}
        className={`act-text search-target highlight-mode ${
          !includeEditorAnnotations ? "hide-editor-anno" : ""
        } ${!includeUserAnnotations ? "hide-user-anno" : ""}`}
        onMouseUp={(e) => e.preventDefault()}
        onClick={onAnnoSelect}
        onContextMenu={(e) => {
          if (isMobileOnly) {
            e.preventDefault();
          }
        }}
      >
        {parse(act.contents, {
          replace: (node) => {
            if ((node as any).data) {
              return RenderTextWithReferences((node as any).data, act.citedReferences);
            }
          },
        })}
      </div>
    </DocumentPageLayout>
  );
};

export const getScope = (annotation: any) =>
  annotation?.body.filter((body: any) => body.purpose === "scope")[0];

export const getCategory = (annotation: any) =>
  annotation?.body.filter((body: any) => body.purpose === "tagging")[0];

export const getAiFlag = (annotation: any) =>
  annotation?.body.filter((body: any) => body.purpose === "ai")[0];

export const getFirstComment = (annotation: any) =>
  annotation?.body.filter((body: any) => body.purpose === "commenting")[0];

export const getFirstCommentText = (annotation: any) =>
  getFirstComment(annotation)?.value?.replace(/<[^>]+>/g, "");

export const toggleCanvas = (recogito: any, q: string | null) => {
  const canvas = document.querySelector("#map") as HTMLCanvasElement;
  canvas.style.left = recogito.getAnnotations().length || q ? "" : "-999px";
  canvas.parentElement!.style.width = recogito.getAnnotations().length || q ? "24px" : "0";
};

export default JudgementViewPage;
