import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaExpand, FaExternalLinkAlt, FaPlus, FaProductHunt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { hasPrivilege, Privileges, useAuth, useProtectedAction } from "../../contexts/auth.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { JudgementAct } from "../../models/JudgementAct.model";
import { fetchJudgementActPdf } from "../../services/judgement-act-services";
import { prefetchSummary, summaryHref } from "../../services/summary-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";

interface JudgementQuickViewPageActionsProps {
  act: EntityModel<JudgementAct>;
  onClose?: () => void;
}

const JudgementQuickViewPageActions: FunctionComponent<JudgementQuickViewPageActionsProps> = ({
  act,
  onClose,
}) => {
  const navigate = useNavigate();
  const protectedAction = useProtectedAction(act.accessMode === DocumentAccessMode.FREE);
  const protectedActionSummary = useProtectedAction(
    act.summaries[0]?.accessMode === DocumentAccessMode.FREE
  );
  const expandAct = protectedAction(() => {
    navigate(`/judgement-acts/${act.id}`);
    onClose && onClose();
  });
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const progress = useLoadingProgress();
  const { cache } = useSWRConfig();

  const { setSelectedActId } = useSelectedActContext();

  const expandActNewTab = protectedAction(() =>
    window.open(`/judgement-acts/${act?.id}`, "_blank")
  );

  const addSummary = protectedAction(async () =>
    navigate(`/summaries/new`, {
      state: { actId: act.id, title: act?.title },
    })
  );

  const viewSummary = protectedActionSummary(async () => {
    progress.start();
    try {
      await prefetchSummary(act.summaries[0].id, cache);
    } finally {
      progress.complete();
    }
    if (isMobileOnly) {
      navigate(summaryHref(act.summaries[0].id));
    } else {
      setSelectedActId("summary:" + act.summaries[0].id);
    }
  });

  const { currentUser } = useAuth();
  const hasEditPrivilege = hasPrivilege(Privileges.EDIT_SUMMARIES)(currentUser);
  const hasSummary = act.summaries?.length > 0;
  const intl = useIntl();
  return (
    <DocumentActionsContainer document={act} quickview>
      <DocumentAction
        handler={expandAct}
        icon={<FaExpand />}
        label={intl.formatMessage({ id: "judgement-act-actions.open-full-screen" })}
      />
      {!isMobileOnly && (
        <DocumentAction
          handler={expandActNewTab}
          icon={<FaExternalLinkAlt />}
          label={intl.formatMessage({ id: "judgement-act-actions.open-new-tab" })}
        />
      )}
      {hasEditPrivilege && !hasSummary && (
        <DocumentAction
          handler={addSummary}
          icon={<FaPlus />}
          label={intl.formatMessage({ id: "judgement-act-actions.add-summary" })}
        />
      )}
      {hasSummary && (
        <DocumentAction
          handler={viewSummary}
          icon={<FaProductHunt />}
          label={intl.formatMessage({ id: "judgement-act-actions.view-summary" })}
        />
      )}
      <DocumentCollectionActions
        showPrintExport
        dataToExport={async () => (await fetchJudgementActPdf(act.id, act.domain)).data}
        accessMode={act.accessMode}
      />
      <DocumentCloseAction handler={onClose} />
    </DocumentActionsContainer>
  );
};

export default JudgementQuickViewPageActions;
