import { useCallback, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { FormattedMessage, useIntl } from "react-intl";
import { toast, TypeOptions } from "react-toastify";
import { PrimaryButton, SecondaryButton } from "../../../components/Button/button";
import TopicSelect from "../../../components/TopicSelect/topic-select";
import { UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
import SummaryTopic from "../../../models/SummaryTopic.model";
import { partition, runChunks } from "./promise-chunk";

type ModalMode = "hidden" | "select";

type ChangeTopicOfDocumentModalProps = {
  action: (documentId: string, topicId: string | number) => void;
  actionType: "add" | "remove";
};

const ChangeTopicOfDocument: React.FC<
  ChangeTopicOfDocumentModalProps & UseMultipleSelectionReturn
> = ({ action, actionType, selection }) => {
  const [selectedTopics, setSelectedTopics] = useState<SummaryTopic[]>([]);
  const [mode, setMode] = useState<ModalMode>("hidden");

  const intl = useIntl();

  const toastId = useRef<any>();
  const [inProgress, setInProgress] = useState(false);

  const isAtLeastOneElementSelected = selection.size >= 1;
  const handleAction = useCallback(
    (topic: SummaryTopic) => {
      const start = (msg: string) => {
        toastId.current = toast(msg, { autoClose: false, type: toast.TYPE.WARNING });
      };

      const progress = (msg: string) => {
        toast.update(toastId.current, { render: msg });
      };

      const finished = (msg: string, type: TypeOptions) =>
        toast.update(toastId.current, { render: msg, type: type, autoClose: 5000 });

      const requests = Array.from(selection.values()).map(
        (entry) => () => action(entry._id, topic.id)
      );
      const chunkSize = 3;
      const chunks = partition(chunkSize, requests);
      start(intl.formatMessage({ id: "bulk.topic.update.start" }));
      setInProgress(true);
      // Run through the execution pipe. Here allDone is a
      // Promise you can .then or await somewhere else.
      runChunks(chunks, (index: number) => {
        progress(
          intl.formatMessage(
            { id: "bulk.topic.update.progress" },
            {
              total: selection.size,
              processed: (index + 1) * chunkSize,
              progress: 100 * (((index + 1) * chunkSize) / selection.size),
            }
          )
        );
      })
        .then((res: any) => {
          finished(intl.formatMessage({ id: "bulk.topic.update.success" }), toast.TYPE.SUCCESS);
          setMode("hidden");
          setInProgress(false);
          setTimeout(() => window.location.reload(), 1000);
        })
        .catch((err: any) => {
          finished(intl.formatMessage({ id: "bulk.topic.update.error" }), toast.TYPE.ERROR);
          setInProgress(false);
        });
    },
    [intl, selection, action]
  );

  return (
    <>
      {mode === "hidden" && (
        <Dropdown.Item onClick={() => setMode("select")} disabled={!isAtLeastOneElementSelected}>
          {actionType === "add" ? <IoMdAdd /> : <IoMdRemove />}{" "}
          <FormattedMessage id={`bulk.topic.${actionType}`} />
        </Dropdown.Item>
      )}
      {mode === "select" && (
        <SelectTopic
          onSelect={() => {
            handleAction(selectedTopics[0]);
          }}
          submitting={inProgress}
          setSelectedTopics={setSelectedTopics}
          selectedTopics={selectedTopics}
          onCancel={() => {
            setMode("hidden");
            setSelectedTopics([]);
          }}
          actionType={actionType}
        />
      )}
    </>
  );
};

type SelectTopicProps = {
  selectedTopics: SummaryTopic[];
  onSelect: (topic: SummaryTopic) => void;
  setSelectedTopics: (topic: SummaryTopic[]) => void;
  onCancel: () => void;
  submitting: boolean;
  actionType: "add" | "remove";
};

const SelectTopic: React.FC<SelectTopicProps> = ({
  selectedTopics,
  setSelectedTopics,
  submitting,
  actionType,
  onSelect,
  onCancel,
}) => {
  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton
        messageId="button.confirm"
        onClick={onSelect}
        disabled={selectedTopics.length === 0}
        submitting={submitting}
      />
    </>
  );
  return (
    <>
      <Modal
        show={true}
        centered
        size="lg"
        className="modal-xxl"
        animation={false}
        onEscapeKeyDown={onCancel}
      >
        <Modal.Header>
          <FormattedMessage id={`bulk.topic.${actionType}`} />
          <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
            {buttons}
          </div>
        </Modal.Header>
        <Modal.Body style={{ height: "400px", overflowY: "auto" }}>
          <TopicSelect
            checkedNodes={selectedTopics}
            setCheckedNodes={(newSelection) =>
              setSelectedTopics(newSelection.filter((n) => !selectedTopics.includes(n)))
            }
            autoFocus
          />
        </Modal.Body>

        <Modal.Footer>{buttons}</Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeTopicOfDocument;
