/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Card from "react-bootstrap/Card";
import { IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";
import PreloadingController from "../../../components/PreloadingLink/preloading-controller";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import Skeleton from "../../../components/Skeleton";
import { prefetchDocumentStats } from "../../../services/document-services";
import { prefetchSummaries } from "../../../services/summary-services";
import { SearchCategory } from "../search-utils";
import "./search-card.scss";

type SearchCardProps = {
  onClick: () => any;
  onMouseEnter?: () => any;
  title: string;
  subTitle?: string;
  items: React.ReactNode;
  newlyAdded: React.ReactNode;
  onRemove?: (e: any) => any;
  thumbnail: any;
  category: SearchCategory;
  topics?: (string | number)[];
  newCount?: number;
};

const SearchCard: React.FC<SearchCardProps> = ({
  onClick,
  onMouseEnter,
  title,
  subTitle,
  items,
  newlyAdded,
  onRemove,
  thumbnail,
  category,
  topics = [],
  newCount,
}) => {
  const intl = useIntl();
  return (
    <Card onClick={onClick} className="search-card" onMouseEnter={onMouseEnter}>
      <Card.Body>
        {!onRemove && <Card.Img src={thumbnail} variant="top" />}
        <div>
          <Card.Title>
            {title}
            {subTitle && <div className="sub-title">{subTitle}</div>}
          </Card.Title>
          <Card.Text>
            {typeof newlyAdded !== "undefined" ? (
              <span>
                {items}
                <br />
                {newCount ? (
                  <SearchFieldController
                    name="latestActs"
                    render={({ field: { onChange, value } }) => (
                      <PreloadingController
                        preload={async (cache) => {
                          const { search } = await onChange(
                            { latestActs: ["isRecentlyPublished:true"], summaryTopic: topics },
                            false
                          );
                          await prefetchDocumentStats(new URLSearchParams(search), cache);
                          await prefetchSummaries(new URLSearchParams(search), cache);
                        }}
                        event="onMouseEnter"
                        onSuccess={async () => {
                          const { navigate } = await onChange(
                            { latestActs: ["isRecentlyPublished:true"], summaryTopic: topics },
                            false
                          );
                          navigate(`/search/${category}`);
                        }}
                      >
                        {(handlers) => (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`newly-added-tooltip-${category}`}>
                                {category === SearchCategory.EU_ACTS ||
                                category === SearchCategory.CONST_ACTS ||
                                category === SearchCategory.SC_ACTS
                                  ? "Последните 30 дни"
                                  : "Последните 7 дни"}
                              </Tooltip>
                            }
                          >
                            <span {...handlers} className="newly-added-link">
                              {newlyAdded}
                            </span>
                          </OverlayTrigger>
                        )}
                      </PreloadingController>
                    )}
                  />
                ) : (
                  <span>{newlyAdded}</span>
                )}
              </span>
            ) : (
              <Skeleton count={2} />
            )}
          </Card.Text>
          {onRemove && typeof newlyAdded !== "undefined" && (
            <span className="card-remove-link">
              <IoMdClose onClick={onRemove} title={intl.formatMessage({ id: "button.remove" })} />
            </span>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default SearchCard;
