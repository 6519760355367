import { CollectionModel, EntityModel } from "hateoas-hal-types";
import memoizee from "memoizee";
import useRequest, { del, get, post, put } from "../api";
import Provision from "../models/Provision.model";

export const fetchProvisions = memoizee(
  async (title: string) =>
    (
      (await get("/ref/domestic/provisions", {
        title,
      })) as CollectionModel<Provision>
    )._embedded?.items,
  { promise: true }
);

export const fetchAllProvisions = memoizee(
  async () =>
    (
      (await get("/ref/domestic/provisions-all")) as CollectionModel<Provision>
    )._embedded?.items,
  { promise: true }
);

export const useProvisions = (title?: string) =>
  useRequest<CollectionModel<Provision>>(title ? `/ref/domestic/provisions?title=${title}` : null)
    .data?._embedded?.items || [];

export const addProvision = async (data: Provision) => {
  fetchProvisions.clear();
  return (await post("/ref/domestic/provisions", data)) as Provision;
};

export const editProvision = async (id: string, data: Provision) => {
  fetchProvisions.clear();
  return (await put(`/ref/domestic/provisions/${id}`, data)) as Provision;
};

export const deleteProvision = async (provisionId: string, successorId: string) => {
  fetchProvisions.clear();
  await del(`/ref/domestic/provisions/${provisionId}?successorId=${successorId}`, {}, true);
};

export const useProvision = (id: string | undefined) => {
  const { data } = useRequest<EntityModel<Provision>>(id ? `/ref/domestic/provisions/${id}` : null);
  return data;
};

export const fetchProvision = (id: string | undefined) => {
  return get<Provision>(`/ref/domestic/provisions/${id}`);
};
