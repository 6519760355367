import { Badge } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";

const SubscriptionStatusBadge: React.FC<{ subscription: Subscription }> = ({ subscription }) => {
  const { formatMessage } = useIntl();
  return (
    <Badge
      bg={
        subscription.status === SubscriptionStatus.ACTIVE
          ? "success"
          : subscription.status === SubscriptionStatus.REQUESTED
          ? "info"
          : subscription.status === SubscriptionStatus.OVERDUE_PAYMENT
          ? "danger"
          : subscription.status === SubscriptionStatus.PENDING_INITIAL_PAYMENT
          ? "warning"
          : "secondary"
      }
    >
      {formatMessage({
        id: `subscription.status.${subscription.status}`,
      })}
    </Badge>
  );
};

export default SubscriptionStatusBadge;
