import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUrl } from "../services/url-services";

export type SearchContextType = {
  lastSearchHref: string;
  setLastSearchHref: (lastSearchHref: string) => void;
  search?: string;
  opened: string[];
  addOpened: (id: string) => void;
  clearSearch: () => void;
};

export const SearchContext = createContext<SearchContextType>({
  lastSearchHref: "",
  setLastSearchHref: () => {},
  search: "",
  opened: [],
  addOpened: () => {},
  clearSearch: () => {},
});

export const SearchContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [lastSearchHref, setLastSearchHref] = useState("/search");
  const [opened, setOpened] = useState<string[]>([]);

  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const search = useUrl(q) || (q ? undefined : "");

  const addOpened = useCallback(
    (id: string) => {
      opened.indexOf(id) === -1 && setOpened([...opened, id]);
    },
    [opened, setOpened]
  );

  const clearSearch = useCallback(() => {
    setLastSearchHref("/search");
    setOpened([]);
  }, [setLastSearchHref, setOpened]);

  const openListener = useCallback(
    (event: StorageEvent) => {
      event.key?.startsWith("opened-document:") &&
        addOpened(event.key.replace("opened-document:", ""));
    },
    [addOpened]
  );

  useEffect(() => {
    window.addEventListener("storage", openListener);
    return () => window.removeEventListener("storage", openListener);
  }, [openListener]);

  return (
    <SearchContext.Provider
      value={useMemo(
        () => ({
          lastSearchHref,
          setLastSearchHref,
          clearSearch,
          search,
          opened,
          addOpened,
        }),
        [lastSearchHref, setLastSearchHref, search, opened, addOpened, clearSearch]
      )}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);

export default SearchContextProvider;
