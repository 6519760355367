/* eslint-disable no-underscore-dangle */
import Mark from "mark.js";
import { useEffect, useRef, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useHotkeys } from "react-hotkeys-hook";
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";

type JudgementSearchPanelProps = {
  setOpened: (opened: boolean) => void;
  opened: boolean;
  defaultText?: string;
};
const JudgementSearchPanel: React.FC<JudgementSearchPanelProps> = ({
  setOpened,
  opened,
  defaultText = "",
}) => {
  const [filterText, setFilterText] = useState(defaultText);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState<Element[]>();
  const [markJs, setMarkJs] = useState<Mark>();

  const searchTargets = ".search-target";

  const { formatMessage } = useIntl();

  const jumpTo = (index: number, results?: Element[]) => {
    if (results) {
      const old = document.querySelector("mark.current");
      if (old) {
        old.className = old.className.replaceAll("current", "");
      }
      const rIndex = index < 0 ? results.length - 1 : index > results.length - 1 ? 0 : index;
      const current = results[rIndex] as HTMLElement;

      if (current) {
        current.className += "current";
        const position = getOffset(current).top - 200;
        window.scrollTo(0, position);
      }
      setCurrentIndex(rIndex);
    }
  };

  useHotkeys("Escape", () => setOpened(false), {
    enableOnTags: ["INPUT"],
  });
  useHotkeys("ctrl+f,command+f", (e) => {
    e.preventDefault();
    setOpened(true);
  });

  useEffect(() => {
    const pageEl = document.querySelector(".judgement-act-page");
    if (opened) {
      if (pageEl && filterText.length > 0 && pageEl.className.indexOf("seaching") === -1) {
        pageEl.className += " searching";
      }
    } else {
      if (pageEl) {
        pageEl.className = pageEl.className.replaceAll(" searching", "");
      }
      const selection = window.getSelection();
      const current = document.querySelector("mark.current");
      current && selection?.setBaseAndExtent(current, 0, current, 1);
    }
  }, [opened, filterText]);

  const ref = useRef<any>();

  useEffect(() => {
    ref.current && opened && setTimeout(() => ref.current.focus(), 100);
  }, [opened]);

  return (
    <div className={`search-panel ${opened ? "d-flex" : "d-none"}`}>
      {opened && (
        <InputGroup>
          <Form.Control
            type="text"
            ref={ref}
            autoComplete="off"
            autoFocus
            value={filterText}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                jumpTo(currentIndex + 1, results);
              }
            }}
            placeholder={formatMessage({ id: "summary-search-panel.search-input.placeholder" })}
            onChange={(e) => {
              const searchVal = e.target.value;
              setFilterText(searchVal);
              const instance = markJs || new Mark(searchTargets);
              setMarkJs(instance);
              instance.unmark({
                done: function () {
                  searchVal.length > 0 &&
                    instance.mark(searchVal, {
                      separateWordSearch: false,
                      done: function () {
                        const results = Array.from(document.querySelectorAll("mark"));
                        setResults(results);
                        jumpTo(0, results);
                      },
                    });
                },
              });
            }}
          />
          {!!filterText && (
            <InputGroup.Text>
              {results?.length ? `${currentIndex + 1}/${results?.length}` : "-"}
            </InputGroup.Text>
          )}
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              jumpTo(currentIndex - 1, results);
            }}
            title={formatMessage({ id: "summary-search-panel.button.previous-result" })}
          >
            <IoIosArrowUp />
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              jumpTo(currentIndex + 1, results);
            }}
            title={formatMessage({ id: "summary-search-panel.button.next-result" })}
          >
            <IoIosArrowDown />
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              setOpened(false);
            }}
            title={formatMessage({ id: "summary-search-panel.button.close" })}
          >
            <IoMdClose />
          </Button>
        </InputGroup>
      )}
    </div>
  );
};

export const getOffset = (element: HTMLElement) => {
  if (!element.getClientRects().length) {
    return { top: 0, left: 0 };
  }

  let rect = element.getBoundingClientRect();
  let win = element.ownerDocument.defaultView!;
  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
};

export default JudgementSearchPanel;
