import { EntityModel } from "hateoas-hal-types";
import { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router";
import SummaryViewContextProvider from "../../contexts/summary-view.context";
import SummaryContextProvider from "../../contexts/summary.context";
import { printSummaryTitle, Summary } from "../../models/Summary.model";
import { useSummary } from "../../services/summary-services";
import DocumentLikeAction from "../DocumentPage/document-like-action";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import SummaryPageNavigation from "../SummaryPage/summary-page-navigation";
import MainInfoCard from "./main-info-card";
import ProvisionsCard from "./provisions-card";
import QuestionCard from "./question-card";
import SummaryViewPageMenu from "./summary-view-page-actions";
import SummaryViewPageLoading from "./summary-view-page-loading";
import "./summary-view-page.scss";

const SummaryViewPage = () => {
  const { summaryId } = useParams();
  const summary = useSummary(summaryId);

  return summary ? (
    <SummaryViewForm summary={summary} />
  ) : (
    <SummaryViewPageLoading className="page-loading summary-page" mode="view" />
  );
};

interface SummaryViewPageProps {
  summary: EntityModel<Summary>;
}

const SummaryViewForm: React.FC<SummaryViewPageProps> = ({ summary }) => {
  const defaultSectionToggles = useMemo(() => {
    const toggles = new Map<string, number>();
    summary?.qas.forEach((qa, idx) => toggles.set(`${idx}-answer`, 1));
    return toggles;
  }, [summary]);
  const [sectionToggles, setSectionToggles] = useState(defaultSectionToggles);
  const getSectionToggleStatus = useCallback(
    (section: string) => sectionToggles.get(section) || 0,
    [sectionToggles]
  );
  const setSectionToggleStatus = useCallback(
    (section: string, status?: any) => {
      const newState = new Map(sectionToggles);
      const oldValue = getSectionToggleStatus(section);
      const newValue = status === undefined ? (oldValue ? oldValue + 1 : 2) : status;
      if (newValue) {
        newState.set(section, newValue);
      } else {
        newState.delete(section);
      }
      setSectionToggles(newState);
    },
    [getSectionToggleStatus, setSectionToggles, sectionToggles]
  );

  const setToggleAllStatus = useCallback(
    (sections: string[], status = 1) => {
      const newState = new Map(sectionToggles);
      sections.forEach((section) => {
        const oldValue = getSectionToggleStatus(section);
        const newValue = status === undefined ? (oldValue ? oldValue + 1 : 2) : status;
        if (newValue) {
          newState.set(section, newValue);
        } else {
          newState.delete(section);
        }
      });

      setSectionToggles(newState);
    },
    [getSectionToggleStatus, setSectionToggles, sectionToggles]
  );

  const { hash } = useLocation();

  const methods = useForm({
    defaultValues: summary,
  });
  return (
    <FormProvider {...methods}>
      <SummaryViewContextProvider toggleAll={setToggleAllStatus}>
        <SummaryContextProvider summary={summary}>
          <DocumentPageLayout
            id={hash?.substring(1) || summary.id}
            title={printSummaryTitle(summary)}
            status={summary.status}
            mode="view"
            accessMode={summary.accessMode}
            className="summary-page"
            copyrighted
            menu={<SummaryViewPageMenu />}
            navigation={
              <SummaryPageNavigation toggleSection={setSectionToggleStatus} mode="view" />
            }
          >
            <h5>
              {printSummaryTitle(summary)}
              <DocumentLikeAction id={summary.id} />
            </h5>
            <h6>{summary.shortTitle && `[${summary.shortTitle}]`}</h6>

            <Row className="info-cards-row">
              <Col md={4}>
                <ProvisionsCard provisions={summary.provisions} />
              </Col>
              <Col md={8}>
                <MainInfoCard />
              </Col>
            </Row>
            {summary.qas.map((qa, idx) => (
              <QuestionCard
                key={qa.id}
                idx={idx}
                qa={qa}
                provisions={summary?.provisions}
                questionCount={summary?.qas.length}
                summaryActDate={summary.relatedJudgementAct.actDate}
                getSectionToggleStatus={getSectionToggleStatus}
                setSectoinToggleStatus={setSectionToggleStatus}
              />
            ))}
          </DocumentPageLayout>
        </SummaryContextProvider>
      </SummaryViewContextProvider>
    </FormProvider>
  );
};

export default SummaryViewPage;
