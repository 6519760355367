/* eslint-disable no-underscore-dangle */
import { InlineLinkButton } from "../../components/Button/button";
import Skeleton from "../../components/Skeleton";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { useSummaryContext } from "../../contexts/summary.context";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { useJudgementAct } from "../../services/judgement-act-services";

const CaseActsTab = () => {
  const { setSelectedActId } = useSelectedActContext();
  const { summary } = useSummaryContext();
  const judgementAct = useJudgementAct(summary.relatedJudgementAct.id);
  return (judgementAct?.relatedActs || []).length > 0 ? (
    <div className="section">
      {judgementAct?.relatedActs
        ?.filter(
          (relatedAct) =>
            relatedAct.status !== DocumentStatus.GENERATED &&
            relatedAct.status !== DocumentStatus.DELETED
        )
        .map((relatedAct) => (
          <InlineLinkButton
            title={relatedAct.title}
            onClick={() => {
              setSelectedActId(relatedAct.id);
            }}
            key={relatedAct.id}
            style={{ fontWeight: judgementAct?.id === relatedAct.id ? "bold" : "inherit" }}
          >
            {relatedAct.title}
          </InlineLinkButton>
        ))}
    </div>
  ) : null;
};

export const CaseActsTabLoading = () => {
  return <Skeleton count={5} />;
};

export default CaseActsTab;
