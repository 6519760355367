/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import img_bgacts from "../../../assets/images/thumbnails/bgacts.png";
import img_euacts from "../../../assets/images/thumbnails/euacts.png";
import img_mytopic from "../../../assets/images/thumbnails/mytopic.png";
import img_news from "../../../assets/images/thumbnails/news.png";
import img_questions from "../../../assets/images/thumbnails/questions.png";
import img_summaries from "../../../assets/images/thumbnails/summaries.png";
import PreloadingController from "../../../components/PreloadingLink/preloading-controller";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import DocumentStats, { Counts } from "../../../models/DocumentStats";
import SummaryTopic from "../../../models/SummaryTopic.model";
import { prefetchDocumentStats } from "../../../services/document-services";
import {
  prefetchAdmJudgementActs,
  prefetchConstJudgementActs,
  prefetchEuJudgementActs,
  prefetchJudgementActs,
  prefetchSupremeCourtJudgementActs,
} from "../../../services/judgement-act-services";
import { prefetchNewsArticles } from "../../../services/news-article-services";
import { prefetchSummaries } from "../../../services/summary-services";
import { unsubscribeFromTopic } from "../../../services/user-services";
import { SearchCategory } from "../search-utils";
import "./category-cards.scss";
import SearchCard from "./search-card";

type CategoryCardsProps = {
  stats?: DocumentStats;
  topics?: SummaryTopic[];
};

const CategoryCards: React.FC<CategoryCardsProps> = ({ stats, topics = [], children }) => {
  const { formatMessage } = useIntl();

  const cards = useMemo(() => {
    const cards: any[] = [];
    topics.forEach((topic) =>
      cards.push(
        <SearchFieldController
          name="summaryTopic"
          key={topic.id}
          render={({ field: { onChange, value } }) => (
            <>
              <PreloadingController
                preload={async (cache) => {
                  const { search } = await onChange({ summaryTopic: [topic.id] }, false);
                  await prefetchDocumentStats(new URLSearchParams(search), cache);
                  await prefetchSummaries(new URLSearchParams(search), cache);
                }}
                event="onMouseEnter"
                onSuccess={async () => {
                  const { navigate } = await onChange({ summaryTopic: [topic.id] }, false);
                  navigate("/search/summaries");
                }}
              >
                {(handlers) => (
                  <SearchCard
                    {...handlers}
                    {...labels(
                      formatMessage,
                      "category-cards.labels.summaries",
                      stats?.summariesCountsByTopic![topic.id as number]
                    )}
                    title={topic.shortName}
                    subTitle={topic.name}
                    onRemove={(e) => {
                      e.stopPropagation();
                      unsubscribeFromTopic(topic.id as number);
                      toast.success(
                        formatMessage(
                          { id: "subscribe-topic.modal.toasts.unsubscribe.success" },
                          { topic: topic.name }
                        )
                      );
                    }}
                    thumbnail={img_mytopic}
                    category={SearchCategory.SUMMARIES}
                    topics={[topic.id]}
                    newCount={stats?.summariesCountsByTopic![topic.id as number].recent}
                  />
                )}
              </PreloadingController>
            </>
          )}
        />
      )
    );
    if (stats && children) {
      cards.push(React.Children.only(React.cloneElement(children as any, { key: "subscribe" })));
    }

    return cards;
  }, [topics, stats, formatMessage, children]);

  const navigate = useNavigate();

  return (
    <div className="category-cards-container">
      <PreloadingController
        preload={(cache) => prefetchJudgementActs(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("acts")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(formatMessage, "category-cards.labels.acts", stats?.domesticActsCount)}
            thumbnail={img_bgacts}
            category={SearchCategory.ACTS}
            newCount={stats?.domesticActsCount.recent}
          />
        )}
      </PreloadingController>

      <PreloadingController
        preload={(cache) => prefetchSummaries(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("summaries")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(formatMessage, "category-cards.labels.summaries", stats?.summariesCount)}
            thumbnail={img_summaries}
            category={SearchCategory.SUMMARIES}
            newCount={stats?.summariesCount.recent}
          />
        )}
      </PreloadingController>
      <PreloadingController
        preload={(cache) => prefetchSummaries(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("questions")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(formatMessage, "category-cards.labels.questions", stats?.questionsCount)}
            thumbnail={img_questions}
            category={SearchCategory.QUESTIONS}
            newCount={stats?.questionsCount.recent}
          />
        )}
      </PreloadingController>
      <PreloadingController
        preload={(cache) => prefetchAdmJudgementActs(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("adm-acts")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(
              formatMessage,
              "category-cards.labels.adm-acts",
              stats?.administrativeActsCount
            )}
            thumbnail={img_bgacts}
            category={SearchCategory.ADM_ACTS}
            newCount={stats?.administrativeActsCount.recent}
          />
        )}
      </PreloadingController>
      <PreloadingController
        preload={(cache) => prefetchSupremeCourtJudgementActs(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("sc-acts")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(
              formatMessage,
              "category-cards.labels.sc-acts",
              stats?.supremeCourtActsCount
            )}
            thumbnail={img_bgacts}
            category={SearchCategory.SC_ACTS}
            newCount={stats?.supremeCourtActsCount.recent}
          />
        )}
      </PreloadingController>
      <PreloadingController
        preload={(cache) => prefetchEuJudgementActs(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("eu-acts")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(formatMessage, "category-cards.labels.eu-acts", stats?.euActsCount)}
            thumbnail={img_euacts}
            category={SearchCategory.EU_ACTS}
            newCount={stats?.euActsCount.recent}
          />
        )}
      </PreloadingController>
      <PreloadingController
        preload={(cache) => prefetchNewsArticles(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("news-articles")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(
              formatMessage,
              "category-cards.labels.news-articles",
              stats?.newsArticlesCount
            )}
            key="news"
            thumbnail={img_news}
            category={SearchCategory.NEWS_ARTICLES}
            newCount={stats?.newsArticlesCount.recent}
          />
        )}
      </PreloadingController>
      <PreloadingController
        preload={(cache) => prefetchConstJudgementActs(new URLSearchParams(), cache)}
        event="onMouseEnter"
        onSuccess={() => navigate("const-acts")}
      >
        {(handlers) => (
          <SearchCard
            {...handlers}
            {...labels(formatMessage, "category-cards.labels.const-acts", stats?.constActsCount)}
            thumbnail={img_bgacts}
            category={SearchCategory.CONST_ACTS}
            newCount={stats?.constActsCount.recent}
          />
        )}
      </PreloadingController>
      {cards}
    </div>
  );
};

const labels = (formatMessage: any, id: string, counts?: Counts) => ({
  title: formatMessage({
    id: `${id}.title`,
  }),
  items: counts
    ? formatMessage(
        {
          id,
        },
        {
          count: counts.total,
        }
      )
    : undefined,
  newlyAdded: counts
    ? formatMessage(
        {
          id: counts.recent === 1 ? `${id}.new.single` : `${id}.new`,
        },
        {
          count: counts.recent,
        }
      )
    : undefined,
});

export default CategoryCards;
