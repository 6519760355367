/* eslint-disable no-underscore-dangle */
import { useState } from "react";
import CheckboxTree, { Node as CheckboxTreeNode } from "react-checkbox-tree";
import { isMobileOnly } from "react-device-detect";
import { FaQuestionCircle, FaRegFileAlt } from "react-icons/fa";
import Skeleton from "../../components/Skeleton";
import { useNavigationDrawerContext } from "../../contexts/navigation-drawer.context";
import { useSummaryContext } from "../../contexts/summary.context";

type TableOfContentsTabProps = {
  toggleSection?: (section: string) => void;
};
const TableOfContentsTab: React.FC<TableOfContentsTabProps> = ({ toggleSection }) => {
  const { summary } = useSummaryContext();
  const navigationDrawerContext = useNavigationDrawerContext();
  const toc = [
    ...summary.qas.map((qa, idx) => ({
      label: qa.question
        ? `Въпрос${summary.qas.length > 1 ? " " + (idx + 1) : ""}: ` + qa.question
        : `<Въпрос ${idx + 1}>`,
      value: idx + "-question",
      icon: <FaQuestionCircle />,
      children: [
        {
          label: "Мотиви",
          value: `${idx}-arguments`,
          icon: <FaRegFileAlt />,
        },
        ...(qa.answer
          ? [
              {
                label: "Отговор",
                value: `${idx}-answer`,
                icon: <FaRegFileAlt />,
              },
            ]
          : []),
        ...(qa.dissentingOpinions || []).slice(0, 1).map((opinion) => ({
          label: `Особено мнение`,
          value: `${idx}-discenting-opinion`,
        })),
      ],
    })),
  ];

  const traverse = (nodes: CheckboxTreeNode[], accumulator: Set<string>) => {
    nodes.forEach((n) => {
      accumulator.add(n.value);
      n.children && traverse(n.children, accumulator);
    });
    return accumulator;
  };

  const [expandedNodes] = useState<string[]>(Array.from(traverse(toc, new Set())));

  return (
    <CheckboxTree
      nodes={toc}
      checked={[]}
      expanded={expandedNodes}
      noCascade
      expandDisabled
      onCheck={(node) => {
        if (
          node[0].indexOf("-arguments") > -1 ||
          node[0].indexOf("-answer") > -1 ||
          node[0].indexOf("-question") > -1 ||
          node[0].indexOf("-discenting-opinion") > -1
        ) {
          isMobileOnly && navigationDrawerContext && navigationDrawerContext.setIsOpen(false);
          toggleSection && toggleSection(node[0]);
        }
      }}
    />
  );
};

export const TableOfContentsTabLoading = () => {
  return <Skeleton count={10} />;
};

export default TableOfContentsTab;
