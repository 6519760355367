import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, LinkProps } from "react-router-dom";
import "./link.css";

export const InlineLink: React.FC<
  React.PropsWithoutRef<LinkProps & React.RefAttributes<HTMLAnchorElement>> & {
    messageId?: string;
  }
> = ({ messageId = "", ...props }) => (
  <Link {...props} className={`link inline-link ${props.className || ""}`}>
    {messageId ? <FormattedMessage id={messageId} /> : props.children}
  </Link>
);
