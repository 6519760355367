import React, { createContext, useContext, useState } from "react";
import Heading from "../components/TableOfContents/Heading";

export type EditorCollectionContextType = {
  activeId?: Heading;
  setActiveId: (heading: Heading) => void;
  searchHits?: Heading[];
  setSearchHits: (heading?: Heading[]) => void;
};

export const EditorCollectionContext = createContext<EditorCollectionContextType>({
  setActiveId: () => {},
  setSearchHits: () => {},
});

export const EditorCollectionContextProvider: React.FC<{}> = ({ children }) => {
  const [activeId, setActiveId] = useState<Heading>();
  const [searchHits, setSearchHits] = useState<Heading[]>();

  return (
    <EditorCollectionContext.Provider
      value={{
        activeId,
        setActiveId,
        searchHits,
        setSearchHits,
      }}
    >
      {children}
    </EditorCollectionContext.Provider>
  );
};

export const useEditorCollectionContext = () => useContext(EditorCollectionContext);

export default EditorCollectionContextProvider;
