import { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";

const AutoSizedTextArea = ({
  minHeight,
  maxHeight = 300,
  ...rest
}: {
  value: any;
  onChange: (value: any) => void;
  minHeight?: number;
  maxHeight?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const calcHeight = (el: any) => {
    el.style.height = "";
    const height = minHeight
      ? Math.max(minHeight, textAreaRef.current!.scrollHeight)
      : textAreaRef.current!.scrollHeight;
    if (maxHeight < height) {
      el.style.height = maxHeight + "px";
      el.style.overflowY = "scroll";
    } else {
      el.style.height = height + "px";
      el.style.overflowY = "hidden";
    }
  };

  useEffect(() => {
    const resize = () => {
      if (textAreaRef.current && textAreaRef.current!.scrollHeight > 0) {
        calcHeight(textAreaRef.current);
      } else {
        setTimeout(resize, 100);
      }
    };
    resize();
  });

  return (
    <Form.Control
      as="textarea"
      style={{ overflowY: "hidden" }}
      onInput={(e: any) => calcHeight(e.target)}
      onLoad={(e: any) => calcHeight(e.target)}
      {...rest}
      ref={textAreaRef}
      spellCheck="true"
      lang="bg"
    />
  );
};

export default AutoSizedTextArea;
