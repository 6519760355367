import "@fortawesome/fontawesome-free/css/all.css";
import React, { ReactNode } from "react";
import { Badge } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Cache } from "swr";
import PreloadingLink from "../../components/PreloadingLink";
import Skeleton from "../../components/Skeleton";
import { displayCount, getTabUrl } from "../SearchPage/search-utils";

type ResultsNavProps = {
  loading: boolean;
  items: ResultsNavLinkProps[];
  activeCategory: string;
};

const ResultsNav: React.FC<ResultsNavProps> = ({ loading, activeCategory, items }) => {
  return !loading ? (
    <Nav className={`search-results-nav nav-tabs`} activeKey={activeCategory}>
      {items.map((item) => (
        <ResultsNavLink {...item} key={item.category} />
      ))}
    </Nav>
  ) : (
    <div style={{ display: "flex" }}>
      <Skeleton height="38px" style={{ marginLeft: "20px", marginRight: "20px" }} />
    </div>
  );
};

type ResultsNavLinkProps = {
  category: string;
  label: string;
  icon: React.ReactNode;
  count?: number;
  preload?: (cache: Cache<any>) => Promise<any>;
};

const ResultsNavLink: React.FC<ResultsNavLinkProps> = ({
  category,
  label,
  count,
  icon,
  preload,
}) => {
  return (
    <span style={{ display: count === 0 ? "none" : "contents" }}>
      <Nav.Link eventKey={category} as={NavTabLink(category, preload)}>
        {icon}
        <span className="search-category-label ms-1">
          {label}{" "}
          <span className="search-category-count">
            {count && count > 0 && <Badge bg="secondary">{displayCount(count)}</Badge>}
          </span>
        </span>
      </Nav.Link>
    </span>
  );
};

const NavTabLink = (category: string, preload?: (cache: Cache<any>) => Promise<any>) => {
  const location = useLocation();
  return ({ children, className }: { children: ReactNode; className: string }) =>
    preload ? (
      <PreloadingLink
        to={getTabUrl(location, category)}
        className={className}
        event="onMouseEnter"
        preload={(cache) => preload(cache)}
      >
        {children}
      </PreloadingLink>
    ) : (
      <Link to={getTabUrl(location, category)} className={className}>
        {children}
      </Link>
    );
};

export default ResultsNav;
