import { EntityModel } from "hateoas-hal-types";
import { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSWRConfig } from "swr";
import { v4 as uuid } from "uuid";
import { InlineLinkButton, PrimaryButton } from "../../components/Button/button";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import Skeleton from "../../components/Skeleton";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { JudgementAct } from "../../models/JudgementAct.model";
import Provision from "../../models/Provision.model";
import { prefetchJudgementActs } from "../../services/judgement-act-services";
import { useProvision } from "../../services/provision.services";
import { getProvisionFullTitle } from "../../services/ref-data-services";
import { summaryHref } from "../../services/summary-services";
import { shortenUrl } from "../../services/url-services";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import { CollapsibleFiltersPanel } from "../SearchPage/FiltersSidebar/filters";
import "./summary-view-page.scss";

interface ProvisionQuickViewPageProps {
  provisionId: string;
  onClose: () => void;
}

const ProvisionQuickViewPage: React.FC<ProvisionQuickViewPageProps> = ({
  provisionId,
  onClose,
}) => {
  const provision = useProvision(provisionId);
  const id = useMemo(() => uuid(), []);
  return provision ? (
    <ProvisionViewForm provision={provision} onClose={onClose} />
  ) : (
    <DocumentQuickViewLayout className="summary-page" onClose={onClose} id={id}>
      <Card className="highlight-card w-100">
        <Card.Body>
          <Card.Subtitle>
            <Skeleton count={1} />
          </Card.Subtitle>
          <br />
          <Card.Text>
            <Skeleton count={3} />
          </Card.Text>
        </Card.Body>
      </Card>
    </DocumentQuickViewLayout>
  );
};

interface ProvisionViewPageProps {
  provision: EntityModel<Provision>;
  onClose: () => void;
}

const ProvisionViewForm: React.FC<ProvisionViewPageProps> = ({ provision, onClose }) => {
  const { cache } = useSWRConfig();
  const [result, setResult] = useState<any>();
  useEffect(() => {
    async function f() {
      const params = new URLSearchParams();
      params.append("relatedProvision", provision.title);
      const result = await prefetchJudgementActs(params, cache);
      setResult(result);
    }
    f();
  }, [cache, provision.title]);
  const { setSelectedActId } = useSelectedActContext();
  const id = useMemo(() => uuid(), []);
  const { formatMessage } = useIntl();
  return (
    <DocumentQuickViewLayout
      id={id}
      title={`Свързана практика по ${getProvisionFullTitle(provision)}`}
      className="summary-page"
      menu={
        <DocumentActionsContainer quickview>
          <DocumentCloseAction handler={onClose} />
        </DocumentActionsContainer>
      }
      onClose={onClose}
    >
      <div className="w-100">
        <Card className="highlight-card">
          <Card.Body>
            <CollapsibleFiltersPanel
              collapseHeight="100px"
              openedLabel="Покажи по-малко"
              closedLabel="Покажи всичко"
            >
              <Card.Subtitle>{getProvisionFullTitle(provision)}</Card.Subtitle>
              <br />
              <Card.Text>
                <div dangerouslySetInnerHTML={{ __html: provision.text! }} />
              </Card.Text>
            </CollapsibleFiltersPanel>
          </Card.Body>
        </Card>

        {result ? (
          result[0].page.totalElements > 1 ? (
            <>
              <h6 className="text-muted mt-4 mb-4">
                Брой документи: {result?.[0].page.totalElements}
              </h6>
              {result[0]._embedded?.items?.map((act: JudgementAct) => (
                <div key={act.id} className="mt-3">
                  <InlineLinkButton
                    onClick={async () => {
                      setSelectedActId(act.id);
                    }}
                    style={{ fontWeight: "600" }}
                  >
                    {act.title}
                  </InlineLinkButton>
                  {act.summaries?.length > 0 && (
                    <LabelValue label={formatMessage({ id: "view-judgement.labels.summary" })}>
                      {act.summaries!.map((summary) => (
                        <InlineLink
                          key={summary.id}
                          to={summaryHref(summary.id)}
                          onClick={(e) => {
                            if (!e.ctrlKey && !e.metaKey) {
                              e.preventDefault();
                              setSelectedActId("summary:" + summary.id);
                            }
                          }}
                        >
                          {summary.shortTitle || <FormattedMessage id="label.link" />}
                        </InlineLink>
                      ))}
                    </LabelValue>
                  )}
                  <hr />
                </div>
              ))}
              {result[0].page.totalElements > 10 && (
                <PrimaryButton
                  messageId="button.show-all"
                  onClick={async () => {
                    const params = new URLSearchParams();
                    params.append("relatedProvision", provision.title);
                    const q = await shortenUrl(params.toString());
                    window.open(`/search/summaries?q=${q}`, "_blank");
                  }}
                />
              )}
            </>
          ) : (
            <span>Няма свързана практика</span>
          )
        ) : (
          [0, 1, 2, 3, 4, 5].map((i) => (
            <div key={i}>
              {" "}
              <Skeleton count={3} />
              <hr />
            </div>
          ))
        )}
      </div>
    </DocumentQuickViewLayout>
  );
};

export default ProvisionQuickViewPage;
