import { EntityModel } from "hateoas-hal-types";
import parse from "html-react-parser";
import { useMemo } from "react";
import { v4 as uuid } from "uuid";
import { NewsArticle } from "../../models/NewsArticle.model";
import { useNewsArticle } from "../../services/news-article-services";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import NewsArticleQuickViewPageActions from "./news-article-quick-view-page-actions";
import { NewsArticleQuickViewPageLoading } from "./news-article-view-page-loading";
import "./news-article-view-page.scss";

interface NewsArticleQuickViewPageProps {
  articleId: string;
  onClose: () => void;
}

const NewsArticleQuickViewPage: React.FC<NewsArticleQuickViewPageProps> = ({
  articleId,
  onClose,
}) => {
  const article = useNewsArticle(articleId);

  return article ? (
    <NewsArticleViewForm article={article} onClose={onClose} />
  ) : (
    <NewsArticleQuickViewPageLoading onClose={onClose} />
  );
};

interface NewsArticleViewPageProps {
  article: EntityModel<NewsArticle>;
  onClose: () => void;
}

const NewsArticleViewForm: React.FC<NewsArticleViewPageProps> = ({ article, onClose }) => {
  const id = useMemo(() => uuid(), []);
  return (
    <DocumentQuickViewLayout
      id={id}
      title={article.title}
      className="news-article-page"
      menu={<NewsArticleQuickViewPageActions article={article} onClose={onClose} />}
      onClose={onClose}
    >
      <div className="act-text">{parse(article.contents)}</div>
    </DocumentQuickViewLayout>
  );
};

export default NewsArticleQuickViewPage;
