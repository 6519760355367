import { EntityModel } from "hateoas-hal-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import ImageUploader from "react-images-upload";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import CarouselPreview from "../../components/Carousel/carousel-preview";
import NotAuthorized from "../../components/NotAuthorized";
import RichTextEditor from "../../components/RichTextEditor";
import SelectMultiple from "../../components/SimpleSelect/select-multiple";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { ConnectUser, hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { NewsArticle } from "../../models/NewsArticle.model";
import NewsArticleSection from "../../models/NewsArticleSection.enum";
import { useNewsArticle } from "../../services/news-article-services";
import { useDocumentSets, useNewsArticleCategories } from "../../services/ref-data-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import NewsArticleEditPageActions from "./news-article-edit-page-actions";
import "./news-article-edit-page.scss";
import NewsArticleViewPageLoading from "./news-article-view-page-loading";
import RelatedActsTable from "./related-acts.table";
interface NewsArticleEditPageProps {
  article?: EntityModel<NewsArticle>;
}

const NewsArticleEditPage = () => {
  const { articleId } = useParams();
  const article = useNewsArticle(articleId);

  return article || !articleId ? (
    <NewsArticleEditForm article={article} />
  ) : (
    <NewsArticleViewPageLoading mode="edit" className="page-loading" />
  );
};

const NewsArticleEditForm: React.FC<NewsArticleEditPageProps> = ({ article }) => {
  const serverData = article;
  const intl = useIntl();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: serverData || {
      status: DocumentStatus.DRAFT,
      accessMode: DocumentAccessMode.FREE,
      showInHeader: "false",
      section: NewsArticleSection.NEWS,
      relatedJudgementActs: [],
    },
  });

  const { control, watch } = methods;
  const [title, subTitle] = watch(["title", "subTitle"]);

  const { data: newsArticleCategories } = useNewsArticleCategories();
  const { data: documentSets } = useDocumentSets();

  const { setValue } = methods;
  const { fields, append, remove } = useFieldArray<EntityModel<NewsArticle>, any, "fieldId">({
    name: `relatedJudgementActs`,
    keyName: "fieldId",
    control,
  });
  const watchFieldArray = useWatch({ control, name: `relatedJudgementActs` as any });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  return (
    <FormProvider {...methods}>
      <DocumentPageLayout
        title={article?.title}
        defaultTitle={intl.formatMessage({ id: "news-article-edit.page.add-new-title" })}
        mode="edit"
        className="news-article-page"
        menu={<NewsArticleEditPageActions />}
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="9">
              <Form.Group controlId="title">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.title" />
                </Form.Label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="status">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.status" />
                </Form.Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={Object.keys(DocumentStatus)}
                      getOption={(status) => ({
                        label: intl.formatMessage({ id: `document.status.${status}` }),
                        value: status,
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="9">
              <Form.Group controlId="subTitle">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.sub-title" />
                </Form.Label>
                <Controller
                  name="subTitle"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="accessMode">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.access-mode" />
                </Form.Label>
                <Controller
                  name="accessMode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={Object.keys(DocumentAccessMode)}
                          getOption={(accessMode) => ({
                            label: intl.formatMessage({ id: `document.access-mode.${accessMode}` }),
                            value: accessMode,
                          })}
                          value={value}
                          onChange={onChange}
                          isDisabled={!hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)(currentUser)}
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group controlId="documentSets">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.documentSets" />
                </Form.Label>
                <Controller
                  name="documentSets"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectMultiple
                      options={documentSets}
                      getOption={(documentSet) => ({
                        label: documentSet.shortName,
                        value: documentSet.id,
                      })}
                      value={value}
                      onChange={onChange}
                      placeholder="Изберете..."
                      isDisabled
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Form.Group controlId="section">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.section" />
                </Form.Label>
                <Controller
                  name="section"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={Object.keys(NewsArticleSection)}
                      getOption={(status) => ({
                        label: intl.formatMessage({ id: `news-article.section.${status}` }),
                        value: status,
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group controlId="category">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.category" />
                </Form.Label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={newsArticleCategories}
                      getOption={(category) => ({
                        label: category.shortName,
                        value: category.id,
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group controlId="showInHeader">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.show-in-header" />
                </Form.Label>
                <Controller
                  name="showInHeader"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={["true", "false"]}
                      getOption={(option) => ({
                        label: intl.formatMessage({ id: `boolean.${option}` }),
                        value: option,
                      })}
                      value={value + ""}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Controller
                name="imageData"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Form.Group controlId="imageData">
                      <Form.Label>
                        <FormattedMessage id="edit-news-article.form.image-data" />
                        {value && (
                          <Button variant="link" onClick={() => onChange(null)}>
                            <FormattedMessage id="button.remove" />
                          </Button>
                        )}
                      </Form.Label>
                      {!value && (
                        <ImageUploader
                          withIcon={false}
                          buttonText={intl.formatMessage({
                            id: "edit-news-article.form.image-data.choose-file",
                          })}
                          onChange={(pictureFiles: any, pictureDataURLs: any) => {
                            onChange(pictureDataURLs[0]);
                          }}
                          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                          maxFileSize={307200}
                          singleImage
                          defaultImage={value}
                          label={intl.formatMessage({
                            id: "edit-news-article.form.image-data.allowed-files",
                          })}
                        />
                      )}
                      {value && (
                        <CarouselPreview imageSrc={value} title={title} subTitle={subTitle} />
                      )}
                    </Form.Group>
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="body">
                <Form.Label>
                  <FormattedMessage id="edit-news-article.form.contents" />
                </Form.Label>
                <Controller
                  name="contents"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor defaultValue={value} height="350px" onChange={onChange} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <RelatedActsTable
                value={controlledFields}
                append={append}
                remove={remove}
                replace={(i, item) => {
                  setValue(`article.relatedJudgementActs.${i}` as any, item);
                }}
              />
            </Col>
          </Row>
        </Form>
      </DocumentPageLayout>
    </FormProvider>
  );
};

const ProtectedNewsArticleEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_ARTICLES)} fallback={NotAuthorized}>
      <NewsArticleEditPage />
    </Visible>
  );
};

export default ProtectedNewsArticleEditPage;
