/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from "react";

import "./filters-sidebar.css";

type FilterPanelProps = {
  label: string | JSX.Element;
};

const FilterPanel: FunctionComponent<FilterPanelProps> = ({ label, children }) => (
  <div className="filter-panel">
    <div className="filter-label" style={{ lineHeight: "0.6rem" }}>
      &nbsp;
    </div>
    {children}
  </div>
);

export default FilterPanel;
