import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import { saveSummary } from "../../services/summary-services";

const SummaryEditPageActions = () => {
  const { summaryId } = useParams();

  const intl = useIntl();

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    (shouldNavigate: boolean) => async (data: any) => {
      try {
        const saved = await saveSummary(data, data.id);
        toast.success(
          intl.formatMessage(
            {
              id: data.id
                ? "summary-edit.page.toasts.update.success"
                : "summary-edit.page.toasts.add.success",
            },
            { title: data.relatedJudgementAct.title }
          )
        );
        setValue("id", saved.id);
        shouldNavigate && navigate(`/summaries/${saved.id}`);
      } catch (e) {
        console.log(e);
        toast.error(
          intl.formatMessage(
            { id: "summary-edit.page.toasts.error" },
            { title: data.relatedJudgementAct.title }
          )
        );
      }
    },
    [intl, navigate, setValue]
  );

  useHotkeys(
    "ctrl+s,command+s",
    (e) => {
      e.preventDefault();
      handleSubmit(onSubmit(false))();
    },
    { enableOnContentEditable: true, enableOnTags: ["INPUT", "TEXTAREA"] }
  );

  return (
    <>
      <ActionButton
        onClick={handleSubmit(onSubmit(true))}
        disabled={isSubmitting}
        submitting={isSubmitting}
        messageId={"button.confirm"}
      />
      <SecondaryButton
        onClick={() => navigate(-1)}
        messageId={"button.cancel"}
        disabled={isSubmitting}
      />
    </>
  );
};

export default SummaryEditPageActions;
