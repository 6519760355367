import React, { useEffect, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaCompressArrowsAlt } from "react-icons/fa";
import ReactQuill from "react-quill";
import { v4 as uuid } from "uuid";
import {
  scrollToTargetAdjusted,
  scrollToTargetAdjustedInsideParent,
} from "../../pages/JudgementPage/judgmenet-view-page-metadata";
import { InlineLinkButton } from "../Button/button";
import Collapse from "../Collapse";
import Heading from "./Heading";
import { toHeading } from "./table-of-contents";
import "./table-of-contents.scss";

const fetchThesis = () => {
  const elements = Array.from(
    document.querySelectorAll(
      ".act-text .preset-thesis summary, #editor-collection > .ql-container *:not(.preset-thesis) + p.preset-thesis"
    )
  ).map((elem) => {
    if (!elem.id) {
      elem.id = "_" + uuid();
    }
    return {
      id: elem.id,
      text: (elem as any).innerText as string,
    };
  });
  return elements;
};

export const TableOfContentThesis: React.FC<{
  activeId?: Heading;
  setActiveId: (headin: Heading) => void;
}> = ({ activeId, setActiveId }) => {
  const [thesises, setThesises] = useState<{ text: string; id: string }[]>([]);

  useEffect(() => {
    setThesises(fetchThesis());
  }, []);

  useEffect(() => {
    const quillContainer = document.querySelector("#editor-collection > .ql-container")!;
    if (quillContainer) {
      let quill = ReactQuill.Quill.find(quillContainer);
      if (quill) {
        const handler = () => setThesises(fetchThesis());
        quill.on("text-change", handler);
        return () => quill.off("text-change", handler);
      }
    }
  }, []);

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  return (
    <Collapse
      icon={<FaCompressArrowsAlt />}
      label={"Тези"}
      className={thesises.length === 0 ? "d-none" : ""}
    >
      <div className="section">
        <ul className="collection-acts">
          {thesises.map((thesis) => (
            <li key={thesis.id}>
              <InlineLinkButton
                className={`${!isMobile ? "text-overflow-ellipsis" : ""}`}
                onClick={(e: any) => {
                  e.preventDefault();
                  const editor = document.querySelector(".ql-editor");
                  if (editor) {
                    scrollToTargetAdjustedInsideParent(document.querySelector(`#${thesis.id}`)!);
                  } else {
                    const element: HTMLElement = document.querySelector(`#${thesis.id}`)!;
                    let section = element.parentElement?.previousElementSibling;
                    while (section && !section.tagName.match(/h\d/i)) {
                      section = section.previousElementSibling;
                    }
                    if (section) {
                      setActiveId(toHeading(section));
                    }
                    setTimeout(() => {
                      scrollToTargetAdjusted(document.querySelector(`#${thesis.id}`)!);
                    }, 10);
                  }
                  return false;
                }}
              >
                {thesis.text}
              </InlineLinkButton>
            </li>
          ))}
        </ul>
      </div>
    </Collapse>
  );
};

export default TableOfContentThesis;
