/* eslint-disable react/jsx-props-no-spreading */
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import useRequest, { laggy } from "../../api";
import SearchFieldController from "../../components/SearchFieldController/search-field-controller";
import SelectMultiple from "../../components/SimpleSelect/select-multiple";
import { useSearchContext } from "../../contexts/search.context";
import {
  usePasswordResetRequestExpired,
  usePasswordResetRequestStatuses,
  useProforma,
  useSubscriptionOtherFilters,
  useSubscriptionReferralCodes,
  useSubscriptionStatuses,
  useSubscriptionTypes,
  useUserEnabled,
  useUserInternal,
} from "../../services/ref-data-services";
import { useRoles } from "../../services/user-services";
import FilterPanel from "../SearchPage/FiltersSidebar/filter-panel";
import { RefDataFilterPanel } from "../SearchPage/FiltersSidebar/filters";
import ResponsiveFilter from "../SearchPage/FiltersSidebar/responsive-filter";
import { displayCount } from "../SearchPage/search-utils";
import "./../SearchPage/FiltersSidebar/filters-sidebar.css";

export enum AdminSearchCategory {
  USERS = "users",
  SUBSCRIPTIONS = "subscriptions",
  PASSWORD_RESET_REQUESTS = "password-reset-requests",
  INVOICES = "invoices",
}

export const useSearchCategory = (): AdminSearchCategory => {
  const { category } = useParams();
  return category as AdminSearchCategory;
};

const StandardFilters = () => {
  const searchCategory = useSearchCategory();

  return (
    <>
      {searchCategory === AdminSearchCategory.SUBSCRIPTIONS && <SubscriptionFilters />}
      {searchCategory === AdminSearchCategory.USERS && <UserFilters />}
      {searchCategory === AdminSearchCategory.PASSWORD_RESET_REQUESTS && (
        <PasswordResetRequestFilters />
      )}
      {searchCategory === AdminSearchCategory.INVOICES && <InvoiceFilters />}
    </>
  );
};

const SubscriptionFilters = () => {
  return (
    <>
      <NameFilter />
      <ClientNameFilter />
      <SubscriptionPlanTypeFilter />
      <SubscriptionStatusFilter />
      <SubscriptionManagementEmailFilter />
      <SubscriptionChannelFilter />
      <SubscriptionyOtherFilter />
    </>
  );
};

const UserFilters = () => {
  return (
    <>
      <NameFilter />
      <SubscriptionPlanTypeFilter />
      <UsernameFilter />
      <UserRoleFilter />
      <UserEnabledFilter />
      <UserInternalFilter />
    </>
  );
};

const PasswordResetRequestFilters = () => {
  return (
    <>
      <NameFilter />
      <UsernameFilter />
      <PasswordReseRequestStatusFilter />
      <PasswordReseRequestExpiredFilter />
    </>
  );
};

const InvoiceFilters = () => {
  return (
    <>
      <ProformaFilter />
    </>
  );
};

export const PasswordReseRequestExpiredFilter = () => (
  <RefDataFilterPanel
    filterName="prrExpired"
    labelKey="passwordResetRequestExpired"
    useData={usePasswordResetRequestExpired}
    useFilters={useAvailableFilters}
    prefetch={false}
  />
);

export const PasswordReseRequestStatusFilter = () => (
  <RefDataFilterPanel
    filterName="prrStatus"
    labelKey="passwordResetRequestStatus"
    useFilters={useAvailableFilters}
    useData={usePasswordResetRequestStatuses}
    prefetch={false}
  />
);

export const UserEnabledFilter = () => (
  <RefDataFilterPanel
    filterName="userEnabled"
    labelKey="userEnabled"
    useData={useUserEnabled}
    useFilters={useAvailableFilters}
    prefetch={false}
  />
);

export const UserInternalFilter = () => (
  <RefDataFilterPanel
    filterName="userInternal"
    labelKey="userInternal"
    useData={useUserInternal}
    useFilters={useAvailableFilters}
    prefetch={false}
  />
);

export const SubscriptionPlanTypeFilter = () => (
  <RefDataFilterPanel
    filterName="subscriptionPlan"
    labelKey="subscriptionPlan"
    useFilters={useAvailableFilters}
    useData={useSubscriptionTypes}
    prefetch={false}
  />
);

export const UserRoleFilter = () => {
  const roles = useRoles();
  const refData = roles?.map((role, i) => ({
    id: role.id,
    code: role.id + "",
    shortName: role.name,
    name: role.name,
    rank: i,
  }));
  return (
    <RefDataFilterPanel
      filterName="userRole"
      labelKey="userRole"
      useFilters={useAvailableFilters}
      useData={() => ({
        data: refData,
      })}
      prefetch={false}
    />
  );
};

export const SubscriptionStatusFilter = () => (
  <RefDataFilterPanel
    filterName="subscriptionStatus"
    labelKey="subscriptionStatus"
    useFilters={useAvailableFilters}
    useData={useSubscriptionStatuses}
    prefetch={false}
  />
);

export const SubscriptionChannelFilter = () => (
  <RefDataFilterPanel
    filterName="channel"
    labelKey="channel"
    useFilters={useAvailableFilters}
    useData={useSubscriptionReferralCodes}
    prefetch={false}
  />
);

export const UsernameFilter = () => {
  const intl = useIntl();

  const data = useAvailableFilters().username;

  return (
    <FilterPanel
      label={<FormattedMessage id="admin.filters-sidebar.filters.username.placeholder" />}
    >
      <SearchFieldController
        name="username"
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              getOption={mapValueToOptionV2(data || [])}
              filterOptions={(options, input) => {
                return Object.keys(data || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(data || []));
              }}
              placeholder={intl.formatMessage({
                id: "admin.filters-sidebar.filters.username.placeholder",
              })}
              hint="Въведете текст"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const NameFilter = () => {
  const intl = useIntl();

  const data = useAvailableFilters().name;

  return (
    <FilterPanel label={<FormattedMessage id="admin.filters-sidebar.filters.name.placeholder" />}>
      <SearchFieldController
        name="name"
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              getOption={mapValueToOptionV2(data || [])}
              filterOptions={(options, input) => {
                return Object.keys(data || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(data || []));
              }}
              placeholder={intl.formatMessage({
                id: "admin.filters-sidebar.filters.name.placeholder",
              })}
              hint="Въведете текст"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const ClientNameFilter = () => {
  const intl = useIntl();

  const data = useAvailableFilters().clientName;

  return (
    <FilterPanel
      label={<FormattedMessage id="admin.filters-sidebar.filters.client-name.placeholder" />}
    >
      <SearchFieldController
        name="clientName"
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              getOption={mapValueToOptionV2(data || [])}
              filterOptions={(options, input) => {
                return Object.keys(data || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(data || []));
              }}
              placeholder={intl.formatMessage({
                id: "admin.filters-sidebar.filters.client-name.placeholder",
              })}
              hint="Въведете текст"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const SubscriptionManagementEmailFilter = () => {
  const intl = useIntl();

  const data = useAvailableFilters().subscriptionManagementEmail;

  return (
    <FilterPanel
      label={<FormattedMessage id="admin.filters-sidebar.filters.management-email.placeholder" />}
    >
      <SearchFieldController
        name="subscriptionManagementEmail"
        render={({ field: { onChange, value } }) => (
          <ResponsiveFilter>
            <SelectMultiple
              onChange={onChange}
              value={value}
              options={[]}
              getOption={mapValueToOptionV2(data || [])}
              filterOptions={(options, input) => {
                return Object.keys(data || [])
                  .filter(filterSelected(value))
                  .filter((value) => value.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1)
                  .sort(sortAlphabetically)
                  .slice(0, 100)
                  .map(mapValueToOptionV2(data || []));
              }}
              placeholder={intl.formatMessage({
                id: "admin.filters-sidebar.filters.management-email.placeholder",
              })}
              hint="Въведете текст"
            />
          </ResponsiveFilter>
        )}
      />
    </FilterPanel>
  );
};

export const SubscriptionyOtherFilter = () => (
  <RefDataFilterPanel
    filterName="other"
    labelKey="subcriptionOthers"
    useData={useSubscriptionOtherFilters}
    useFilters={useAvailableFilters}
    prefetch={false}
  />
);

export const ProformaFilter = () => (
  <RefDataFilterPanel
    filterName="proforma"
    labelKey="proforma"
    useData={useProforma}
    useFilters={useAvailableFilters}
    prefetch={false}
  />
);

const mapValueToOptionV2 = (countsPerKey: any) => (key: string) => {
  return {
    value: key,
    label: `${key}` + (countsPerKey[key] ? ` (${displayCount(countsPerKey[key])})` : ""),
    disabled: !countsPerKey[key],
  };
};

const filterSelected = (value: any[]) => (key: any) => value.indexOf(key) === -1;

const sortAlphabetically = (v1: string, v2: string) => v1.localeCompare(v2);

export const useAvailableFilters = () => {
  const searchCategory = useSearchCategory();
  const { search } = useSearchContext();

  const queryParam = new URLSearchParams(search);
  queryParam.delete("sort");
  const { data } = useRequest<any>(
    `/${searchCategory}/available-filters?` + queryParam.toString(),
    (a) => a,
    { use: [laggy] }
  );
  return data || {};
};

export default StandardFilters;
