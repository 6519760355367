import React, { useState } from "react";
import { Button, Offcanvas, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import { FaSearch } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import JudgementSearchPanel from "./judgement-search-panel";

const JudgementSearchAction: React.FC<{ popover?: boolean; defaultText?: string }> = ({
  popover,
  defaultText,
}) => {
  const { formatMessage } = useIntl();

  const [opened, setOpened] = useState(false);

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { selectedActId } = useSelectedActContext();

  if (popover) {
    return (
      <Button
        className="inline-link"
        key="1"
        size="sm"
        variant="link"
        onClick={() => {
          (window.document.querySelector(".search-button") as HTMLElement).click();
        }}
      >
        <OverlayTrigger
          placement={selectedActId ? "bottom" : "top"}
          overlay={
            <Tooltip id="doc-action-favourites">
              {formatMessage({ id: "document-actions.search" })}
            </Tooltip>
          }
        >
          <span>
            <FaSearch />
          </span>
        </OverlayTrigger>
      </Button>
    );
  }

  return isMobileOnly ? (
    <JudgementSearchModal defaultText={defaultText} />
  ) : (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom-end"
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [98, 10],
              },
            },
          ],
        }}
        overlay={
          <Popover
            id="search-panel-popover"
            className={`shadow ${opened ? "" : "d-none"}`}
            body
            popper={{
              strategy: "fixed",
            }}
          >
            <JudgementSearchPanel setOpened={setOpened} opened={opened} defaultText={defaultText} />
          </Popover>
        }
        show={true}
      >
        <Button
          className="inline-link search-button"
          size="sm"
          variant="link"
          onClick={() => setOpened(!opened)}
        >
          <OverlayTrigger
            placement={selectedActId ? "bottom" : "top"}
            overlay={
              <Tooltip id="doc-action-favourites">
                {formatMessage({ id: "document-actions.search" })}
              </Tooltip>
            }
          >
            <span>
              <FaSearch />
            </span>
          </OverlayTrigger>
        </Button>
      </OverlayTrigger>
    </>
  );
};

const JudgementSearchModal: React.FC<{ defaultText?: string }> = ({ defaultText }) => {
  const { formatMessage } = useIntl();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button
        className="inline-link search-button"
        size="sm"
        variant="link"
        onClick={() => setOpened(!opened)}
      >
        <FaSearch title={formatMessage({ id: "document-actions.search" })} />
      </Button>
      <Offcanvas show={opened} placement="bottom" id="search-modal" backdrop={false} scroll>
        <Offcanvas.Body>
          <JudgementSearchPanel setOpened={setOpened} opened={opened} defaultText={defaultText} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default JudgementSearchAction;
