/* eslint-disable no-underscore-dangle */
import Mark from "mark.js";
import { useEffect, useRef, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useHotkeys } from "react-hotkeys-hook";
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";
import { useDebounce } from "react-use";
import { toHeading } from "../../components/TableOfContents/table-of-contents";
import { useEditorCollectionContext } from "../../contexts/editor-collection.context";

type EditorCollectionSearchPanelProps = {
  setOpened: (opened: boolean) => void;
  opened: boolean;
};
const EditorCollectionSearchPanel: React.FC<EditorCollectionSearchPanelProps> = ({
  setOpened,
  opened,
}) => {
  const [filterText, setFilterText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState<Element[]>();
  const [markJs, setMarkJs] = useState<Mark>();

  const searchTargets = ".act-text";

  const { formatMessage } = useIntl();

  const { setActiveId, setSearchHits } = useEditorCollectionContext();

  const close = () => {
    setFilterText("");
    setCurrentIndex(0);
    setResults(undefined);
    setMarkJs(undefined);
    setOpened(false);
    setSearchHits(undefined);
  };

  const jumpTo = (index: number, results?: Element[]) => {
    if (results) {
      const old = document.querySelector("mark.current");
      if (old) {
        old.className = old.className.replaceAll("current", "");
      }
      const rIndex = index < 0 ? results.length - 1 : index > results.length - 1 ? 0 : index;
      const current = results[rIndex] as HTMLElement;

      if (current) {
        current.className += "current";
        const position = getOffset(current).top - 200;
        window.scrollTo(0, position);
        if (
          ["h2", "h3", "h4", "h5"].indexOf(current.parentElement?.tagName.toLowerCase() || "") > -1
        ) {
          setActiveId(toHeading(current.parentElement!));
        } else {
          let c = findHeading(current);
          setActiveId(toHeading(c));
        }
      }
      setCurrentIndex(rIndex);
    }
  };

  useHotkeys("Escape", () => close(), {
    enableOnTags: ["INPUT"],
  });
  useHotkeys("ctrl+f,command+f", (e) => {
    e.preventDefault();
    setOpened(true);
  });

  // useEffect(() => {

  // }, [opened, filterText]);

  const ref = useRef<any>();

  useEffect(() => {
    ref.current && opened && setTimeout(() => ref.current.focus(), 100);
  }, [opened]);

  useDebounce(
    () => {
      const instance = markJs || new Mark(searchTargets);
      setMarkJs(instance);
      if (filterText.length > 0) {
        document.querySelectorAll(".act-text .h-0").forEach((el) => {
          el.classList.replace("h-0", "h--0");
        });
        document.querySelectorAll(".act-text details").forEach((el) => {
          el.setAttribute("open", "");
        });
      }
      instance.unmark({
        done: function () {
          filterText.length > 0 &&
            instance.mark(filterText, {
              separateWordSearch: false,
              done: function () {
                const results = Array.from(document.querySelectorAll("mark"));
                setResults(results);
                setSearchHits(
                  results.map((e) => findHeading(e)).map((e) => toHeading(e))
                  // .filter(function (item, pos, arr) {
                  //   return pos === 0 || item.id !== arr[pos - 1].id;
                  // })
                );
                jumpTo(0, results);
              },
            });
        },
      });
      const pageEl = document.querySelector(".editor-collection-page");
      if (opened) {
        if (pageEl && filterText.length > 0 && pageEl.className.indexOf("seaching") === -1) {
          pageEl.className += " searching";
        }
      } else {
        if (pageEl) {
          pageEl.className = pageEl.className.replaceAll(" searching", "");
        }
        const selection = window.getSelection();
        const current = document.querySelector("mark.current");
        current && selection?.setBaseAndExtent(current, 0, current, 1);
      }
    },
    500,
    [filterText, opened]
  );

  return (
    <div className={`search-panel ${opened ? "d-flex" : "d-none"}`}>
      {opened && (
        <InputGroup>
          <Form.Control
            type="text"
            ref={ref}
            autoComplete="off"
            autoFocus
            value={filterText}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                jumpTo(currentIndex + 1, results);
              }
            }}
            placeholder={formatMessage({ id: "summary-search-panel.search-input.placeholder" })}
            onChange={(e) => {
              const searchVal = e.target.value;
              setFilterText(searchVal);
            }}
          />
          {!!filterText && (
            <InputGroup.Text>
              {results?.length ? `${currentIndex + 1}/${results?.length}` : "-"}
            </InputGroup.Text>
          )}
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              jumpTo(currentIndex - 1, results);
            }}
            title={formatMessage({ id: "summary-search-panel.button.previous-result" })}
          >
            <IoIosArrowUp />
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              jumpTo(currentIndex + 1, results);
            }}
            title={formatMessage({ id: "summary-search-panel.button.next-result" })}
          >
            <IoIosArrowDown />
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              close();
              document.querySelectorAll(".act-text .h--0").forEach((el) => {
                el.classList.replace("h--0", "h-0");
              });
              document.querySelectorAll(".act-text details").forEach((el) => {
                el.removeAttribute("open");
              });
            }}
            title={formatMessage({ id: "summary-search-panel.button.close" })}
          >
            <IoMdClose />
          </Button>
        </InputGroup>
      )}
    </div>
  );
};

export const getOffset = (element: HTMLElement) => {
  if (!element.getClientRects().length) {
    return { top: 0, left: 0 };
  }

  let rect = element.getBoundingClientRect();
  let win = element.ownerDocument.defaultView!;
  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
};

const findHeading = (current: Element) => {
  let c: Element = current;
  // find parent of the match directly under the main section
  while (!c.parentElement!.classList.contains("main-section")) {
    c = c.parentElement!;
  }
  //find the closest heading
  while (["h2", "h3", "h4", "h5"].indexOf(c.tagName.toLowerCase() || "") === -1) {
    c = c.previousElementSibling!;
  }
  return c;
};

export default EditorCollectionSearchPanel;
