/* eslint-disable react/jsx-props-no-spreading */
import Container from "react-bootstrap/Container";
import { VscMenu } from "react-icons/vsc";
import { FormattedMessage } from "react-intl";

import Collapse from "../../../components/SimpleCollapse";
import Skeleton from "../../../components/Skeleton";

import "./filters-sidebar.css";

const FiltersSideBarLoading = () => {
  return (
    <Container fluid className="filters-sidebar">
      <Collapse
        icon={<VscMenu />}
        label={<FormattedMessage id="filters-sidebar.filters" />}
        defaultExpanded={false}
      >
        <Skeleton count={7} height={30} style={{ marginTop: "10px" }} />
      </Collapse>
      <div style={{ lineHeight: "1" }}>&nbsp;</div>
      <Collapse
        className="topic-filter"
        icon={<VscMenu />}
        label={<FormattedMessage id="filters-sidebar.topic.search" />}
      >
        <Skeleton count={8} />
      </Collapse>
    </Container>
  );
};

export default FiltersSideBarLoading;
