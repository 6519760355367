import React from "react";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { FormattedMessage } from "react-intl";
import PreloadingLink from "../../components/PreloadingLink";
import { Subscription } from "../../models/Subscription.model";
import { prefetchSubscription } from "../../services/subscription-services";
import SubscriptionStatusBadge from "../AdminPage/subscription-status-badge";

const SubscriptionsTable: React.FC<{
  value: Subscription[];
}> = ({ value }) => {
  return (
    <Tabs activeKey={"0"} className={`sub-section-tabs`}>
      <Tab eventKey="0" disabled title={<FormattedMessage id="edit-user.form.subscriptions" />}>
        {value.length === 0 ? (
          <span style={{ padding: "12px" }}>
            <FormattedMessage id="edit-user.form.subscriptions.no-data" />
          </span>
        ) : (
          <Table responsive style={{ width: "100%" }} className="sub-section-table">
            <colgroup>
              <col span={1} />
            </colgroup>

            <tbody>
              {[...value].map((v, i) => (
                <tr key={i}>
                  <td style={{ cursor: "pointer" }}>
                    <div className="d-flex w-100">
                      <span>
                        <PreloadingLink
                          to={`/admin/subscriptions/${v.id}`}
                          state={{ title: v.code }}
                          preload={(cache) => prefetchSubscription(v.id, cache)}
                        >
                          {v.code}
                        </PreloadingLink>{" "}
                        ({v.plan.type.name})
                      </span>
                      <span className="ms-auto">
                        <SubscriptionStatusBadge subscription={v} />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Tab>
    </Tabs>
  );
};

export default SubscriptionsTable;
