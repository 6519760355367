import { ReactElement } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import { useDeviceSelectors } from "react-device-detect";
import { FaPlus, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import SimpleSelect from "../../../components/SimpleSelect";
import { hasPrivilege, Privileges, useAuth, Visible } from "../../../contexts/auth.context";
import { UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
import { JudgementActDomain } from "../../../models/JudgementAct.model";
import {
  isActsCategory,
  isConstCourtActsCategory,
  isSupremeCourtActsCategory,
} from "../FiltersSidebar/filters";
import ResponsiveFilter from "../FiltersSidebar/responsive-filter";
import { SearchCategory, useQuery, useSearchCategory, useSort } from "../search-utils";

const getSortOptionLabel = (value: string) => {
  return value.indexOf("actDate") >= 0
    ? "Дата на акта"
    : value.indexOf("case") >= 0
    ? "Година на делото"
    : value.indexOf("mentions") >= 0
    ? "Позовавания"
    : value.indexOf("createdOn") >= 0
    ? "Дата на създаване"
    : value.indexOf("updatedOn") >= 0
    ? "Дата на промяна"
    : value.indexOf("importance") >= 0
    ? "Йерархия"
    : "Релевантност";
};

interface ResultsTableHeaderProps extends UseMultipleSelectionReturn {
  actions?: ReactElement;
}

const ResultsTableHeader: React.FC<ResultsTableHeaderProps> = ({
  actions,
  ...multipleSelectProps
}) => {
  const { column, direction } = useSort({ column: "actDate", direction: "desc" })!;
  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();

  const auth = useAuth();
  const showAdditionalSortOptions = auth.hasPrivilige(Privileges.SEARCH_EXTENDED_FILTERS);
  const sortOptions = [
    "actDate," + direction,
    "caseDate," + direction,
    ...(showAdditionalSortOptions ? ["updatedOn," + direction] : []),
    ...(showAdditionalSortOptions ? ["createdOn," + direction] : []),
  ];
  const searchCategory = useSearchCategory();

  if (query.query) {
    sortOptions.unshift("relevance," + direction);
  }
  sortOptions.push("mentions," + direction);

  if (searchCategory !== SearchCategory.EU_ACTS) {
    sortOptions.push("importance," + direction);
  }

  const addNewPath =
    searchCategory === SearchCategory.ACTS ||
    searchCategory === SearchCategory.EU_ACTS ||
    searchCategory === SearchCategory.ADM_ACTS ||
    searchCategory === SearchCategory.CONST_ACTS ||
    searchCategory === SearchCategory.SC_ACTS
      ? "/judgement-acts/new"
      : searchCategory === SearchCategory.NEWS_ARTICLES
      ? "/news-articles/new"
      : "";

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  return (
    <thead>
      <tr>
        {!isMobileOnly && (
          <th className="checkbox">
            {location.hash === "#questions" ? (
              <span>&nbsp;</span>
            ) : (
              <Form.Group controlId="chk-all" className="checkbox">
                <Form.Check
                  type="checkbox"
                  label=""
                  onChange={multipleSelectProps.selectAll}
                  checked={multipleSelectProps.isAllSelected}
                />
              </Form.Group>
            )}
          </th>
        )}
        <th className="controls-bar">
          <div className={"actions-dropdown"}>
            {location.hash === "#questions" ? (
              <span>&nbsp;</span>
            ) : (
              <DropdownButton
                as={ButtonGroup}
                size="sm"
                variant="light"
                title="Действия"
                className={multipleSelectProps.selection.size ? "" : "no-selection"}
              >
                {actions}
                {!actions && <Dropdown.Header>Няма налични действия</Dropdown.Header>}
              </DropdownButton>
            )}
          </div>
          <div className="sort-control">
            {searchCategory !== SearchCategory.NEWS_ARTICLES && (
              <>
                <span className="sort-control-label">Сортирай по</span>
                <SearchFieldController
                  name="sort"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <ResponsiveFilter>
                        <SimpleSelect
                          className="inline-select"
                          onChange={onChange}
                          getOption={(value) => ({ value, label: getSortOptionLabel(value) })}
                          value={[column + "," + direction]}
                          options={sortOptions}
                          isMulti={false}
                          placeholder="Сортирай по"
                        />
                      </ResponsiveFilter>
                      {direction === "asc" && (
                        <Button
                          onClick={() => {
                            onChange([column + ",desc"]);
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="sort-asc">
                                <FormattedMessage
                                  id={`results-table.sort.${column}.asc`}
                                  defaultMessage="Сортиране: възходящо"
                                />
                              </Tooltip>
                            }
                          >
                            <span>
                              <FaSortAmountUp />
                            </span>
                          </OverlayTrigger>
                        </Button>
                      )}
                      {direction === "desc" && (
                        <Button
                          onClick={() => {
                            onChange([column + ",asc"]);
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="sort-desc">
                                <FormattedMessage
                                  id={`results-table.sort.${column}.desc`}
                                  defaultMessage="Сортиране: низходящо"
                                />
                              </Tooltip>
                            }
                          >
                            <span>
                              <FaSortAmountDown />
                            </span>
                          </OverlayTrigger>
                        </Button>
                      )}
                    </>
                  )}
                />
              </>
            )}
            <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)}>
              {addNewPath && (
                <Button
                  onClick={() =>
                    navigate(addNewPath, {
                      state: {
                        type:
                          isActsCategory(searchCategory) ||
                          isConstCourtActsCategory(searchCategory) ||
                          isSupremeCourtActsCategory(searchCategory)
                            ? JudgementActDomain.DOMESTIC
                            : searchCategory === SearchCategory.ADM_ACTS
                            ? JudgementActDomain.ADMINISTRATIVE
                            : searchCategory === SearchCategory.EU_ACTS
                            ? JudgementActDomain.EU
                            : undefined,
                      },
                    })
                  }
                >
                  <FaPlus />
                </Button>
              )}
            </Visible>
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default ResultsTableHeader;
