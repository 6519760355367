import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

const NotFound = () => {
  return (
    <Container style={{ marginTop: "50px", textAlign: "center" }}>
      <Alert variant="danger">Страницата не е намерена</Alert>
    </Container>
  );
};

export default NotFound;
