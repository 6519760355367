import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import { useState } from "react";
import { Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Controller, useForm } from "react-hook-form";
import { FaQuestionCircle } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import {
  updateSubscriptionStatus,
  UpdateSubscriptionStatusInput,
} from "../../services/subscription-services";
import AttachInvoiceForm from "./attach-invoice.form";
import GenerateInvoice from "./generate-invoice.form";

type ActivateSubscriptionModalProps = {
  prefix: string;
  licenseCount: number;
  subscription: Subscription;
  onUpdate: (subscription: EntityModel<Subscription>) => void;
  onClose: () => void;
};

const ActivateSubscriptionModal: React.FC<ActivateSubscriptionModalProps> = ({
  prefix,
  licenseCount,
  subscription,
  onUpdate,
  onClose,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<UpdateSubscriptionStatusInput>({
    defaultValues: {
      status: SubscriptionStatus.ACTIVE,
      prefix,
      start: new Date(),
      end: undefined,
      licenseCount,
    },
  });

  const intl = useIntl();
  const onSumbit = async (data: UpdateSubscriptionStatusInput) => {
    try {
      onUpdate(await updateSubscriptionStatus(subscription.id, data));
      toast.success(
        intl.formatMessage(
          {
            id: "activate-subscription.modal.toasts.success",
          },
          data
        )
      );
      onClose();
    } catch (e) {
      console.log(e);
      toast.error(intl.formatMessage({ id: "activate-subscription.modal.toasts.error" }, data));
    }
  };

  const invoice = watch("invoice");

  const [selectedTab, setSelectedTab] = useState<string | null>();

  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onClose} />
      <ActionButton
        messageId="button.confirm"
        onClick={handleSubmit(onSumbit)}
        submitting={isSubmitting}
        disabled={isSubmitting || (selectedTab !== "no-invoice" && !invoice)}
      />
    </>
  );

  return (
    <Modal show={true} centered size="lg" onEscapeKeyDown={onClose} animation={false}>
      <Modal.Header style={{ alignItems: "center", padding: "0.6rem" }}>
        <div>
          <FormattedMessage id="activate-subscription.modal.header" />
        </div>
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body style={{ height: "600px" }}>
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="4">
              <Form.Group controlId="prefix">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.prefix" />
                  <OverlayTrigger
                    placement="right"
                    overlay={(props) => (
                      <Tooltip id="code-tooltip" {...props}>
                        <ul className="mb-0">
                          <li>Между 3 и 10 символа</li>
                          <li>Изписан на латиница</li>
                          <li>Главни букви</li>
                        </ul>
                      </Tooltip>
                    )}
                  >
                    <span>
                      <FaQuestionCircle className="ms-1 text-muted" style={{ cursor: "pointer" }} />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" {...register("prefix")} />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="start">
                <Form.Label>
                  <FormattedMessage id="add-period-modal.form.start" />
                </Form.Label>
                <div>
                  <Controller
                    name="start"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value}
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format={"DD.MM.YYYY"}
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(true),
                          weekdaysLong: dayjs.weekdays(true),
                        }}
                        onDayChange={onChange}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="end">
                <Form.Label>
                  <FormattedMessage id="add-period-modal.form.end" />
                </Form.Label>
                <div>
                  <Controller
                    name="end"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value}
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format={"DD.MM.YYYY"}
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(true),
                          weekdaysLong: dayjs.weekdays(true),
                        }}
                        onDayChange={onChange}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Tabs
          defaultActiveKey="generate-invoice"
          className="mb-3 mt-3"
          onSelect={(eventKey) => {
            eventKey === "no-invoice" && setValue("invoice", undefined);
            setSelectedTab(eventKey);
          }}
        >
          <Tab eventKey="generate-invoice" title="Генерирай фактура">
            <GenerateInvoice
              proforma={false}
              subscription={subscription}
              onGenerate={(data) => {
                setValue(
                  "invoice",
                  data
                    ? {
                        ...data,
                        amount: data.amount,
                        proforma: false,
                      }
                    : undefined
                );
              }}
            />
          </Tab>
          <Tab eventKey="attach-invoice" title="Прикачи фактура">
            <AttachInvoiceForm
              subscription={subscription}
              onAttach={(data) => {
                setValue("invoice", data);
                data && setValue("licenseCount", data.licenseCount);
                data && setValue("discount", data.discount);
              }}
            />
          </Tab>
          <Tab eventKey="no-invoice" title="Без фактура">
            Без фактура към абонамента
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ActivateSubscriptionModal;
