import React, { createContext, useContext, useState } from "react";

export type HighlightContextType = {
  includeUserAnnotations: boolean;
  includeEditorAnnotations: boolean;
  setIncludeUserAnnotations: (enabled: boolean) => void;
  setIncludeEditorAnnotations: (enabled: boolean) => void;
};

export const HighlightContext = createContext<HighlightContextType>({
  includeUserAnnotations: true,
  includeEditorAnnotations: true,
  setIncludeUserAnnotations: () => {},
  setIncludeEditorAnnotations: () => {},
});

export const HighlightContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [includeUserAnnotations, setIncludeUserAnnotations] = useState(true);
  const [includeEditorAnnotations, setIncludeEditorAnnotations] = useState(true);

  return (
    <HighlightContext.Provider
      value={{
        includeUserAnnotations,
        includeEditorAnnotations,
        setIncludeUserAnnotations,
        setIncludeEditorAnnotations,
      }}
    >
      {children}
    </HighlightContext.Provider>
  );
};

export const useHighlightContext = () => useContext(HighlightContext);

export default HighlightContextProvider;
