import React, { createContext, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { UserDocumentType } from "../models/UserDocument.model";
import { addUserDocument, delUserDocument } from "../services/user-services";
import { useAuth } from "./auth.context";
import { useSearchContext } from "./search.context";

export type OpenedDocument = {
  title: string;
  href: string;
};

export type DocumentContextType = {
  openDocument: (document: OpenedDocument) => Promise<void>;
  closeDocument: (document?: OpenedDocument) => Promise<void>;
  isCurrentlyOpened: (href: string) => boolean;
};

export const DocumentContext = createContext<DocumentContextType>({
  openDocument: async () => {},
  closeDocument: async () => {},
  isCurrentlyOpened: () => false,
});

export const getIdFromHref = (href: string) => {
  const matches = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i.exec(
    href
  );
  return matches ? matches[0] : undefined;
};

export const DocumentContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoginSessionActive } = useAuth();
  const currentlyOpened = location.pathname;

  const isCurrentlyOpened = useCallback(
    (href: string) => !!currentlyOpened && getIdFromHref(currentlyOpened) === getIdFromHref(href),
    [currentlyOpened]
  );

  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");

  const openDocument = useCallback(
    async (document: OpenedDocument) => {
      isLoginSessionActive() &&
        (await addUserDocument(
          [
            {
              title: document.title,
              href: document.href,
              query,
            },
          ],
          UserDocumentType.LAST_OPENED
        ));
    },
    [query, isLoginSessionActive]
  );

  const { lastSearchHref } = useSearchContext();

  const closeDocument = useCallback(
    async (document?: OpenedDocument) => {
      if (!document || isCurrentlyOpened(document.href)) {
        navigate(lastSearchHref);
        if (document) {
          await delUserDocument([getIdFromHref(document.href)!], UserDocumentType.LAST_OPENED);
        }
      } else {
        await delUserDocument([getIdFromHref(document.href)!], UserDocumentType.LAST_OPENED);
      }
    },
    [lastSearchHref, isCurrentlyOpened, navigate]
  );

  return (
    <DocumentContext.Provider
      value={{
        openDocument,
        closeDocument,
        isCurrentlyOpened,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocumentContext = () => {
  const { openDocument, closeDocument, isCurrentlyOpened } = useContext(DocumentContext);
  return {
    openDocument,
    closeDocument,
    isCurrentlyOpened,
  };
};

export default DocumentContextProvider;
