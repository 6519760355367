import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { useSearchContext } from "../../../contexts/search.context";
import { prefetchDocumentStats } from "../../../services/document-services";
import { useFiltersCount } from "../search-utils";
import "./current-filters-layout.scss";

const CurrentFiltersLayout: React.FC = ({ children }) => {
  const { clearSearch } = useSearchContext();
  const navigate = useNavigate();
  const filterCount = useFiltersCount();
  const { cache } = useSWRConfig();
  const [isOpen, setOpen] = useState(filterCount > 0);

  // intentianlly does not list isOpen/setOpen as dependencies
  useEffect(() => {
    filterCount > 0 && !isOpen && setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCount]);

  return (
    <Accordion className="current-filters" defaultActiveKey="0" activeKey={isOpen ? "0" : ""}>
      <div className="current-filters-buttons">
        {filterCount > 0 && (
          <Button variant="link" onClick={() => setOpen(!isOpen)}>
            {isOpen ? (
              <FaCaretDown className="toggle-indicator" />
            ) : (
              <FaCaretRight className="toggle-indicator" />
            )}{" "}
            <FormattedMessage id="current-filers.label" /> <Badge bg="primary">{filterCount}</Badge>
          </Button>
        )}
        <Button
          variant="link"
          className="clear-search noSelect"
          onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            await prefetchDocumentStats(new URLSearchParams(), cache);
            clearSearch();
            navigate("/search", { replace: true });
          }}
        >
          <FormattedMessage id="current-filers.clear-search" />
        </Button>
      </div>
      <Accordion.Collapse eventKey="0">
        <div className="current-filters-values">{children}</div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default CurrentFiltersLayout;
