import React, { createContext, useCallback, useContext, useState } from "react";
import { useSummaryEditContext } from "./summary-edit.context";

export type SummaryQaContextType = {
  modeAddRelatedActModal: RelatedActModalMode;
  setModeAddRelatedActModal: (mode: RelatedActModalMode, selectedText?: string) => void;
};

export type RelatedActModalMode = "hidden" | "add" | "edit";

export const SummaryQaContext = createContext<SummaryQaContextType>({
  modeAddRelatedActModal: "hidden",
  setModeAddRelatedActModal: () => {},
});

export const SummaryQaContextProvider: React.FC<{}> = ({ children }) => {
  const [modeAddRelatedActModal, setModeAddRelatedActModal] =
    useState<RelatedActModalMode>("hidden");
  const { selectedText, setSelectedText } = useSummaryEditContext();
  return (
    <SummaryQaContext.Provider
      value={{
        modeAddRelatedActModal,
        setModeAddRelatedActModal: useCallback(
          (mode, text) => {
            setModeAddRelatedActModal(mode);
            if (mode === "hidden") {
              setSelectedText(text);
            } else {
              setSelectedText(text || selectedText);
            }
          },
          [setSelectedText, setModeAddRelatedActModal, selectedText]
        ),
      }}
    >
      {children}
    </SummaryQaContext.Provider>
  );
};

export const useSummaryQaContext = () => useContext(SummaryQaContext);

export default SummaryQaContextProvider;
