import { useState } from "react";
import Badge from "react-bootstrap/Badge";
import { useIntl } from "react-intl";
import { UseSearchRequestReturn } from "../../../api";
import LabelValue from "../../../components/label-value";
import PreloadingLink from "../../../components/PreloadingLink";
import { Privileges, useAuth } from "../../../contexts/auth.context";
import { useSearchContext } from "../../../contexts/search.context";
import { Selection, UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
import { printSummaryTitle, Summary } from "../../../models/Summary.model";
import {
  addTopicToSummary,
  fetchSummary,
  fetchSummaryPdf,
  fetchSummaryRtf,
  prefetchSummary,
  removeTopicFromSummary,
  summaryHref,
  useSummaries,
} from "../../../services/summary-services";
import ResultsTable from "../ResultsTable/results-table";
import ResultsTableDocumentCollectionMenuActions from "../ResultsTable/results-table-document-collection-actions";
import ResultsTableHeader from "../ResultsTable/results-table-header";
import { useSort } from "../search-utils";
import ChangeTopicOfDocument from "./change-topic-of-document.modal";
import ReferencedFromDrawer from "./referenced-from-drawer";

const SummariesResults = () => {
  const results = useSummaries();

  return <SummaryDocumentsResults results={results} />;
};

type ResultsViewProps = {
  results: UseSearchRequestReturn<Summary>;
};

const SummaryDocumentsResults: React.FC<ResultsViewProps> = ({ results }) => {
  const [selectedSummary, setSelectedSummary] = useState<Summary>();
  const auth = useAuth();
  const { formatMessage } = useIntl();

  const sort = useSort();

  const { opened, addOpened } = useSearchContext();

  return (
    <>
      <ReferencedFromDrawer
        selectedAct={selectedSummary?.relatedJudgementAct}
        clearSelectedAct={() => setSelectedSummary(undefined)}
        title={selectedSummary?.relatedJudgementAct.title}
      />

      <ResultsTable
        results={results}
        header={(params) => (
          <ResultsTableHeader {...params} actions={<SummariesActionsMenu {...params} />} />
        )}
        itemToSelection={itemToSelection}
        allowBulkLoad={auth.hasPrivilige(Privileges.EDIT_SUMMARIES)}
        row={(item) => (
          <ResultsTable.ResultTableRow>
            <PreloadingLink
              to={summaryHref(item.id)}
              preload={(cache) => prefetchSummary(item.id, cache)}
              onClick={() => addOpened(item.id)}
              className={opened.indexOf(item.id) > -1 ? "visited" : ""}
            >
              {printSummaryTitle(item)}
            </PreloadingLink>
            <div>{item.shortTitle}</div>
            {item.reporters?.length > 0 && (
              <LabelValue label={formatMessage({ id: "view-judgement.labels.reporter" })}>
                {item.reporters?.join(", ")}
              </LabelValue>
            )}
            <ResultsTable.RowIndicators item={item}>
              {sort?.column === "mentions" && (
                <Badge
                  bg="info"
                  title="Брой позовавания"
                  className="mentions"
                  onClick={() => setSelectedSummary(item)}
                >
                  {item.mentions}
                </Badge>
              )}
            </ResultsTable.RowIndicators>
            <ResultsTable.RowHighlights item={item} />
          </ResultsTable.ResultTableRow>
        )}
      />
    </>
  );
};

const SummariesActionsMenu: React.FC<UseMultipleSelectionReturn> = (props) => (
  <>
    <ChangeTopicOfDocument {...props} action={addTopicToSummary} actionType="add" />
    <ChangeTopicOfDocument {...props} action={removeTopicFromSummary} actionType="remove" />
    <ResultsTableDocumentCollectionMenuActions
      {...props}
      printExportHandler={async (first: any, rtf?: boolean) => {
        const summary = await fetchSummary(first._id);
        return rtf
          ? {
              contents: (await fetchSummaryRtf(summary!.id)).data,
              fileName: `${summary?.relatedJudgementAct.title}.doc`,
            }
          : {
              contents: (await fetchSummaryPdf(summary!.id)).data,
              fileName: `${summary?.relatedJudgementAct.title}.pdf`,
            };
      }}
    />
  </>
);

const itemToSelection = (item: Summary): Selection => ({
  _id: item.id,
  title: printSummaryTitle(item),
  href: summaryHref(item.id),
});

export default SummariesResults;
