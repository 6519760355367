import React, { useEffect, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaArrowCircleUp } from "react-icons/fa";
import "./scroll-top-arrow.css";

type ScrollTopArrowProps = {
  attachToWindow?: boolean;
};
const ScrollTopArrow: React.FC<ScrollTopArrowProps> = ({ attachToWindow = false }) => {
  const [showScroll, setShowScroll] = useState(false);

  const ref = useRef<HTMLSpanElement>(null);
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const checkScrollTop = () => {
    const span = ref.current;
    if (span) {
      const offset = span.parentElement!.scrollTop;
      if (!showScroll && Math.abs(offset) > 50) {
        setShowScroll(true);
      } else if (showScroll && Math.abs(offset) <= 50) {
        setShowScroll(false);
      }
    }
  };

  const checkScrollTopWin = () => {
    const offset = window.scrollY;
    if (!showScroll && Math.abs(offset) > 50) {
      setShowScroll(true);
    } else if (showScroll && Math.abs(offset) <= 50) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    const span = ref.current;
    if (attachToWindow) {
      window.addEventListener("scroll", checkScrollTopWin);
      return () => {
        window.removeEventListener("scroll", checkScrollTopWin);
      };
    } else {
      if (span && !isMobileOnly) {
        span.parentElement!.addEventListener("scroll", checkScrollTop);
        return () => {
          span.parentElement!.removeEventListener("scroll", checkScrollTop);
        };
      }
    }
  });

  const scrollTop = (e: any) => {
    if (attachToWindow) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    const span = ref.current;
    span && span.parentElement!.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <span ref={ref} className={`scrollTop ${attachToWindow ? "scrollTop-window" : ""}  col-md-1`}>
      <FaArrowCircleUp
        onClick={scrollTop}
        style={{ height: 30, width: 30, marginLeft: "auto", display: showScroll ? "flex" : "none" }}
      />
    </span>
  );
};

export default ScrollTopArrow;
