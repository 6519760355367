/* eslint-disable react/jsx-props-no-spreading */
import "react-bootstrap-typeahead/css/Typeahead.css";
import Card from "react-bootstrap/Card";
import img_newtopic from "../../../assets/images/thumbnails/newtopic.png";
import SubscribeTopicModal from "./subscribe-topic.modal";

const SubscribeToTopicCard = () => (
  <Card className="search-card subscribe-topic-card">
    <Card.Body>
      <Card.Img src={img_newtopic} variant="top" />
      <div>
        <Card.Title>Моята тема</Card.Title>
        <Card.Text>
          Добавете <SubscribeTopicModal />
        </Card.Text>
      </div>
    </Card.Body>
  </Card>
);

export default SubscribeToTopicCard;
