import FingerprintJS from "@fingerprintjs/fingerprintjs";
import React, { useCallback, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import { PrimaryButton, SecondaryButton } from "../components/Button/button";
import { LoggedUser, useAuth } from "../contexts/auth.context";
import { login } from "../services/auth.service";

const LoginModal: React.FC<{ links?: React.ReactNode; suspend?: boolean }> = ({
  links,
  suspend = false,
}) => {
  const { handleSubmit, register, watch, setError, setValue, formState, clearErrors } = useForm<{
    username: string;
    password: string;
    code: string;
  }>();
  const { setCurrentUser, setShowLoginModal, hasSuccessCallback, isLoginCancelable } = useAuth();
  const intl = useIntl();

  const addBlur = useCallback(() => {
    (document.querySelector(".router > div")! as HTMLElement).style.filter = "blur(2px)";
  }, []);
  const removeBlur = useCallback(() => {
    (document.querySelector(".router > div")! as HTMLElement).style.filter = "";
  }, []);

  const onSubmit = useCallback(
    async ({ username, password, code }: { username: string; password: string; code: string }) => {
      try {
        await doLogin(username, password, code, setCurrentUser, () => {
          setShowLoginModal(false);
          toast.info(intl.formatMessage({ id: "login.modal.toasts.login.success" }));
        });
      } catch (e: any) {
        if (e.status && e.status === 401) {
          setValue("password", "");
          setError("username", {
            type: "manual",
            message: intl.formatMessage({ id: "login.modal.form.errors.username" }),
          });
        } else if (e.status && e.status === 503) {
          setValue("password", "");
          setError("username", {
            type: "manual",
            message: intl.formatMessage({ id: "login.modal.form.errors.too-many-attempts" }),
          });
        } else if (e.status && e.status === 406) {
          setValue("password", "");
          setValue("code", "");
          setError("code", {
            type: "manual",
            message: intl.formatMessage(
              { id: "login.modal.form.errors.device-inactive" },
              { email: username }
            ),
          });
        }
      }
    },
    [setError, setValue, setShowLoginModal, setCurrentUser, intl]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<any>): void => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        clearErrors();
        handleSubmit(onSubmit)(event);
      }
    },
    [clearErrors, handleSubmit, onSubmit]
  );
  const username = watch("username");
  const password = watch("password");
  const code = watch("code");

  const onClose = useCallback(() => {
    setShowLoginModal(false, { successCallback: () => {} });
  }, [setShowLoginModal]);

  useEffect(() => {
    const element = scrollingElement();
    const top = element.offsetTop;
    element.style.position = "fixed";
    element.style.overflowY = "hidden";
    element.style.top = `${-top}`;
    element.style.height = `${top + 5000}`;
    !isLoginCancelable && addBlur();
    return () => {
      //var top = $(scrollingElement()).position().top;

      const element = scrollingElement();
      element.style.position = "relative";
      element.style.overflowY = "auto";
      element.style.top = "0";
      element.style.height = "";

      element.scrollTo({
        top: -top,
      });
      removeBlur();
    };
  }, [isLoginCancelable, addBlur, removeBlur]);

  const navigate = useNavigate();

  useTitle(intl.formatMessage({ id: "login.modal.header" }));

  return (
    <Modal
      show={!suspend}
      centered
      animation={false}
      keyboard
      //onShow={onOpen}
      onEscapeKeyDown={onClose}
      onHide={onClose}
      backdrop={isLoginCancelable ? true : "static"}
      fullscreen="sm-down"
    >
      <Modal.Header>
        <FormattedMessage id="login.modal.header" />
      </Modal.Header>
      <Modal.Body>
        {hasSuccessCallback && (
          <Alert variant="primary">
            Достъпът до избраната функционалност изисква вход с потребителско име и парола.
          </Alert>
        )}
        <Form autoComplete="off" noValidate>
          <Form.Group controlId="username">
            <Form.Label>
              <FormattedMessage id="login.modal.form.fields.username" />
            </Form.Label>
            <Form.Control type="text" {...register("username")} autoFocus onKeyDown={onKeyDown} />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>
              <FormattedMessage id="login.modal.form.fields.password" />
            </Form.Label>
            <Form.Control type="password" {...register("password")} onKeyDown={onKeyDown} />
          </Form.Group>

          {code !== undefined && (
            <Form.Group controlId="code">
              <Form.Label>
                <FormattedMessage id="login.modal.form.fields.code" />
              </Form.Label>
              <Form.Control type="text" {...register("code")} onKeyDown={onKeyDown} />
            </Form.Group>
          )}
          {formState.errors.code && (
            <Alert variant="danger">{formState.errors.code?.message}</Alert>
          )}
          {formState.errors.username && (
            <Alert variant="danger">{formState.errors.username?.message}</Alert>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        {links}
        {!isLoginCancelable && (
          <SecondaryButton
            messageId="login.modal.button.back-to-home"
            onClick={() => {
              setShowLoginModal(false, { successCallback: () => navigate("/") });
            }}
          />
        )}
        <PrimaryButton
          messageId="login.modal.button.login"
          onClick={(e: any) => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
          disabled={!username || !password || formState.isSubmitting}
          submitting={formState.isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};

const scrollingElement = () =>
  (document.scrollingElement as HTMLElement) || document.documentElement;

export async function doLogin(
  username: string,
  password: string,
  code: string | undefined,
  setCurrentUser: (user?: LoggedUser) => void,
  onSuccess: () => void
) {
  const result = await FingerprintJS.load().then(async (fp: any) => {
    const result = await fp.get();
    return result;
  });
  const user = await login(
    username,
    password,
    result.visitorId,
    JSON.stringify(result.components),
    code
  );

  setCurrentUser(user);
  onSuccess();
  //removeBlur();
}

export default LoginModal;
