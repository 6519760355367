import { useMemo } from "react";
import { v4 as uuid } from "uuid";
import Skeleton from "../../components/Skeleton";
import DocumentPageLayout, { DocumentPageLayoutProps } from "../DocumentPage/document-page-layout";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";

const NewsArticleViewPageLoading: React.FC<DocumentPageLayoutProps> = (props) => {
  return (
    <DocumentPageLayout {...props}>
      <Skeleton count={1} height="200px" />
      <Skeleton count={1} style={{ marginLeft: "60%", width: "40%", marginTop: "40px" }} />
      <Skeleton count={1} style={{ marginLeft: "10%", width: "90%" }} />
      <Skeleton count={3} />
      <Skeleton count={1} style={{ marginLeft: "10%", width: "90%" }} />
      <Skeleton count={10} />
    </DocumentPageLayout>
  );
};

export const NewsArticleQuickViewPageLoading: React.FC<{ onClose: () => void }> = (props) => {
  const id = useMemo(() => uuid(), []);
  return (
    <DocumentQuickViewLayout onClose={props.onClose} id={id}>
      <div className="w-100">
        <Skeleton count={10} />
      </div>
    </DocumentQuickViewLayout>
  );
};

export default NewsArticleViewPageLoading;
