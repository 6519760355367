import { useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaDownload, FaPrint, FaRegStar } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import useDocumentActionHandlers from "../../../hooks/useDocumentActionHandlers";
import { Selection, UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
const ResultsTableDocumentCollectionMenuActions: React.FC<
  UseMultipleSelectionReturn & {
    printExportHandler?: (
      firstSelected: Selection,
      rtf?: boolean
    ) => Promise<{
      contents: string;
      fileName: string;
    }>;
  }
> = ({ selection, clearAfterAction, printExportHandler }) => {
  const isAtLeastOneElementSelected = selection.size >= 1;
  const isExactlyOneElementSelected = selection.size === 1;
  const first = isExactlyOneElementSelected ? selection.values().next().value : null;

  const { addToFavourites, handlePrint, handleExport } = useDocumentActionHandlers({
    docs: Array.from(selection.values()),
    handlerDecorator: clearAfterAction,
    printExport: useCallback(async () => {
      return printExportHandler!(first, false);
    }, [printExportHandler, first]),
    rtfExport: useCallback(async () => {
      return printExportHandler!(first, true);
    }, [printExportHandler, first]),
  });

  return (
    <>
      <Dropdown.Item onClick={() => addToFavourites()} disabled={!isAtLeastOneElementSelected}>
        <FaRegStar /> <FormattedMessage id="document-actions.add-to-read-later" />
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => handlePrint()}
        disabled={!isExactlyOneElementSelected || !printExportHandler}
      >
        <FaPrint /> Принтирай
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => handleExport()}
        disabled={!isExactlyOneElementSelected || !printExportHandler}
      >
        <FaDownload /> Експорт в PDF
      </Dropdown.Item>
    </>
  );
};

export default ResultsTableDocumentCollectionMenuActions;
