import { saveAs } from "file-saver";
import { EntityModel } from "hateoas-hal-types";
import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { useFormContext } from "react-hook-form";
import { FaPlay, FaPowerOff } from "react-icons/fa";
import { FiSlash } from "react-icons/fi";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { InlineLinkButton } from "../../components/Button/button";
import LabelValue from "../../components/label-value";
import { useViewEditPageContext } from "../../contexts/view-edit-page.context";
import { Subscription } from "../../models/Subscription.model";
import { SubscriptionPeriod } from "../../models/SubscriptionPeriod.model";
import SubscriptionPeriodStatus from "../../models/SubscriptionPeriodStatus.enum";
import { formatInvoiceNumber } from "../../services/subscription-services";
import ActivateSubscriptionPeriodModal from "./activate-subscription-period.modal";
import AddPeriodModal, { SubscriptionPeriodModalMode } from "./add-period.modal";
import CancelSubscriptionPeriodModal from "./cancel-subscription-period.modal";
import StartSubscriptionPeriodModal from "./start-subscription-period.modal";

const PeriodsTable: React.FC<{
  value: SubscriptionPeriod[];
  append: (data: SubscriptionPeriod) => void;
  remove: (idx: number) => void;
  replace: (idx: number, data: SubscriptionPeriod) => void;
}> = ({ value, append, remove, replace }) => {
  const intl = useIntl();

  const [mode, setMode] = useState<SubscriptionPeriodModalMode>("hidden");
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>();
  const { mode: pageMode } = useViewEditPageContext();

  const [startPeriodId, setStartPeriodId] = useState<string>();
  const [activatePeriodId, setActivatePeriodId] = useState<string>();
  const [cancelPeriodId, setCancelPeriodId] = useState<string>();
  const { reset, watch } = useFormContext<EntityModel<Subscription>>();

  const subscription = watch();

  const firstScheduledPeriod = value.find(
    (period) => period.status === SubscriptionPeriodStatus.SCHEDULED
  );

  const firstAwaitingPaymentPeriod = value.find(
    (period) => period.status === SubscriptionPeriodStatus.AWAITING_PAYMENT
  );

  return (
    <>
      {cancelPeriodId && (
        <CancelSubscriptionPeriodModal
          onClose={() => setCancelPeriodId(undefined)}
          subscriptionId={subscription.id}
          periodId={cancelPeriodId}
          onUpdate={reset}
        />
      )}
      {startPeriodId && (
        <StartSubscriptionPeriodModal
          subscription={subscription}
          periodId={startPeriodId}
          onClose={() => setStartPeriodId(undefined)}
          onUpdate={reset}
        />
      )}
      {activatePeriodId && (
        <ActivateSubscriptionPeriodModal
          subscription={subscription}
          periodId={activatePeriodId}
          onClose={() => setActivatePeriodId(undefined)}
          onUpdate={reset}
        />
      )}
      <Tabs activeKey={"0"} className={`sub-section-tabs ${pageMode === "edit" ? "editable" : ""}`}>
        <Tab eventKey="0" disabled title={<FormattedMessage id="edit-subscription.form.periods" />}>
          {value.length === 0 ? (
            <span style={{ padding: "12px" }}>
              <FormattedMessage id="edit-subscription.form.periods.no-data" />
            </span>
          ) : (
            <Table responsive style={{ width: "100%" }} className="sub-section-table">
              <colgroup>
                <col span={1} />
                <col span={1} style={{ width: "50px" }} />
              </colgroup>

              <tbody>
                {[...value].map((v, i) => (
                  <tr key={i}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedPeriod(v);
                        setMode("edit");
                      }}
                    >
                      <div className="d-flex w-100">
                        <span className="d-flex flex-column">
                          <span>
                            <FormattedDate
                              value={v.start}
                              month="long"
                              day="2-digit"
                              year="numeric"
                            />
                            &nbsp;-&nbsp;
                            <FormattedDate
                              value={v.end}
                              month="long"
                              day="2-digit"
                              year="numeric"
                            />
                          </span>
                          <div className="d-flex" style={{ gap: "5px" }}>
                            {v.invoices.length > 0 && (
                              <LabelValue
                                className="d-flex align-items-end"
                                label={intl.formatMessage({
                                  id: "subcription-edit-page.labels.periods.invoices",
                                })}
                              >
                                {v.invoices.map((invoice) => (
                                  <>
                                    <InlineLinkButton
                                      onClick={() => {
                                        saveAs(invoice.contents, invoice.fileName);
                                      }}
                                      className="me-1"
                                    >
                                      {formatInvoiceNumber(invoice)}
                                      {invoice.proforma &&
                                        `(${intl.formatMessage({
                                          id: "subcription-edit-page.labels.periods.proforma",
                                        })})`}
                                    </InlineLinkButton>
                                  </>
                                ))}
                              </LabelValue>
                            )}
                          </div>
                        </span>
                        <span className="ms-auto">
                          <Badge
                            bg={
                              v.status === SubscriptionPeriodStatus.PAID
                                ? "success"
                                : v.status === SubscriptionPeriodStatus.SCHEDULED
                                ? "info"
                                : v.status === SubscriptionPeriodStatus.CANCELED
                                ? "secondary"
                                : "warning"
                            }
                          >
                            <FormattedMessage id={`subscription-period.status.${v.status}`} />
                          </Badge>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        {v.status === SubscriptionPeriodStatus.SCHEDULED &&
                          !firstAwaitingPaymentPeriod &&
                          firstScheduledPeriod?.id === v.id && (
                            <Button
                              className="p-1 d-flex align-items-center"
                              variant="link"
                              onClick={() => {
                                setStartPeriodId(v.id);
                              }}
                              title={intl.formatMessage({ id: "button.remove" })}
                            >
                              <FaPowerOff />
                            </Button>
                          )}
                        {v.status === SubscriptionPeriodStatus.AWAITING_PAYMENT && (
                          <Button
                            className="p-1 d-flex align-items-center"
                            variant="link"
                            onClick={() => {
                              setActivatePeriodId(v.id);
                            }}
                            title={intl.formatMessage({ id: "button.remove" })}
                          >
                            <FaPlay />
                          </Button>
                        )}
                        {(v.status === SubscriptionPeriodStatus.AWAITING_PAYMENT ||
                          v.status === SubscriptionPeriodStatus.SCHEDULED) && (
                          <Button
                            className="p-1 d-flex align-items-center"
                            variant="link"
                            onClick={() => {
                              setCancelPeriodId(v.id);
                            }}
                            title={intl.formatMessage({ id: "button.remove" })}
                          >
                            <FiSlash />
                          </Button>
                        )}
                      </div>
                    </td>
                    {/* <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        const idx = value.findIndex((existingPeriod) => existingPeriod.id === v.id);
                        remove(idx);
                      }}
                      title={intl.formatMessage({ id: "button.remove" })}
                      className="remove-link"
                    >
                      <FaTimes />
                    </Button>
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab>

        {
          /*pageMode === "edit"*/ 1 > 2 && (
            <Tab
              eventKey="-1"
              title={
                <AddPeriodModal
                  append={(beneficiary) => {
                    const idx = value.findIndex(
                      (existingPeriod) => existingPeriod.id === beneficiary.id
                    );
                    if (idx > -1) {
                      replace(idx, { ...beneficiary });
                    } else {
                      append(beneficiary);
                    }
                  }}
                  period={selectedPeriod}
                  setSelectedPeriod={setSelectedPeriod}
                  mode={mode}
                  setMode={setMode}
                />
              }
            />
          )
        }
      </Tabs>
    </>
  );
};

export default PeriodsTable;
