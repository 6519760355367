import React from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useIntl } from "react-intl";
import SimpleSelect from "../../components/SimpleSelect";
import SummaryTopic from "../../models/SummaryTopic.model";
import { useCurrentUser } from "../../services/user-services";
import ResponsiveFilter from "../SearchPage/FiltersSidebar/responsive-filter";

export type TimelineTopicSelectProps = {
  selectedTopic?: SummaryTopic;
  setSelectedTopic: (summaryTopic?: SummaryTopic) => void;
};

const TimelineTopicSelect: React.FC<TimelineTopicSelectProps> = ({
  selectedTopic,
  setSelectedTopic,
}) => {
  return (
    <TimelineTopicSelectComponent
      selectedTopic={selectedTopic}
      setSelectedTopic={setSelectedTopic}
    />
  );
};

const TimelineTopicSelectComponent: React.FC<TimelineTopicSelectProps> = ({
  selectedTopic,
  setSelectedTopic,
}) => {
  const currentUser = useCurrentUser();
  const intl = useIntl();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  return (
    <ResponsiveFilter>
      <SimpleSelect
        className="inline-select"
        options={currentUser?.topics || []}
        getOption={(topic) => ({
          label: topic.shortName,
          value: topic.id + "",
          subLabel: topic.name,
        })}
        value={selectedTopic ? [selectedTopic] : []}
        onChange={(selection) => setSelectedTopic(selection[0])}
        isClearable={!isMobileOnly}
        clearAllOption={{
          label: "Всички теми",
          subLabel: "Всички теми",
          value: undefined,
        }}
        isMulti={false}
        ItemRenderer={({ checked, option, onClick, disabled }: any) => (
          <div
            className={`item-renderer ${disabled && "disabled"}`}
            onClick={(e) => {
              onClick(e);
              (document.activeElement as HTMLElement)?.blur();
            }}
          >
            <span className="title">{option.label}</span>
            <span className="sub-title">{option.subLabel}</span>
          </div>
        )}
        overrideStrings={{
          noOptions: "Нямате абонамент за теми",
          selectSomeItems: intl.formatMessage({ id: "home-page.sections.all-topics" }),
        }}
        placeholder="Избор на тема"
      />
    </ResponsiveFilter>
  );
};

export default TimelineTopicSelect;
