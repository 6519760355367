import { useCallback } from "react";
import { FormText } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import { PrimaryButton, SecondaryButton } from "../../components/Button/button";
import SubscriptionTypeCode from "../../models/SubscriptionTypeCode.enum";
import { renewSubscription } from "../../services/subscription-services";
type RegistrationModalProps = {
  onClose: () => void;
  renewedSubscriptionId: string;
};

const ExtendSubscriptionModal: React.FC<RegistrationModalProps> = ({
  onClose,
  renewedSubscriptionId,
}) => {
  const { handleSubmit, formState, clearErrors, register } = useForm<{
    type: SubscriptionTypeCode;
  }>({
    defaultValues: {
      type: SubscriptionTypeCode.ANNUAL,
    },
  });
  const intl = useIntl();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await renewSubscription(data, renewedSubscriptionId);
        toast.success(
          intl.formatMessage(
            {
              id: "extend-subscription.modal.success",
            },
            data
          )
        );
        onClose();
      } catch (e: any) {
        toast.error(
          "Грешка при изпращане на заявката! Моля опитайте отново или ни пишете на info@lexebra.com"
        );
      }
    },
    [intl, onClose, renewedSubscriptionId]
  );

  useTitle(intl.formatMessage({ id: "extend-subscription.modal.header" }));

  return (
    <>
      <Modal
        show={true}
        centered
        animation={false}
        keyboard
        onEscapeKeyDown={onClose}
        onHide={onClose}
        backdrop={true}
        size="lg"
        fullscreen="sm-down"
      >
        <Modal.Header>
          <FormattedMessage id="extend-subscription.modal.header" />
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off" noValidate>
            <Form.Group controlId="subscription">
              <Form.Label>
                <FormattedMessage id="extend-subscription.modal.form.fields.subscriptions" />
              </Form.Label>
              <Form.Select {...register("type")}>
                <option value={SubscriptionTypeCode.ANNUAL_MONTHLY_PAYMENT}>
                  Годишен абонамент с предплащане за всеки месец за 145 лв/месец
                </option>
                <option value={SubscriptionTypeCode.ANNUAL}>
                  Годишен абонамент с предплащане за целия период за 95 лв/месец
                </option>
              </Form.Select>
              <FormText className="text-secondary">
                <FormattedMessage
                  id="extend-subscription.modal.form.fields.info"
                  values={{
                    br: <br />,
                  }}
                />
              </FormText>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton
            messageId="button.cancel"
            onClick={onClose}
            disabled={formState.isSubmitting}
          />
          <PrimaryButton
            messageId="button.confirm"
            onClick={(e: any) => {
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
            submitting={formState.isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExtendSubscriptionModal;
