import { useMemo } from "react";
import { FaArrowCircleRight, FaExclamationCircle } from "react-icons/fa";
import { useSummaryEditContext } from "../../contexts/summary-edit.context";
import { useSummaryQaContext } from "../../contexts/summary-qa.context";
import RichTextEditor from "./rich-text-editor";

const SummaryRichTextEditor: React.FC<any> = (props) => {
  const { setModeAddProvisionModal } = useSummaryEditContext();
  const { setModeAddRelatedActModal } = useSummaryQaContext();

  // const handlers = useMemo(
  //   () => ({
  //     addAct: (quill: ReactQuill) => {
  //       setModeAddRelatedActModal("add");
  //     },
  //     addProvision: (quill: ReactQuill) => {
  //       setModeAddProvisionModal("select");
  //     },
  //   }),
  //   [setModeAddProvisionModal, setModeAddRelatedActModal]
  // );

  return (
    <RichTextEditor
      {...props}
      //handlers={handlers}
      buttons={useMemo(
        () => (
          <>
            <button
              className="ql-addProvision"
              title="Добави разпоредба"
              onClick={() => setModeAddProvisionModal("select", window.getSelection()?.toString())}
            >
              <FaExclamationCircle />
            </button>
            <button
              className="ql-addAct"
              title="Добави свързан акт"
              onClick={() => setModeAddRelatedActModal("add", window.getSelection()?.toString())}
            >
              <FaArrowCircleRight />
            </button>
          </>
        ),
        [setModeAddRelatedActModal, setModeAddProvisionModal]
      )}
    />
  );
};

export default SummaryRichTextEditor;
