import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import { FaPenSquare, FaProductHunt, FaStar, FaTag } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { GiEuropeanFlag } from "react-icons/gi";
import { RiFocus3Line } from "react-icons/ri";
import { SiPalantir } from "react-icons/si";
import { ReactComponent as AiIcon } from "../../assets/ai.svg";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { isDocumentInCollection, useUserDocuments } from "../../services/user-services";
import "./indicator-icons.scss";

const IndicatorIcons: React.FC<{
  item: {
    relatedToSpecialGroundOfAppeal?: boolean;
    hasDissentingOpinion?: boolean;
    hasEditorialOpinion?: boolean;
    relatedToEuLegislation?: boolean;
    status?: DocumentStatus;
  };
}> = ({
  item: {
    relatedToSpecialGroundOfAppeal,
    hasDissentingOpinion,
    hasEditorialOpinion,
    relatedToEuLegislation,
    status,
  },
}) => {
  const onFocus = status === DocumentStatus.FOCUSED;
  return (
    <div
      className={`indicator-icons ${
        !onFocus &&
        !relatedToSpecialGroundOfAppeal &&
        !relatedToEuLegislation &&
        !hasDissentingOpinion &&
        !hasEditorialOpinion
          ? "empty"
          : ""
      }`}
    >
      {onFocus && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="focus-tooltip" {...props}>
              На фокус
            </Tooltip>
          )}
        >
          <span data-title="На фокус">
            <RiFocus3Line />
          </span>
        </OverlayTrigger>
      )}
      {relatedToSpecialGroundOfAppeal && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="legdev-tooltip" {...props}>
              Развитие на правото
            </Tooltip>
          )}
        >
          <span data-title="Развитие на правото">
            <FiAlertCircle />
          </span>
        </OverlayTrigger>
      )}
      {relatedToEuLegislation && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="euleg-tooltip" {...props}>
              Връзка с европейско законодателтво
            </Tooltip>
          )}
        >
          <span data-title="Връзка с европейско законодателтво">
            <GiEuropeanFlag />
          </span>
        </OverlayTrigger>
      )}
      {hasDissentingOpinion && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="dissopp-tooltip" {...props}>
              Има особено мнение
            </Tooltip>
          )}
        >
          <span data-title="Има особено мнение">
            <AiOutlineUsergroupDelete />
          </span>
        </OverlayTrigger>
      )}
      {hasEditorialOpinion && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="editopp-tooltip" {...props}>
              Има редакторска бележка
            </Tooltip>
          )}
        >
          <span data-title="Има редакторска бележка">
            <SiPalantir style={{ opacity: 0.9 }} />
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export const UserIndicatorIcons: React.FC<{
  item: {
    id: string;
    hasAnnotations?: boolean;
    hasAiAnnotations?: boolean;
    hasSummary?: boolean;
    hasEditorAnnotation?: boolean;
    favouritesId?: string;
  };
}> = ({
  item: { id, favouritesId, hasAnnotations, hasSummary, hasEditorAnnotation, hasAiAnnotations },
}) => {
  const { favourite } = useUserDocuments();
  return (
    <span className="d-flex flex-column">
      {isDocumentInCollection(favouritesId || id, favourite) && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="fav-tooltip" {...props}>
              {favourite.find((doc) => doc.href!.endsWith(favouritesId || id))?.category}
            </Tooltip>
          )}
        >
          <span>
            <FaStar />
          </span>
        </OverlayTrigger>
      )}
      {hasAnnotations && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="anno-tooltip" {...props}>
              Актът е с подчертаване
            </Tooltip>
          )}
        >
          <span>
            <FaTag />
          </span>
        </OverlayTrigger>
      )}
      {hasAiAnnotations && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="anno-tooltip" {...props}>
              Актът е с автоматично подчертаване
            </Tooltip>
          )}
        >
          <span className="ai">
            <AiIcon />
          </span>
        </OverlayTrigger>
      )}
      {hasEditorAnnotation && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="editor-annotation-tooltip" {...props}>
              Актът е анотиран
            </Tooltip>
          )}
        >
          <span>
            <FaPenSquare />
          </span>
        </OverlayTrigger>
      )}
      {hasSummary && (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="summary-tooltip" {...props}>
              Актът е с резюме
            </Tooltip>
          )}
        >
          <span>
            <FaProductHunt />
          </span>
        </OverlayTrigger>
      )}
    </span>
  );
};

export default IndicatorIcons;
