import { useCallback, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { useTitle } from "react-use";
import { PrimaryButton } from "../components/Button/button";
import { useAuth } from "../contexts/auth.context";

const ErrorModal = () => {
  const { setShowLoginModal } = useAuth();
  const intl = useIntl();

  const addBlur = useCallback(() => {
    (document.querySelector(".router > div")! as HTMLElement).style.filter = "blur(2px)";
  }, []);
  const removeBlur = useCallback(() => {
    (document.querySelector(".router > div")! as HTMLElement).style.filter = "";
  }, []);

  const onClose = useCallback(() => {
    setShowLoginModal(false, { successCallback: () => {} });
  }, [setShowLoginModal]);

  useEffect(() => {
    const element = scrollingElement();
    const top = element.offsetTop;
    element.style.position = "fixed";
    element.style.overflowY = "hidden";
    element.style.top = `${-top}`;
    element.style.height = `${top + 5000}`;
    addBlur();
    return () => {
      const element = scrollingElement();
      element.style.position = "relative";
      element.style.overflowY = "auto";
      element.style.top = "0";
      element.style.height = "";

      element.scrollTo({
        top: -top,
      });
      removeBlur();
    };
  }, [addBlur, removeBlur]);

  useTitle(intl.formatMessage({ id: "error.modal.header" }));

  return (
    <Modal
      show={true}
      centered
      animation={false}
      keyboard
      onEscapeKeyDown={onClose}
      onHide={onClose}
      backdrop={"static"}
      fullscreen="sm-down"
    >
      <Modal.Header>
        <FormattedMessage id="error.modal.header" />
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          Съжаляваме, възникна неочаквана грешка 5005. Опитайте да презаредите страницата с бутона
          по-долу. При неуспех, моля, пишете ни на support@lexebra.com
        </Alert>
      </Modal.Body>

      <Modal.Footer>
        <PrimaryButton
          messageId="error.modal.button.reload"
          onClick={(e: any) => {
            window.location.reload();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

const scrollingElement = () =>
  (document.scrollingElement as HTMLElement) || document.documentElement;

export default ErrorModal;
