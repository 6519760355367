/* eslint-disable react/prop-types */

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaCaretDown, FaTimes } from "react-icons/fa";
import { MultiSelect } from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/types/lib/interfaces";
import "./simple-select.scss";

export interface SourcedOption<T> extends Option {
  source: T;
}

interface SimpleSelectProps<T> {
  getOption?: (option: T) => Option;
  options?: T[];
  value: T[];
  onChange: (selection: T[]) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  disableSearch?: boolean;
  overrideStrings?: {
    [key: string]: string;
  };
  ItemRenderer?: any;
  valueRenderer?: (selected: Option[], options: Option[]) => React.ReactNode;
  defaultIsOpen?: boolean;
  onMenuToggle?: (expanded: boolean) => any;
  filterOptions?: ((options: Option[], filter: string) => Option[] | Promise<Option[]>) | undefined;
  placeholder?: string;
  className?: string;
  clearAllOption?: Option & { subLabel?: string };
  closeOnSelection?: boolean;
}

const SimpleSelect = <T extends unknown>({
  options = [],
  onChange = () => {},
  isMulti = true,
  isClearable = false,
  isDisabled = false,
  isCreatable = false,
  getOption = (option) => option as Option,
  value = [],
  disableSearch = true,
  defaultIsOpen = false,
  overrideStrings,
  ItemRenderer,
  valueRenderer,
  onMenuToggle,
  filterOptions,
  className = "",
  clearAllOption,
  closeOnSelection = true,
}: SimpleSelectProps<T>) => {
  const getSourcedOption = (source: T) => ({
    ...getOption(source),
    source,
  });
  const valueOptions = value.map(getSourcedOption);
  const sourcedOptions: any = options.map(getSourcedOption);
  if (clearAllOption) {
    sourcedOptions.unshift(clearAllOption);
    if (valueOptions.length === 0) {
      valueOptions.push({ ...clearAllOption, source: undefined as any });
    }
  }
  if (isCreatable) {
    sourcedOptions.push({
      value: "",
      label: overrideStrings?.createNew || "Създай нов",
      source: "" as any,
      className: "fst-italic",
    });
  }
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  return (
    <MultiSelect
      className={`${className} noSelect ${isDisabled ? "disabled" : ""} ${
        isMobileOnly ? "mobile-only" : ""
      }`}
      options={sourcedOptions}
      value={valueOptions}
      disabled={isDisabled}
      hasSelectAll={false}
      onChange={(selection: SourcedOption<T>[]) => {
        const selectAll = selection.find(
          (sel) => sel.value?.indexOf && sel.value.indexOf("!#!") > -1
        );
        if (selectAll) {
          const selected = selectAll.value.split("!#!");
          onChange(
            selected.map((v: any) => {
              return { value: v };
            })
          );
        } else {
          onChange(
            selection
              .filter((item) => isMulti || !valueOptions.map((v) => v.value).includes(item.value))
              .map((item) => item.source || item.value)
          );
        }
      }}
      onMenuToggle={(expanded: boolean) => {
        document.querySelectorAll(".rmsc .search input").forEach((element) => {
          (element as HTMLElement).onclick = () => {
            element.setAttribute("inputmode", "text");
          };
        });
        onMenuToggle && onMenuToggle(expanded);
      }}
      isOpen={defaultIsOpen}
      defaultIsOpen={defaultIsOpen}
      disableSearch={disableSearch}
      labelledBy="Select"
      valueRenderer={
        valueRenderer ? valueRenderer : (value) => value.map((s) => s.label).join(", ")
      }
      ArrowRenderer={() => (
        <span className="arrow">
          <FaCaretDown />
        </span>
      )}
      ClearSelectedIcon={isClearable ? <FaTimes /> : <span />}
      ItemRenderer={
        ItemRenderer
          ? ItemRenderer
          : ({ checked, option, onClick, disabled }: any) => (
              <div
                className={`item-renderer ${disabled && "disabled"} ${
                  (option as any).className || ""
                }`}
                onClick={(e) => {
                  onClick(e);
                  closeOnSelection && (document.activeElement as HTMLElement)?.blur();
                }}
              >
                {option.label}
              </div>
            )
      }
      filterOptions={
        filterOptions
          ? filterOptions
          : isMulti
          ? (options, filter) => {
              return options.filter(
                (option) =>
                  !valueOptions.map((v) => v.value).includes(option.value) &&
                  option.label.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase())
              );
            }
          : undefined
      }
      overrideStrings={overrideStrings}
    />
  );
};

export default SimpleSelect;
