import { CollectionModel } from "hateoas-hal-types";
import memoizee from "memoizee";
import useRequest, { ApiResponse, del, get, mutate, post, put } from "../api";
import RefData from "../models/RefData.model";
import SummaryTopic from "../models/SummaryTopic.model";

export const NO_TOPIC_ID = 99999;

export const useNoTopic = () => {
  return {
    id: NO_TOPIC_ID,
    code: "Без тема",
    shortName: "Без тема",
    name: "Без тема",
    rank: NO_TOPIC_ID,
    summariesCount: 1184,
  } as SummaryTopic;
};

export interface HierarchicalLabelValue<T> {
  label: string;
  shortName: string;
  title: string;
  value: string;
  parent: T | undefined;
  children: HierarchicalLabelValue<T>[] | undefined;
  ancestors: string[];
  ref: SummaryTopic;
  disabled: boolean;
  summariesCount: number;
  rank: number;
}

const identityMapper = <T>(data: T): T => data;

export const updateSummaryTopicsTree = async (data: any) => {
  const result = (await put("/ref/summary-topics", data)) as SummaryTopic;
  await mutate("/ref/summary-topics", fetchSummaryTopics, false);
  return result;
};

export const addSummaryTopic = async (data: SummaryTopic) => {
  const result = (await post("/ref/summary-topics", data)) as SummaryTopic;
  await mutate("/ref/summary-topics", result, false);
  return result;
};

export const updateSummaryTopic = async (topicId: number, data: SummaryTopic) => {
  const result = (await put(`/ref/summary-topics/${topicId}`, data)) as SummaryTopic;
  await mutate("/ref/summary-topics", fetchSummaryTopics, false);
  return result;
};

export const removeSummaryTopic = async (topicId: number) => {
  await del(`/ref/summary-topics/${topicId}`, {}, true);
  await mutate("/ref/summary-topics", fetchSummaryTopics, false);
};

export const useSummaryTopics = (): ApiResponse<SummaryTopic[]> =>
  useRequest<SummaryTopic[]>("/ref/summary-topics", (data) => data?._embedded.items || []);

export const fetchSummaryTopics = () => get("/ref/summary-topics");

const emptyTree = { tree: [], nodes: new Map<number, HierarchicalLabelValue<SummaryTopic>>() };

export const useSummaryTopicsTree = (input?: {
  disabledTopics?: RefData[];
  countsPerTopic?: any;
}) => {
  const response = useRequest<CollectionModel<SummaryTopic>>("/ref/summary-topics", identityMapper);
  return response.data
    ? buildTopicsTree(response.data, input?.disabledTopics, input?.countsPerTopic)
    : emptyTree;
};

const nodesSorter = (
  a: HierarchicalLabelValue<SummaryTopic>,
  b: HierarchicalLabelValue<SummaryTopic>
) => a.rank - b.rank;

const buildAncestorsArray = (
  nodes: Map<number, HierarchicalLabelValue<SummaryTopic>>,
  item: HierarchicalLabelValue<SummaryTopic>
) => {
  let current = item;
  const result = [];
  while (current.parent) {
    result.push(current.parent!.id + "");
    current = nodes.get(current.parent.id as number)!;
  }
  return result;
};

const buildTopicsTree = memoizee(
  (data: CollectionModel<SummaryTopic>, disabledTopics?: RefData[], countsPerTopic?: any) => {
    const roots = data?._embedded.items.filter((item) => !item.parent).map((item) => item.id);
    const nodes = new Map<number, HierarchicalLabelValue<SummaryTopic>>();

    data?._embedded.items.forEach((item) => {
      const count = countsPerTopic
        ? countsPerTopic[item.id]
          ? ` (${countsPerTopic[item.id]})`
          : ""
        : item.summariesCount
        ? ` (${item.summariesCount})`
        : "";
      nodes.set(item.id as number, {
        label: item.shortName + count,
        title: item.shortName + count,
        summariesCount: item.summariesCount,
        rank: item.rank,
        value: item.id + "",
        parent: item.parent,
        children: [],
        ancestors: [],
        ref: item,
        shortName: item.shortName,
        disabled: !!disabledTopics && disabledTopics.some((dt) => dt.id === item.id),
      });
    });
    nodes.forEach((value, key, map) => {
      if (value.parent) {
        map.get(value.parent.id as number)!.children!.push(value);
      }
    });
    nodes.forEach((value) => {
      if (!value.children?.length) {
        delete value.children;
      } else {
        value.children.sort(nodesSorter);
      }
      value.ancestors = buildAncestorsArray(nodes, value);
    });
    return {
      tree: roots?.map((id) => nodes.get(id as number)!).sort(nodesSorter),
      nodes,
    };
  },
  {
    normalizer: (args: any[]) => JSON.stringify(args),
  }
);
