import { useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { v4 as uuid } from "uuid";
import { InlineLinkButton } from "../../../components/Button/button";
import { useLoadingProgress } from "../../../contexts/loading-progress.context";
import useBodyScrollLock from "../../../hooks/useBodyScrollLock";
import ReferencedJudgementAct from "../../../models/ReferencedJudgementAct.model";
import { useJudgementActReferences } from "../../../services/judgement-act-services";
import { prefetchSummary, summaryHref } from "../../../services/summary-services";
import DocumentQuickViewLayout from "../../DocumentPage/document-quick-view-layout";
import "./referenced-from-drawer.scss";

const ReferencedFromDrawer = ({
  selectedAct,
  clearSelectedAct,
  title,
}: {
  selectedAct?: ReferencedJudgementAct;
  clearSelectedAct: () => void;
  title?: string;
}) => {
  const { formatMessage } = useIntl();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const progress = useLoadingProgress();
  const { cache } = useSWRConfig();
  const navigate = useNavigate();

  const references = useJudgementActReferences(selectedAct?.id);

  useBodyScrollLock(!!selectedAct);

  const id = useMemo(() => uuid(), []);

  return (
    <Offcanvas
      id="quick-view-drawer"
      show={!!references}
      onHide={clearSelectedAct}
      backdrop
      placement={isMobileOnly ? "bottom" : "end"}
      scroll
      className={`quick-view-drawer ${isMobileOnly ? "mobile-view" : ""}`}
    >
      <Offcanvas.Body>
        <DocumentQuickViewLayout
          id={id}
          title={formatMessage({ id: "referenced-from-drawer.heading" }, { title })}
          className="referenced-from-drawer"
          onClose={clearSelectedAct}
        >
          <ul>
            {references?.referencedFrom.map((summary) => (
              <li key={summary.id}>
                <InlineLinkButton
                  onClick={async () => {
                    if (isMobileOnly) {
                      progress.start();
                      try {
                        await prefetchSummary(summary.id, cache);
                      } finally {
                        progress.complete();
                      }
                      navigate(summaryHref(summary.id));
                    } else {
                      window.open(summaryHref(summary.id), "_blank");
                    }
                  }}
                >
                  {summary.shortTitle}
                </InlineLinkButton>
              </li>
            ))}
          </ul>
        </DocumentQuickViewLayout>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ReferencedFromDrawer;
