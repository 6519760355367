import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaRegPlusSquare } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ActionButton, InlineLinkButton, SecondaryButton } from "../../../components/Button/button";
import TopicSelect from "../../../components/TopicSelect/topic-select";
import SummaryTopic from "../../../models/SummaryTopic.model";
import { subscribeToTopic, useCurrentUser } from "../../../services/user-services";

const SubscribeTopicModal = () => {
  const intl = useIntl();
  const currentUser = useCurrentUser();

  const [selectedTopic, setSelectedTopic] = useState<SummaryTopic[]>([]);
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hideModal = () => {
    setShow(false);
    setSelectedTopic([]);
  };

  const cancelHandler = () => {
    hideModal();
  };
  const confirmHandler = async () => {
    try {
      setIsSubmitting(true);
      await subscribeToTopic(selectedTopic[0]);
      toast.success(
        intl.formatMessage(
          { id: "subscribe-topic.modal.toasts.subscribe.success" },
          { topic: selectedTopic[0].name }
        )
      );
    } catch (e) {
      console.log(e);
      toast.error(
        intl.formatMessage(
          { id: "subscribe-topic.modal.toasts.subscribe.error" },
          { topic: selectedTopic[0].name }
        )
      );
    } finally {
      setIsSubmitting(false);
    }
    hideModal();
  };

  return (
    <>
      {
        <InlineLinkButton
          onClick={() => setShow(true)}
          style={{ verticalAlign: "baseline", fontSize: "0.8rem" }}
        >
          <FaRegPlusSquare size={12} title="Добавяне на тема" />
        </InlineLinkButton>
      }
      <Modal show={show} centered size="lg" fullscreen="sm-down" animation={false}>
        <Modal.Header>
          <FormattedMessage id="subscribe-topic.modal.header" />
        </Modal.Header>
        <Modal.Body style={{ height: "400px", overflowY: "auto" }}>
          <TopicSelect
            checkedNodes={selectedTopic}
            setCheckedNodes={(newSelection) =>
              setSelectedTopic(newSelection.filter((n) => !selectedTopic.includes(n)))
            }
            disabledNodes={currentUser?.topics || []}
            autoFocus
          />
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton messageId="button.cancel" onClick={cancelHandler} />
          <ActionButton
            messageId="button.confirm"
            onClick={confirmHandler}
            disabled={selectedTopic.length === 0 || isSubmitting}
            submitting={isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscribeTopicModal;
