/* eslint-disable no-underscore-dangle */
import { useMemo } from "react";
import CheckboxTree, { Node as CheckboxTreeNode } from "react-checkbox-tree";
import { isMobileOnly } from "react-device-detect";
import { FaQuestionCircle, FaRegFileAlt, FaTag } from "react-icons/fa";
import ErrorBoundary from "../../components/ErrorBoundary";
import Collapse from "../../components/SimpleCollapse";
import Skeleton from "../../components/Skeleton";
import { useHighlightContext } from "../../contexts/highlight.context";
import { useNavigationDrawerContext } from "../../contexts/navigation-drawer.context";
import { CollapsibleFiltersPanel } from "../SearchPage/FiltersSidebar/filters";
import { RecogitoAnno, scrollAndHighlight } from "./judgmenet-view-page-metadata";

type TableOfContentsTabProps = {
  annotations?: RecogitoAnno[];
};

const TableOfContentsTab: React.FC<TableOfContentsTabProps> = ({ annotations }) => {
  return (
    <Collapse icon={<FaTag />} label="Редакторско подчертаване">
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <TableOfContentsTabBody annotations={annotations} />
      </ErrorBoundary>
    </Collapse>
  );
};

const TableOfContentsTabBody: React.FC<TableOfContentsTabProps> = ({ annotations }) => {
  const toc = useMemo(
    () =>
      annotations!.map((anno) => {
        const body = anno.body.find((body) => body.purpose === "tagging");
        return {
          label: (
            <div>
              {body?.value === "Въпрос"
                ? body?.value +
                  ": " +
                  normalizeQuestion(
                    anno.target.selector.find((selector) => !!selector.exact)!.exact
                  )
                : body?.value}
            </div>
          ),
          value: anno.id,
          icon: body?.value === "Въпрос" ? <FaQuestionCircle /> : <FaRegFileAlt />,
          children: [],
        };
      }),
    [annotations]
  );

  const expandedNodes = useMemo(() => Array.from(traverse(toc, new Set())), [toc]);
  const navigationDrawerContext = useNavigationDrawerContext();
  const { includeEditorAnnotations } = useHighlightContext();

  return (
    <CollapsibleFiltersPanel
      collapseHeight="250px"
      openedLabel="Покажи по-малко"
      closedLabel="Покажи всичко"
      initiallyOpened
      key={annotations?.length}
    >
      <CheckboxTree
        nodes={toc}
        checked={[]}
        expanded={expandedNodes}
        noCascade
        expandDisabled
        onCheck={(node) => {
          isMobileOnly && navigationDrawerContext && navigationDrawerContext.setIsOpen(false);
          scrollAndHighlight(node[0], includeEditorAnnotations ? "rgb(247, 249, 255)" : undefined);
        }}
      />
    </CollapsibleFiltersPanel>
  );
};

const traverse = (nodes: CheckboxTreeNode[], accumulator: Set<string>) => {
  nodes.forEach((n) => {
    accumulator.add(n.value);
    n.children && traverse(n.children, accumulator);
  });
  return accumulator;
};

const normalizeQuestion = (text: string) => {
  text = text.replace(/^въпрос(а|ът|ите|-)?,?\s*/i, "");
  text = text.charAt(0).toUpperCase() + text.slice(1);
  return text.endsWith(".") ? text.replace(/\.$/, "?") : text.endsWith("?") ? text : text + "?";
};

export const TableOfContentsTabLoading = () => {
  return <Skeleton count={10} />;
};

export default TableOfContentsTab;
