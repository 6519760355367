import "@recogito/recogito-js/dist/recogito.min.css";
import React, { useEffect } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCheck, FaEraser, FaTimes } from "react-icons/fa";
import RichTextEditor from "../../components/RichTextEditor";
import { UserDocumentAnno } from "../../models/UserDocumentAnno.model";
import { addUserDocumentAnno, delUserDocumentAnno } from "../../services/user-services";
import { getFirstComment } from "./judgement-view-page";
import "./judgement-view-page.scss";

const EditAnnotationNotePopover: React.FC<{
  actId: string;
  recogito: any;
  target?: [any, HTMLElement];
  closePopover: () => void;
  updateAnnotations: (annotations: UserDocumentAnno[]) => void;
}> = ({ recogito, target, actId, closePopover, updateAnnotations }) => {
  const annotation = target?.[0];

  const annoNote = getFirstComment(annotation)?.value || "";

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { isSubmitting, isDirty },
  } = useForm<{
    note: string;
  }>({
    defaultValues: {
      note: annoNote,
    },
  });

  useEffect(() => {
    reset({
      note: annoNote,
    });
  }, [reset, annoNote]);

  const note = watch("note");

  return target ? (
    <Overlay
      target={target[1]}
      show={!!target}
      placement="top"
      popperConfig={{
        strategy: "fixed",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -4],
            },
          },
        ],
      }}
    >
      <Tooltip id="note-tooltip">
        <Controller
          name="note"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RichTextEditor
              placeholder="Въведете текст..."
              defaultValue={value}
              height="250px"
              headless
              onChange={onChange}
              cleanupPastedContent
            />
          )}
        />

        <div className="d-flex">
          {isDirty && (
            <Button
              onClick={handleSubmit(async (data) => {
                await delUserDocumentAnno(actId, encodeURIComponent(annotation.id));
                recogito.removeAnnotation(annotation);
                const updated = { ...annotation };
                const taggingBody = getFirstComment(updated);
                if (taggingBody) {
                  taggingBody.value = data.note;
                } else {
                  updated.body.push({
                    type: "TextualBody",
                    purpose: "commenting",
                    value: data.note,
                  });
                }

                const backendAnno = await addUserDocumentAnno(actId, updated);
                recogito.addAnnotation(updated);
                recogito.pagemap.redraw();
                updateAnnotations(backendAnno);
                reset(data);
              })}
              disabled={isSubmitting}
              variant="link"
              title="Запази"
            >
              <FaCheck />
            </Button>
          )}
          {note && (
            <Button
              onClick={() => setValue("note", "", { shouldDirty: true })}
              disabled={isSubmitting}
              variant="link"
              title="Изчисти"
            >
              <FaEraser />
            </Button>
          )}
          <Button
            onClick={() => closePopover()}
            disabled={isSubmitting}
            variant="link"
            title="Затвори"
          >
            <FaTimes />
          </Button>
        </div>
      </Tooltip>
    </Overlay>
  ) : null;
};

export default EditAnnotationNotePopover;
