import { EntityModel } from "hateoas-hal-types";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionPeriodStatus from "../../models/SubscriptionPeriodStatus.enum";
import {
  updateSubscriptionPeriodStatus,
  UpdateSubscriptionPeriodStatusInput,
} from "../../services/subscription-services";
import AttachInvoiceForm from "./attach-invoice.form";
import GenerateInvoice from "./generate-invoice.form";

type StartSubscriptionPeriodModalProps = {
  subscription: Subscription;
  periodId: string;
  onUpdate: (subscription: EntityModel<Subscription>) => void;
  onClose: () => void;
};

const StartSubscriptionModal: React.FC<StartSubscriptionPeriodModalProps> = ({
  subscription,
  periodId,
  onClose,
  onUpdate,
}) => {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<UpdateSubscriptionPeriodStatusInput>({
    defaultValues: {
      status: SubscriptionPeriodStatus.AWAITING_PAYMENT,
    },
  });

  const intl = useIntl();
  const onSumbit = async (data: UpdateSubscriptionPeriodStatusInput) => {
    try {
      onUpdate(await updateSubscriptionPeriodStatus(subscription.id, periodId, data));
      toast.success(
        intl.formatMessage(
          {
            id: "start-subscription-period.modal.toasts.success",
          },
          data
        )
      );
      onClose();
    } catch (e) {
      console.log(e);
      toast.error(intl.formatMessage({ id: "start-subscription-period.modal.toasts.error" }, data));
    }
  };

  const invoice = watch("invoice");

  const [selectedTab, setSelectedTab] = useState<string | null>();

  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onClose} />
      <ActionButton
        messageId="button.confirm"
        onClick={handleSubmit(onSumbit)}
        submitting={isSubmitting}
        disabled={isSubmitting || (selectedTab !== "no-invoice" && !invoice)}
      />
    </>
  );

  return (
    <Modal show={true} centered size="lg" onEscapeKeyDown={onClose} animation={false}>
      <Modal.Header style={{ alignItems: "center", padding: "0.6rem" }}>
        <div>
          <FormattedMessage id="start-subscription-period.modal.header" />
        </div>
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="generate-invoice"
          className="mb-3"
          onSelect={(eventKey) => {
            eventKey === "no-invoice" && setValue("invoice", undefined);
            setSelectedTab(eventKey);
          }}
        >
          <Tab eventKey="generate-invoice" title="Генерирай проформа">
            <GenerateInvoice
              proforma
              period
              subscription={subscription}
              onGenerate={(data) => {
                setValue(
                  "invoice",
                  data
                    ? {
                        ...data,
                        amount: data.amount,
                        proforma: true,
                      }
                    : undefined
                );
              }}
            />
          </Tab>
          <Tab eventKey="attach-invoice" title="Прикачи проформа">
            <AttachInvoiceForm
              proforma
              period
              subscription={subscription}
              onAttach={(data) => {
                setValue("invoice", data);
              }}
            />
          </Tab>
          <Tab eventKey="no-invoice" title="Без проформа">
            Без проформа към абонамента
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const extractReference = (fileName: string) => {
  const rx = /(\d+_\d{2}\.\d{2}\.\d{4})/g;
  const arr = rx.exec(fileName);
  return arr![1].replace("_", "/");
};

export default StartSubscriptionModal;
