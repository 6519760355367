/* eslint-disable no-underscore-dangle */
import { PagedModel } from "hateoas-hal-types";
import { useCallback, useEffect, useRef } from "react";
import useSWR, {
  Cache,
  mutate as swrMutate,
  SWRConfiguration,
  unstable_serialize as swrSerialize,
} from "swr";
import useSWRInfinite, { SWRInfiniteResponse, unstable_serialize } from "swr/infinite";
import {
  BadRequestError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  TooManyRequestsError,
  UnauthorizedError,
} from "./components/GlobalErrorBoundary";
import { config } from "./constants";
import { getToken, useAuth } from "./contexts/auth.context";
import { useSearchContext } from "./contexts/search.context";

const baseUrl = config.url + "/api";

export interface ApiResponse<T> {
  data: T | undefined;
  error?: any;
}

export interface SearchApiResponse<T> extends PagedModel<T> {}

export const mutate = async (input: string, data?: any, revalidate?: boolean) => {
  //cache.clear();
  await swrMutate([baseUrl + input, getToken()], data, revalidate);
};

const useRequest = <R>(
  input: string | null,
  mapper = (data: any): R => data as any as R,
  swrConfig: SWRConfiguration = {}
): ApiResponse<R> => {
  const auth = useAuth();
  const { data, error } = useSWR(
    input ? [baseUrl + input, auth.currentUser?.token] : null,
    swrConfig
  );

  if (error?.name === "UnauthorizedError") {
    auth.setCurrentUser(undefined);
    auth.setShowLoginModal(true, { cancelable: true });
  }

  return {
    data: mapper(data),
    error,
  };
};

export interface UseSearchRequestReturn<R> extends SWRInfiniteResponse<SearchApiResponse<R>, any> {
  hasMore: boolean;
  totalCount: number | undefined;
  totalPages: number | undefined;
  isLoadingMore: boolean | undefined;
  didYouMean: string | undefined;
}

export const useSearchRequest = <R>(firstPageUrl: string): UseSearchRequestReturn<R> => {
  const { search } = useSearchContext();
  const getKey = useCallback(
    (pageIndex: number, previousPageData: SearchApiResponse<R> | null) => {
      if (search === undefined) {
        return null;
      }
      if (pageIndex === 0) {
        return `${config.url}/api${firstPageUrl}?${search}`;
      }
      return previousPageData?._links.next.href || null;
    },
    [firstPageUrl, search]
  );

  const result = useSWRInfinite<SearchApiResponse<R>>(getKey);
  const totalCount = result.data ? result.data[0].page.totalElements : undefined;
  const totalPages = result.data ? result.data[0].page.totalPages : undefined;
  const complete =
    result.data === undefined ||
    totalCount === 0 ||
    result.data[0].page.totalPages === result.data.length;
  const isLoadingMore =
    result.size > 0 && result.data && typeof result.data[result.size - 1] === "undefined";

  const didYouMean =
    result.data &&
    result.data[0]?._links?.self &&
    Object.entries(result.data[0]?._links?.self)[1] &&
    Object.entries(result.data[0]?._links?.self)[1][1] &&
    Object.values(Object.entries(result.data[0]?._links?.self)[1][1])[0];

  return { ...result, hasMore: !complete, totalCount, totalPages, isLoadingMore, didYouMean };
};

export const useInfiniteRequest = <R>(firstPageUrl: string): UseSearchRequestReturn<R> => {
  const getKey = useCallback(
    (pageIndex: number, previousPageData: SearchApiResponse<R> | null) => {
      if (pageIndex === 0) {
        return `${config.url}/api${firstPageUrl}`;
      }
      return previousPageData?._links.next.href || null;
    },
    [firstPageUrl]
  );

  const result = useSWRInfinite<SearchApiResponse<R>>(getKey);
  const totalCount = result.data ? result.data[0].page.totalElements : undefined;
  const totalPages = result.data ? result.data[0].page.totalPages : undefined;
  const complete =
    result.data === undefined ||
    totalCount === 0 ||
    result.data[0].page.totalPages === result.data.length;
  const isLoadingMore =
    result.size > 0 && result.data && typeof result.data[result.size - 1] === "undefined";

  const didYouMean =
    result.data &&
    result.data[0]?._links?.last &&
    Object.entries(result.data[0]?._links?.last)[1][1] &&
    Object.values(Object.entries(result.data[0]?._links?.last)[1][1])[0];
  return { ...result, hasMore: !complete, totalCount, totalPages, isLoadingMore, didYouMean };
};

export const prefetchSearchRequest = async (
  firstPageUrl: string,
  searchParams: URLSearchParams,
  cache: Cache<any> | null
) => {
  const query = searchParams.toString();
  const path = `${firstPageUrl}?${query}`;
  const key = unstable_serialize(() => `${config.url}/api` + path) as string;
  if (cache && cache.get(key)) {
    return Promise.resolve(cache.get(key));
  }
  const result = await get(path);
  return swrMutate(key, [result], false);
};

export const prefetch = async (path: string, cache: Cache<any>) => {
  const key = swrSerialize([baseUrl + path, getToken()]);
  if (cache && cache.get(key)) {
    return Promise.resolve();
  }
  const result = await get(path);
  return swrMutate(key, result, false);
};

export const post = async (path: string, data = {}, emptyResponse?: boolean) => {
  if (!path) {
    throw new Error("Path is required");
  }
  const response = await fetch(path.indexOf("http") !== -1 ? path : baseUrl + path, {
    method: "POST",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { status: response.status, payload: await response.json() };
  }
  //cache.clear();
  return !emptyResponse ? response.json() : null; // parses JSON response into native JavaScript objects
};

export const del = async (path: string, data = {}, emptyResponse?: boolean) => {
  if (!path) {
    throw new Error("Path is required");
  }
  const response = await fetch(path.indexOf("http") !== -1 ? path : baseUrl + path, {
    method: "DELETE",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { status: response.status, payload: await response.json() };
  }
  //cache.clear();
  return !emptyResponse ? response.json() : null; // parses JSON response into native JavaScript objects
};

export const put = async (path: string, data = {}, emptyResponse?: boolean) => {
  if (!path) {
    throw new Error("Path is required");
  }
  const response = await fetch(path.indexOf("http") !== -1 ? path : baseUrl + path, {
    method: "PUT",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { status: response.status, payload: await response.json() };
  }
  //cache.clear();
  return !emptyResponse ? response.json() : null; // parses JSON response into native JavaScript objects
};

export const get = async <T>(path: string, params = {}): Promise<T> => {
  if (!path) {
    throw new Error("Path is required");
  }
  const response = await fetch(
    (path.indexOf("http") !== -1 ? path : baseUrl + path) +
      (Object.keys(params).length ? "?" : "") +
      new URLSearchParams(params),
    {
      method: "GET",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  );
  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { status: response.status, payload: await response.json() };
  }
  return response.json(); // parses JSON response into native JavaScript objects
};

// export const href = (data) => data._links.self.href;

export const fetcher = (...args: any[]) =>
  fetch(args[0], { credentials: "same-origin" }).then((res) => {
    if (!res.ok) {
      if (res.status === 400) {
        throw new BadRequestError();
      }
      if (res.status === 401) {
        throw new UnauthorizedError();
      }
      if (res.status === 403) {
        throw new ForbiddenError();
      }
      if (res.status === 404) {
        throw new NotFoundError();
      }
      if (res.status === 429) {
        throw new TooManyRequestsError();
      }
      if (res.status === 500 || res.status === 504) {
        throw new NetworkError();
      }
      const error = new Error("An error occurred while fetching the data.");
      (error as any).status = res.status;
      throw error;
    }

    return res.json();
  });

// This is a SWR middleware for keeping the data even if key changes.
export function laggy(useSWRNext: any) {
  return (key: any, fetcher: any, config: any) => {
    // Use a ref to store previous returned data.
    const laggyDataRef = useRef();

    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data;
      }
    }, [swr.data]);

    // Expose a method to clear the laggy data, if any.
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined;
    }, []);

    // Fallback to previous data if the current data is undefined.
    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;

    // Is it showing previous data?
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined;

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    });
  };
}

export default useRequest;
