import { EntityModel } from "hateoas-hal-types";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import ConfirmModal from "../../components/ConfirmModal";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import { updateSubscriptionStatus } from "../../services/subscription-services";

type RestartSubscriptionModalProps = {
  subscriptionId: string;
  onUpdate: (subscription: EntityModel<Subscription>) => void;
  onClose: () => void;
};

const RestartSubscriptionModal: React.FC<RestartSubscriptionModalProps> = ({
  subscriptionId,
  onClose,
  onUpdate,
}) => {
  const intl = useIntl();
  const onSumbit = async () => {
    try {
      onUpdate(
        await updateSubscriptionStatus(subscriptionId, {
          status: SubscriptionStatus.REQUESTED,
        })
      );
      toast.success(
        intl.formatMessage({
          id: "restart-subscription.modal.toasts.success",
        })
      );
      onClose();
    } catch (e) {
      console.log(e);
      toast.error(intl.formatMessage({ id: "restart-subscription.modal.toasts.error" }));
    }
  };

  return (
    <ConfirmModal
      onConfirm={onSumbit}
      onClose={onClose}
      title={intl.formatMessage({ id: "restart-subscription.modal.header" })}
      text={intl.formatMessage({ id: "restart-subscription.modal.body" })}
    />
  );
};

export default RestartSubscriptionModal;
