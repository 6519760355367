export type UserDocument = {
  id?: string;
  title?: string;
  href?: string;
  timestamp?: string;
  category?: string;
  shortTitle?: string;
  type?: UserDocumentType;
  query?: string | null;
};

export enum UserDocumentType {
  FAVOURITE = "FAVOURITE",
  LAST_OPENED = "LAST_OPENED",
}
