import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import dayjs from "dayjs";
import { createRef, useEffect, useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { ActionButton } from "../../components/Button/button";
import { useProtectedAction } from "../../contexts/auth.context";
import { useHiglightedArticles } from "../../services/home-services";
import { PUBLISH_DATE_FORMAT } from "./focused-section";
import "./highlighted-news-articles.css";

const HighlightedNewsArticles = () => <HighlightedNewsArticlesComponent />;

const HighlightedNewsArticlesComponent = () => {
  const { data } = useHiglightedArticles();

  const protectedAction = useProtectedAction(true);

  const arrLength = 5;
  const [elRefs, setElRefs] = useState([] as any[]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill(undefined)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [arrLength]);

  const onIndexChange = async (index: number) => {
    const el = elRefs[index + 1]?.current as HTMLImageElement;
    if (el && !el.getAttribute("src")) {
      setTimeout(() => {
        el.src = el.getAttribute("data-src")!;
      }, 1000);
    }
  };

  const navigate = useNavigate();

  const carouselCards = data?.flatMap((page) => {
    return page?._embedded.items.map((article, i) => (
      <SplideSlide key={article.id}>
        <div>
          <img
            // src={
            //   i < 1
            //     ? process.env.PUBLIC_URL + `/api/home/highlighted-articles/${article.id}/image`
            //     : undefined
            // }
            src={process.env.PUBLIC_URL + `/api/home/highlighted-articles/${article.id}/image`}
            // data-src={
            //   i >= 1
            //     ? process.env.PUBLIC_URL + `/api/home/highlighted-articles/${article.id}/image`
            //     : undefined
            // }
            alt=""
            className="highlighted-article-image"
            ref={elRefs[i]}
          />
          <div className="legend">
            <h3 className="article-title">{article.title}</h3>
            <div className="article-subtitle">{article.subTitle}</div>
            <div className="article-details">
              <ActionButton
                onClick={protectedAction(() =>
                  navigate(`/news-articles/${article.id}`, { state: { from: "/" } })
                )}
                submitting={false}
              >
                <FormattedMessage id="home-page.sections.learn-more.link" />
              </ActionButton>

              <div className="item-details">
                <FormattedDate
                  value={dayjs(article.publishDate, PUBLISH_DATE_FORMAT).toDate()}
                  month="long"
                  day="2-digit"
                />
                {article.timeReading ? (
                  <>
                    &nbsp;-&nbsp;
                    <BiTimeFive />
                    &nbsp;{article.timeReading} мин. за преглед
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </SplideSlide>
    ));
  }) as any;

  return data ? (
    <Splide
      className="carousel"
      options={{
        autoplay: true,
        interval: 6000,
        speed: 1500,
        type: "fade",
        rewind: true,
        arrows: false,
        fixedHeight: "200px",
      }}
      // onActive={(splide, slide) => {
      //   onIndexChange(splide.index);
      // }}
    >
      {carouselCards}
    </Splide>
  ) : (
    <div className="highlighted-articles-loader" />
  );
};

export default HighlightedNewsArticles;
