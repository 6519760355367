import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { SubmittingActionButton } from "../../components/Button/button";
import NotAuthorized from "../../components/NotAuthorized";
import RichTextEditor from "../../components/RichTextEditor";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { ConnectUser, hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { Bulletin } from "../../models/Bulletin.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import {
  fetchBulletinContentsTemplate,
  useExistingBulletin,
  useNextIssueNumber,
} from "../../services/bulletin-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import BulletinEditPageActions from "./bulletin-edit-page-actions";
import "./bulletin-edit-page.scss";
import BulletinViewPageLoading from "./bulletin-view-page-loading";
interface BulletinEditPageProps {
  bulletin?: EntityModel<Bulletin>;
}

const BulletinEditPage = () => {
  const { bulletinId } = useParams();
  const bulletin = useExistingBulletin(bulletinId);

  return bulletin || !bulletinId ? (
    <BulletinEditForm bulletin={bulletin} />
  ) : (
    <BulletinViewPageLoading mode="edit" className="page-loading" />
  );
};

const BulletinEditForm: React.FC<BulletinEditPageProps> = ({ bulletin }) => {
  const serverData = bulletin;
  const intl = useIntl();
  const { state } = useLocation();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: serverData || {
      status: DocumentStatus.DRAFT,
      accessMode: DocumentAccessMode.PAID,
      publishDate: state.date,
      fromDate: dayjs(state.date, "DD.MM.YYYY").subtract(3, "months").format("DD.MM.YYYY"),
      toDate: state.date,
    },
  });

  const { control, watch, setValue, register } = methods;
  const [publishDate, fromDate, toDate, issueNumber] = watch([
    "publishDate",
    "fromDate",
    "toDate",
    "issueNumber",
  ]);

  const nextIssueNumber = useNextIssueNumber(
    state?.date ? dayjs(state.date, "DD.MM.YYYY").get("year") : undefined
  );

  useEffect(() => {
    if (nextIssueNumber) {
      const newIssueNumber = issueNumber || nextIssueNumber.year + "/" + nextIssueNumber.sequence;
      const parts = newIssueNumber.split(/\//g);
      setValue(
        "title",
        `Бюлетин № ${parseInt(parts[1])}/${parts[0]} за ${dayjs(
          publishDate,
          typeof publishDate === "string" ? "D.MM.YYYY" : undefined
        ).format("DD MMMM YYYY г.")}`
      );
      setValue("issueNumber", newIssueNumber);
    }
  }, [setValue, publishDate, nextIssueNumber, issueNumber]);

  return (
    <FormProvider {...methods}>
      <DocumentPageLayout
        title={bulletin?.title}
        defaultTitle={intl.formatMessage({ id: "bulletin-edit.page.add-new-title" })}
        mode="edit"
        className="bulletin-page"
        menu={<BulletinEditPageActions />}
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="9">
              <Form.Group controlId="title">
                <Form.Label>
                  <FormattedMessage id="edit-bulletin.form.title" />
                </Form.Label>
                <Form.Control type="text" {...register("title")} readOnly={true} />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="status">
                <Form.Label>
                  <FormattedMessage id="edit-bulletin.form.status" />
                </Form.Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={[DocumentStatus.DRAFT, DocumentStatus.PUBLIC]}
                          getOption={(status) => ({
                            label: intl.formatMessage({ id: `document.status.${status}` }),
                            value: status,
                          })}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Form.Group controlId="fromDate">
                <Form.Label>
                  <FormattedMessage id="edit-bulletin.form.from-to-date" />
                </Form.Label>
                <div>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value}
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format={"DD.MM.YYYY"}
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(false),
                          weekdaysLong: dayjs.weekdays(false),
                          firstDayOfWeek: 1,
                        }}
                        parseDate={(value) => dayjs(value, "DD.MM.YYYY").toDate()}
                        onDayChange={(date) => onChange(dayjs(date).format("DD.MM.YYYY"))}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                          disabled: "true",
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="toDate">
                <Form.Label>&nbsp;</Form.Label>
                <div>
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value}
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format={"DD.MM.YYYY"}
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(false),
                          weekdaysLong: dayjs.weekdays(false),
                          firstDayOfWeek: 1,
                        }}
                        parseDate={(value) => dayjs(value, "DD.MM.YYYY").toDate()}
                        onDayChange={(date) => onChange(dayjs(date).format("DD.MM.YYYY"))}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                          disabled: "true",
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="start">
                <Form.Label>
                  <FormattedMessage id="edit-bulletin.form.publish-date" />
                </Form.Label>
                <div>
                  <Controller
                    name="publishDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DayPickerInput
                        value={value}
                        formatDate={(value, format) => dayjs(value).format(format)}
                        format={"DD.MM.YYYY"}
                        dayPickerProps={{
                          months: dayjs.months(),
                          weekdaysShort: dayjs.weekdaysMin(false),
                          weekdaysLong: dayjs.weekdays(false),
                          firstDayOfWeek: 1,
                        }}
                        parseDate={(value) => dayjs(value, "DD.MM.YYYY").toDate()}
                        onDayChange={(date) => onChange(dayjs(date).format("DD.MM.YYYY"))}
                        placeholder="дд.мм.гггг"
                        inputProps={{
                          className: "form-control",
                          disabled: "true",
                        }}
                      />
                    )}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="edit-bulletin.form.issueNumber" />
                </Form.Label>
                <Form.Control type="text" {...register("issueNumber")} readOnly={true} />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="accessMode">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.access-mode" />
                </Form.Label>
                <Controller
                  name="accessMode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ConnectUser>
                      {({ currentUser }) => (
                        <SelectSingle
                          options={Object.keys(DocumentAccessMode)}
                          getOption={(accessMode) => ({
                            label: intl.formatMessage({ id: `document.access-mode.${accessMode}` }),
                            value: accessMode,
                          })}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    </ConnectUser>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="body">
                <Form.Label>
                  <FormattedMessage id="edit-bulletin.form.contents" />
                </Form.Label>
                <Controller
                  name="contents"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return value ? (
                      <RichTextEditor
                        defaultValue={value}
                        onChange={onChange}
                        id="bulletin"
                        buttons={[
                          <button className="ql-indent" value="-1"></button>,
                          <button className="ql-indent" value="+1"></button>,
                          <select className="ql-bp">
                            <option value="">Text</option>
                            <option value="title">Title</option>
                            <option value="topic">Topic</option>
                            <option value="question">Question</option>
                            <option value="answer">Answer</option>
                          </select>,
                        ]}
                      />
                    ) : (
                      <SubmittingActionButton
                        messageId="button.generate"
                        onClick={async () => {
                          try {
                            onChange((await fetchBulletinContentsTemplate(fromDate, toDate)).data);
                          } catch (e) {
                            console.log(e);
                            toast.error("Възникна грешка при генериране на съдържание на бюлетина");
                          }
                        }}
                      />
                    );
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </DocumentPageLayout>
    </FormProvider>
  );
};

const ProtectedBulletinEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_BULLETINS)} fallback={NotAuthorized}>
      <BulletinEditPage />
    </Visible>
  );
};

export default ProtectedBulletinEditPage;
