import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useState } from "react";
import { Badge, Button, Card, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { FaDownload, FaEye } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton } from "../../components/Button/button";
import LabelValue from "../../components/label-value";
import { withAuth } from "../../contexts/auth.context";
import SubscriberType from "../../models/SubscriberType.enum";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import { formatInvoiceNumber } from "../../services/subscription-services";
import { useCurrentUser } from "../../services/user-services";
import SubscriptionStatusBadge from "../AdminPage/subscription-status-badge";
import AppPageLayout from "../DocumentPage/app-page-layout";
import ChangePasswordModal from "./change-password.modal";
import RenewSubscriptionModal from "./renew-subscription.modal";

const ProfilePage = () => {
  const currentUser = useCurrentUser();

  return (
    <AppPageLayout title={"Моят профил"} className={`document-page view-page`}>
      <Container fluid className="m-0 document-page-main-content-header-container">
        <div className="document-page-main-content-header">
          <span className="h6">Моят профил</span>
        </div>
      </Container>
      {currentUser && (
        <Container fluid className="m-0">
          <Row className="info-cards-row">
            <Col md={5}>
              <UserInfoCard />
            </Col>
            <Col md={7}>
              <ClientInfoCard />
            </Col>
          </Row>
        </Container>
      )}
      {currentUser?.currentSubscription?.subscriber?.customerInfo?.email &&
        currentUser?.currentSubscription?.subscriber?.customerInfo?.email ===
          currentUser?.email && (
          <Container fluid className="m-0">
            <br />
            <InvoicesTable />
          </Container>
        )}
    </AppPageLayout>
  );
};

const UserInfoCard = () => {
  const currentUser = useCurrentUser();
  const intl = useIntl();

  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  return currentUser ? (
    <Card className="info-card">
      <Card.Body className="d-flex flex-column">
        {showPasswordChangeModal && (
          <ChangePasswordModal onClose={() => setShowPasswordChangeModal(false)} />
        )}
        <span>
          <FormattedMessage id="profile-page.labels.user-info" />
        </span>
        <LabelValue label={intl.formatMessage({ id: "profile-page.labels.name" })}>
          {currentUser.firstName} {currentUser.lastName}
        </LabelValue>
        <LabelValue label={intl.formatMessage({ id: "profile-page.labels.email-login" })}>
          {currentUser.email}
        </LabelValue>
        {currentUser.currentSubscription?.subscriber?.customerInfo?.email && (
          <LabelValue label={intl.formatMessage({ id: "profile-page.labels.email-admin" })}>
            {currentUser.currentSubscription?.subscriber?.customerInfo?.email}
          </LabelValue>
        )}
        <br />
        <PrimaryButton
          messageId="profile-page.labels.change-password"
          className="mt-auto"
          onClick={(e: any) => {
            setShowPasswordChangeModal(true);
          }}
        />
      </Card.Body>
    </Card>
  ) : null;
};

const ClientInfoCard = () => {
  const currentUser = useCurrentUser();
  const intl = useIntl();
  const currentSubscription = currentUser?.currentSubscription;
  const latestSubscription = currentUser?.latestSubscription;
  const customerInfo = currentSubscription?.subscriber.customerInfo;

  const [renewedSubscriptionId, setRenewedSubsriptionId] = useState<string>();

  return (
    <Card className="info-card">
      <Card.Body className="d-flex flex-column">
        {renewedSubscriptionId && (
          <RenewSubscriptionModal
            renewedSubscriptionId={renewedSubscriptionId}
            onClose={() => setRenewedSubsriptionId(undefined)}
          />
        )}
        <span>
          <FormattedMessage id="profile-page.labels.subscription-info" />
        </span>
        {currentSubscription ? (
          <>
            <LabelValue label={intl.formatMessage({ id: "profile-page.labels.status" })}>
              <SubscriptionStatusBadge subscription={currentSubscription} />
            </LabelValue>
            <LabelValue label={intl.formatMessage({ id: "profile-page.labels.subscription-type" })}>
              {currentSubscription?.plan.type.name}
            </LabelValue>
            <LabelValue label={intl.formatMessage({ id: "profile-page.labels.client" })}>
              {currentSubscription?.subscriber.type === SubscriberType.PERSONAL
                ? customerInfo?.firstName +
                  " " +
                  customerInfo?.middleName +
                  " " +
                  customerInfo?.lastName
                : customerInfo?.companyName}{" "}
              (
              {currentSubscription?.subscriber.type === SubscriberType.PERSONAL
                ? "Частно лице"
                : "Юридическо лице"}
              )
            </LabelValue>
            {currentSubscription?.subscriber?.customerInfo?.phoneNumber && (
              <LabelValue
                label={intl.formatMessage({ id: "profile-page.labels.client-phoneNumber" })}
              >
                {currentSubscription?.subscriber?.customerInfo?.phoneNumber}
              </LabelValue>
            )}
            {currentSubscription?.activatedOn && (
              <LabelValue label={intl.formatMessage({ id: "profile-page.labels.activatedOn" })}>
                {intl.formatDate(currentSubscription?.activatedOn, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </LabelValue>
            )}
            {currentSubscription?.expiresOn && (
              <LabelValue
                label={
                  dayjs(currentSubscription?.expiresOn).isBefore(new Date())
                    ? intl.formatMessage({ id: "profile-page.labels.expiredOn" })
                    : intl.formatMessage({ id: "profile-page.labels.expiresOn" })
                }
              >
                {intl.formatDate(currentSubscription?.expiresOn, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </LabelValue>
            )}
            {/* {currentSubscription?.paidUntil && (
              <LabelValue
                label={intl.formatMessage({ id: "profile-page.labels.payment-due-date" })}
              >
                {intl.formatDate(currentSubscription?.paidUntil, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </LabelValue>
            )} */}

            {(currentSubscription?.expiresSoon ||
              currentSubscription?.status === SubscriptionStatus.EXPIRED) && (
              <>
                <br />
                <PrimaryButton
                  messageId="profile-page.labels.renew-subscription"
                  className="mt-auto"
                  onClick={(e: any) => {
                    setRenewedSubsriptionId(currentSubscription.id);
                  }}
                  disabled={
                    latestSubscription?.status === SubscriptionStatus.REQUESTED ||
                    latestSubscription?.status === SubscriptionStatus.PENDING_INITIAL_PAYMENT
                  }
                />
              </>
            )}
          </>
        ) : (
          <span className="fw-normal">
            <FormattedMessage id="profile-page.labels.no-subscription" />
          </span>
        )}
      </Card.Body>
    </Card>
  );
};

const InvoicesTable = () => {
  const intl = useIntl();

  const currentUser = useCurrentUser();
  const invoices = currentUser?.currentSubscription?.invoices;

  return invoices ? (
    <>
      <Tabs activeKey={"0"} className={`sub-section-tabs`}>
        <Tab
          eventKey="0"
          disabled
          title={<FormattedMessage id="edit-subscription.form.invoices" />}
        >
          {invoices?.length === 0 ? (
            <span style={{ padding: "12px" }}>
              <FormattedMessage id="edit-subscription.form.invoices.no-data" />
            </span>
          ) : (
            <Table responsive style={{ width: "100%" }} className="sub-section-table">
              <colgroup>
                <col span={1} />
                <col span={1} style={{ width: "50px" }} />
              </colgroup>

              <tbody>
                {invoices?.map((v, i) => (
                  <tr key={i}>
                    <td>
                      <div className="d-flex w-100">
                        <span className="d-flex flex-column">{formatInvoiceNumber(v)}</span>
                        <span className="ms-auto">
                          {v.proforma ? (
                            <Badge bg="info" className="ms-1">
                              <FormattedMessage id="edit-subscription.form.invoices.proforma" />
                            </Badge>
                          ) : (
                            <Badge bg="danger" className="ms-1">
                              <FormattedMessage id="edit-subscription.form.invoices.invoice" />
                            </Badge>
                          )}{" "}
                          <Badge bg="secondary">{v.amount} лв.</Badge>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <Button
                          className="p-1 d-flex align-items-center"
                          variant="link"
                          onClick={() => {
                            const pdfWindow = window.open("");
                            pdfWindow &&
                              pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='" +
                                  v.contents +
                                  "'></iframe>"
                              );
                          }}
                          title={intl.formatMessage({ id: "button.view" })}
                        >
                          <FaEye />
                        </Button>
                        <Button
                          className="p-1 d-flex align-items-center"
                          variant="link"
                          onClick={() => {
                            saveAs(v.contents, v.fileName);
                          }}
                          title={intl.formatMessage({ id: "button.download" })}
                        >
                          <FaDownload />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab>
      </Tabs>
    </>
  ) : null;
};

export default withAuth(ProfilePage);
