import { useCallback, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { createPortal } from "react-dom";
import { FaDownload, FaFileWord, FaPrint, FaStar } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { Privileges, useAuth, useProtectedAction } from "../../contexts/auth.context";
import useDocumentActionHandlers from "../../hooks/useDocumentActionHandlers";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { UserDocumentType } from "../../models/UserDocument.model";
import { useIsDocumentInCollection } from "../../services/user-services";
import TooManyRequestsErrorModal from "../too-many-requests.modal";
import AddToFavourites from "./add-to-favourites";
import DocumentAction from "./document-action";
import { useDocumentActionsContext } from "./document-actions-container";
import ExportDocument from "./export";
import HighlightDocument from "./highlight";
import PrintDocument from "./print";

export type ExportDocumentOptions = {
  rtf?: boolean;
  includeUserAnnotations?: boolean;
  includeEditorAnnotations?: boolean;
  contents?: string;
};

type DocumentCollectionActionsProps = {
  showPrintExport?: boolean;
  hasPrintExportOptions?: boolean;
  showRtfExport?: boolean;
  showHighlight?: boolean;
  dataToExport?: (options: ExportDocumentOptions) => Promise<any>;
  popover?: boolean;
  accessMode: DocumentAccessMode;
};

const DocumentCollectionActions: React.FC<DocumentCollectionActionsProps> = ({
  showPrintExport = false,
  hasPrintExportOptions = false,
  showRtfExport = false,
  showHighlight = false,
  dataToExport,
  popover,
  accessMode,
}) => {
  const location = useLocation();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  const { document } = useDocumentActionsContext();

  const protectedAction = useProtectedAction(accessMode === DocumentAccessMode.FREE);

  const isDocumentInFavourites = useIsDocumentInCollection(
    document!.id,
    UserDocumentType.FAVOURITE
  );

  const { removeFromFavourites, handleExport, handlePrint, handleRtfExport } =
    useDocumentActionHandlers({
      docs: [
        {
          _id: document!.id,
          title: document!.title,
          href: location.pathname,
        },
      ],
      printExport: useCallback(
        async (options?: ExportDocumentOptions) => {
          return {
            contents:
              dataToExport &&
              (await dataToExport({
                ...(options || {}),
                rtf: false,
              }).catch((e) => {
                e.status === 429 && setShowTooManyRequestsModal(true);
                throw e;
              })),
            fileName: `${document!.title}.pdf`,
          };
        },
        [document, dataToExport]
      ),
      rtfExport: useCallback(
        async (options?: ExportDocumentOptions) => {
          return {
            contents:
              dataToExport &&
              (await dataToExport({
                ...(options || {}),
                rtf: true,
              })),
            fileName: `${document!.title}.doc`,
          };
        },
        [document, dataToExport]
      ),
    });

  const intl = useIntl();

  const ref = useRef<LoadingBarRef>(null);

  const auth = useAuth();
  const canEditAllSummaries = auth.hasPrivilige(Privileges.EDIT_ALL_SUMMARIES);

  const [showTooManyRequestModal, setShowTooManyRequestsModal] = useState(false);

  return (
    <>
      {showTooManyRequestModal && (
        <TooManyRequestsErrorModal onClose={() => setShowTooManyRequestsModal(false)} />
      )}
      {showHighlight && <HighlightDocument popover={popover} />}
      {isDocumentInFavourites ? (
        <DocumentAction
          handler={removeFromFavourites}
          icon={<FaStar />}
          label={intl.formatMessage({ id: "document-actions.remove-from-read-later" })}
        />
      ) : (
        <AddToFavourites popover={popover} />
      )}
      {showPrintExport && (
        <>
          {createPortal(
            <LoadingBar color="rgb(179, 8, 56)" ref={ref} containerClassName="loading-bar" />,
            window.document.body
          )}
          {!isMobileOnly &&
            (hasPrintExportOptions ? (
              <PrintDocument
                popover={popover}
                handlePrint={protectedAction(async (options) => {
                  ref.current && ref.current.continuousStart(30, 300);
                  try {
                    return await handlePrint(options);
                  } finally {
                    ref.current && ref.current.complete();
                  }
                })}
              />
            ) : (
              <DocumentAction
                handler={protectedAction(async () => {
                  ref.current && ref.current.continuousStart(30, 300);
                  try {
                    return await handlePrint({});
                  } finally {
                    ref.current && ref.current.complete();
                  }
                })}
                icon={<FaPrint />}
                label={intl.formatMessage({ id: "document-actions.print" })}
              />
            ))}

          {hasPrintExportOptions ? (
            <ExportDocument
              popover={popover}
              handleExport={protectedAction(async (options) => {
                ref.current && ref.current.continuousStart(30, 300);
                try {
                  return await handleExport(options);
                } finally {
                  ref.current && ref.current.complete();
                }
              })}
            />
          ) : (
            <DocumentAction
              handler={protectedAction(async () => {
                ref.current && ref.current.continuousStart(30, 300);
                try {
                  return await handleExport({});
                } finally {
                  ref.current && ref.current.complete();
                }
              })}
              icon={<FaDownload />}
              label={intl.formatMessage({ id: "document-actions.exportPdf" })}
            />
          )}

          {canEditAllSummaries && showRtfExport && (
            <DocumentAction
              handler={protectedAction(async () => {
                ref.current && ref.current.continuousStart(30, 300);
                try {
                  return await handleRtfExport();
                } finally {
                  ref.current && ref.current.complete();
                }
              })}
              icon={<FaFileWord />}
              label={intl.formatMessage({ id: "document-actions.exportRtf" })}
            />
          )}
        </>
      )}
    </>
  );
};

export default DocumentCollectionActions;
