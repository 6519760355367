import { useCallback } from "react";
import { FormText, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm, UseFormSetError } from "react-hook-form";
import { FaQuestionCircle } from "react-icons/fa";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import { PrimaryButton, SecondaryButton } from "../../components/Button/button";
import { changePassword, ChangePasswordInput } from "../../services/user-services";

type RegistrationModalProps = {
  onClose: () => void;
};

const ChangePasswordModal: React.FC<RegistrationModalProps> = ({ onClose }) => {
  const { handleSubmit, register, setError, formState, clearErrors } =
    useForm<ChangePasswordInput>();
  const intl = useIntl();

  const onSubmit = useCallback(
    async (data: ChangePasswordInput) => {
      try {
        await changePassword(data);
        toast.success(
          intl.formatMessage(
            {
              id: "change-password.modal.success",
            },
            data
          )
        );
        onClose();
      } catch (e: any) {
        handleApiErrors(e, setError, intl);
      }
    },
    [setError, intl, onClose]
  );

  useTitle(intl.formatMessage({ id: "change-password.modal.header" }));

  return (
    <>
      <Modal
        show={true}
        centered
        animation={false}
        keyboard
        onEscapeKeyDown={onClose}
        onHide={onClose}
        backdrop={true}
        size="lg"
        fullscreen="sm-down"
      >
        <Modal.Header>
          <FormattedMessage id="change-password.modal.header" />
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off" noValidate>
            <Form.Group controlId="currentPassword">
              <Form.Label>
                <FormattedMessage id="change-password.modal.form.fields.currentPassword" />*
              </Form.Label>
              <Form.Control type="password" {...register("currentPassword")} />
              {!!formState.errors.currentPassword && (
                <FormText className="text-danger">
                  {formState.errors.currentPassword.message}
                </FormText>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>
                <FormattedMessage id="change-password.modal.form.fields.password" />
                *&nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={(props) => (
                    <Tooltip id="code-tooltip" {...props}>
                      Паролата трябва да е с дължина от поне 8 символа.
                    </Tooltip>
                  )}
                >
                  <span>
                    <FaQuestionCircle className="text-muted" />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control type="password" {...register("password")} />
              {!!formState.errors.password && (
                <FormText className="text-danger">{formState.errors.password.message}</FormText>
              )}
            </Form.Group>
            <Form.Group controlId="passwordConfirm">
              <Form.Label>
                <FormattedMessage id="change-password.modal.form.fields.passwordConfirm" />*
              </Form.Label>
              <Form.Control type="password" {...register("passwordConfirm")} />
              {!!formState.errors.passwordConfirm && (
                <FormText className="text-danger">
                  {formState.errors.passwordConfirm.message}
                </FormText>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <SecondaryButton
            messageId="button.cancel"
            onClick={onClose}
            disabled={formState.isSubmitting}
          />
          <PrimaryButton
            messageId="button.confirm"
            onClick={(e: any) => {
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
            submitting={formState.isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const handleApiErrors = (
  e: any,
  setError: UseFormSetError<ChangePasswordInput>,
  intl: IntlShape
) => {
  if (e.status && e.status === 400) {
    (e.payload.subErrors || []).forEach((error: any) => {
      setError(error.field, {
        type: "manual",
        message: intl.formatMessage({
          id: `change-password.modal.form.errors.${error.field}.${error.message}`,
        }),
      });
    });
  }
};

export default ChangePasswordModal;
