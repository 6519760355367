import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import RefData from "../../models/RefData.model";
import { Summary } from "../../models/Summary.model";
import { NO_TOPIC_ID, useNoTopic } from "../../services/summary-topic.services";
import AddTopicModal from "./add-topic.modal";
import TopicsTable from "./topics.table";

type TopicsSectionProps = {
  idx: number;
};

const TopicsSection: React.FC<TopicsSectionProps> = ({ idx }) => {
  const { control } = useFormContext<Summary>();
  const { fields, append, remove } = useFieldArray<Summary, any, "fieldId">({
    name: `qas.${idx}.topics`,
    keyName: "fieldId",
    control,
  });

  const noTopic = useNoTopic();

  useEffect(() => {
    if (fields.length === 0) {
      append(noTopic!);
    }
    if (fields.length > 1) {
      const noTopicIdx = fields.findIndex((t: any) => t.id === NO_TOPIC_ID);
      noTopicIdx > -1 && remove(noTopicIdx);
    }
  }, [fields, append, remove, noTopic]);

  return (
    <Form.Group controlId={`qas.${idx}.topics`}>
      <Form.Label>
        <FormattedMessage
          id="edit-summary.form.summaryTopic"
          values={{ count: (fields || []).length }}
        />

        <AddTopicModal
          append={(newValue) => {
            if (fields.findIndex((item: any) => newValue.id === item.id) === -1) {
              append(newValue);
            }
          }}
        />
      </Form.Label>
      <TopicsTable value={fields as any as RefData[]} remove={remove} />
    </Form.Group>
  );
};

export default TopicsSection;
