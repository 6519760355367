import React from "react";

type ErrorBoundaryProps = {
  message?: string;
  fallback?: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <span className="text-muted">{this.props.fallback || this.props.message}</span>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
