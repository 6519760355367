import "@fortawesome/fontawesome-free/css/all.css";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { withActiveSubscrption } from "../../../contexts/auth.context";
import { useSearchContext } from "../../../contexts/search.context";
import { useDocumentStats } from "../../../services/document-services";
import SearchResultsLoading from "../ResultsTable/results-table-loadng";
import { getTabUrl, useSearchCategory } from "../search-utils";
import AdmJudgementActsResults from "./adm-judgement-acts-results-table";
import EuJudgementActsResults from "./eu-judgement-acts-results-table";
import {
  CsJudgementActsResults,
  SccJudgementActsResults,
  ScJudgementActsResults,
} from "./judgement-acts-results-table";
import NewsArticleResults from "./news-articles-results-table";
import QuestionsResults from "./questions-results-table";
import SearchResultsNav from "./search-results-nav";
import "./search-results.scss";
import SummariesResults from "./summaries-results-table";

const SearchResults = () => {
  const category = useSearchCategory();

  return (
    <>
      <SearchResultsNavBar />

      {category === "acts" && <SccJudgementActsResults />}
      {category === "summaries" && <SummariesResults />}
      {category === "questions" && <QuestionsResults />}
      {category === "adm-acts" && <AdmJudgementActsResults />}
      {category === "sc-acts" && <ScJudgementActsResults />}
      {category === "const-acts" && <CsJudgementActsResults />}
      {category === "eu-acts" && <EuJudgementActsResults />}
      {category === "news-articles" && <NewsArticleResults />}
    </>
  );
};

const SearchResultsNavBar = () => {
  const category = useSearchCategory();

  const location = useLocation();

  const stats = useDocumentStats();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      stats &&
      ((!stats.summariesCount.total && category === "summaries") ||
        (!stats.summariesCount.total && category === "questions") ||
        (!stats.domesticActsCount.total && category === "acts") ||
        (!stats.constActsCount.total && category === "const-acts") ||
        (!stats.supremeCourtActsCount.total && category === "sc-acts") ||
        (!stats.administrativeActsCount.total && category === "adm-acts") ||
        (!stats.euActsCount.total && category === "eu-acts") ||
        (!stats.newsArticlesCount.total && category === "news-articles"))
    ) {
      if (stats.summariesCount.total) {
        navigate(getTabUrl(location, "summaries"));
      } else if (stats.questionsCount.total) {
        navigate(getTabUrl(location, "questions"));
      } else if (stats.domesticActsCount.total) {
        navigate(getTabUrl(location, "acts"));
      } else if (stats.constActsCount.total) {
        navigate(getTabUrl(location, "const-acts"));
      } else if (stats.supremeCourtActsCount.total) {
        navigate(getTabUrl(location, "sc-acts"));
      } else if (stats.administrativeActsCount.total) {
        navigate(getTabUrl(location, "adm-acts"));
      } else if (stats.euActsCount.total) {
        navigate(getTabUrl(location, "eu-acts"));
      } else if (stats.newsArticlesCount.total) {
        navigate(getTabUrl(location, "news-articles"));
      }
    }
  }, [stats, location, navigate, category]);

  const { setLastSearchHref } = useSearchContext();
  useEffect(() => {
    setLastSearchHref(location.pathname + location.search);
  }, [setLastSearchHref, location.pathname, location.search]);

  return (
    <SearchResultsNav
      domJudgementActCount={stats?.domesticActsCount.total}
      constJudgementActCount={stats?.constActsCount.total}
      supremeCourtJudgementActCount={stats?.supremeCourtActsCount.total}
      admJudgementActCount={stats?.administrativeActsCount.total}
      euJudgementActCount={stats?.euActsCount.total}
      summariesCount={stats?.summariesCount.total}
      questionsCount={stats?.questionsCount.total}
      newsArticlesCount={stats?.newsArticlesCount.total}
    />
  );
};

export default withActiveSubscrption(SearchResults, SearchResultsLoading);
