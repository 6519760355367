import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import "./focused-section.css";

const FocusedSection: React.FC<{ title: string }> = ({ title, children }) => (
  <div className="focused-section">
    <h2>{title}</h2>

    <ErrorBoundary FallbackComponent={FocusedSectionErrorFallback}>{children}</ErrorBoundary>
  </div>
);

const FocusedSectionErrorFallback = () => {
  return <FormattedMessage id="home-page.sections.focused-section.loading-error" />;
};

export const PUBLISH_DATE_FORMAT = "YYYY-MM-DD";

export default FocusedSection;
