/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import useBodyScrollLock from "../../../hooks/useBodyScrollLock";
import "./filters-sidebar.css";

const ResponsiveFilter: React.FC<{}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const child = React.Children.only(children) as React.ReactElement;
  const ref = useRef<HTMLDivElement>(null);
  useBodyScrollLock(isOpen);
  return (
    <div ref={ref} onScroll={(e) => e.preventDefault()} className="responsive-select">
      {React.cloneElement(child, {
        onMenuToggle: isMobileOnly
          ? (expanded: boolean) => {
              const nav = document.querySelector(
                ".navigation-drawer .drawer-container"
              ) as HTMLElement;
              if (nav) {
                nav.style.overflow = expanded ? "hidden" : "auto";
              }
              expanded && setIsOpen(true);
            }
          : undefined,
      })}
      {isMobileOnly && (
        <Offcanvas
          show={isOpen}
          placement="bottom"
          className="selection-drawer"
          onHide={() => setIsOpen(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{child.props.placeholder}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {isOpen &&
              React.cloneElement(child, {
                defaultIsOpen: true,
                onMenuToggle: (expanded: boolean) => !expanded && setIsOpen(false),
                onChange: (value: any[]) => {
                  setIsOpen(false);
                  child.props.onChange(value);
                },
              })}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
};

export default ResponsiveFilter;
