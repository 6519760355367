import dayjs from "dayjs";
import { FormattedDate, useIntl } from "react-intl";
import LabelValue from "../../../components/label-value";
import PreloadingLink from "../../../components/PreloadingLink";
import { useSearchContext } from "../../../contexts/search.context";
import { Selection, UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
import { NewsArticle } from "../../../models/NewsArticle.model";
import {
  newsArticleHref,
  prefetchNewsArticle,
  useNewsArticles,
} from "../../../services/news-article-services";
import ResultsTable from "../ResultsTable/results-table";
import ResultsTableDocumentCollectionMenuActions from "../ResultsTable/results-table-document-collection-actions";
import ResultsTableHeader from "../ResultsTable/results-table-header";

const NewsArticleResults = () => {
  const { formatMessage } = useIntl();
  const results = useNewsArticles();

  const { opened, addOpened } = useSearchContext();

  return (
    <ResultsTable
      results={results}
      header={(params) => (
        <ResultsTableHeader {...params} actions={<NewsArticlesActionsMenu {...params} />} />
      )}
      itemToSelection={itemToSelection}
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <PreloadingLink
            to={newsArticleHref(item.id)}
            state={{ title: item.title }}
            preload={(cache) => prefetchNewsArticle(item.id, cache)}
            onClick={() => addOpened(item.id)}
            className={opened.indexOf(item.id) > -1 ? "visited" : ""}
          >
            {item.title}
          </PreloadingLink>
          <div>{item.subTitle}</div>
          <LabelValue label={formatMessage({ id: "edit-news-article.form.category" })}>
            {item.category?.name || "-"}
          </LabelValue>
          <LabelValue label={formatMessage({ id: "edit-news-article.form.publishDate" })}>
            <FormattedDate
              value={dayjs(item.publishDate, "YYYY-MM-DD").toDate()}
              month="long"
              day="2-digit"
              year="numeric"
            />
          </LabelValue>
          <ResultsTable.RowIndicators item={item} />

          <ResultsTable.RowHighlights item={item} />
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const NewsArticlesActionsMenu: React.FC<UseMultipleSelectionReturn> = (props) => (
  <ResultsTableDocumentCollectionMenuActions {...props} />
);

const itemToSelection = (item: NewsArticle): Selection => ({
  _id: item.id,
  title: item.title,
  href: newsArticleHref(item.id),
});

export default NewsArticleResults;
