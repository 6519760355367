import { saveAs } from "file-saver";
import { EntityModel } from "hateoas-hal-types";
import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { useFormContext } from "react-hook-form";
import { FaDownload, FaEye } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useViewEditPageContext } from "../../contexts/view-edit-page.context";
import { Subscription } from "../../models/Subscription.model";
import { SubscriptionInvoice } from "../../models/SubscriptionInvoice.model";
import { formatInvoiceNumber } from "../../services/subscription-services";

const InvoicesTable: React.FC<{
  value: SubscriptionInvoice[];
  append: (data: SubscriptionInvoice) => void;
  remove: (idx: number) => void;
  replace: (idx: number, data: SubscriptionInvoice) => void;
}> = ({ value, append, remove, replace }) => {
  const intl = useIntl();

  const [selectedInvoice, setSelectedInvoice] = useState<SubscriptionInvoice>();
  const { mode: pageMode } = useViewEditPageContext();

  const { reset, watch } = useFormContext<EntityModel<Subscription>>();

  return (
    <>
      <Tabs activeKey={"0"} className={`sub-section-tabs ${pageMode === "edit" ? "editable" : ""}`}>
        <Tab
          eventKey="0"
          disabled
          title={<FormattedMessage id="edit-subscription.form.invoices" />}
        >
          {value.length === 0 ? (
            <span style={{ padding: "12px" }}>
              <FormattedMessage id="edit-subscription.form.invoices.no-data" />
            </span>
          ) : (
            <Table responsive style={{ width: "100%" }} className="sub-section-table">
              <colgroup>
                <col span={1} />
                <col span={1} style={{ width: "50px" }} />
              </colgroup>

              <tbody>
                {[...value].map((v, i) => (
                  <tr key={i}>
                    <td
                      onClick={() => {
                        setSelectedInvoice(v);
                      }}
                    >
                      <div className="d-flex w-100">
                        <span className="d-flex flex-column">{formatInvoiceNumber(v)}</span>
                        <span className="ms-auto">
                          {v.proforma ? (
                            <Badge bg="info" className="ms-1">
                              <FormattedMessage id="edit-subscription.form.invoices.proforma" />
                            </Badge>
                          ) : (
                            <Badge bg="danger" className="ms-1">
                              <FormattedMessage id="edit-subscription.form.invoices.invoice" />
                            </Badge>
                          )}{" "}
                          <Badge bg="secondary">{v.amount} лв.</Badge>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <Button
                          className="p-1 d-flex align-items-center"
                          variant="link"
                          onClick={() => {
                            const pdfWindow = window.open("");
                            pdfWindow &&
                              pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='" +
                                  v.contents +
                                  "'></iframe>"
                              );
                          }}
                          title={intl.formatMessage({ id: "button.view" })}
                        >
                          <FaEye />
                        </Button>
                        <Button
                          className="p-1 d-flex align-items-center"
                          variant="link"
                          onClick={() => {
                            saveAs(v.contents, v.fileName);
                          }}
                          title={intl.formatMessage({ id: "button.download" })}
                        >
                          <FaDownload />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab>

        {/* {pageMode === "edit" && (
          <Tab
            eventKey="-1"
            title={
              <AddInvoiceModal
                append={(beneficiary) => {
                  const idx = value.findIndex(
                    (existingInvoice) => existingInvoice.id === beneficiary.id
                  );
                  if (idx > -1) {
                    replace(idx, { ...beneficiary });
                  } else {
                    append(beneficiary);
                  }
                }}
                invoice={selectedInvoice}
                setSelectedInvoice={setSelectedInvoice}
                mode={mode}
                setMode={setMode}
              />
            }
          />
        )} */}
      </Tabs>
    </>
  );
};

export default InvoicesTable;
