import DocumentStatus from "./DocumentStatus.enum";

type ReferencedSummaryQa = {
  question: string;
  id: string;
  type: SummaryQaLinkType;
  summary: {
    id: string;
    shortTitle: string;
    status: DocumentStatus;
  };
};

export enum SummaryQaLinkType {
  CITATION = "CITATION",
  SIMILARITY = "SIMILARITY",
  ADDENDUM = "ADDENDUM",
  CONTRADICTION = "CONTRADICTION",
  NONE = "NONE",
}

export default ReferencedSummaryQa;
