import { EntityModel } from "hateoas-hal-types";
import { toast } from "react-toastify";
import { Cache } from "swr";
import useRequest, {
  del,
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { useAuth } from "../contexts/auth.context";
import LexebraEditor from "../models/LexebraEditor.model";
import RefData from "../models/RefData.model";
import { Role } from "../models/Role.model";
import SummaryTopic from "../models/SummaryTopic.model";
import { User } from "../models/User.model";
import { UserDocument, UserDocumentType } from "../models/UserDocument.model";
import { UserDocumentAnno } from "../models/UserDocumentAnno.model";
import { UserDocuments } from "../models/UserDocuments.model";
import { UserSearch } from "../models/UserSearch.model";
import { UserSearches } from "../models/UserSearches.model";
import { fetchDocumentStats } from "./document-services";

export interface CurrentUser extends User {
  initials: string;
  topics: SummaryTopic[];
}

export type ChangePasswordInput = {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
};

export type EditorStats = {
  numberOfSummaries: number;
  numberOfAnnotatedActs: number;
  numberOfHighlightedActs: number;
};

export const useCurrentUser = () => {
  const { isLoginSessionActive } = useAuth();
  let { data } = useRequest<CurrentUser>(isLoginSessionActive() ? "/user/current" : null);

  return data;
};

export const useEditorStats = (editorId?: number) => {
  let { data } = useRequest<EditorStats>(editorId ? `/users/${editorId}/stats` : null);

  return data;
};

export const changePassword = async (data: ChangePasswordInput) => {
  await put(`/user/current/password`, data, true);
};

export const useUserDocumentAnno = (documentId: string) => {
  let { data } = useRequest<UserDocumentAnno[]>(
    `/user/current/documents/${documentId}/annotations`
  );
  return data;
};

export const addUserDocumentAnno = async (documentId: string, anno: any) => {
  return await post(`/user/current/documents/${documentId}/annotations`, anno);
};

export const delUserDocumentAnno = async (documentId: string, annotationId: string) => {
  return await del(`/user/current/documents/${documentId}/annotations/${annotationId}`, {});
};

export const useUserDocuments = () => {
  const { isLoginSessionActive } = useAuth();
  let { data } = useRequest<UserDocuments>(
    isLoginSessionActive() ? "/user/current/documents" : null
  );
  return data || { opened: [], favourite: [] };
};

export const useUserSearches = () => {
  let { data } = useRequest<UserSearches>("/user/current/searches");
  return data || { recent: [], saved: [] };
};

export const addUserSearch = async (search: UserSearch) => {
  const searches = await put(`/user/current/searches`, search);
  await mutate("/user/current/searches", async () => searches, false);
};

export const delUserSearch = async (searchId: string) => {
  const searches = await del(`/user/current/searches/${searchId}`, {});
  await mutate("/user/current/searches", async () => searches, false);
};

export const addUserDocument = async (docs: UserDocument[], type: UserDocumentType) => {
  const documents = await put(`/user/current/documents/${type}`, docs);
  await mutate("/user/current/documents", async () => documents, false);
};

export const delUserDocument = async (
  docIds: string[],
  type: UserDocumentType,
  category?: string
) => {
  const documents = await del(
    category
      ? `/user/current/documents/${type}/${encodeURIComponent(category)}`
      : `/user/current/documents/${type}`,
    docIds
  );
  await mutate("/user/current/documents", async () => documents, false);
};

export const delAllUserDocuments = async (type: UserDocumentType) => {
  const documents = await del(`/user/current/documents/${type}`, []);
  await mutate("/user/current/documents", async () => documents, false);
};

export const useIsDocumentInCollection = (docId: string, type: UserDocumentType) => {
  const { favourite } = useUserDocuments();
  return type === UserDocumentType.FAVOURITE ? isDocumentInCollection(docId, favourite) : false;
};

export const isDocumentInCollection = (docId: string, documents: UserDocument[]) => {
  return documents.findIndex((doc) => doc.href!.endsWith(docId)) > -1;
};

const fetchCurrentUser = () => get<User>("/user/current");

export const subscribeToTopic = async (topic: RefData) => {
  await post("/user/current/topics", topic, true);
  await mutate("/documents/stats", fetchDocumentStats);
  await mutate("/user/current", fetchCurrentUser);
};

export const unsubscribeFromTopic = async (topicId: number) => {
  await del(`/user/current/topics/${topicId}`, {}, true);
  await mutate("/user/current", fetchCurrentUser);
};

export const useUserNames = (name = "") => {
  let { data } = useRequest<LexebraEditor[]>(`/users/names?name=${name}`);
  return data || [];
};

export const addToCollection = async (
  docs: UserDocument[],
  type: UserDocumentType,
  name: string
) => {
  try {
    await addUserDocument(docs, type);
    toast.success(
      docs.length === 1
        ? `Документът бе успешно добавен в "${name}"`
        : `Документите бяха успешно добавени в "${name}"`
    );
  } catch (e) {
    console.log(e);
    toast.error(
      docs.length === 1
        ? `Грешка при добавене на документа в "${name}"`
        : `Грешка при добавене на документа в "${name}"`
    );
  }
};

export const removeFromCollection = async (
  docIds: string[],
  type: UserDocumentType,
  name: string
) => {
  try {
    await delUserDocument(docIds, type);
    toast.success(
      docIds.length === 1
        ? `Документът бе успешно премахнат от "${name}"`
        : `Документите бяха успешно премахнати от "${name}"`
    );
  } catch (e) {
    console.log(e);
    toast.error(
      docIds.length === 1
        ? `Грешка при премахване на документа от "${name}"`
        : `Грешка при премахване на документите от "${name}"`
    );
  }
};

export const USERS_BASE_URL = "/users";

export const useUsers = () => useSearchRequest<User>(USERS_BASE_URL);

export const useRoles = () => {
  const { data } = useRequest<Role[]>(USERS_BASE_URL + "/roles");
  return data;
};

export const prefetcUsers = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(USERS_BASE_URL, searchParams, cache);

export const useUser = (id?: string) => {
  const { data } = useRequest<EntityModel<User>>(id ? `${USERS_BASE_URL}/${id}` : null);
  return data;
};

export type UserActivityStats = {
  lastActivity: string;
  opened: {
    articles: UserActivityCategoryStats;
    acts: UserActivityCategoryStats;
    summaries: UserActivityCategoryStats;
    bulletins: UserActivityCategoryStats;
    totals: UserActivityCategoryStats;
  };

  exported: {
    acts: UserActivityCategoryStats;
    summaries: UserActivityCategoryStats;
    bulletins: UserActivityCategoryStats;
    totals: UserActivityCategoryStats;
  };
};

type UserActivityCategoryStats = {
  LAST_WEEK: number;
  LAST_MONTH: number;
  YESTERDAY: number;
  TODAY: number;
  ALL: number;
};

export const useUserActivity = (id?: number) => {
  const { data } = useRequest<UserActivityStats>(
    id ? `${USERS_BASE_URL}/${id}/activity-stats` : null
  );
  return data;
};

export const fetchUser = (id: string) => {
  return get<EntityModel<User>>(`${USERS_BASE_URL}/${id}`);
};

export const prefetchUser = (id: number, cache: Cache<any>) =>
  prefetch(`${USERS_BASE_URL}/${id}`, cache);

export const saveUser = async (data: User, userId?: string) => {
  const result = (
    userId ? await put(`${USERS_BASE_URL}/${userId}`, data) : await post(`${USERS_BASE_URL}`, data)
  ) as EntityModel<User>;
  mutate(`${USERS_BASE_URL}/${userId}`, () => result, false);
  return result;
};

export const userHref = (id: number): string => `${USERS_BASE_URL}/${id}`;
