import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton, SecondaryButton } from "../../components/Button/button";
import Select from "../../components/Select";
import { SearchHit } from "../../models/SearchHit.model";
import { DocumentStatusBadge, useSuggestions } from "../../services/document-services";

type AddRelatedActModalProps = {
  onConfirm: (hit: SearchHit) => void;
  onCancel: () => void;
};
const AddRelatedActModal: React.FC<AddRelatedActModalProps> = ({ onConfirm, onCancel }) => {
  const intl = useIntl();
  const [selectedAct, setSelectedAct] = useState<SearchHit>();
  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton
        messageId="button.confirm"
        onClick={() => onConfirm(selectedAct!)}
        disabled={!selectedAct}
      />
    </>
  );

  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue("");
  }, [setInputValue]);
  const options = useSuggestions(inputValue, "judgement-acts-summaries");

  return (
    <Modal
      show={true}
      centered
      size="xl"
      animation={false}
      className="modal-xxl"
      onEscapeKeyDown={onCancel}
    >
      <Modal.Header>
        <FormattedMessage id="add-related-document.modal.header" />
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Select
          onChange={(...event: any[]) => {
            setSelectedAct(event[0]);
          }}
          inputValue={inputValue}
          onInputChange={setInputValue}
          menuIsOpen={!!inputValue}
          value={selectedAct ? [selectedAct] : []}
          components={{
            DropdownIndicator: null,
          }}
          inputStyles={{ singleValue: { width: "90%", textOverflow: "ellipsis" } }}
          options={options}
          filterOption={() => true}
          isMulti={false}
          isClearable
          getOptionValue={(value: any) => value.text || value.title}
          getOptionLabel={(value: any) => (
            <>
              {value.text || value.title}
              <span style={{ position: "absolute", right: "5px" }}>
                <DocumentStatusBadge status={value.status} />
              </span>
            </>
          )}
          onKeyDown={(e: any) => {
            e.stopPropagation();
            if (e.key === "Escape") {
              onCancel();
            }
          }}
          placeholder={intl.formatMessage({
            id: "add-related-document.modal.select-act.placeholder",
          })}
          autoFocus
        />
      </Modal.Body>

      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

export default AddRelatedActModal;
