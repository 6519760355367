import { ReactElement } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FaPlus, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import SimpleSelect from "../../../components/SimpleSelect";
import ResponsiveFilter from "../../SearchPage/FiltersSidebar/responsive-filter";
import { useSort } from "../../SearchPage/search-utils";

interface ResultsTableHeaderProps {
  actions?: ReactElement;
  addNewPath?: string;
  sortOptions: { label: string; value: string }[];
}

const ResultsTableHeader: React.FC<ResultsTableHeaderProps> = ({
  actions,
  addNewPath,
  sortOptions,
}) => {
  const { column, direction } = useSort({ column: sortOptions[0]?.value, direction: "desc" })!;
  const navigate = useNavigate();

  return (
    <thead>
      <tr>
        <th className="controls-bar">
          <div className="sort-control">
            <>
              <span className="sort-control-label">Сортирай по</span>
              <SearchFieldController
                name="sort"
                render={({ field: { onChange, value } }) => (
                  <>
                    <ResponsiveFilter>
                      <SimpleSelect
                        className="inline-select"
                        onChange={onChange}
                        getOption={(option) => ({
                          value: option + "," + direction,
                          label:
                            sortOptions.find((o) => o.value + "," + direction === option)?.label ||
                            "",
                        })}
                        value={[column + "," + direction]}
                        options={sortOptions.map((option) => option.value + "," + direction)}
                        isMulti={false}
                        placeholder="Сортирай по"
                      />
                    </ResponsiveFilter>
                    {direction === "asc" && (
                      <Button
                        onClick={() => {
                          onChange([column + ",desc"]);
                        }}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="sort-asc">
                              <FormattedMessage
                                id={`results-table.sort.${column}.asc`}
                                defaultMessage="Сортиране: възходящо"
                              />
                            </Tooltip>
                          }
                        >
                          <span>
                            <FaSortAmountUp />
                          </span>
                        </OverlayTrigger>
                      </Button>
                    )}
                    {direction === "desc" && (
                      <Button
                        onClick={() => {
                          onChange([column + ",asc"]);
                        }}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="sort-desc">
                              <FormattedMessage
                                id={`results-table.sort.${column}.desc`}
                                defaultMessage="Сортиране: низходящо"
                              />
                            </Tooltip>
                          }
                        >
                          <span>
                            <FaSortAmountDown />
                          </span>
                        </OverlayTrigger>
                      </Button>
                    )}
                  </>
                )}
              />
            </>
            {addNewPath && (
              <Button onClick={() => navigate(addNewPath)}>
                <FaPlus />
              </Button>
            )}
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default ResultsTableHeader;
