import { saveAs } from "file-saver";
import printJS from "print-js";
import { UserDocumentType } from "../models/UserDocument.model";
import { ExportDocumentOptions } from "../pages/DocumentPage/document-collection-actions";
import { addToCollection, removeFromCollection } from "../services/user-services";
import { Selection } from "./useMultipleSelection";

const usePrintHandler = (
  dataToPrint: (options?: ExportDocumentOptions) => Promise<{
    contents: string;
  }>
) => {
  return async (options?: ExportDocumentOptions) => {
    const data = await dataToPrint(options);
    printJS({ printable: data.contents, type: "pdf", base64: true });
  };
};

const useExportHandler = (
  dataToExport: (options?: ExportDocumentOptions) => Promise<{
    contents: string;
    fileName: string;
  }>
) => {
  return async (options?: ExportDocumentOptions) => {
    const data = await dataToExport(options);
    const blob = "data:application/pdf;base64," + data.contents;
    saveAs(blob, data.fileName);
  };
};

const useExportRtfHandler = (
  dataToExport: (options?: ExportDocumentOptions) => Promise<{
    contents: string;
    fileName: string;
  }>
) => {
  return async (options?: ExportDocumentOptions) => {
    const data = await dataToExport(options);
    const blob = "data:application/rtf;base64," + data.contents;
    saveAs(blob, data.fileName);
  };
};

const useDocumentActionHandlers = (config: {
  docs: Selection[];
  printExport: (options?: ExportDocumentOptions) => Promise<{
    contents: string;
    fileName: string;
  }>;
  rtfExport: (options?: ExportDocumentOptions) => Promise<{
    contents: string;
    fileName: string;
  }>;
  handlerDecorator?: (
    func: (options?: ExportDocumentOptions) => Promise<void>
  ) => (options?: ExportDocumentOptions) => Promise<void>;
}) => {
  const addToFavourites = async () =>
    await addToCollection(
      config.docs.map((doc) => ({ ...doc, category: "За четене по-късно" })),
      UserDocumentType.FAVOURITE,
      "За четене по-късно"
    );

  const removeFromFavourites = async () =>
    await removeFromCollection(
      config.docs.map((doc) => doc._id),
      UserDocumentType.FAVOURITE,
      "За четене по-късно"
    );

  const handlePrint = usePrintHandler(config.printExport);

  const handleExport = useExportHandler(config.printExport);

  const handleRtfExport = useExportRtfHandler(config.rtfExport);

  const hd = config.handlerDecorator || ((func) => func);
  return {
    addToFavourites: hd(addToFavourites),
    removeFromFavourites: hd(removeFromFavourites),
    handlePrint: hd(handlePrint),
    handleExport: hd(handleExport),
    handleRtfExport: hd(handleRtfExport),
  };
};

export default useDocumentActionHandlers;
