import React, { useEffect, useRef } from "react";
import { PrimaryButton } from "../../components/Button/button";
import "./search-input.scss";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";

type SearchInputProps = {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onSearch: () => void;
  hasSearched: boolean;
  onClearSearch: () => void;
};

const SearchInputLexebraGPT: React.FC<SearchInputProps> = ({
  inputValue,
  setInputValue,
  onSearch,
  hasSearched,
  onClearSearch,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      autoResizeTextarea();
    }
  }, []);

  const autoResizeTextarea = () => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  return (
    <div className="textarea-button-container">
      <textarea
        className="form-control"
        placeholder="Търсене по правен въпрос"
        ref={ref}
        value={inputValue}
        rows={2}
        onChange={(e) => {
          setInputValue(e.target.value);
          autoResizeTextarea();
        }}
        style={{ resize: "none", overflow: "hidden" }}
      />
      <div className="button-group">
        <PrimaryButton
          messageId=""
          className="inside-textarea-primary-button"
          disabled={inputValue.trim() === ""}
          onClick={onSearch}
        >
          Питай LexebraGPT
        </PrimaryButton>
      </div>
      {hasSearched && inputValue.trim() !== "" && (
        <Button
          variant="link"
          className="clear-search noSelect"
          onClick={(e) => {
            e.preventDefault();
            onClearSearch();
          }}
        >
          <FormattedMessage id="current-filers.clear-search" />
        </Button>
      )}
    </div>
  );
};

export default SearchInputLexebraGPT;
