import React, { useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../contexts/auth.context";
import { confirmCode } from "../services/subscription-services";

const SubscriptionConfirmationPage = () => {
  const { confirmationId } = useParams();
  const { setShowLoginModal } = useAuth();
  const navigate = useNavigate();
  const intl = useIntl();
  const [result, setResult] = useState<string>();
  const [error, setError] = useState<string>();
  useEffect(() => {
    async function confirm() {
      try {
        confirmationId && (await confirmCode(confirmationId, "activate"));
        setResult("success");
      } catch (e: any) {
        console.log(e);
        setResult("error");
        if (e.status && e.status === 400) {
          (e.payload.subErrors || []).splice(0, 1).forEach((error: any) => {
            setError(error.message);
          });
        }
      }
    }
    confirm();
  }, [confirmationId, setError, intl]);

  return (
    <Container fluid className="app-page w-50" style={{ minWidth: "400px" }}>
      {result === "resent" && (
        <Alert variant="success">
          <FormattedMessage id="subscription-confirmation-page.modal.messages.resent" />
        </Alert>
      )}
      {result === "success" && (
        <Alert variant="success">
          <FormattedMessage
            id="subscription-confirmation-page.modal.messages.success"
            values={{
              b: (...chunks: any[]) => <b>{chunks}</b>,
              br: <br />,
              a: (...chunks: any[]) => (
                <Alert.Link
                  onClick={() =>
                    setShowLoginModal(true, {
                      cancelable: true,
                      successCallback: () => navigate("/"),
                    })
                  }
                >
                  {chunks}
                </Alert.Link>
              ),
            }}
          />
        </Alert>
      )}
      {result === "error" &&
        (error === "NotFound" ? (
          <Alert variant="danger">
            <FormattedMessage
              id="subscription-confirmation-page.modal.errors.NotFound"
              values={{
                b: (...chunks: any[]) => <b>{chunks}</b>,
              }}
            />
          </Alert>
        ) : error === "Expired" ? (
          <Alert variant="danger">
            <FormattedMessage
              id="subscription-confirmation-page.modal.errors.Expired"
              values={{
                b: (...chunks: any[]) => <b>{chunks}</b>,
                br: <br />,
                a: (...chunks: any[]) => (
                  <Alert.Link
                    onClick={async () => {
                      try {
                        confirmationId && (await confirmCode(confirmationId, "resend"));
                        setResult("resent");
                      } catch (e: any) {
                        console.log(e);
                        setResult("error");
                        setError("");
                      }
                    }}
                  >
                    {chunks}
                  </Alert.Link>
                ),
              }}
            />
          </Alert>
        ) : error === "AlreadyUsed" || error === "CodeAlreadyUsed" ? (
          <Alert variant="success">
            <FormattedMessage
              id="subscription-confirmation-page.modal.errors.AlreadyUsed"
              values={{
                br: <br />,
                a: (...chunks: any[]) => (
                  <Alert.Link
                    onClick={() =>
                      setShowLoginModal(true, {
                        cancelable: true,
                        successCallback: () => navigate("/"),
                      })
                    }
                  >
                    {chunks}
                  </Alert.Link>
                ),
              }}
            />
          </Alert>
        ) : error === "NotFound" ? (
          <Alert variant="danger">
            <FormattedMessage
              id="subscription-confirmation-page.modal.errors.NotActiveSubscription"
              values={{
                b: (...chunks: any[]) => <b>{chunks}</b>,
              }}
            />
          </Alert>
        ) : (
          <Alert variant="danger">
            <FormattedMessage id="subscription-confirmation-page.modal.errors.Unexpected" />
          </Alert>
        ))}
    </Container>
  );
};

export default SubscriptionConfirmationPage;
