import Collapse from "@kunukn/react-collapse";
import { Suspense, useEffect, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { InlineLinkButton, PrimaryButton, SecondaryButton } from "../../components/Button/button";
import Select from "../../components/Select";
import Skeleton from "../../components/Skeleton";
import { useSummaryEditContext } from "../../contexts/summary-edit.context";
import { RelatedActModalMode, useSummaryQaContext } from "../../contexts/summary-qa.context";
import { useSummaryContext } from "../../contexts/summary.context";
import ReferencedJudgementAct from "../../models/ReferencedJudgementAct.model";
import { SearchHit } from "../../models/SearchHit.model";
import ReferencedSummaryQa, { SummaryQaLinkType } from "../../models/SummaryQaLink.model";
import { DocumentStatusBadge, useSuggestions } from "../../services/document-services";
import { useJudgementAct } from "../../services/judgement-act-services";

type AddRelatedActModalProps = {
  append: (prov: ReferencedJudgementAct) => void;
  relatedAct?: ReferencedJudgementAct;
  setRelatedAct: (act?: ReferencedJudgementAct) => void;
};

const AddRelatedActModal: React.FC<AddRelatedActModalProps> = ({
  append,
  relatedAct,
  setRelatedAct,
}) => {
  const [selectedAct, setSelectedAct] = useState<
    SearchHit & { linkedQuestions: ReferencedSummaryQa[]; actDate?: string }
  >();
  const [selectedSummaryQa, setSelectedSummaryQa] = useState<ReferencedSummaryQa[]>([]);

  useEffect(() => {
    if (relatedAct) {
      setSelectedAct({
        id: relatedAct.id,
        text: relatedAct.title,
        status: relatedAct.status,
        category: "",
        linkedQuestions: [],
        actDate: relatedAct.actDate,
      });
    }
  }, [setSelectedAct, relatedAct]);

  const act = useJudgementAct(selectedAct?.id);
  const { modeAddRelatedActModal: mode, setModeAddRelatedActModal: setMode } =
    useSummaryQaContext();

  return (
    <>
      <span onClick={() => setMode("add", "")}>
        <FormattedMessage id="button.add" />
      </span>
      {(mode === "add" || mode === "edit") && (
        <SelectAct
          onConfirm={() => {
            append({
              id: selectedAct!.id,
              title: selectedAct!.text,
              status: selectedAct!.status,
              referencesTo: selectedSummaryQa,
              actDate: act!.actDate,
              referencesFrom: [],
            });
            setMode("hidden");
            setSelectedAct(undefined);
            setSelectedSummaryQa([]);
          }}
          mode={mode}
          setSelectedAct={setSelectedAct}
          selectedAct={selectedAct}
          setSelectedSummaryQa={setSelectedSummaryQa}
          selectedSummaryQa={selectedSummaryQa}
          relatedAct={relatedAct}
          onCancel={() => {
            setMode("hidden");
            setRelatedAct(undefined);
            setSelectedAct(undefined);
            setSelectedSummaryQa([]);
          }}
        />
      )}
    </>
  );
};

type SelectActProps = {
  selectedAct?: SearchHit;
  setSelectedAct: (
    act?: SearchHit & { linkedQuestions: ReferencedSummaryQa[]; actDate?: string }
  ) => void;
  selectedSummaryQa: ReferencedSummaryQa[];
  setSelectedSummaryQa: (qas: ReferencedSummaryQa[]) => void;
  relatedAct?: ReferencedJudgementAct;
  onConfirm: () => void;
  mode: RelatedActModalMode;
  onCancel: () => void;
};

const SelectAct: React.FC<SelectActProps> = ({
  selectedAct,
  setSelectedAct,
  selectedSummaryQa,
  setSelectedSummaryQa,
  onConfirm,
  mode,
  relatedAct,
  onCancel,
}) => {
  const intl = useIntl();
  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton messageId="button.confirm" onClick={onConfirm} disabled={!selectedAct} />
    </>
  );

  const { selectedText } = useSummaryEditContext();
  const [inputValue, setInputValue] = useState("");
  const { summary } = useSummaryContext();
  useEffect(() => {
    setInputValue(selectedText || "");
  }, [setInputValue, selectedText]);

  const options = useSuggestions(inputValue, "judgement-acts");
  return (
    <Modal
      show={true}
      centered
      size="xl"
      animation={false}
      className="modal-xxl"
      onEscapeKeyDown={onCancel}
    >
      <Modal.Header>
        <table>
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="add-related-act.modal.header" />
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                  ({summary.relatedJudgementAct.title})
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Select
          onChange={async (...event: any[]) => {
            setSelectedAct(event[0]);
            setSelectedSummaryQa([]);
            await navigator.clipboard.writeText(event[0].title || event[0].text);
            toast.info("Заглавието на акта бе копирано в клипборда");
          }}
          inputValue={inputValue}
          onInputChange={setInputValue}
          menuIsOpen={!!inputValue}
          value={selectedAct ? [selectedAct] : []}
          components={{
            DropdownIndicator: null,
          }}
          inputStyles={{ singleValue: { width: "90%", textOverflow: "ellipsis" } }}
          options={options}
          filterOption={() => true}
          isMulti={false}
          isClearable
          getOptionValue={(value: any) => value.text || value.title}
          getOptionLabel={(value: any) => (
            <>
              {value.text || value.title}
              <span style={{ position: "absolute", right: "5px" }}>
                <DocumentStatusBadge status={value.status} />
              </span>
            </>
          )}
          onKeyDown={(e: any) => {
            e.stopPropagation();
            if (e.key === "Escape") {
              onCancel();
            }
          }}
          placeholder={intl.formatMessage({ id: "add-related-act.modal.select-act.placeholder" })}
          isDisabled={mode === "edit"}
          autoFocus
        />
        <br />
        {selectedAct && (
          <Suspense fallback={<Skeleton height={15} count={5} />}>
            {
              <QaSelection
                selectedAct={selectedAct}
                setSelectedAct={setSelectedAct}
                relatedAct={relatedAct}
                setSelectedSummaryQa={setSelectedSummaryQa}
                selectedSummaryQa={selectedSummaryQa}
              />
            }
          </Suspense>
        )}
      </Modal.Body>

      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

const QaSelection = ({
  selectedAct,
  setSelectedAct,
  selectedSummaryQa,
  setSelectedSummaryQa,
  relatedAct,
}: {
  selectedAct?: SearchHit;
  setSelectedAct: (
    act: SearchHit & { linkedQuestions: ReferencedSummaryQa[]; actDate?: string }
  ) => void;
  selectedSummaryQa: ReferencedSummaryQa[];
  setSelectedSummaryQa: (qas: ReferencedSummaryQa[]) => void;
  relatedAct?: ReferencedJudgementAct;
}) => {
  const act = useJudgementAct(selectedAct?.id);
  const intl = useIntl();
  const questions = useMemo(
    () =>
      act?.summaries.flatMap((summary) =>
        summary.questions.map((question) => ({ ...question, type: SummaryQaLinkType.NONE }))
      ) as ReferencedSummaryQa[],
    [act]
  );

  useEffect(() => {
    setSelectedSummaryQa(
      questions?.map((q) => {
        const rq = relatedAct?.referencesTo.find((rq) => rq.id === q.id);
        return rq ? rq : q;
      }) || []
    );
  }, [questions, setSelectedSummaryQa, relatedAct]);

  const { summary } = useSummaryContext();

  return selectedSummaryQa && selectedSummaryQa.length > 0 ? (
    <Form.Group>
      <Form.Label>
        <FormattedMessage id="add-related-act.modal.form.related-questions" />{" "}
        <InlineLinkButton
          onClick={() => window.open(`/summaries/${act?.summaries[0].id}`, "_blank")}
        >
          {act?.summaries[0].shortTitle}
        </InlineLinkButton>
        <span style={{ right: "5px" }}>
          <DocumentStatusBadge status={act?.summaries[0].status} />
        </span>
      </Form.Label>

      <Table responsive style={{ width: "100%" }}>
        <colgroup>
          <col span={1} />
          <col span={1} style={{ width: "180px" }} />
        </colgroup>
        <tbody>
          {selectedSummaryQa?.map((question, i) => (
            <tr key={question.id}>
              <td>
                <QaText
                  value={"Въпрос " + (i + 1) + ": " + question.question}
                  muted={question.type === SummaryQaLinkType.NONE}
                />
              </td>
              <td>
                <Form.Select
                  size="sm"
                  value={question.type}
                  onChange={(e) => {
                    const newValue = selectedSummaryQa.map((v, idx) =>
                      i !== idx ? v : { ...v, type: e.target.value as SummaryQaLinkType }
                    );
                    setSelectedSummaryQa(newValue);
                  }}
                >
                  {Object.keys(SummaryQaLinkType).map((v) => (
                    <option value={v} key={v}>
                      {intl.formatMessage({ id: `summary.qa.link.type.${v}` })}
                    </option>
                  ))}
                </Form.Select>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Form.Group>
  ) : (
    <>
      {act?.actDate &&
        summary?.relatedJudgementAct.actDate &&
        (act?.actDate || "").localeCompare(summary?.relatedJudgementAct.actDate || "") > 0 && (
          <Alert variant="danger" style={{ marginLeft: "10px", marginRight: "10px" }}>
            Внимание: Избрали сте акт с бъдеща дата спрямо датата на резюмето, което редактирате!
            {act?.title}
            {relatedAct?.actDate}
          </Alert>
        )}
      <Alert variant="success" style={{ marginLeft: "10px", marginRight: "10px" }}>
        Няма свързани въпроси към избрания
        <InlineLinkButton
          onClick={() => window.open(`/judgement-acts/${selectedAct?.id}`, "_blank")}
        >
          &nbsp;акт.
        </InlineLinkButton>
      </Alert>

      {act?.summaries.length === 0 && (
        <Alert variant="warning" style={{ marginLeft: "10px", marginRight: "10px" }}>
          {" "}
          Няма резюме към избрания акт. Можете да създадете в нов таб от{" "}
          <InlineLinkButton
            onClick={() => window.open(`/judgement-acts/${act?.id}/summaries/new/`, "_blank")}
          >
            тук.
          </InlineLinkButton>
        </Alert>
      )}
    </>
  );
};

const QaText = ({ value, muted }: { value: string; muted: boolean }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Collapse isOpen={isOpen} collapseHeight="54px">
        <span
          style={{
            color: muted ? "#c1c1c1" : "#212529",
          }}
        >
          {value}
        </span>
      </Collapse>
      <InlineLinkButton onClick={() => setOpen(!isOpen)}>
        {!isOpen ? "Повече" : "По-малко"}
      </InlineLinkButton>
    </>
  );
};

export default AddRelatedActModal;
