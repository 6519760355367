import { useCallback, useState } from "react";
import { FormText, OverlayTrigger, Tooltip } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm, UseFormSetError } from "react-hook-form";
import { FaQuestionCircle } from "react-icons/fa";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import { PrimaryButton, SecondaryButton } from "../components/Button/button";
import { useAuth } from "../contexts/auth.context";
import SubscriberType from "../models/SubscriberType.enum";
import SubscriptionBeneficiaryStatus from "../models/SubscriptionBeneficiaryStatus.enum";
import { activateCode, getByCode } from "../services/subscription-services";
import { doLogin } from "./login.modal";

type RegistrationModalProps = {
  onClose: () => void;
};

type RegistrationModalFormModel = {
  code: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
};

enum RegistrationStep {
  FILL_CODE = 1,
  FILL_FULL_DATA = 2,
  FILL_PASS_ONLY = 3,
  DATA_SUBMITTED = 4,
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({ onClose }) => {
  const { handleSubmit, register, setError, setValue, formState, clearErrors, watch } =
    useForm<RegistrationModalFormModel>();
  const { hasSuccessCallback, isLoginCancelable, setCurrentUser } = useAuth();
  const intl = useIntl();

  const [step, setStep] = useState<RegistrationStep>(RegistrationStep.FILL_CODE);
  const onSubmit = useCallback(
    async (data: RegistrationModalFormModel) => {
      try {
        const beneficiary = await activateCode(data);
        if (beneficiary.status === SubscriptionBeneficiaryStatus.CONFIRMED) {
          await doLogin(data.email, data.password, undefined, setCurrentUser, () => {
            setTimeout(() => {
              toast.info(
                <FormattedMessage
                  id="registration.modal.toasts.complete.success"
                  values={{
                    br: <br />,
                  }}
                />
              );
            }, 100);

            onClose();
          });
        } else {
          setStep(RegistrationStep.DATA_SUBMITTED);
        }
      } catch (e: any) {
        handleApiErrors(e, setError, intl);
      }
    },
    [setError, onClose, setCurrentUser]
  );

  const code = watch("code");
  const email = watch("email");
  const [companyName, setCompanyName] = useState<string>();

  const fetchSubscriptionData = useCallback(
    async (data: RegistrationModalFormModel) => {
      if (data.code) {
        try {
          const subscription = await getByCode(data.code);
          const customerInfo = subscription.subscriber.customerInfo;
          if (
            subscription.subscriber.type === SubscriberType.PERSONAL &&
            subscription.licenseCount === 1
          ) {
            setValue("email", customerInfo.email);
            setValue("firstName", customerInfo.firstName!);
            setValue("lastName", customerInfo.lastName!);
            setStep(RegistrationStep.FILL_PASS_ONLY);
          } else {
            if (subscription.subscriber.type === SubscriberType.COMPANY) {
              setCompanyName(customerInfo.companyName);
            }
            setStep(RegistrationStep.FILL_FULL_DATA);
          }
        } catch (e: any) {
          handleApiErrors(e, setError, intl);
        }
      } else {
        setError("code", {
          type: "manual",
          message: intl.formatMessage({
            id: "registration.modal.form.errors.code.NotBlank",
          }),
        });
      }
    },
    [setValue, setStep, setError, intl]
  );

  useTitle(intl.formatMessage({ id: "registration.modal.header" }));

  return (
    <>
      <Modal
        show={true}
        centered
        animation={false}
        keyboard
        onEscapeKeyDown={onClose}
        onHide={onClose}
        backdrop={isLoginCancelable ? true : "static"}
        size="lg"
        fullscreen="sm-down"
      >
        <Modal.Header>
          <FormattedMessage id="registration.modal.header" />
        </Modal.Header>
        <Modal.Body>
          {hasSuccessCallback && (
            <Alert variant="primary">
              Достъпът до избраната функционалност изисква вход с потребителско име и парола.
            </Alert>
          )}
          {step === RegistrationStep.DATA_SUBMITTED && (
            <Alert variant="success">
              <FormattedMessage
                id="registration.modal.toasts.activation.success"
                values={{
                  br: <br />,
                  email,
                  b: (...chunks: any[]) => <b>{chunks}</b>,
                }}
              />
            </Alert>
          )}
          {step !== RegistrationStep.DATA_SUBMITTED && (
            <Form autoComplete="off" noValidate>
              <Form.Group controlId="code">
                <Form.Label>
                  <FormattedMessage id="registration.modal.form.fields.code" />
                  *&nbsp;
                  <OverlayTrigger
                    placement="right"
                    overlay={(props) => (
                      <Tooltip id="code-tooltip" {...props}>
                        Кодът за регистрация, който сте получили след заплащане на абонамент
                      </Tooltip>
                    )}
                  >
                    <span>
                      <FaQuestionCircle className="text-muted" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("code")}
                  autoFocus
                  readOnly={step !== RegistrationStep.FILL_CODE}
                />
                {!!formState.errors.code ? (
                  <FormText className="text-danger">{formState.errors.code.message}</FormText>
                ) : (
                  <Form.Text className="text-muted">
                    {companyName ? 'Регистрация към абонамента на "' + companyName + '"' : ""}
                  </Form.Text>
                )}
              </Form.Group>
              {step !== RegistrationStep.FILL_CODE && (
                <>
                  <Form.Group controlId="email">
                    <Form.Label>
                      <FormattedMessage id="registration.modal.form.fields.email" />*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("email")}
                      readOnly={step !== RegistrationStep.FILL_FULL_DATA}
                    />
                    {!!formState.errors.email ? (
                      <FormText className="text-danger">{formState.errors.email.message}</FormText>
                    ) : (
                      <Form.Text className="text-muted">
                        Адресът ще бъде използван за потребителско име и управление на профила
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="firstName">
                    <Form.Label>
                      <FormattedMessage id="registration.modal.form.fields.firstName" />*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("firstName")}
                      readOnly={step !== RegistrationStep.FILL_FULL_DATA}
                    />
                    {!!formState.errors.firstName && (
                      <FormText className="text-danger">
                        {formState.errors.firstName.message}
                      </FormText>
                    )}
                  </Form.Group>
                  <Form.Group controlId="lastName">
                    <Form.Label>
                      <FormattedMessage id="registration.modal.form.fields.lastName" />*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("lastName")}
                      readOnly={step !== RegistrationStep.FILL_FULL_DATA}
                    />
                    {!!formState.errors.lastName && (
                      <FormText className="text-danger">
                        {formState.errors.lastName.message}
                      </FormText>
                    )}
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>
                      <FormattedMessage id="login.modal.form.fields.password" />
                      *&nbsp;
                      <OverlayTrigger
                        placement="right"
                        overlay={(props) => (
                          <Tooltip id="code-tooltip" {...props}>
                            Паролата трябва да е с дължина от поне 8 символа.
                          </Tooltip>
                        )}
                      >
                        <span>
                          <FaQuestionCircle className="text-muted" />
                        </span>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      {...register("password")}
                      disabled={
                        step !== RegistrationStep.FILL_FULL_DATA &&
                        step !== RegistrationStep.FILL_PASS_ONLY
                      }
                    />
                    {!!formState.errors.password && (
                      <FormText className="text-danger">
                        {formState.errors.password.message}
                      </FormText>
                    )}
                  </Form.Group>
                  <Form.Group controlId="passwordConfirm">
                    <Form.Label>
                      <FormattedMessage id="registration.modal.form.fields.passwordConfirm" />*
                    </Form.Label>
                    <Form.Control
                      type="password"
                      {...register("passwordConfirm")}
                      disabled={
                        step !== RegistrationStep.FILL_FULL_DATA &&
                        step !== RegistrationStep.FILL_PASS_ONLY
                      }
                    />
                    {!!formState.errors.passwordConfirm && (
                      <FormText className="text-danger">
                        {formState.errors.passwordConfirm.message}
                      </FormText>
                    )}
                  </Form.Group>
                </>
              )}
            </Form>
          )}
        </Modal.Body>

        <Modal.Footer>
          {step !== RegistrationStep.DATA_SUBMITTED ? (
            <>
              <SecondaryButton
                messageId="button.cancel"
                onClick={onClose}
                disabled={formState.isSubmitting}
              />

              {step !== RegistrationStep.FILL_CODE ? (
                <PrimaryButton
                  messageId="button.confirm"
                  onClick={(e: any) => {
                    clearErrors();
                    handleSubmit(onSubmit)(e);
                  }}
                  disabled={formState.isSubmitting}
                  submitting={formState.isSubmitting}
                />
              ) : (
                <PrimaryButton
                  messageId="button.continue"
                  onClick={async (e: any) => {
                    clearErrors();
                    handleSubmit(fetchSubscriptionData)(e);
                  }}
                  disabled={!code}
                />
              )}
            </>
          ) : (
            <PrimaryButton messageId="button.close" onClick={onClose} />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const handleApiErrors = (
  e: any,
  setError: UseFormSetError<RegistrationModalFormModel>,
  intl: IntlShape
) => {
  if (e.status && e.status === 400) {
    (e.payload.subErrors || []).forEach((error: any) => {
      setError(error.field, {
        type: "manual",
        message: intl.formatMessage({
          id: `registration.modal.form.errors.${error.field}.${error.message}`,
        }),
      });
    });
  }
};

export default RegistrationModal;
