import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { useTitle } from "react-use";
import { PrimaryButton } from "../components/Button/button";

const TooManyRequestsErrorModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const intl = useIntl();

  useTitle(intl.formatMessage({ id: "too-many-requests.modal.header" }));

  return (
    <Modal
      show={true}
      centered
      animation={false}
      keyboard
      onEscapeKeyDown={onClose}
      onHide={onClose}
      backdrop={"static"}
      fullscreen="sm-down"
    >
      <Modal.Header>
        <FormattedMessage id="too-many-requests.modal.header" />
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          Надвишили сте месечния лимит за експорт/принт на документи. Моля свържете се с нас на{" "}
          <b>info@lexebra.com</b>.
        </Alert>
      </Modal.Body>

      <Modal.Footer>
        <PrimaryButton messageId="button.close" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default TooManyRequestsErrorModal;
