import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Form, FormText, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { ActionButton } from "../components/Button/button";
import { useAuth } from "../contexts/auth.context";
import PasswordResetRequestStatus from "../models/PasswordResetRequestStatus.enum";
import {
  fetchPasswordResetRequest,
  reCreatePasswordResetRequest,
  updatePasswordResetRequestStatus,
  UpdatePasswordResetRequestStatusInput,
} from "../services/password-reset-services";
import EditPageLayout from "./DocumentPage/edit-page-layout";

const PasswordResetRequestPage = () => {
  const { resetRequestId } = useParams();
  const { setShowLoginModal } = useAuth();
  const navigate = useNavigate();
  const intl = useIntl();
  const [result, setResult] = useState<string>();
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    setError: setFormError,
    formState,
  } = useForm<UpdatePasswordResetRequestStatusInput>({
    defaultValues: {
      status: PasswordResetRequestStatus.USED,
    },
  });

  useEffect(() => {
    async function validate() {
      try {
        resetRequestId && (await fetchPasswordResetRequest(resetRequestId, true));
        setResult("link-ok");
      } catch (e: any) {
        console.log(e);
        setResult("error");
        if (e.status && e.status === 400) {
          (e.payload.subErrors || []).splice(0, 1).forEach((error: any) => {
            setError(error.message);
          });
        }
      }
    }
    validate();
  }, [resetRequestId, setError, intl]);

  const onSubmit = async (data: any) => {
    try {
      resetRequestId && (await updatePasswordResetRequestStatus(resetRequestId, data));
      setResult("success");
    } catch (e: any) {
      console.log(e);
      setResult("error");
      if (e.status && e.status === 400) {
        let hasGenericError = false;
        (e.payload.subErrors || [])
          .filter((error: any) => error.field && error.field.indexOf("password") === -1)
          .splice(0, 1)
          .forEach((error: any) => {
            setError(error.message);
            hasGenericError = true;
          });
        if (!hasGenericError) {
          setResult("form-error");
          (e.payload.subErrors || [])
            .filter((error: any) => error.field && error.field.indexOf("password") > -1)
            .forEach((error: any) => {
              setFormError(error.field, {
                type: "manual",
                message: intl.formatMessage({
                  id: `password-reset-page.modal.form.errors.${error.field}.${error.message}`,
                }),
              });
            });
        }
      }
    }
  };

  return (
    <Container fluid className="app-page" style={{ minWidth: "400px" }}>
      <EditPageLayout
        menu={<></>}
        title={intl.formatMessage({ id: "password-reset-page.modal.title" })}
      >
        {result === "resent" && (
          <Alert variant="success">
            <FormattedMessage id="password-reset-page.modal.messages.resent" />
          </Alert>
        )}
        {result === "success" && (
          <Alert variant="success">
            <FormattedMessage
              id="password-reset-page.modal.messages.success"
              values={{
                br: <br />,
                a: (...chunks: any[]) => (
                  <Alert.Link
                    onClick={() =>
                      setShowLoginModal(true, {
                        cancelable: true,
                        successCallback: () => navigate("/"),
                      })
                    }
                  >
                    {chunks}
                  </Alert.Link>
                ),
              }}
            />
          </Alert>
        )}
        {(result === "link-ok" || result === "form-error") && (
          <Form autoComplete="off">
            <Row>
              <Col md="6">
                <Form.Group controlId="password">
                  <Form.Label>
                    <FormattedMessage id="password-reset-page.modal.form.password" />
                  </Form.Label>
                  <Form.Control type="password" {...register("password")} />
                  {!!formState.errors.password && (
                    <FormText className="text-danger">{formState.errors.password.message}</FormText>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="passwordConfirm">
                  <Form.Label>
                    <FormattedMessage id="password-reset-page.modal.form.passwordConfirm" />
                  </Form.Label>
                  <Form.Control type="password" {...register("passwordConfirm")} />
                  {!!formState.errors.passwordConfirm && (
                    <FormText className="text-danger">
                      {formState.errors.passwordConfirm.message}
                    </FormText>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ActionButton
                  onClick={handleSubmit(onSubmit)}
                  disabled={formState.isSubmitting}
                  submitting={formState.isSubmitting}
                  messageId={"button.confirm"}
                />
              </Col>
            </Row>
          </Form>
        )}
        {result === "error" &&
          (error === "NotFound" ? (
            <Alert variant="danger">
              <FormattedMessage
                id="password-reset-page.modal.errors.NotFound"
                values={{
                  b: (...chunks: any[]) => <b>{chunks}</b>,
                }}
              />
            </Alert>
          ) : error === "Expired" ? (
            <Alert variant="danger">
              <FormattedMessage
                id="password-reset-page.modal.errors.Expired"
                values={{
                  b: (...chunks: any[]) => <b>{chunks}</b>,
                  br: <br />,
                  a: (...chunks: any[]) => (
                    <Alert.Link
                      onClick={async () => {
                        try {
                          resetRequestId && (await reCreatePasswordResetRequest(resetRequestId));
                          setResult("resent");
                        } catch (e: any) {
                          console.log(e);
                          setResult("error");
                          setError("");
                        }
                      }}
                    >
                      {chunks}
                    </Alert.Link>
                  ),
                }}
              />
            </Alert>
          ) : error === "InvalidStatus" ? (
            <Alert variant="danger">
              <FormattedMessage
                id="password-reset-page.modal.errors.InvalidStatus"
                values={{
                  br: <br />,
                  a: (...chunks: any[]) => (
                    <Alert.Link
                      onClick={async () => {
                        try {
                          resetRequestId && (await reCreatePasswordResetRequest(resetRequestId));
                          setResult("resent");
                        } catch (e: any) {
                          console.log(e);
                          setResult("error");
                          setError("");
                        }
                      }}
                    >
                      {chunks}
                    </Alert.Link>
                  ),
                }}
              />
            </Alert>
          ) : (
            <Alert variant="danger">
              <FormattedMessage id="subscription-confirmation-page.modal.errors.Unexpected" />
            </Alert>
          ))}
      </EditPageLayout>
    </Container>
  );
};

export default PasswordResetRequestPage;
