/* eslint-disable react/jsx-props-no-spreading */
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useIntl } from "react-intl";
import { ManageScroll } from "../../../components/ManageScroll";
import { withActiveSubscrption } from "../../../contexts/auth.context";
import useRemindExpireSoon from "../../../hooks/useRemindExpireSoon";
import { useDocumentStats } from "../../../services/document-services";
import { useCurrentUser } from "../../../services/user-services";
import AppPageLayout from "../../DocumentPage/app-page-layout";
import FiltersSideBar from "../FiltersSidebar/filters-sidebar";
import SearchInput from "../SearchInput";
import CategoryCards from "./category-cards";
import "./search-landing.scss";
import SubscribeToTopicCard from "./subscribe-topic-card";

const SearchLanding = () => {
  const intl = useIntl();
  const stats = useDocumentStats();
  const currentUser = useCurrentUser();
  useRemindExpireSoon();
  return (
    <AppPageLayout
      navigation={<FiltersSideBar landingPage />}
      title={intl.formatMessage({ id: "landing-page.title" })}
      className="search-landing-page"
    >
      <ManageScroll />
      <SearchInput />
      <CategoryCards stats={stats} topics={currentUser?.topics || []}>
        <SubscribeToTopicCard />
      </CategoryCards>
    </AppPageLayout>
  );
};

export default withActiveSubscrption(SearchLanding);
