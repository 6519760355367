import { useEffect } from "react";
import { isMobileOnly } from "react-device-detect";

const useBodyScrollLock = (showMenu: boolean) => {
  useEffect(() => {
    if (showMenu && isMobileOnly) {
      const element = (document.scrollingElement as HTMLElement) || document.documentElement;
      const previousValue = element.style.overflowY;
      element.style.overflowY = "hidden";
      return () => {
        if (previousValue !== "hidden") {
          element.style.overflowY = previousValue;
        }
      };
    }
  }, [showMenu]);
};

export default useBodyScrollLock;
