import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { FaTimes } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import ReferencedJudgementAct from "../../models/ReferencedJudgementAct.model";
import { DocumentStatusBadge } from "../../services/document-services";
import AddRelatedActModal from "./add-related-act.modal";
import "./related-acts.table.css";

const RelatedActsTable: React.FC<{
  value: ReferencedJudgementAct[];
  append: (data: ReferencedJudgementAct) => void;
  remove: (idx: number) => void;
  replace: (idx: number, data: ReferencedJudgementAct) => void;
}> = ({ value, append, remove, replace }) => {
  const intl = useIntl();

  const [selectedAct, setSelectedAct] = useState<ReferencedJudgementAct>();

  return (
    <Tabs activeKey={"0"} className="question-sub-tabs">
      <Tab
        eventKey="0"
        disabled
        title={<FormattedMessage id={`edit-summary.form.relatedJudgementActs`} />}
      >
        {value.length === 0 ? (
          <span style={{ padding: "12px" }}>Липсва информация за свързани актове</span>
        ) : (
          <Table responsive style={{ width: "100%" }} className="related-acts">
            <colgroup>
              <col span={1} />
              <col span={1} style={{ width: "100px" }} />
              <col span={1} style={{ width: "87px" }} />
            </colgroup>

            <tbody>
              {[...value]
                .sort((a1, a2) => (a1.actDate || "").localeCompare(a2.actDate || ""))
                .map((v, i) => (
                  <tr key={v.id}>
                    <td>
                      {v.title}
                      <span style={{ marginLeft: "5px" }}>
                        <DocumentStatusBadge status={v.status} />
                      </span>
                    </td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          const idx = value.findIndex((existingAct) => existingAct.id === v.id);
                          remove(idx);
                        }}
                        title={intl.formatMessage({ id: "button.remove" })}
                        className="remove-link"
                      >
                        <FaTimes />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </Tab>

      <Tab
        eventKey="-1"
        title={
          <AddRelatedActModal
            append={(act) => {
              const idx = value.findIndex((existingAct) => existingAct.id === act.id);
              if (idx > -1) {
                replace(idx, { ...act });
              } else {
                append(act);
              }
            }}
            relatedAct={selectedAct}
            setRelatedAct={setSelectedAct}
          />
        }
      />
    </Tabs>
  );
};

export default RelatedActsTable;
