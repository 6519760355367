import dayjs from "dayjs";
import { EntityModel, PagedModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, { get, mutate, post, prefetch, prefetchSearchRequest, put } from "../api";
import { Bulletin } from "../models/Bulletin.model";
import { JudgementAct } from "../models/JudgementAct.model";
import { StatsActsPeriod } from "../models/StatsActsPeriod.model";

const BULLETINS_BASE_URL = "/bulletins";

export const useBulletins = (period?: Date) =>
  useRequest<PagedModel<Bulletin>>(
    period ? `${BULLETINS_BASE_URL}?period=${dayjs(period).format("YYYY-MM")}` : null
  );

export const prefetchBulletins = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(BULLETINS_BASE_URL, searchParams, cache);

export const useBulletin = (id?: string) => {
  const { data } = useRequest<EntityModel<Bulletin>>(`${BULLETINS_BASE_URL}/${id || "latest"}`);
  return data;
};

export const useExistingBulletin = (id?: string) => {
  const { data } = useRequest<EntityModel<Bulletin>>(id ? `${BULLETINS_BASE_URL}/${id}` : null);
  return data;
};

export const useLatestBulletin = () => {
  const { data } = useRequest<EntityModel<Bulletin>>(`${BULLETINS_BASE_URL}/latest`);
  return data;
};

export const useBulletinFocusedActs = (id: string) => {
  return useRequest<PagedModel<JudgementAct>>(`${BULLETINS_BASE_URL}/${id}/focused-acts`);
};

export const useBulletinStats = (id: string) => {
  return useRequest<StatsActsPeriod[]>(`${BULLETINS_BASE_URL}/${id}/stats`)?.data;
};

export const useNextIssueNumber = (year?: number) => {
  const { data } = useRequest<{ year: string; sequence: string }>(
    year ? `${BULLETINS_BASE_URL}/next-issue-number?year=${year}` : null
  );
  return data;
};

export const fetchBulletin = (id: string) => {
  return get<EntityModel<Bulletin>>(`${BULLETINS_BASE_URL}/${id}`);
};

export const fetchBulletinContentsTemplate = (from: string, to: string) => {
  return get<{ data: string }>(`${BULLETINS_BASE_URL}/contents-template`, {
    from,
    to,
  });
};

export const fetchBulletinWord = (
  id: string,
  options?: {
    includeArguments: boolean;
    includeQuestionMetadata: boolean;
  }
) => {
  return post(`${BULLETINS_BASE_URL}/${id}/docx`, options);
};

export const prefetchBulletin = (id: string, cache: Cache<any>) =>
  prefetch(`${BULLETINS_BASE_URL}/${id}`, cache);

export const saveBulletin = async (data: Bulletin, bulletinId?: string) => {
  const result = (
    bulletinId
      ? await put(`${BULLETINS_BASE_URL}/${bulletinId}`, data)
      : await post(`${BULLETINS_BASE_URL}`, data)
  ) as EntityModel<Bulletin>;
  mutate(`${BULLETINS_BASE_URL}/${bulletinId}`, () => result, false);
  return result;
};

export const fetchBulletinPdf = (
  id: string,
  data: {
    contents: string;
  }
) => {
  return post(`${BULLETINS_BASE_URL}/${id}/pdf`, data);
};

export const bulletinHref = (id: string): string => `${BULLETINS_BASE_URL}/${id}`;
