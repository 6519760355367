import "@fortawesome/fontawesome-free/css/all.css";
import React, { ReactNode } from "react";
import { Badge } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useLocation } from "react-router";
import { useSwipeable } from "react-swipeable";
import { Cache } from "swr";
import img_bgacts from "../../../assets/images/thumbnails/bgacts.png";
import img_euacts from "../../../assets/images/thumbnails/euacts.png";
import img_news from "../../../assets/images/thumbnails/news.png";
import img_questions from "../../../assets/images/thumbnails/questions.png";
import img_summaries from "../../../assets/images/thumbnails/summaries.png";
import PreloadingLink from "../../../components/PreloadingLink";
import Skeleton from "../../../components/Skeleton";
import { useSearchContext } from "../../../contexts/search.context";
import {
  prefetchAdmJudgementActs,
  prefetchConstJudgementActs,
  prefetchEuJudgementActs,
  prefetchJudgementActs,
  prefetchSupremeCourtJudgementActs,
} from "../../../services/judgement-act-services";
import { prefetchNewsArticles } from "../../../services/news-article-services";
import { prefetchQuestions, prefetchSummaries } from "../../../services/summary-services";
import { displayCount, getTabUrl, useSearchCategory } from "../search-utils";

type SearchResultsNavProps = {
  domJudgementActCount?: number;
  admJudgementActCount?: number;
  constJudgementActCount?: number;
  supremeCourtJudgementActCount?: number;
  euJudgementActCount?: number;
  summariesCount?: number;
  questionsCount?: number;
  newsArticlesCount?: number;
};

const SearchResultsNav: React.FC<SearchResultsNavProps> = ({
  domJudgementActCount,
  admJudgementActCount,
  constJudgementActCount,
  supremeCourtJudgementActCount,
  euJudgementActCount,
  summariesCount,
  questionsCount,
  newsArticlesCount,
}) => {
  const category = useSearchCategory();
  const { search } = useSearchContext();
  const searchParams = new URLSearchParams(search);
  const handlers = useSwipeable({
    onSwiped: (eventData) => eventData.event.stopPropagation(),
  });
  return domJudgementActCount !== undefined &&
    euJudgementActCount !== undefined &&
    summariesCount !== undefined &&
    newsArticlesCount !== undefined ? (
    <Nav className={`search-results-nav nav-tabs`} activeKey={category} {...handlers}>
      <SearchResultsNavLink
        searchCategory="acts"
        label="ВКС"
        count={domJudgementActCount}
        icon={img_bgacts}
        preload={(cache) => prefetchJudgementActs(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="summaries"
        label="Резюмета"
        count={summariesCount}
        icon={img_summaries}
        preload={(cache) => prefetchSummaries(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="questions"
        label="Въпроси"
        count={questionsCount}
        icon={img_questions}
        preload={(cache) => prefetchQuestions(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="adm-acts"
        label="ВАС"
        count={admJudgementActCount}
        icon={img_bgacts}
        preload={(cache) => prefetchAdmJudgementActs(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="sc-acts"
        label="ВС"
        count={supremeCourtJudgementActCount}
        icon={img_bgacts}
        preload={(cache) => prefetchSupremeCourtJudgementActs(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="eu-acts"
        label="ЕС"
        count={euJudgementActCount}
        icon={img_euacts}
        preload={(cache) => prefetchEuJudgementActs(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="news-articles"
        label="Новини"
        count={newsArticlesCount}
        icon={img_news}
        preload={(cache) => prefetchNewsArticles(searchParams, cache)}
      />
      <SearchResultsNavLink
        searchCategory="const-acts"
        label="КС"
        count={constJudgementActCount}
        icon={img_bgacts}
        preload={(cache) => prefetchConstJudgementActs(searchParams, cache)}
      />
    </Nav>
  ) : (
    <div style={{ display: "flex" }}>
      <Skeleton height="38px" style={{ marginLeft: "20px", marginRight: "20px" }} />
    </div>
  );
};

type SearchResultsNavLinkProps = {
  searchCategory: string;
  label: string;
  icon: string;
  count?: number;
  preload: (cache: Cache<any>) => Promise<any>;
};

const SearchResultsNavLink: React.FC<SearchResultsNavLinkProps> = ({
  searchCategory,
  label,
  count,
  icon,
  preload,
}) => {
  return (
    <span style={{ display: count === 0 ? "none" : "contents" }}>
      <Nav.Link eventKey={searchCategory} as={NavTabLink(searchCategory, preload)}>
        <img src={icon} alt={label} className="search-category-icon" />
        <span className="search-category-label">
          {label}{" "}
          <span className="search-category-count">
            {count && count > 0 && <Badge bg="secondary">{displayCount(count)}</Badge>}
          </span>
        </span>
      </Nav.Link>
    </span>
  );
};

const NavTabLink = (category: string, preload: (cache: Cache<any>) => Promise<any>) => {
  const location = useLocation();
  return ({ children, className }: { children: ReactNode; className: string }) => (
    <PreloadingLink
      to={getTabUrl(location, category)}
      className={className}
      event="onMouseEnter"
      preload={(cache) => preload(cache)}
    >
      {children}
    </PreloadingLink>
  );
};

export default SearchResultsNav;
