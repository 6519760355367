import { saveAs } from "file-saver";
import { Suspense, useCallback, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import {
  ActionButton,
  InlineLinkButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Button/button";
import RichTextEditor from "../../components/RichTextEditor";
import Select from "../../components/Select";
import Skeleton from "../../components/Skeleton";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import { ProvisionModalMode } from "../../contexts/summary-edit.context";
import Provision from "../../models/Provision.model";
import {
  addProvision,
  deleteProvision,
  editProvision,
  fetchAllProvisions,
  fetchProvisions,
  useProvision,
  useProvisions,
} from "../../services/provision.services";
import { getProvisionFullTitle } from "../../services/ref-data-services";

type AddProvisionModalProps = {
  append: (prov: Provision) => void;
  remove: (id: string) => void;
  selectedForEdit?: Provision;
  setSelectedForEdit: (prov?: Provision) => void;
  documentTitle: string;
  mode: ProvisionModalMode;
  setMode: (mode: ProvisionModalMode) => void;
  selectedText?: string;
};

const AddProvisionModal: React.FC<AddProvisionModalProps> = ({
  append,
  selectedForEdit,
  setSelectedForEdit,
  documentTitle,
  mode,
  remove,
  setMode,
  selectedText,
}) => {
  const [selectedProvision, setSelectedProvision] = useState<Provision | undefined>();

  useEffect(() => {
    setSelectedProvision(selectedForEdit);
    selectedForEdit && setMode("select");
  }, [selectedForEdit, setMode]);

  return (
    <>
      {mode === "hidden" && (
        <InlineLinkButton messageId="button.add" onClick={() => setMode("select")} />
      )}
      {mode === "select" && (
        <SelectProvision
          onConfirm={() => {
            append({ ...selectedProvision! });
            setMode("hidden");
            setSelectedProvision(undefined);
            setSelectedForEdit(undefined);
          }}
          setMode={setMode}
          setSelectedProvision={setSelectedProvision}
          selectedProvision={selectedProvision}
          onCancel={() => {
            setMode("hidden");
            setSelectedProvision(undefined);
            setSelectedForEdit(undefined);
          }}
          documentTitle={documentTitle}
          selectedText={selectedText}
        />
      )}
      {(mode === "add" || mode === "edit") && (
        <CreateOrEditProvision
          onConfirm={(provision) => {
            setMode("select");
            setSelectedProvision(provision);
          }}
          mode={mode}
          selectedProvision={selectedProvision}
          onCancel={() => {
            setMode("select");
          }}
          documentTitle={documentTitle}
          selectedText={selectedText}
        />
      )}
      {mode === "delete" && (
        <DeleteProvision
          onConfirm={(provision) => {
            setMode("select");
            remove(selectedProvision!.id);
            setSelectedProvision(provision);
          }}
          selectedProvision={selectedProvision}
          onCancel={() => {
            setMode("select");
          }}
          documentTitle={documentTitle}
        />
      )}
    </>
  );
};

type SelectProvisionProps = {
  selectedProvision?: Provision;
  onConfirm: () => void;
  setSelectedProvision: (provision: Provision) => void;
  setMode: (mode: ProvisionModalMode) => void;
  onCancel: () => void;
  documentTitle: string;
  selectedText?: string;
};

const replacer = (key: any, value: any) => (value === null ? "" : value);

const convertToCSV = (data: any, delimiter = ",") => {
  if (!data || data.length === 0) return "";

  const csvRows = data.map((row: any) =>
    Object.values(row)
      .map((value) => `"${String(value).replace(/"/g, '""')}"`)
      .join(delimiter)
  );

  return "\uFEFF" + csvRows.join("\n");
};

const getFormattedDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year}_${hours}-${minutes}-${seconds}`;
};

const exportProvisionsToCSV = async () => {
  try {
    const provisions = await fetchAllProvisions();
    const csv = convertToCSV(provisions, ";");
    const blob = new Blob([csv], { type: "text/csv;charset=Windows-1251;" });
    const dateTime = getFormattedDateTime();
    const filename = `provisions_${dateTime}.csv`;
    saveAs(blob, filename);
    toast.success("Успешно експортиране на всички разпоредби в csv файл!");
  } catch (error) {
    console.log(error);
    toast.error("Грешка при експортирането на разпоредбите!");
  }
};

const SelectProvision: React.FC<SelectProvisionProps> = ({
  selectedProvision,
  onConfirm,
  setSelectedProvision,
  setMode,
  onCancel,
  documentTitle,
  selectedText,
}) => {
  const intl = useIntl();
  const buttons = (
    <>
      <Visible when={hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)}>
        <PrimaryButton onClick={exportProvisionsToCSV}>
          <FormattedMessage id="add-provision.modal.export-provisions" />
        </PrimaryButton>
      </Visible>
      <PrimaryButton onClick={() => setMode("add")}>
        {!selectedProvision ? (
          <FormattedMessage id="add-provision.modal.create-provision" />
        ) : (
          <FormattedMessage id="add-provision.modal.clone-provision" />
        )}
      </PrimaryButton>
      <Visible when={hasPrivilege(Privileges.EDIT_PROVISIONS)}>
        {!!selectedProvision && (
          <PrimaryButton
            onClick={async () => {
              setMode("edit");
            }}
          >
            <FormattedMessage id="add-provision.modal.edit-provision" />
          </PrimaryButton>
        )}
        {!!selectedProvision && (
          <PrimaryButton
            onClick={async () => {
              setMode("delete");
            }}
          >
            <FormattedMessage id="add-provision.modal.delete-provision" />
          </PrimaryButton>
        )}
      </Visible>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <PrimaryButton messageId="button.confirm" onClick={onConfirm} disabled={!selectedProvision} />
    </>
  );
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue(selectedText || "");
  }, [setInputValue, selectedText]);
  const options = useProvisions(inputValue);

  return (
    <Modal
      show={true}
      centered
      size="xl"
      className="modal-xxl"
      onEscapeKeyDown={onCancel}
      animation={false}
    >
      <Modal.Header style={{ alignItems: "center", padding: "0.6rem" }}>
        <table>
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="add-provision.modal.header" />
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>({documentTitle})</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Select
          onChange={async (...event: any[]) => {
            setSelectedProvision(event[0]);
            await navigator.clipboard.writeText(event[0].title);
            toast.info("Заглавието на разпоредбата бе копирано в клипборда");
          }}
          value={selectedProvision ? [selectedProvision] : []}
          components={{
            DropdownIndicator: null,
          }}
          inputValue={inputValue}
          onInputChange={setInputValue}
          menuIsOpen={!!inputValue}
          isClearable
          shadowed
          options={options}
          filterOption={() => true}
          getOptionValue={(provision: Provision) => provision.id}
          getOptionLabel={(provision: Provision) => getProvisionFullTitle(provision)}
          placeholder={intl.formatMessage({ id: "add-provision.modal.select.placeholder" })}
          autoFocus
        />
        <br />
        <Suspense fallback={<Skeleton height={15} count={5} />}>
          <ProvisionText
            selectedProvision={selectedProvision}
            setSelectedProvision={setSelectedProvision}
          />
        </Suspense>
      </Modal.Body>

      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

type CreateOrEditProvisionProps = {
  onConfirm: (prov: Provision) => void;
  mode: ProvisionModalMode;
  selectedProvision?: Provision;
  onCancel: () => void;
  documentTitle: string;
  selectedText?: string;
};

const CreateOrEditProvision: React.FC<CreateOrEditProvisionProps> = ({
  onConfirm,
  mode,
  selectedProvision,
  onCancel,
  documentTitle,
  selectedText,
}) => {
  const isAddMode = mode === "add";

  const provision = useProvision(selectedProvision?.id);

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<Provision>({
    defaultValues: { title: selectedText, ...selectedProvision, text: provision?.text },
  });

  const [selectedTemplateProvision, setSelectedTemplateProvision] = useState<Provision | undefined>(
    selectedProvision
  );
  const templateProvision = useProvision(selectedTemplateProvision?.id);

  const [createAnotherProvision, setCreateAnotherProvision] = useState<boolean>(false);
  const [duplicatedProvision, setDuplicatedProvision] = useState<Provision>();
  const duplicatedProvisionFull = useProvision(duplicatedProvision?.id);

  const onSumbit = async (data: Provision) => {
    try {
      const provision = isAddMode
        ? await addProvision(data)
        : await editProvision(selectedProvision!.id, data);
      if (isAddMode && createAnotherProvision) {
        setValue("id", null as any);
        setDuplicatedProvision(provision);
      } else {
        onConfirm(provision);
      }
      toast.success(
        isAddMode
          ? `Разпоредба "${provision.title}" беше успешно добавена.`
          : `Разпоредба "${data.title}" беше успешно обновена.`
      );
      await navigator.clipboard.writeText(data.title);
      toast.info("Заглавието на разпоредбата бе копирано в клипборда");
    } catch (e) {
      console.log(e);
      toast.error(
        isAddMode
          ? `Възникна грешка при добавяне на разпоредба "${data.title}".`
          : `Възникна грешка при обновяване на разпоредба "${data.title}".`
      );
    }
  };

  const id = watch("id");
  const title = watch("title");
  const sgIssueNumber = watch("sgIssueNumber");
  const sgYear = watch("sgYear");
  const text = watch("text");

  useEffect(() => {
    if (isAddMode && templateProvision) {
      setValue("id", null as any);
      setValue("title", templateProvision?.title || "");
      setValue("sgIssueNumber", templateProvision?.sgIssueNumber || "");
      setValue("sgYear", templateProvision?.sgYear || "");
      setValue("text", templateProvision?.text || "");
    }
  }, [templateProvision, setValue, isAddMode]);

  useEffect(() => {
    (async () => {
      if (title) {
        const possibleDuplicates = await fetchProvisions(title);
        setDuplicatedProvision(
          (possibleDuplicates || []).filter(
            (dup) =>
              dup.title === title &&
              (dup.sgIssueNumber || "") === (sgIssueNumber || "") &&
              (dup.sgYear || "") === (sgYear || "") &&
              id !== dup.id
          )[0]
        );
      } else {
        setDuplicatedProvision(undefined);
      }
    })();
  }, [title, sgIssueNumber, sgYear, id]);

  const buttons = (
    <>
      {isAddMode && (
        <Form.Group
          controlId="createAnother"
          style={{
            display: "flex",
            marginRight: "20px",
          }}
        >
          <Form.Label
            style={{
              margin: "0 8px 0 0",
            }}
          >
            Добави следваща
          </Form.Label>
          <Form.Check
            type="checkbox"
            label=""
            onChange={() => setCreateAnotherProvision(!createAnotherProvision)}
            checked={createAnotherProvision}
          />
        </Form.Group>
      )}
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <ActionButton
        messageId="button.confirm"
        onClick={handleSubmit(onSumbit)}
        submitting={isSubmitting}
        disabled={!title || !text || !!duplicatedProvision || isSubmitting}
      />
    </>
  );

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Provision[]>([]);

  const handleSearch = useCallback(
    async (query: string) => {
      setIsLoading(true);

      setOptions(await fetchProvisions(query));

      setIsLoading(false);
    },
    [setIsLoading, setOptions]
  );

  useEffect(() => {
    (async () => {
      if (selectedText || selectedProvision) {
        setIsLoading(true);

        setOptions(await fetchProvisions(selectedText || selectedProvision!.title));

        setIsLoading(false);
      }
    })();
  }, [selectedProvision, setIsLoading, setOptions, selectedText]);

  return (
    <>
      <Modal
        show={true}
        centered
        size="xl"
        className="modal-xxl"
        onEscapeKeyDown={onCancel}
        animation={false}
      >
        <Modal.Header style={{ alignItems: "center", padding: "0.6rem" }}>
          <table>
            <tbody>
              <tr>
                <td>
                  {isAddMode ? (
                    <FormattedMessage id="create-provision.modal.header" />
                  ) : (
                    <FormattedMessage id="edit-provision.modal.header" />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>({documentTitle})</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
            {buttons}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="edit-form" autoComplete="off">
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id="add-provision.modal.form.title" />{" "}
                    <span className="text-muted">
                      <i>
                        /напр. чл. 28, ал. 2, т. 4 СК-1968 (отм.) или § 4, ал. 1, т. 4 от ДР на
                        СК-1985 (отм.)/
                      </i>
                    </span>
                  </Form.Label>
                  <br />
                  <AsyncTypeahead
                    filterBy={() => true}
                    id="title-select"
                    isLoading={isLoading}
                    labelKey="title"
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder=""
                    promptText="Въведете дума за търсене"
                    emptyLabel="Не са открити резултати"
                    searchText="Търсене..."
                    useCache={false}
                    onChange={(selected: Provision[]) => {
                      setSelectedTemplateProvision(selected[0]);
                    }}
                    defaultInputValue={title}
                    onInputChange={(value) => setValue("title", value)}
                    autoFocus
                    renderMenuItemChildren={(option, props) => getProvisionFullTitle(option)}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id="add-provision.modal.form.sgIssueNumber" />
                  </Form.Label>
                  <br />
                  <Form.Control type="text" {...register("sgIssueNumber")} />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id="add-provision.modal.form.sgYear" />
                  </Form.Label>
                  <br />
                  <Form.Control type="text" {...register("sgYear")} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="text">
                  <Form.Label>
                    <FormattedMessage id="add-provision.modal.form.text" />
                  </Form.Label>
                  <Controller
                    name={"text"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RichTextEditor
                        height="300px"
                        cleanupPastedContent
                        value={value}
                        headless
                        onChange={onChange}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            {!!duplicatedProvision && (
              <Row>
                <Col>
                  <Alert variant="danger" style={{ marginTop: "10px", overflowY: "auto" }}>
                    Вече съществува разпоредба с избраните реквизити:{" "}
                    <strong>{getProvisionFullTitle(duplicatedProvision)}</strong>
                    {duplicatedProvisionFull?.text && (
                      <div dangerouslySetInnerHTML={{ __html: duplicatedProvisionFull?.text }} />
                    )}
                  </Alert>
                </Col>
              </Row>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>{buttons}</Modal.Footer>
      </Modal>
    </>
  );
};

const ProvisionText = ({
  selectedProvision,
  setSelectedProvision,
}: {
  selectedProvision?: Provision;
  setSelectedProvision: (provision: Provision) => void;
}) => {
  const provision = useProvision(selectedProvision?.id);

  useEffect(() => {
    if (provision && selectedProvision && provision.text !== selectedProvision.text) {
      setSelectedProvision({ ...selectedProvision, text: provision.text });
    }
  }, [provision, selectedProvision, setSelectedProvision]);

  return (
    <>
      {provision?.text && (
        <div
          dangerouslySetInnerHTML={{ __html: provision.text }}
          style={{ overflowY: "auto", height: "250px" }}
        />
      )}
    </>
  );
};

type DeleteProvisionProps = {
  selectedProvision?: Provision;
  onConfirm: (provision: Provision) => void;
  onCancel: () => void;
  documentTitle: string;
};

const DeleteProvision: React.FC<DeleteProvisionProps> = ({
  selectedProvision,
  onConfirm,
  onCancel,
  documentTitle,
}) => {
  const intl = useIntl();
  const [replacementProvision, setReplacementProvision] = useState<Provision>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSumbit = async (data: Provision) => {
    try {
      setIsSubmitting(true);
      await deleteProvision(selectedProvision!.id, replacementProvision!.id);
      onConfirm(replacementProvision!);
      toast.success(`Разпоредба "${selectedProvision!.title}" беше успешно изтрита.`);
    } catch (e) {
      console.log(e);
      toast.error(`Възникна грешка при изтриване на разпоредба "${selectedProvision!.title}".`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttons = (
    <>
      <SecondaryButton messageId="button.cancel" onClick={onCancel} />
      <ActionButton
        messageId="button.confirm"
        onClick={onSumbit}
        disabled={!replacementProvision || isSubmitting}
        submitting={isSubmitting}
      />
    </>
  );
  return (
    <Modal
      show={true}
      centered
      size="xl"
      className="modal-xxl"
      onEscapeKeyDown={onCancel}
      animation={false}
    >
      <Modal.Header style={{ alignItems: "center", padding: "0.6rem" }}>
        <table>
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="delete-provision.modal.header" />
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>({documentTitle})</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginLeft: "auto", padding: 0, border: "none" }} className="modal-footer">
          {buttons}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="add-provision.modal.form.selected-provision" />
              </Form.Label>
              <br />
              <strong>{getProvisionFullTitle(selectedProvision!)}</strong>
              {selectedProvision?.text && (
                <div
                  dangerouslySetInnerHTML={{ __html: selectedProvision.text }}
                  style={{ overflowY: "auto", height: "auto" }}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="add-provision.modal.form.replacement-provision" />
              </Form.Label>
              <Select
                onChange={(...event: any[]) => setReplacementProvision(event[0])}
                value={replacementProvision ? [replacementProvision] : []}
                components={{
                  DropdownIndicator: null,
                }}
                isClearable
                shadowed
                loadOptions={fetchProvisions}
                getOptionValue={(provision: Provision) => provision.id}
                getOptionLabel={(provision: Provision) => getProvisionFullTitle(provision)}
                placeholder={intl.formatMessage({ id: "add-provision.modal.select.placeholder" })}
                autoFocus
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Suspense fallback={<Skeleton height={15} count={5} />}>
          <ProvisionText
            selectedProvision={replacementProvision}
            setSelectedProvision={setReplacementProvision}
          />
        </Suspense>
      </Modal.Body>

      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

export default AddProvisionModal;
