import React, { useEffect, useState } from "react";
import { FaList } from "react-icons/fa";
import ReactQuill from "react-quill";
import { v4 as uuid } from "uuid";
import { useEditorCollectionContext } from "../../contexts/editor-collection.context";
import { InlineLinkButton } from "../Button/button";
import Collapse from "../Collapse";
import Heading from "./Heading";
import "./table-of-contents.scss";

const fetchHeadings = () => {
  const elements = Array.from(
    document.querySelectorAll(
      ".act-text h2, .act-text h3, .act-text h4, .act-text h5, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5"
    )
  ).map(toHeading);
  return elements;
};

export const TableOfContent: React.FC<{
  activeId?: Heading;
  setActiveId: (headin: Heading) => void;
}> = ({ activeId, setActiveId }) => {
  const [headings, setHeadings] = useState<Heading[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);

  const { searchHits } = useEditorCollectionContext();
  useEffect(() => {
    const newHeadings = fetchHeadings();
    setHeadings(newHeadings);
    setActiveId(newHeadings[0]);
  }, [setActiveId]);

  useEffect(() => {
    const quillContainer = document.querySelector("#editor-collection > .ql-container")!;
    if (quillContainer) {
      let quill = ReactQuill.Quill.find(quillContainer);
      if (quill) {
        const handler = () => setHeadings(fetchHeadings());
        quill.on("text-change", handler);
        return () => quill.off("text-change", handler);
      }
    }
  }, []);

  useEffect(() => {
    setShowAll((searchHits?.length || -1) > -1);
  }, [searchHits]);

  return (
    <Collapse
      icon={<FaList />}
      label={"Съдържание"}
      className={headings.length === 0 ? "d-none" : ""}
    >
      <nav className="toc">
        <ul>
          {headings.map((heading) => {
            const hits = searchHits?.filter((e) => e.id === heading.id);
            return (
              <li
                key={heading.id}
                className={`${getClassName(heading.level)} ${
                  activeId?.section === heading.section || heading.level === 2 || showAll
                    ? ""
                    : "h-0"
                }`}
              >
                <a
                  className="link inline-link"
                  href={`#${heading.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveId(heading);
                  }}
                  style={{
                    fontWeight: activeId?.id === heading.id ? "bold" : "normal",
                    color: !hits || hits.length ? "" : "lightgray",
                  }}
                >
                  {heading.text} {hits?.length ? `(${hits?.length})` : ""}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
      <div style={{ display: "grid" }}>
        <InlineLinkButton
          onClick={() => setShowAll(!showAll)}
          style={{ marginTop: "10px", placeSelf: "center" }}
        >
          {!showAll ? "Покажи всичко" : "Покажи по-малко"}
        </InlineLinkButton>
      </div>
    </Collapse>
  );
};

const getClassName = (level: number) => {
  switch (level) {
    case 2:
      return "head2";
    case 3:
      return "head3";
    case 4:
      return "head4";
    case 5:
      return "head5";
    default:
      return undefined;
  }
};

export const toHeading = (elem: Element) => {
  if (!elem.id) {
    elem.id = "_" + uuid();
  }
  return {
    id: elem.id,
    text: (elem as any).innerText as string,
    level: Number(elem.nodeName.charAt(1)),
    section: elem.closest(".main-section")?.querySelector("h2")?.id,
  };
};

export default TableOfContent;
