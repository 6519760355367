import React, { createContext, useContext, useState } from "react";

export type ViewEditPageContextType = {
  mode: PageMode;
  setMode: (mode: PageMode) => void;
};

export type PageMode = "view" | "edit";

export const ViewEditPageContext = createContext<ViewEditPageContextType>({
  mode: "view",
  setMode: () => {},
});

export const ViewEditPageContextProvider: React.FC<{ defaultMode: PageMode }> = ({
  defaultMode = "view",
  children,
}) => {
  const [mode, setMode] = useState<PageMode>(defaultMode);
  return (
    <ViewEditPageContext.Provider
      value={{
        mode,
        setMode,
      }}
    >
      {children}
    </ViewEditPageContext.Provider>
  );
};

export const useViewEditPageContext = () => useContext(ViewEditPageContext);

export default ViewEditPageContextProvider;
