/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Badge,
  Container,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useDeviceSelectors } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import { BiCategory } from "react-icons/bi";
import { FaAngleDown, FaAngleUp, FaBuffer, FaRegClone } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { InlineLinkButton } from "../../components/Button/button";
import { HelpLink, HELP_LINK_DOCUMENTS } from "../../components/HelpLink/help-link";
import PreloadingLink from "../../components/PreloadingLink";
import { config } from "../../constants";
import { isLogged, not, useAuth, Visible } from "../../contexts/auth.context";
import { getIdFromHref, useDocumentContext } from "../../contexts/document.context";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import { UserDocument, UserDocumentType } from "../../models/UserDocument.model";
import { UserDocuments } from "../../models/UserDocuments.model";
import { delUserDocument, useUserDocuments } from "../../services/user-services";
import { CollapsibleFiltersPanel } from "../SearchPage/FiltersSidebar/filters";
import "./documents-menu.scss";
import { popperConfig } from "./header";

const DocumentsMenu = () => {
  const userDocuments = useUserDocuments();
  return <DocumentsMenuContents userDocuments={userDocuments} />;
};

export const DocumentsMenuContents: React.FC<{
  userDocuments: UserDocuments;
}> = ({ userDocuments }) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  return isMobile ? (
    <DocumentsMenuMobileContents userDocuments={userDocuments} />
  ) : (
    <DocumentsMenuNonMobileContents userDocuments={userDocuments} />
  );
};

const DocumentsMenuNonMobileContents: React.FC<{
  userDocuments: UserDocuments;
}> = ({ userDocuments }) => {
  const [showMenu, setShowMenu] = useState(false);

  useHotkeys("Escape", () => {
    setShowMenu(false);
  });

  const { showLoginModal, showSubscriptionModal } = useAuth();
  useEffect(() => {
    if (showLoginModal || showSubscriptionModal) {
      setShowMenu(false);
    }
  }, [showLoginModal, showSubscriptionModal]);

  const documentsPopover = (
    <Popover id="documents-popover" className="shadow generic-popover">
      <Popover.Header className="d-flex">
        Документи
        <HelpLink articleId={HELP_LINK_DOCUMENTS} />
        <InlineLinkButton onClick={() => setShowMenu(false)} className="ms-auto">
          <IoMdClose />
        </InlineLinkButton>
      </Popover.Header>
      <Popover.Body>
        <DocumentsMenuPopoverContents userDocuments={userDocuments} setShowMenu={setShowMenu} />
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      overlay={documentsPopover}
      show={showMenu}
      rootClose
      onToggle={(show) => {
        setShowMenu(show);
      }}
    >
      <Nav.Link className={`documents-menu-toggle ${showMenu ? "hovered" : ""}`}>
        <div className="link-body">
          <OverlayTrigger
            placement="bottom"
            popperConfig={popperConfig}
            overlay={(props) => (
              <Tooltip id="documents-tooltip" {...props}>
                Документи
              </Tooltip>
            )}
          >
            <span>
              <FaBuffer />{" "}
            </span>
          </OverlayTrigger>
          <span className="nav-link-label">Документи</span>
        </div>
      </Nav.Link>
    </OverlayTrigger>
  );
};

const DocumentsMenuMobileContents: React.FC<{
  userDocuments: UserDocuments;
}> = ({ userDocuments }) => {
  const [showMenu, setShowMenu] = useState(false);

  useBodyScrollLock(showMenu);

  return (
    <>
      <Nav.Link className={`${showMenu ? "hovered" : ""}`} onClick={() => setShowMenu(true)}>
        <div className="link-body">
          <FaBuffer /> <span className="nav-link-label">Документи</span>
        </div>
      </Nav.Link>
      <Offcanvas
        id="documents-menu-modal"
        show={showMenu}
        onHide={() => setShowMenu(false)}
        backdrop
        placement="bottom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Документи</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DocumentsMenuPopoverContents userDocuments={userDocuments} setShowMenu={setShowMenu} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export const DocumentsMenuPopoverContents: React.FC<{
  userDocuments: UserDocuments;
  setShowMenu: (show: boolean) => void;
}> = ({ userDocuments, setShowMenu }) => {
  const { closeDocument } = useDocumentContext();
  const { formatMessage } = useIntl();

  const groupedData = useMemo(() => {
    const data = userDocuments.favourite
      ?.reduce((acc: any[], item) => {
        const category = item.category;
        const element = acc.filter((e) => e.label === category);
        if (!element[0]) {
          acc.push({ label: category, items: [item] });
        } else {
          element[0].items.push(item);
        }
        return acc;
      }, [])
      .sort((g1, g2) => {
        const l1 = g1.label || "";
        const l2 = g2.label || "";
        if (l1 === "За четене по-късно") {
          return -1;
        }
        if (l2 === "За четене по-късно") {
          return 1;
        }
        return l1.localeCompare(l2);
      });
    data.unshift({ label: "Последно отваряни", items: userDocuments.opened });
    return data;
  }, [userDocuments]);

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  return (
    <>
      <Visible when={isLogged()}>
        <Accordion flush defaultActiveKey={isMobileOnly ? undefined : "Последно отваряни"}>
          {groupedData.map((group, idx) => {
            const listGroup = (
              <ListGroup
                variant="flush"
                style={{
                  overflowY: "auto",
                  height: "auto",
                }}
              >
                {group.items.map((document: UserDocument) => (
                  <ListGroupItem key={document.id}>
                    <div className="d-flex align-items-center">
                      <span>
                        <PreloadingLink
                          to={document.href!}
                          preload={
                            async (cache) => {}
                            //await prefetchDocumentStats(new URLSearchParams(search.query), cache)
                          }
                          onClick={() => setShowMenu(false)}
                        >
                          {document.title}
                        </PreloadingLink>
                        {document.shortTitle && (
                          <div className="text-muted fst-italic">{document.shortTitle}</div>
                        )}
                      </span>
                      <IoMdClose
                        onClick={async () =>
                          idx === 0
                            ? closeDocument(document as any)
                            : removeFromCollection(
                                getIdFromHref(document.href!)!,
                                document.type!,
                                group.label,
                                document.title!
                              )
                        }
                        className="ms-auto"
                        title={formatMessage({ id: "button.remove" })}
                      />
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            );
            return (
              <Accordion.Item eventKey={group.label} key={group.label}>
                <Accordion.Header>
                  <BiCategory />
                  {group.label}
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="copy-documents-tooltip" {...props}>
                        Копирай документите от категорията
                      </Tooltip>
                    )}
                  >
                    <span className="copy-button">
                      <FaRegClone
                        onClick={async (ev) => {
                          ev.stopPropagation();
                          await copyListToClipboard(ev.target as HTMLElement);
                        }}
                      />
                    </span>
                  </OverlayTrigger>
                  <Badge>{group.items.length}</Badge>
                  <FaAngleUp className="toggle-indicator up" />
                  <FaAngleDown className="toggle-indicator down" />
                </Accordion.Header>
                <Accordion.Body>
                  {group.items.length ? (
                    idx === 0 && !isMobileOnly ? (
                      <CollapsibleFiltersPanel
                        collapseHeight="130px"
                        openedLabel="Покажи по-малко"
                        closedLabel="Покажи всичко"
                      >
                        {listGroup}
                      </CollapsibleFiltersPanel>
                    ) : (
                      listGroup
                    )
                  ) : (
                    <Container fluid className="text-center mt-2 mb-2" style={{ fontSize: "1rem" }}>
                      Нямате документи в тази секция
                    </Container>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Visible>
      <Visible when={not(isLogged())}>
        <Container fluid className="text-center m-2" style={{ fontSize: "1rem" }}>
          Моля влезте с потребителския си профил, за да видите информацията в тази секция.
        </Container>
      </Visible>
    </>
  );
};

const removeFromCollection = async (
  docId: string,
  type: UserDocumentType,
  category: string,
  name: string
) => {
  try {
    await delUserDocument([docId], type, category);
    toast.success(`Документът бе успешно премахнат от "${name}"`);
  } catch (e) {
    console.log(e);
    toast.error(`Грешка при премахване на документа от "${name}"`);
  }
};

const copyListToClipboard = async (target: HTMLElement) => {
  const dataEls = target
    .closest(".accordion-item")!
    .querySelectorAll(".list-group-item a, .list-group-item div.text-muted")!;
  const elements = Array.from(dataEls);

  const html =
    "<div>" +
    elements.reduce(
      (previous, item) =>
        (previous += item.outerHTML.replaceAll('href="', 'href="' + config.url) + "<br/>"),
      ""
    ) +
    "</div>";
  const text = elements.reduce((previous, item) => (previous += item.textContent + "\n"), "");
  try {
    const item = new ClipboardItem({
      "text/html": new Blob([html], {
        type: "text/html",
      }),
      "text/plain": new Blob([text], {
        type: "text/plain",
      }),
    });
    await navigator.clipboard.write([item]);
  } catch (e) {
    //firefox
    await navigator.clipboard.writeText(text);
  }

  toast.success(`Списъкът бе копиран в клипборда`);
};

export default DocumentsMenu;
