import Skeleton from "../../components/Skeleton";
import DocumentPageLayout, { DocumentPageLayoutProps } from "../DocumentPage/document-page-layout";

const EditorCollectionViewPageLoading: React.FC<DocumentPageLayoutProps> = (props) => {
  return (
    <DocumentPageLayout {...props}>
      <Skeleton count={1} height="200px" />
      <Skeleton count={1} style={{ marginLeft: "60%", width: "40%", marginTop: "40px" }} />
      <Skeleton count={1} style={{ marginLeft: "10%", width: "90%" }} />
      <Skeleton count={3} />
      <Skeleton count={1} style={{ marginLeft: "10%", width: "90%" }} />
      <Skeleton count={50} />
    </DocumentPageLayout>
  );
};

export default EditorCollectionViewPageLoading;
