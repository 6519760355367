import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { FaTimes } from "react-icons/fa";
import { useIntl } from "react-intl";
import RefData from "../../models/RefData.model";
import { NO_TOPIC_ID } from "../../services/summary-topic.services";
import "./topics.table.scss";

const TopicsTable: React.FC<{ value: RefData[]; remove: (idx: number) => void }> = ({
  value,
  remove,
}) => {
  const intl = useIntl();
  return (
    <Table responsive style={{ width: "100%" }} className="topics-table">
      <colgroup>
        <col span={1} style={{ width: "90%" }} />
        <col span={1} style={{ width: "10%" }} />
      </colgroup>
      {value && value.length > 0 ? (
        <tbody>
          {value.map((v, i) => (
            <tr key={v.id}>
              <td>
                {v.shortName}
                <div style={{ fontSize: "0.75rem" }}>{v.name}</div>
              </td>
              <td>
                {v.id !== NO_TOPIC_ID && (
                  <Button
                    variant="link"
                    onClick={() => remove(i)}
                    title={intl.formatMessage({ id: "button.remove" })}
                    className="remove-link"
                  >
                    <FaTimes />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tfoot>
          <tr>
            <td colSpan={2}>{intl.formatMessage({ id: "topics-table.no-topics" })}</td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
};

export default TopicsTable;
