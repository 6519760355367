/* eslint-disable no-underscore-dangle */
import React from "react";
import Card from "react-bootstrap/Card";
import { useSummaryContext } from "../../contexts/summary.context";
import { isPublishedStatus } from "../../models/DocumentStatus.enum";
import { missingActs, missingProvisions } from "../SummaryViewPage/renderTextWithProvisions";
import "./summary-page.scss";
const MissingInformationCard = () => {
  const { summary } = useSummaryContext();
  const missingInformation = [];
  const missingInformationResume = [];
  !summary.hasRelatedCase && missingInformationResume.push("Свързано дело");
  !summary.shortTitle && missingInformationResume.push("Кратко заглавие");
  !summary.summaryType && missingInformationResume.push("Вид резюме");
  !summary.provisions?.length && missingInformationResume.push("Тълкувани разпоредби");
  !summary.editor?.name && missingInformationResume.push("Автор");
  !isPublishedStatus(summary.relatedJudgementAct.status) &&
    missingInformationResume.push("Резюмираният акт не е публикуван");
  missingInformationResume.length &&
    missingInformation.push({ name: "Резюме", information: missingInformationResume });

  summary.qas.forEach((qa, idx) => {
    const missingInformationQuestion = [];

    !qa.shortTitle && missingInformationQuestion.push("Кратко заглавие");
    !qa.groundOfAppeal &&
      (summary.summaryType?.code === "DECISION_2007" ||
        summary.summaryType?.code === "APPEAL_IN_CASSATION_2007") &&
      missingInformationQuestion.push("Основание за допускане");
    !qa.keyLegislation && missingInformationQuestion.push("Водеща разпоредба");
    !qa.question && missingInformationQuestion.push("Текст");
    !qa.arguments && missingInformationQuestion.push("Мотиви");
    !qa.answer && missingInformationQuestion.push("Отговор");
    !qa.topics?.filter((topic) => topic.id + "" !== "99999").length &&
      missingInformationQuestion.push("Теми");
    qa.relatedJudgementActs?.filter((act) => !isPublishedStatus(act.status)).length &&
      missingInformationQuestion.push("Връзка с непубликуван акт");

    missingInformationQuestion.length &&
      missingInformation.push({
        name: summary.qas.length > 1 ? `Въпрос ${idx + 1}` : "Въпрос",
        information: missingInformationQuestion,
      });

    if (missingInformationQuestion.length === 0) {
      let missingProvisionsArguments =
        qa.arguments && summary.provisions && missingProvisions(qa.arguments, summary.provisions);

      if (missingProvisionsArguments) {
        const appendedText = "Недобавени разпоредби в Мотиви: ";
        missingProvisionsArguments = (
          <>
            {appendedText}
            {missingProvisionsArguments}
          </>
        );

        missingInformationQuestion.push(missingProvisionsArguments);
      }

      let missingProvisionsAnswer =
        qa.answer && summary.provisions && missingProvisions(qa.answer, summary.provisions);

      if (missingProvisionsAnswer) {
        const appendedText = "Недобавени разпоредби в Отговор: ";
        missingProvisionsAnswer = (
          <>
            {appendedText}
            {missingProvisionsAnswer}
          </>
        );

        missingInformationQuestion.push(missingProvisionsAnswer);
      }

      let editorialOpinions = qa.editorialOpinions.map((opinion) => opinion.text).join(" ");
      let dissentingOpinions = qa.dissentingOpinions.map((opinion) => opinion.text).join(" ");

      let missingProvisionsEditorialOpinion =
        editorialOpinions !== "" &&
        summary.provisions &&
        missingProvisions(editorialOpinions, summary.provisions);

      if (missingProvisionsEditorialOpinion) {
        const appendedText = "Недобавени разпоредби в Редакторска бележка: ";
        missingProvisionsEditorialOpinion = (
          <>
            {appendedText}
            {missingProvisionsEditorialOpinion}
          </>
        );

        missingInformationQuestion.push(missingProvisionsEditorialOpinion);
      }

      let missingProvisionsDissentingOpinion =
        dissentingOpinions !== "" &&
        summary.provisions &&
        missingProvisions(dissentingOpinions, summary.provisions);

      if (missingProvisionsDissentingOpinion) {
        const appendedText = "Недобавени разпоредби в Особено мнение: ";
        missingProvisionsDissentingOpinion = (
          <>
            {appendedText}
            {missingProvisionsDissentingOpinion}
          </>
        );

        missingInformationQuestion.push(missingProvisionsDissentingOpinion);
      }

      let missingActsArguments =
        qa.arguments &&
        qa.relatedJudgementActs &&
        missingActs(qa.arguments, qa.relatedJudgementActs);

      if (missingActsArguments) {
        const appendedText = "Недобавени актове в Мотиви: ";
        missingActsArguments = (
          <>
            {appendedText}
            {missingActsArguments}
          </>
        );

        missingInformationQuestion.push(missingActsArguments);
      }

      let missingActsAnswer =
        qa.answer && qa.relatedJudgementActs && missingActs(qa.answer, qa.relatedJudgementActs);

      if (missingActsAnswer) {
        const appendedText = "Недобавени актове в Отговор: ";
        missingActsAnswer = (
          <>
            {appendedText}
            {missingActsAnswer}
          </>
        );

        missingInformationQuestion.push(missingActsAnswer);
      }

      let missingActsEditorialOpinions =
        editorialOpinions !== "" &&
        qa.relatedJudgementActs &&
        missingActs(editorialOpinions, qa.relatedJudgementActs);

      if (missingActsEditorialOpinions) {
        const appendedText = "Недобавени актове в Редакторска бележка: ";
        missingActsEditorialOpinions = (
          <>
            {appendedText}
            {missingActsEditorialOpinions}
          </>
        );

        missingInformationQuestion.push(missingActsEditorialOpinions);
      }

      let missingActsDissentingOpinions =
        dissentingOpinions !== "" &&
        qa.relatedJudgementActs &&
        missingActs(dissentingOpinions, qa.relatedJudgementActs);

      if (missingActsDissentingOpinions) {
        const appendedText = "Недобавени актове в Особено мнение: ";
        missingActsDissentingOpinions = (
          <>
            {appendedText}
            {missingActsDissentingOpinions}
          </>
        );

        missingInformationQuestion.push(missingActsDissentingOpinions);
      }

      missingInformationQuestion.length &&
        missingInformation.push({
          name: summary.qas.length > 1 ? `Въпрос ${idx + 1}` : "Въпрос",
          information: missingInformationQuestion,
        });
    }
  });

  return (
    <>
      {!isPublishedStatus(summary.status) && missingInformation.length > 0 && (
        <Card
          className="missing-information"
          style={{
            marginTop: "10px",
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "15px",
          }}
        >
          <Card.Body>
            {missingInformation.map((section, idx) => (
              <div key={section.name} id={section.name} className="missing-information-section">
                <Card.Subtitle>Липсваща информация към "{section.name}"</Card.Subtitle>

                <ul>
                  {section.information.map((info, idx) => (
                    <li key={idx}>{info}</li>
                  ))}
                </ul>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default MissingInformationCard;
