import React, { useEffect, useRef } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { FaArrowCircleRight, FaClone, FaExclamationCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import RichTextEditor from "../../components/RichTextEditor";
import SummaryRichTextEditor from "../../components/RichTextEditor/summary-rich-text-editor";
import RefDataSelectSingle from "../../components/SimpleSelect/ref-data-select-single";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { useSummaryEditContext } from "../../contexts/summary-edit.context";
import { useSummaryQaContext } from "../../contexts/summary-qa.context";
import { useTextSelection } from "../../hooks/useTextSelection";
import Provision from "../../models/Provision.model";
import { Summary } from "../../models/Summary.model";
import SummaryQa from "../../models/SummaryQa.model";
import { getProvisionFullTitle, useGroundOfAppeals } from "../../services/ref-data-services";
import TopicsSection from "./summary-edit-page-topics";

type SummaryQaMainProps = {
  i: number;
  qa: SummaryQa;
  active: boolean;
};

const SummaryQaMain: React.FC<SummaryQaMainProps> = ({ i, qa, active }) => {
  const { control } = useFormContext<Summary>();

  const { data: groundOfAppeals } = useGroundOfAppeals();

  const { setModeAddRelatedActModal } = useSummaryQaContext();

  useHotkeys(
    "ctrl+shift+a,command+shift+a",
    (e) => {
      e.preventDefault();
      setModeAddRelatedActModal("add", window.getSelection()?.toString());
    },
    { enableOnContentEditable: true, enableOnTags: ["INPUT", "TEXTAREA"], enabled: active }
  );

  return (
    <>
      {active && <SelectedActTextPopover />}
      <Row>
        <Col md={12}>
          <Form.Group controlId={`qas.${i}.question`}>
            <Form.Label>Въпрос</Form.Label>

            <Controller
              name={`qas.${i}.question` as const}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor
                  id={`qas-${i}-question`}
                  defaultValue={value}
                  cleanupPastedContent
                  headless
                  plainText
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId={`qas.${i}.shortTitle`}>
            <Form.Label>Кратко заглавие на въпроса</Form.Label>
            <Controller
              name={`qas.${i}.shortTitle` as const}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor
                  id={`qas-${i}-shortTitle`}
                  defaultValue={value}
                  cleanupPastedContent
                  headless
                  plainText
                  singleLine
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <TopicsSection idx={i} />
        </Col>
        <Col md={5}>
          <Row>
            <Col md={12}>
              <Form.Group controlId={`qas.${i}.groundOfAppeal`}>
                <Form.Label>Основание за допускане</Form.Label>
                <Controller
                  name={`qas.${i}.groundOfAppeal` as const}
                  control={control}
                  defaultValue={qa.groundOfAppeal as any}
                  render={({ field: { onChange, value } }) => (
                    <RefDataSelectSingle
                      onChange={onChange}
                      value={value}
                      isClearable
                      options={groundOfAppeals}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId={`qas.${i}.keyLegislation`}>
                <Form.Label>Водеща разпоредба</Form.Label>
                <Controller
                  name={`qas.${i}.keyLegislation` as const}
                  control={control}
                  defaultValue={qa.keyLegislation as any}
                  render={({ field: { onChange, value } }) => (
                    <KeyLegislationSelect onChange={onChange} value={value} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId={`qas.${i}.arguments`}>
            <Form.Label>Мотиви</Form.Label>
            <Controller
              name={`qas.${i}.arguments` as const}
              control={control}
              defaultValue={qa.arguments as any}
              render={({ field: { onChange, value } }) => (
                <SummaryRichTextEditor
                  id={`qas-${i}-arguments`}
                  height="150px"
                  expandHeight="400px"
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId={`qas.${i}.answer`}>
            <Form.Label>Отговор</Form.Label>
            <Controller
              name={`qas.${i}.answer` as const}
              control={control}
              defaultValue={qa.answer as any}
              render={({ field: { onChange, value } }) => (
                <SummaryRichTextEditor
                  id={`qas-${i}-answer`}
                  height="150px"
                  expandHeight="400px"
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

type KeyLegislationSelectProps = {
  onChange: (prov?: Provision) => void;
  value?: Provision;
};

const KeyLegislationSelect: React.FC<KeyLegislationSelectProps> = ({ onChange, value }) => {
  const { control } = useFormContext<Summary>();
  const provisions = useWatch({ control, name: "provisions" });

  useEffect(() => {
    if (value && provisions.filter((p) => value.id === p.id).length === 0) {
      onChange(undefined);
    }
  }, [provisions, onChange, value]);

  return (
    <SelectSingle
      options={provisions}
      getOption={(provision) => ({
        label: getProvisionFullTitle(provision),
        value: provision.title,
      })}
      value={value}
      onChange={onChange}
      isClearable
      disableSearch={false}
    />
  );
};

// const UpdatingTooltip = React.forwardRef<any, any>(
//   ({ popper, children, show: _, ...props }, ref) => {
//     useEffect(() => {
//       !popper.state && popper.scheduleUpdate();
//     }, [children, popper]);

//     return (
//       <Tooltip ref={ref} {...props}>
//         {children}
//       </Tooltip>
//     );
//   }
// );

export const SelectedActTextPopover = () => {
  const { clientRect, isCollapsed, textContent, reset } = useTextSelection();
  const ref = useRef(null);

  const { setModeAddRelatedActModal } = useSummaryQaContext();
  const { setModeAddProvisionModal } = useSummaryEditContext();

  return (
    <>
      <div
        ref={ref}
        style={
          !isCollapsed && clientRect
            ? {
                left: clientRect.left + clientRect.width / 2,
                top: window.scrollY + clientRect.top,
                position: "absolute",
              }
            : { marginLeft: "-999px", position: "absolute" }
        }
      ></div>
      <Overlay
        key={textContent}
        target={ref}
        show={!isCollapsed && !!textContent?.trim() && !!clientRect}
        placement="top"
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -4],
              },
            },
          ],
        }}
      >
        <Tooltip id="annotation-tooltip">
          <div className="d-flex align-items-center">
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={(e) => e.stopPropagation()}
              onClick={async (e) => {
                e.stopPropagation();
                await navigator.clipboard.writeText(textContent || "");
                toast.info("Текстът бе копиран в клипборда");
                reset();
                window.getSelection()?.removeAllRanges();
              }}
              title="Копирай"
            >
              <FaClone />
            </Button>
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setModeAddProvisionModal("select", window.getSelection()?.toString());
              }}
              title="Добави разпоредба"
            >
              <FaExclamationCircle />
            </Button>
            <Button
              variant="link"
              className="m-auto"
              onMouseUp={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setModeAddRelatedActModal("add", window.getSelection()?.toString());
              }}
              title="Добави акт"
            >
              <FaArrowCircleRight />
            </Button>
          </div>
        </Tooltip>
      </Overlay>{" "}
    </>
  );
};

export default SummaryQaMain;
