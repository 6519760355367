import { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import { useDeviceSelectors } from "react-device-detect";
import { useSwipeable } from "react-swipeable";
import stickybits from "stickybits";
import "./document-page-layout.scss";

export type DocumentQuickViewLayoutProps = {
  id: string;
  title?: string;
  className?: string;
  menu?: React.ReactNode;
  onClose: () => void;
};

const DocumentQuickViewLayout: React.FC<DocumentQuickViewLayoutProps> = ({
  id,
  title,
  menu,
  className = "",
  children,
  onClose,
}) => {
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const navHandlers = useSwipeable(
    isMobileOnly
      ? {
          onSwipedDown: () => {
            const scrollEl = headerRef.current?.parentElement!.parentElement!.parentElement!;
            if (scrollEl?.scrollTop === 0) {
              onClose();
            }
          },
        }
      : {
          onSwipedRight: onClose,
        }
  );

  useEffect(() => {
    if (headerRef.current && isMobileOnly) {
      const scrollEl = headerRef.current.parentElement!.parentElement!.parentElement!;
      stickybits(headerRef.current, {
        scrollEl,
      });
      // const headroom = new Headroom(headerRef.current as any, {
      //   scroller: scrollEl,
      // });
      // headroom.init();
    }
  }, [isMobileOnly]);
  return (
    <>
      {/* <MobileOnlyView>
        <Navbar className="user-bar">
          <Nav className="ms-3">
            <Nav.Link onClick={onClose} as={PrimaryButton}>
              <FaAngleRight />
            </Nav.Link>
          </Nav>
        </Navbar>
      </MobileOnlyView> */}
      <Container fluid className={`document-page view-page ${className} ps-0`} {...navHandlers}>
        <Container fluid className="document-page-main-content p-0">
          <div className="document-page-main-content-header ms-4" ref={headerRef}>
            <span className="h6">{title}</span>
            <div className="document-page-main-content-header-actions">{menu}</div>
          </div>
          <div className="w-100 d-flex">
            <div className="quick-view-page-map">
              <canvas className="quick-view-map" id={`quick-view-map-${id}`}></canvas>
            </div>
            {children}
          </div>
        </Container>
      </Container>
    </>
  );
};

export default DocumentQuickViewLayout;
