import { FaLink } from "react-icons/fa";
import Collapse from "../../components/SimpleCollapse";
import { CaseActsTabLoading } from "./case-acts-tab";
import { RelatedActsTabLoading } from "./related-acts-tab";
import { SummaryPageNavigationProps } from "./summary-page-navigation";
import { TableOfContentsTabLoading } from "./table-of-contents-tab";

export const SummaryPageNavigationLoading: React.FC<SummaryPageNavigationProps> = ({ mode }) => {
  return (
    <div className="filters-sidebar" style={{ fontSize: "0.90rem" }}>
      {mode === "edit" && (
        <Collapse icon={<FaLink />} label="Актове по делото">
          <CaseActsTabLoading />
        </Collapse>
      )}
      <Collapse icon={<FaLink />} label="Съдържание" defaultExpanded={mode !== "edit"}>
        <TableOfContentsTabLoading />
      </Collapse>
      <Collapse icon={<FaLink />} label="Свързани актове" defaultExpanded={mode !== "edit"}>
        <RelatedActsTabLoading />
      </Collapse>
      <Collapse
        icon={<FaLink />}
        label="Автоматично разпознати референции"
        defaultExpanded={mode !== "edit"}
      >
        <RelatedActsTabLoading />
      </Collapse>
    </div>
  );
};

export default SummaryPageNavigationLoading;
