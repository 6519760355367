import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";

type ClientRect = Record<keyof Omit<DOMRect, "toJSON">, number>;

function roundValues(_rect: ClientRect) {
  const rect = {
    ..._rect,
  };
  for (const key of Object.keys(rect)) {
    // @ts-ignore
    rect[key] = Math.round(rect[key]);
  }
  return rect;
}

function shallowDiff(prev: any, next: any) {
  if (prev != null && next != null) {
    for (const key of Object.keys(next)) {
      if (prev[key] != next[key]) {
        return true;
      }
    }
  } else if (prev != next) {
    return true;
  }
  return false;
}

export type TextSelectionReturn = {
  clientRect?: ClientRect;
  isCollapsed?: boolean;
  textContent?: string;
  isOutOfScreen?: boolean;
  reset: () => void;
};

type TextSelectionState = {
  clientRect?: ClientRect;
  isCollapsed?: boolean;
  textContent?: string;
  isOutOfScreen?: boolean;
};

const defaultState: TextSelectionState = {};

/**
 * useTextSelection(ref)
 *
 * @description
 * hook to get information about the current text selection
 *
 */
export function useTextSelection(target?: HTMLElement): TextSelectionReturn {
  const [intState, setIntState] = useState<TextSelectionState>(defaultState);
  const ref = useRef<TextSelectionState>(defaultState);

  const setState = useCallback((newState: TextSelectionState) => {
    ref.current = newState;
    setIntState(newState);
  }, []);

  const reset = useCallback(() => {
    setState(defaultState);
  }, []);

  const handler = useCallback(
    (e: any) => {
      const { clientRect } = ref.current;
      let newRect: ClientRect;
      const selection = window.getSelection();
      let newState: TextSelectionState = {};

      if (selection == null || !selection.rangeCount) {
        setState(newState);
        return;
      }

      const range = selection.getRangeAt(0);

      if (target != null && !target.contains(range.commonAncestorContainer)) {
        setState(newState);
        return;
      }

      if (range == null) {
        setState(newState);
        return;
      }

      const contents = range.cloneContents();

      if (contents.textContent != null) {
        newState.textContent = contents.textContent;
      }

      const rects = range.getClientRects();
      // console.log(rects);

      if (rects.length === 0 && range.commonAncestorContainer != null) {
        const el = range.commonAncestorContainer as HTMLElement;
        newRect = roundValues(el.getBoundingClientRect().toJSON());
      } else {
        if (rects.length < 1) return;
        //Array.from(rects).reduce((item, acc) => Math.max(item.width, acc.width));
        newState.isOutOfScreen = rects[0].top < 0;
        newRect = roundValues((rects[0].top < 0 ? rects[rects.length - 1] : rects[0]).toJSON());
      }
      if (shallowDiff(clientRect, newRect)) {
        newState.clientRect = newRect;
      } else {
        if (!isMobileOnly && e.button !== 2) {
          reset();
        }
        return;
      }
      newState.isCollapsed = range.collapsed;

      setState(newState);
    },
    [target]
  );

  useLayoutEffect(() => {
    if (isMobileOnly) {
      document.addEventListener("selectionchange", handler);
    } else {
      document.addEventListener("mouseup", handler);
      //document.addEventListener("keydown", handler);
    }
    //document.addEventListener("keyup", handler);
    window.addEventListener("resize", handler);

    return () => {
      if (isMobileOnly) {
        document.addEventListener("selectionchange", handler);
      } else {
        document.removeEventListener("mouseup", handler);
        //document.removeEventListener("keydown", handler);
      }
      //document.removeEventListener("keyup", handler);
      window.removeEventListener("resize", handler);
    };
  }, [target]);

  return {
    ...intState,
    reset,
  };
}
