import { Option } from "react-multi-select-component/dist/types/lib/interfaces";
import SimpleSelect from ".";

interface SelectSingleProps<T> {
  getOption?: (option: T) => Option;
  options?: T[];
  value?: T;
  onChange: (selection: T) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  overrideStrings?: {
    [key: string]: string;
  };
  ItemRenderer?: any;
  isDisabled?: boolean;
  disableSearch?: boolean;
  onMenuToggle?: (expanded: boolean) => any;
}

const SelectSingle = <T extends unknown>({
  options = [],
  onChange = () => {},
  getOption = (option) => option as Option,
  value,
  isClearable = false,
  isDisabled = false,
  onMenuToggle,
  disableSearch = true,
}: SelectSingleProps<T>) => {
  return (
    <SimpleSelect
      options={options}
      getOption={getOption}
      value={value !== undefined && value != null && value !== "" ? [value] : []}
      onChange={(selection) => onChange(selection[0])}
      isClearable={isClearable}
      isMulti={false}
      isDisabled={isDisabled}
      disableSearch={disableSearch}
      onMenuToggle={onMenuToggle}
      filterOptions={(options, input) => {
        return options.filter(
          (option) => option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) > -1
        );
      }}
      overrideStrings={{
        selectSomeItems: "Изберете...",
        search: "Търсене",
        noOptions: "Няма резултати",
      }}
    />
  );
};

export default SelectSingle;
