import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useDeviceSelectors } from "react-device-detect";
import {
  FaAngleDown,
  FaAngleUp,
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaBars,
  FaQuestionCircle,
} from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { InlineLinkButton } from "../../components/Button/button";
import Collapse from "../../components/Collapse";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import { useProtectedAction } from "../../contexts/auth.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import Provision from "../../models/Provision.model";
import RefData from "../../models/RefData.model";
import SummaryQa from "../../models/SummaryQa.model";
import ReferencedSummaryQa, { SummaryQaLinkType } from "../../models/SummaryQaLink.model";
import { DocumentStatusBadge } from "../../services/document-services";
import { judgementActHref } from "../../services/judgement-act-services";
import { prefetchSummary, summaryHref } from "../../services/summary-services";
import { shortenUrl } from "../../services/url-services";
import AccessIndicator from "../HomePage/access-indicator";
import { renderTextWithProvisionsAndActs } from "./renderTextWithProvisions";
import { getOffset } from "./summary-search-panel";

type QuestionCardProps = {
  qa: SummaryQa;
  idx: number;
  questionCount: number;
  provisions: Provision[];
  setSectoinToggleStatus: (section: string, status?: number) => void;
  getSectionToggleStatus: (section: string) => number;
  summaryActDate?: string;
};
const QuestionCard: React.FC<QuestionCardProps> = ({
  idx,
  qa,
  questionCount,
  provisions,
  setSectoinToggleStatus,
  getSectionToggleStatus,
  summaryActDate,
}) => {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  const ts = getSectionToggleStatus(idx + "-question");

  useEffect(() => {
    if (
      (ts && typeof ts === "number" && ts > 1 && ref.current) ||
      window.location.hash === `#${qa.id}`
    ) {
      setTimeout(async () => {
        const position = getOffset(ref.current!).top - 150;
        window.scrollTo(0, position);
      }, 10);
      ref.current!.style.transition = "";
      ref.current!.style.backgroundColor = "#feffb3";
      setTimeout(() => {
        ref.current!.style.transition = "background-color 1500ms linear";
        ref.current!.style.backgroundColor = "var(--summary-view-page-card-question-background)";
      }, 1500);
    }
  }, [ts, location.hash, qa.id]);

  const { setSelectedActId } = useSelectedActContext();

  const [questionDetailToggle, setQuestionDetailToggle] = useState("0");

  const intl = useIntl();

  return (
    <div className="question">
      <Card className="highlight-card" id={`${qa.id}`} ref={ref}>
        <Card.Body
          onClick={() => setQuestionDetailToggle(questionDetailToggle === "1" ? "0" : "1")}
        >
          <Card.Subtitle>
            <FormattedMessage
              id="view-summary.labels.question"
              values={{ num: questionCount > 1 ? ` ${idx + 1}` : "" }}
            />
          </Card.Subtitle>
          <Card.Text className="search-target">
            {qa.question}
            {questionDetailToggle === "1" ? (
              <FaAngleUp className="toggle-indicator question-info-icon" />
            ) : (
              <FaAngleDown className="toggle-indicator question-info-icon" />
            )}
          </Card.Text>
        </Card.Body>
        <Accordion
          activeKey={questionDetailToggle}
          className={questionDetailToggle === "1" ? "" : "search-exclude"}
        >
          <Accordion.Collapse eventKey="1">
            <Card.Header>
              <div className="highlight-card-details">
                <span className="highlight-card-title">{qa.shortTitle}</span>
                <LabelValue
                  label={intl.formatMessage({ id: "view-summary.labels.topic" })}
                  show={qa.topics?.length === 1}
                >
                  {qa.topics.map((topic) => (
                    <TopicLink topic={topic} />
                  ))}
                </LabelValue>
                <LabelValue
                  label={intl.formatMessage({ id: "view-summary.labels.topics" })}
                  show={qa.topics?.length > 1}
                >
                  {qa.topics.map((topic) => (
                    <div key={topic.id}>
                      <TopicLink topic={topic} />
                    </div>
                  ))}
                </LabelValue>
                {qa.keyLegislation && (
                  <LabelValue
                    label={intl.formatMessage({ id: "view-summary.labels.keyProvision" })}
                  >
                    {qa.keyLegislation.title}
                  </LabelValue>
                )}
                <LabelValue
                  label={intl.formatMessage({ id: "view-summary.labels.groundOfAppeal" })}
                  show={!!qa.groundOfAppeal}
                >
                  {qa.groundOfAppeal?.name}
                  {qa.groundOfAppeal?.code === "280-1-3_2007" && (
                    <span className="ms-1">
                      <FiAlertCircle title="Развитие на правото" />
                    </span>
                  )}
                </LabelValue>
              </div>
            </Card.Header>
          </Accordion.Collapse>
        </Accordion>
      </Card>
      <br />
      <div className="filters-sidebar">
        {qa.arguments && (
          <Collapse
            icon={<FaBars />}
            label={intl.formatMessage({ id: "view-summary.labels.arguments" })}
            defaultExpanded={false}
            toggleStatus={getSectionToggleStatus(idx + "-arguments")}
            setToggleStatus={(status: string) =>
              setSectoinToggleStatus(idx + "-arguments", status ? 1 : 0)
            }
          >
            <h6 className="print-header">
              {intl.formatMessage({ id: "view-summary.labels.arguments" })}
            </h6>
            <div className="act-text search-target">
              {parse(qa.arguments, {
                replace: (node) => {
                  if ((node as any).data) {
                    return renderTextWithProvisionsAndActs(
                      (node as any).data,
                      provisions,
                      qa.relatedJudgementActs,
                      setSelectedActId
                    );
                  }
                },
              })}
            </div>
          </Collapse>
        )}
        {qa.answer && (
          <Collapse
            icon={<FaBars />}
            label={intl.formatMessage({ id: "view-summary.labels.answer" })}
            defaultExpanded={true}
            toggleStatus={getSectionToggleStatus(idx + "-answer")}
            setToggleStatus={(status: string) =>
              setSectoinToggleStatus(idx + "-answer", status ? 1 : 0)
            }
          >
            <h6 className="print-header">
              {intl.formatMessage({ id: "view-summary.labels.answer" })}
            </h6>
            <div className="act-text search-target">
              {parse(qa.answer, {
                replace: (node) => {
                  if ((node as any).data) {
                    return renderTextWithProvisionsAndActs(
                      (node as any).data,
                      provisions,
                      qa.relatedJudgementActs,
                      setSelectedActId
                    );
                  }
                },
              })}
            </div>
          </Collapse>
        )}
        {qa.dissentingOpinions.length > 0 && (
          <Collapse
            icon={<FaBars />}
            label={intl.formatMessage({ id: "view-summary.labels.dissentingOpinion" })}
            defaultExpanded={false}
            toggleStatus={getSectionToggleStatus(idx + "-discenting-opinion")}
            setToggleStatus={(status: string) =>
              setSectoinToggleStatus(idx + "-discenting-opinion", status ? 1 : 0)
            }
          >
            <h6 className="print-header">
              {intl.formatMessage({ id: "view-summary.labels.dissentingOpinion" })}
            </h6>
            <Tab.Container defaultActiveKey="0">
              <Nav variant="pills" className={`opinion-tabs tabs-${qa.dissentingOpinions.length}`}>
                {qa.dissentingOpinions.map((opinion, i) => (
                  <Nav.Item key={opinion.author}>
                    <Nav.Link eventKey={i + ""}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-dissentingOpinion-${i}`}>{opinion.author}</Tooltip>
                        }
                      >
                        <span>{opinion.author}</span>
                      </OverlayTrigger>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Tab.Content>
                {qa.dissentingOpinions.map((opinion, i) => (
                  <Tab.Pane eventKey={i + ""} key={opinion.author}>
                    <div className="act-text">
                      <div className="opinion-author">{opinion.author}</div>
                      {parse(opinion.text, {
                        replace: (node) => {
                          if ((node as any).data) {
                            return renderTextWithProvisionsAndActs(
                              (node as any).data,
                              provisions,
                              qa.relatedJudgementActs,
                              setSelectedActId
                            );
                          }
                        },
                      })}
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </Collapse>
        )}

        {qa.editorialOpinions.length > 0 && (
          <Collapse
            icon={<FaBars />}
            label={intl.formatMessage({ id: "view-summary.labels.editorialOpinion" })}
            defaultExpanded={false}
            toggleStatus={getSectionToggleStatus(idx + "-editorial-opinion")}
            setToggleStatus={(status: string) =>
              setSectoinToggleStatus(idx + "-editorial-opinion", status ? 1 : 0)
            }
          >
            <h6 className="print-header">
              {intl.formatMessage({ id: "view-summary.labels.editorialOpinion" })}
            </h6>
            <Tab.Container defaultActiveKey="0">
              <Nav variant="pills" className={`opinion-tabs tabs-${qa.dissentingOpinions.length}`}>
                {qa.editorialOpinions.map((opinion, i) => (
                  <Nav.Item key={opinion.author}>
                    <Nav.Link eventKey={i + ""} title={opinion.author}>
                      {opinion.author}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Tab.Content>
                {qa.editorialOpinions.map((opinion, i) => (
                  <Tab.Pane eventKey={i + ""} key={opinion.author}>
                    <div className="act-text">
                      {parse(opinion.text, {
                        replace: (node) => {
                          if ((node as any).data) {
                            return renderTextWithProvisionsAndActs(
                              (node as any).data,
                              provisions,
                              qa.relatedJudgementActs,
                              setSelectedActId
                            );
                          }
                        },
                      })}
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </Collapse>
        )}
        {qa.relatedJudgementActs.length > 0 && (
          <Collapse
            icon={<FaBars />}
            label={intl.formatMessage({ id: "view-summary.labels.relatedJudgementActs" })}
            defaultExpanded={false}
          >
            <h6 className="print-header">
              {intl.formatMessage({ id: "view-summary.labels.relatedJudgementActs" })}
            </h6>
            <Tab.Container
              defaultActiveKey={
                [
                  ...qa.relatedJudgementActs.map((act) =>
                    act.summaries
                      ? act.referencesTo.filter((r) => r.type === SummaryQaLinkType.CONTRADICTION)
                          .length +
                        act.referencesFrom.filter((r) => r.type === SummaryQaLinkType.CONTRADICTION)
                          .length
                      : 0
                  ),
                ].reduce((a, b) => a + b, 0) > 0
                  ? "1"
                  : "0"
              }
            >
              <Nav variant="pills" className="opinion-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="0">
                    <FormattedMessage id="view-summary.labels.relatedJudgementActs.acts" />
                  </Nav.Link>
                </Nav.Item>
                {[
                  ...qa.relatedJudgementActs.map((act) =>
                    act.summaries ? act.referencesTo.length + act.referencesFrom.length : 0
                  ),
                ].reduce((a, b) => a + b, 0) > 0 && (
                  <Nav.Item>
                    <Nav.Link eventKey="1">
                      <FormattedMessage id="view-summary.labels.relatedJudgementActs.questions" />
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="0">
                  <div className="act-text">
                    {qa.relatedJudgementActs.map((act) => (
                      <div key={act.id}>
                        <div className="d-flex align-items-center">
                          {(summaryActDate || "").localeCompare(act.actDate || "") > 0 ? (
                            <FaArrowCircleRight />
                          ) : (
                            <FaArrowCircleLeft />
                          )}
                          <InlineLink
                            to={judgementActHref(act.id)}
                            onClick={(ev) => {
                              if (!ev.ctrlKey && !ev.metaKey) {
                                ev.preventDefault();
                                setSelectedActId(act.id);
                              }
                            }}
                            className="ms-1"
                          >
                            {act.title} <DocumentStatusBadge status={act.status} />
                          </InlineLink>
                        </div>

                        {act.summaries?.map((summary) => (
                          <SummaryLink key={summary.id} summary={summary} />
                        ))}
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="1">
                  <div className="act-text">
                    {qa.relatedJudgementActs.map((act) => (
                      <div key={act.id}>
                        {act.summaries?.map((summary) => (
                          <div key={summary.id}>
                            {act.referencesTo.map((r) => (
                              <SummaryQaLink
                                key={r.id}
                                r={r}
                                summary={summary}
                                label={
                                  <FormattedMessage id={`summary.qa.link.type.to.${r.type}`} />
                                }
                              />
                            ))}
                            {act.referencesFrom.map((r) => (
                              <SummaryQaLink
                                key={r.id}
                                r={r}
                                summary={summary}
                                label={
                                  <FormattedMessage id={`summary.qa.link.type.from.${r.type}`} />
                                }
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Collapse>
        )}
      </div>
    </div>
  );
};

const TopicLink: React.FC<{ topic: RefData }> = ({ topic }) => {
  const navigate = useNavigate();
  return (
    <>
      {topic.name.indexOf(">") === -1 ? (
        <InlineLinkButton
          style={{ verticalAlign: "baseline" }}
          onClick={async () => {
            const params = new URLSearchParams();
            params.append("summaryTopic", `${topic.id}`);
            const q = await shortenUrl(params.toString());
            navigate(`/search/summaries?q=${q}`);
          }}
        >
          {topic.name}
        </InlineLinkButton>
      ) : (
        <>
          {topic.name.substring(0, topic.name.lastIndexOf(">") + 2)}
          <InlineLinkButton
            style={{ verticalAlign: "baseline" }}
            onClick={async () => {
              const params = new URLSearchParams();
              params.append("summaryTopic", `${topic.id}`);
              const q = await shortenUrl(params.toString());
              navigate(`/search/summaries?q=${q}`);
            }}
          >
            {topic.name.substring(topic.name.lastIndexOf(">") + 2)}
          </InlineLinkButton>
        </>
      )}
    </>
  );
};

const SummaryLink: React.FC<{
  summary: {
    id: string;
    shortTitle: string;
    status: DocumentStatus;
    accessMode: DocumentAccessMode;
  };
}> = ({ summary }) => {
  const protectedAction = useProtectedAction(summary.accessMode === DocumentAccessMode.FREE);
  const progress = useLoadingProgress();
  const navigate = useNavigate();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { cache } = useSWRConfig();
  return (
    <div className="related-judgement-acts-summary">
      <Badge bg="light" text="secondary">
        <FormattedMessage id="view-summary.labels.relatedJudgementActs.summary" />
      </Badge>
      <InlineLinkButton
        className="ms-1"
        onClick={protectedAction(async () => {
          if (isMobileOnly) {
            progress.start();
            try {
              await prefetchSummary(summary.id, cache);
            } finally {
              progress.complete();
            }
            navigate(summaryHref(summary.id));
          } else {
            window.open(summaryHref(summary.id), "_blank");
          }
        })}
      >
        {summary.shortTitle} <DocumentStatusBadge status={summary.status} />{" "}
        <AccessIndicator mode={summary.accessMode} />
      </InlineLinkButton>
    </div>
  );
};

const SummaryQaLink: React.FC<{
  r: ReferencedSummaryQa;
  summary: {
    id: string;
    shortTitle: string;
    status: DocumentStatus;
    accessMode: DocumentAccessMode;
  };
  label: React.ReactNode;
}> = ({ r, summary, label }) => {
  const protectedAction = useProtectedAction(summary.accessMode === DocumentAccessMode.FREE);
  const progress = useLoadingProgress();
  const navigate = useNavigate();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { cache } = useSWRConfig();
  return (
    <div>
      <FaQuestionCircle />{" "}
      <Badge bg="light" text={r.type === SummaryQaLinkType.CONTRADICTION ? "danger" : "secondary"}>
        {label}
      </Badge>
      <br />
      <InlineLinkButton
        onClick={protectedAction(async () => {
          if (isMobileOnly) {
            progress.start();
            try {
              await prefetchSummary(summary.id, cache);
            } finally {
              progress.complete();
            }
            navigate(summaryHref(`${summary.id}#${r.id}`));
          } else {
            window.open(summaryHref(`${summary.id}#${r.id}`), "_blank");
          }
        })}
      >
        {r.question}
      </InlineLinkButton>
    </div>
  );
};

export default QuestionCard;
