// NOTE: works but probably not optimal. I fired this off the top of my head,
// for something like this in production I'd use a library function from
// lodash or ramda or something.
export const partition = (n: number, xs: any[]) => {
  const results: any[] = [];
  let i = 0;
  let k = 0;
  while (i < xs.length) {
    results.push([]);
    while (k < n) {
      if (i === xs.length) break;
      results[results.length - 1].push(xs[i]);
      i++;
      k++;
    }
    k = 0;
  }
  return results;
};

// This will make the requests from the passed in array and
// wait for them all to finish. Note that unlike Promise.all
// allSettled will not bail early if you get a rejection.
const runPromiseFns = async (ps: any[]) => Promise.allSettled(ps.map((x) => x()));

// This will take an array of arrays of Promise generating functions
// and wait for each sub-array to settle before moving on
// to the next one.
export const runChunks = async (chunks: any[][], callback: Function) => {
  for (const [idx, chunk] of Array.from(chunks.entries())) {
    await runPromiseFns(chunk);
    callback(idx);
  }
  return;
};
