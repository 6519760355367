import React from "react";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import { withActiveSubscrption } from "../../contexts/auth.context";
import { useSearchContext } from "../../contexts/search.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { Selection } from "../../hooks/useMultipleSelection";
import SummaryQa from "../../models/SummaryQa.model";
import { summaryHref, useQuestions } from "../../services/summary-services";
import ResultsTable from "../SearchPage/ResultsTable/results-table";
import ResultsTableHeader from "../SearchPage/ResultsTable/results-table-header";
import "../SearchPage/SearchResults/search-results.scss";
const SearchResultsLexebraGPT = () => {
  const results = useQuestions();
  const { opened, addOpened } = useSearchContext();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  const { setSelectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();
  if (!query) {
    return <div className="no-results"></div>;
  }

  if (results.totalCount === 0) {
    return <div className="no-results">Няма намерени резултати.</div>;
  }

  return (
    <ResultsTable
      results={results}
      header={(params) => <ResultsTableHeader {...params} />}
      itemToSelection={itemToSelection}
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <InlineLink
            to={summaryHref(`${item.summary.id}#${item.id}`)}
            //onClick={() => addOpened(item.id)}
            onClick={(e) => {
              if (!e.ctrlKey && !e.metaKey) {
                e.preventDefault();
                setSelectedActId("summary:" + item.summary.id);
              }
            }}
            className={opened.indexOf(item.id) > -1 ? "visited" : ""}
          >
            {item.question}
          </InlineLink>
          <div>{item.shortTitle}</div>
          {item.caseType &&
            item.caseType.code !== "CRIMINAL" &&
            item.caseType.code !== "CRIMINAL_PRIVATE" && (
              <LabelValue label={formatMessage({ id: "view-judgement.labels.proceedingBy" })}>
                {item.proceeding?.shortName}
              </LabelValue>
            )}

          <ResultsTable.RowIndicators item={item} />
          <ResultsTable.RowHighlights item={item} />
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

const itemToSelection = (item: SummaryQa): Selection => ({
  _id: item.id,
  title: item.question,
  href: summaryHref(`${item.summary.id}#${item.id}`),
  hasSummary: false,
  hasAnnotations: false,
  hasEditorAnnotation: false,
  hasAiAnnotations: false,
});

export default withActiveSubscrption(SearchResultsLexebraGPT);
