/* eslint-disable no-underscore-dangle */
import { useMemo } from "react";
import CheckboxTree, { Node as CheckboxTreeNode } from "react-checkbox-tree";
import { isMobileOnly } from "react-device-detect";
import { FaHighlighter, FaRegCommentAlt } from "react-icons/fa";
import ErrorBoundary from "../../components/ErrorBoundary";
import Collapse from "../../components/SimpleCollapse";
import Skeleton from "../../components/Skeleton";
import { useHighlightContext } from "../../contexts/highlight.context";
import { useNavigationDrawerContext } from "../../contexts/navigation-drawer.context";
import { CollapsibleFiltersPanel } from "../SearchPage/FiltersSidebar/filters";
import { getFirstCommentText } from "./judgement-view-page";
import { RecogitoAnno, scrollAndHighlight } from "./judgmenet-view-page-metadata";

type PersonalMarkersTabProps = {
  annotations?: RecogitoAnno[];
};

const PersonalMarkersTab: React.FC<PersonalMarkersTabProps> = ({ annotations }) => {
  return (
    <Collapse icon={<FaHighlighter />} label="Лично подчертаване" className="personal-markers-tab">
      <ErrorBoundary message="Възникна грешка при зареждането на данните">
        <PersonalMarkersTabBody annotations={annotations} />
      </ErrorBoundary>
    </Collapse>
  );
};

const PersonalMarkersTabBody: React.FC<PersonalMarkersTabProps> = ({ annotations }) => {
  const toc = useMemo(
    () =>
      annotations!.map((anno, i) => {
        const markedText = anno.target.selector
          .filter((selector: any) => selector.type === "TextQuoteSelector")
          .map((selector: any) => selector.exact)[0];

        const noteText: string = getFirstCommentText(anno);

        const label = noteText ? Array.from(noteText.matchAll(/^(.+?):/g), (m) => m[1])[0] : "";

        return {
          label: (
            <div className="personal-marker">
              {label || `Подчертаване ${i + 1}`} {noteText && <FaRegCommentAlt />}
              <div className="text-truncate" style={{ fontSize: "0.7rem" }}>
                {markedText}
              </div>
            </div>
          ),
          value: anno.id,
          children: [],
        };
      }),
    [annotations]
  );

  const expandedNodes = useMemo(() => Array.from(traverse(toc, new Set())), [toc]);
  const navigationDrawerContext = useNavigationDrawerContext();

  const { includeUserAnnotations } = useHighlightContext();

  return (
    <CollapsibleFiltersPanel
      collapseHeight="250px"
      openedLabel="Покажи по-малко"
      closedLabel="Покажи всичко"
      key={annotations?.length}
    >
      <CheckboxTree
        nodes={toc}
        checked={[]}
        expanded={expandedNodes}
        noCascade
        expandDisabled
        onCheck={(node) => {
          isMobileOnly && navigationDrawerContext && navigationDrawerContext.setIsOpen(false);
          scrollAndHighlight(
            node[0],
            includeUserAnnotations ? "rgba(255, 165, 0, 0.2)" : undefined
          );
        }}
      />
    </CollapsibleFiltersPanel>
  );
};

const traverse = (nodes: CheckboxTreeNode[], accumulator: Set<string>) => {
  nodes.forEach((n) => {
    accumulator.add(n.value);
    n.children && traverse(n.children, accumulator);
  });
  return accumulator;
};

export const PersonalMarkersTabLoading = () => {
  return <Skeleton count={10} />;
};

export default PersonalMarkersTab;
