/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Transition } from "react-transition-group";
import { getOffset } from "../../pages/SummaryViewPage/summary-search-panel";

type CollapseProps = {
  icon: ReactNode;
  label: string;
  toggleStatus?: number;
  setToggleStatus?: (status: any) => void;
  className?: string;
  defaultExpanded?: boolean;
  scrollIntoViewOnExpand?: boolean;
};

const Collapse: React.FC<CollapseProps> = ({
  icon,
  label,
  children,
  toggleStatus = undefined,
  setToggleStatus = undefined,
  className = "",
  defaultExpanded = true,
  scrollIntoViewOnExpand = true,
}) => {
  const [toggleStatusInState, setToggleStatusInState] = useState<any>(defaultExpanded ? "A" : null);

  const accordionRef = useRef<HTMLDivElement>(null);

  const ts = setToggleStatus ? toggleStatus : toggleStatusInState;
  const setTs = setToggleStatus || setToggleStatusInState;

  useEffect(() => {
    if (ts && typeof ts === "number" && ts > 1 && accordionRef.current) {
      setTimeout(() => {
        const position = getOffset(accordionRef.current!).top - 150;
        window.scrollTo(0, position);
      }, 10);
    }
  }, [ts]);

  const defaultStyle = {
    backgroundColor: "#feffb3",
    transition: "background-color 1500ms linear",
  };
  const transitionStyles: any = {
    entered: { backgroundColor: "transparent" },
  };

  const nodeRef = useRef(null);

  return (
    <Accordion
      // onSelect={(e) => setTs(e)}
      className={className}
      activeKey={ts ? "A" : undefined}
      ref={accordionRef}
    >
      <Card>
        <Card.Header>
          <Container
            style={{ cursor: "pointer" }}
            onClick={() => setTs(ts ? undefined : "A")}
            fluid
          >
            {icon} {label}
            {ts ? (
              <FaAngleUp className="toggle-indicator" />
            ) : (
              <FaAngleDown className="toggle-indicator" />
            )}
          </Container>
          {/* <Accordion.Button as={Container} variant="link" eventKey="A">
           
          </Accordion.Button> */}
        </Card.Header>
        <Accordion.Collapse eventKey="A">
          <Card.Body key={ts + ""}>
            <Transition in={!!ts} appear={ts > 1} timeout={{ enter: 4000 }} nodeRef={nodeRef}>
              {(state) => {
                return (
                  <div
                    ref={nodeRef}
                    style={{
                      ...(state === "exited" || state === "exiting" ? {} : defaultStyle),
                      ...transitionStyles[state],
                    }}
                  >
                    {children}
                  </div>
                );
              }}
            </Transition>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Collapse;
