import dayjs from "dayjs";
import React from "react";
import { BiTimeFive } from "react-icons/bi";
import { FormattedDate, FormattedMessage } from "react-intl";
import PreloadingLink from "../../components/PreloadingLink";
import Skeleton from "../../components/Skeleton";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import NewsArticleSection from "../../models/NewsArticleSection.enum";
import { prefetchDocumentStats } from "../../services/document-services";
import { useFocusedArticles } from "../../services/home-services";
import {
  newsArticleHref,
  prefetchNewsArticle,
  prefetchNewsArticles,
} from "../../services/news-article-services";
import AccessIndicator from "./access-indicator";
import { PUBLISH_DATE_FORMAT } from "./focused-section";

const FocusedNewsArticles: React.FC<{ section: NewsArticleSection }> = ({ section }) => {
  const { data, totalCount } = useFocusedArticles();
  return data ? (
    <>
      {totalCount! > 0 ? (
        data?.map((page) => {
          return page?._embedded.items.map((article) => (
            <div key={article.id}>
              <div className="item-heading">
                <PreloadingLink
                  preload={(cache) => prefetchNewsArticle(article.id, cache)}
                  to={newsArticleHref(article.id)}
                  state={{ from: "/" }}
                  protect={article.accessMode === DocumentAccessMode.PAID}
                >
                  {article.title}
                </PreloadingLink>
                <AccessIndicator mode={article.accessMode} />
              </div>
              <div>{article.subTitle}</div>
              <div className="item-details">
                <FormattedDate
                  value={dayjs(article.publishDate, PUBLISH_DATE_FORMAT).toDate()}
                  month="long"
                  day="2-digit"
                />
                {article.timeReading ? (
                  <>
                    &nbsp;-&nbsp;
                    <BiTimeFive />
                    &nbsp;{article.timeReading} мин. за преглед
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ));
        })
      ) : (
        <div className="missing-articles">
          <FormattedMessage
            id={
              section === NewsArticleSection.NEWS
                ? "home-page.sections.focused-news.no-data"
                : "home-page.sections.focused-events.no-data"
            }
          />
        </div>
      )}
      {totalCount! > 2 && (
        <PreloadingLink
          preload={async (cache) => {
            await prefetchDocumentStats(new URLSearchParams(), cache);
            await prefetchNewsArticles(new URLSearchParams(), cache);
          }}
          to={`/search/news-articles`}
          className="view-all-link"
          event="onMouseEnter"
        >
          <FormattedMessage id="home-page.sections.view-all.link" />
        </PreloadingLink>
      )}
    </>
  ) : (
    <Skeleton count={5} />
  );
};

export default FocusedNewsArticles;
