import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import { useViewEditPageContext } from "../../contexts/view-edit-page.context";
import SubscriberType from "../../models/SubscriberType.enum";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import { useSubscription } from "../../services/subscription-services";
const CustomerInfoSection = () => {
  const { watch } = useFormContext<Subscription>();

  const type = watch("subscriber.type");

  return (
    <Tabs activeKey={"0"} className="sub-section-tabs">
      <Tab
        eventKey="0"
        disabled
        title={<FormattedMessage id="edit-subscription.form.customerInfo" />}
      >
        {type === SubscriberType.COMPANY ? <CompanyInfo /> : <PrivatePersonInfo />}
      </Tab>
    </Tabs>
  );
};

const PrivatePersonInfo = () => {
  const { control, register } = useFormContext<Subscription>();

  const { mode } = useViewEditPageContext();

  const { subscriptionId } = useParams();
  const subscription = useSubscription(subscriptionId);
  const status = subscription?.status || SubscriptionStatus.REQUESTED;

  return (
    <>
      <Row>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.firstName">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.firstName" />
            </Form.Label>
            <Form.Control
              type="text"
              {...register("subscriber.customerInfo.firstName")}
              readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.middleName">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.middleName" />
            </Form.Label>
            <Form.Control
              type="text"
              {...register("subscriber.customerInfo.middleName")}
              readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.lastName">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.lastName" />
            </Form.Label>
            <Form.Control
              type="text"
              {...register("subscriber.customerInfo.lastName")}
              readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <Row>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.email">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.email" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.email")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.phoneNumber">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.phoneNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.phoneNumber")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.personalIdentificationNumber">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.personalIdentificationNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.personalIdentificationNumber")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.vatNumber">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.vatNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.vatNumber")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.address">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.address" />
            </Form.Label>
            <Controller
              name="subscriber.customerInfo.address"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSizedTextArea
                  onChange={onChange}
                  value={value}
                  minHeight={100}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const CompanyInfo = () => {
  const { control, register } = useFormContext<Subscription>();

  const { mode } = useViewEditPageContext();

  const { subscriptionId } = useParams();
  const subscription = useSubscription(subscriptionId);
  const status = subscription?.status || SubscriptionStatus.REQUESTED;

  return (
    <>
      <Row>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.companyName">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.companyName" />
            </Form.Label>
            <Form.Control
              type="text"
              {...register("subscriber.customerInfo.companyName")}
              readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.legalRepresentative">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.legalRepresentative" />
            </Form.Label>
            <Form.Control
              type="text"
              {...register("subscriber.customerInfo.legalRepresentative")}
              readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.contactPerson">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.contactPerson" />
            </Form.Label>
            <Form.Control
              type="text"
              {...register("subscriber.customerInfo.contactPerson")}
              readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <Row>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.email">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.email" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.email")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.phoneNumber">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.phoneNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.phoneNumber")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.unifiedIdentificationNumber">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.unifiedIdentificationNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.unifiedIdentificationNumber")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="subscriber.customerInfo.vatNumber">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.vatNumber" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("subscriber.customerInfo.vatNumber")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Form.Group controlId="subscriber.customerInfo.address">
            <Form.Label>
              <FormattedMessage id="edit-subscription.form.address" />
            </Form.Label>
            <Controller
              name="subscriber.customerInfo.address"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSizedTextArea
                  onChange={onChange}
                  value={value}
                  minHeight={100}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default CustomerInfoSection;
