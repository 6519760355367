/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DocumentsMenuContents } from "./documents-menu";

const DocumentsMenuAnonymous = () => {
  return (
    <DocumentsMenuContents
      isLogged={false}
      userDocuments={{
        opened: [],
        favourite: [],
      }}
    />
  );
};

export default DocumentsMenuAnonymous;
