import React, { ReactElement, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { InlineLinkButton } from "../../components/Button/button";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { useDocumentActionsContext } from "./document-actions-container";

type DocumentActionProps = {
  label: string;
  icon: ReactElement;
  handler: () => any | Promise<any>;
};

const DocumentAction: React.FC<DocumentActionProps> = ({ label, icon, handler }) => {
  const { dropdown } = useDocumentActionsContext();

  const { selectedActId } = useSelectedActContext();

  const [id] = useState(uniqueId("doc-action-"));
  return dropdown ? (
    <Dropdown.Item onClick={handler}>
      {icon}
      {label}
    </Dropdown.Item>
  ) : (
    <InlineLinkButton onClick={handler}>
      <OverlayTrigger
        placement={selectedActId ? "bottom" : "top"}
        overlay={<Tooltip id={id}>{label}</Tooltip>}
      >
        <span>{icon}</span>
      </OverlayTrigger>
    </InlineLinkButton>
  );
};

const uniqueId = (prefix = "id-") => prefix + Math.random().toString(16).slice(-4);

export default DocumentAction;
