import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Summary } from "../../models/Summary.model";
import RelatedActsTable from "./related-acts.table";

type RelatedActsSectionProps = {
  idx: number;
};

const RelatedActsSection: React.FC<RelatedActsSectionProps> = ({ idx }) => {
  const { control, setValue } = useFormContext<Summary>();
  const { fields, append, remove } = useFieldArray<Summary, any, "fieldId">({
    name: `qas.${idx}.relatedJudgementActs`,
    keyName: "fieldId",
    control,
  });

  const watchFieldArray = useWatch({ control, name: `qas.${idx}.relatedJudgementActs` as any });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return (
    <RelatedActsTable
      value={controlledFields}
      append={append}
      remove={remove}
      replace={(i, item) => {
        setValue(`qas.${idx}.relatedJudgementActs.${i}` as any, item);
      }}
    />
  );
};

export default RelatedActsSection;
