import React from "react";
import { Cache, useSWRConfig } from "swr";
import { useLoadingProgress } from "../../contexts/loading-progress.context";

const PreloadingController: React.FC<{
  preload: (cache: Cache<any>) => Promise<any>;
  event?: "onMouseEnter" | "onClick";
  children: (props: { onClick: any; onMouseEnter?: any }) => React.ReactNode;
  onSuccess: () => void;
}> = ({ preload, event = "onClick", onSuccess, children }) => {
  const { cache } = useSWRConfig();
  const progress = useLoadingProgress();

  const handler =
    (isOnClick: boolean) => async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      isOnClick && progress.start();

      try {
        await preload(cache);
      } finally {
        isOnClick && progress.complete();
      }
      isOnClick && onSuccess();
    };

  return (
    <>
      {children({
        [event]: handler(false),
        onClick: handler(true),
      })}
    </>
  );
};

export default PreloadingController;
