import { EntityModel } from "hateoas-hal-types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaEdit, FaPowerOff, FaRedo, FaRegPlayCircle, FaTimes } from "react-icons/fa";
import { FiSlash } from "react-icons/fi";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ActionButton, InlineLinkButton, SecondaryButton } from "../../components/Button/button";
import { useViewEditPageContext } from "../../contexts/view-edit-page.context";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import { saveSubscription, SUBCRIPTIONS_BASE_URL } from "../../services/subscription-services";
import ActivateSubscriptionModal from "./activate-subscription.modal";
import CancelSubscriptionModal from "./cancel-subscription.modal";
import RestartSubscriptionModal from "./restart-subscription.modal";
import StartSubscriptionModal from "./start-subscription.modal";

const SubscriptionEditPageActions = () => {
  const intl = useIntl();

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isSubmitting },
  } = useFormContext<EntityModel<Subscription>>();

  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      const saved = await saveSubscription(data, data.id);
      toast.success(
        intl.formatMessage(
          {
            id: data.id
              ? "subscription-edit.page.toasts.update.success"
              : "subscription-edit.page.toasts.add.success",
          },
          data
        )
      );
      setValue("id", saved.id);
      if (data.id) {
        setMode("view");
      } else {
        navigate(`/admin${SUBCRIPTIONS_BASE_URL}/${saved.id}`);
      }
    } catch (e) {
      console.log(e);
      toast.error(intl.formatMessage({ id: "subscription-edit.page.toasts.error" }, data));
    }
  };

  const { mode, setMode } = useViewEditPageContext();
  const [showStartSubscriptionModal, setShowStartSubscriptionModal] = useState<boolean>(false);
  const [showActivateSubscriptionModal, setShowActivateSubscriptionModal] =
    useState<boolean>(false);

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState<boolean>(false);
  const [showRestartSubscriptionModal, setShowRestartSubscriptionModal] = useState<boolean>(false);

  const subscription = watch();
  const { status, prefix, licenseCount } = subscription;

  return mode === "view" ? (
    <>
      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal
          subscriptionId={subscription.id}
          onClose={() => setShowCancelSubscriptionModal(false)}
          onUpdate={reset}
        />
      )}
      {showRestartSubscriptionModal && (
        <RestartSubscriptionModal
          subscriptionId={subscription.id}
          onClose={() => setShowRestartSubscriptionModal(false)}
          onUpdate={reset}
        />
      )}
      {showStartSubscriptionModal && (
        <StartSubscriptionModal
          subscription={subscription}
          onClose={() => setShowStartSubscriptionModal(false)}
          prefix={prefix}
          licenseCount={licenseCount}
          onUpdate={reset}
        />
      )}
      {showActivateSubscriptionModal && (
        <ActivateSubscriptionModal
          subscription={subscription}
          onClose={() => setShowActivateSubscriptionModal(false)}
          prefix={prefix}
          licenseCount={licenseCount}
          onUpdate={reset}
        />
      )}
      {status === SubscriptionStatus.PENDING_INITIAL_PAYMENT && (
        <InlineLinkButton onClick={() => setShowRestartSubscriptionModal(true)}>
          <FaRedo title="Рестратирай" />
        </InlineLinkButton>
      )}
      {status === SubscriptionStatus.REQUESTED && (
        <InlineLinkButton onClick={() => setShowStartSubscriptionModal(true)}>
          <FaPowerOff title="Стартирай" />
        </InlineLinkButton>
      )}
      {status === SubscriptionStatus.PENDING_INITIAL_PAYMENT && (
        <InlineLinkButton onClick={() => setShowActivateSubscriptionModal(true)}>
          <FaRegPlayCircle title="Активирай" />
        </InlineLinkButton>
      )}
      {(status === SubscriptionStatus.REQUESTED ||
        status === SubscriptionStatus.PENDING_INITIAL_PAYMENT) && (
        <InlineLinkButton onClick={() => setShowCancelSubscriptionModal(true)}>
          <FiSlash title="Отмени" />
        </InlineLinkButton>
      )}
      <InlineLinkButton onClick={() => setMode("edit")}>
        <FaEdit />
      </InlineLinkButton>
      <span className="ms-2  mb-1">|</span>
      <InlineLinkButton onClick={() => navigate(-1)}>
        <FaTimes />
      </InlineLinkButton>
    </>
  ) : (
    <>
      <ActionButton
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        submitting={isSubmitting}
        messageId={"button.confirm"}
      />
      <SecondaryButton
        onClick={() => {
          if (subscription.id) {
            setMode("view");
            reset();
          } else {
            navigate(-1);
          }
        }}
        messageId={"button.cancel"}
        disabled={isSubmitting}
      />
    </>
  );
};

export default SubscriptionEditPageActions;
