/* eslint-disable react/jsx-props-no-spreading */
import Container from "react-bootstrap/Container";
import { VscMenu } from "react-icons/vsc";
import { FormattedMessage } from "react-intl";
import Collapse from "../../components/SimpleCollapse";
import Skeleton from "../../components/Skeleton";
import "./../SearchPage/FiltersSidebar/filters-sidebar.css";

const FiltersSideBarLoading = () => {
  return (
    <Container fluid className="filters-sidebar">
      <Collapse
        icon={<VscMenu />}
        label={<FormattedMessage id="filters-sidebar.filters" />}
        defaultExpanded={false}
      >
        <Skeleton count={7} height={30} style={{ marginTop: "10px" }} />
      </Collapse>
    </Container>
  );
};

export default FiltersSideBarLoading;
