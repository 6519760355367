import { useHotkeys } from "react-hotkeys-hook";
import { FaTimes } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useSearchContext } from "../../contexts/search.context";
import DocumentAction from "./document-action";

type DocumentCloseActionProps = {
  handler?: () => void;
};

const DocumentCloseAction: React.FC<DocumentCloseActionProps> = ({ handler }) => {
  const intl = useIntl();

  const { lastSearchHref } = useSearchContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  const close = async () => {
    navigate(state && state.from ? state.from : lastSearchHref, { state: state?.callbackState });
  };

  useHotkeys("ctrl+q,command+q", (e) => {
    e.preventDefault();
    close();
  });

  return (
    <DocumentAction
      handler={handler || close}
      icon={<FaTimes />}
      label={intl.formatMessage({ id: "document-actions.close" })}
    />
  );
};

export default DocumentCloseAction;
