import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Subscription } from "../../models/Subscription.model";
import PeriodsTable from "./periods.table";

const PeriodsSection = () => {
  const { control, setValue } = useFormContext<Subscription>();
  const { fields, append, remove } = useFieldArray<Subscription, any, "fieldId">({
    name: "periods",
    keyName: "fieldId",
    control,
  });

  const watchFieldArray = useWatch({ control, name: "periods" as any });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return controlledFields.length > 0 ? (
    <PeriodsTable
      value={controlledFields}
      append={append}
      remove={remove}
      replace={(i, item) => {
        setValue(`periods.${i}` as any, item);
      }}
    />
  ) : null;
};

export default PeriodsSection;
