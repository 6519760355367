import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ActionButton, SecondaryButton } from "../../components/Button/button";
import { saveUser } from "../../services/user-services";

const UserEditPageActions = () => {
  const intl = useIntl();

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      const saved = await saveUser(data, data.id);
      toast.success(
        intl.formatMessage(
          {
            id: data.id
              ? "user-edit.page.toasts.update.success"
              : "user-edit.page.toasts.add.success",
          },
          data
        )
      );
      setValue("id", saved.id);
      navigate(`/admin/users`);
    } catch (e) {
      console.log(e);
      toast.error(intl.formatMessage({ id: "user-edit.page.toasts.error" }, data));
    }
  };

  return (
    <>
      <ActionButton
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        submitting={isSubmitting}
        messageId={"button.confirm"}
      />
      <SecondaryButton
        onClick={() => navigate(-1)}
        messageId={"button.cancel"}
        disabled={isSubmitting}
      />
    </>
  );
};

export default UserEditPageActions;
