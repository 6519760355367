import SimpleSelect from ".";
import RefData from "../../models/RefData.model";

interface RefDataSelectSingleProps {
  options?: RefData[];
  value?: RefData;
  onChange: (selection: RefData) => void;
  isClearable?: boolean;
  overrideStrings?: {
    [key: string]: string;
  };
  isDisabled?: boolean;
}

const RefDataSelectSingle: React.FC<RefDataSelectSingleProps> = ({
  options = [],
  onChange = () => {},
  isClearable = false,
  isDisabled = false,
  value,
}) => {
  return (
    <SimpleSelect
      options={options}
      getOption={(option) => ({ value: option.id, label: option.name })}
      value={value ? [value] : []}
      onChange={(selection) => onChange(selection[0])}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={false}
      overrideStrings={{
        selectSomeItems: "Изберете...",
      }}
    />
  );
};

export default RefDataSelectSingle;
