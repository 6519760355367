/* eslint-disable no-underscore-dangle */
import { EntityModel } from "hateoas-hal-types";
import React, { FunctionComponent, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useLocation } from "react-use";
import NotAuthorized from "../../components/NotAuthorized";
import { AuthContext, hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import SummaryEditContextProvider from "../../contexts/summary-edit.context";
import SummaryContextProvider from "../../contexts/summary.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { JudgementAct } from "../../models/JudgementAct.model";
import { Summary } from "../../models/Summary.model";
import { fetchSummaryType, useJudgementAct } from "../../services/judgement-act-services";
import { useSummary } from "../../services/summary-services";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import SummaryPageNavigation from "../SummaryPage/summary-page-navigation";
import SummaryViewPageLoading from "../SummaryViewPage/summary-view-page-loading";
import SummaryEditPageActions from "./summary-edit-page-actions";
import SummaryEditMainSection from "./summary-edit-page-main";
import SummaryQaTabs from "./summary-edit-page-qa-tabs";
import "./summary-edit-page.scss";

interface SummaryEditPageProps {
  summary?: EntityModel<Summary>;
}

const SummaryEditPage = () => {
  const { summaryId } = useParams();
  const summary = useSummary(summaryId);
  const location = useLocation();
  const relatedAct = useJudgementAct(location.state?.usr?.actId as string);

  return summary || (!summaryId && relatedAct) ? (
    <SummaryEditForm summary={summary} relatedAct={relatedAct} />
  ) : (
    <SummaryViewPageLoading className="page-loading summary-page" mode="view" />
  );
};

const SummaryEditForm: FunctionComponent<SummaryEditPageProps & { relatedAct?: JudgementAct }> = ({
  summary,
  relatedAct,
}) => {
  const serverData = summary;
  const intl = useIntl();
  const { currentUser } = useContext(AuthContext);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: (serverData as any) || {
      relatedJudgementAct: {
        id: relatedAct?.id,
        title: relatedAct?.title,
        status: relatedAct?.status,
      },
      hasRelatedCase: !!relatedAct?.caseYear,
      linkWithEuLegsilation: "false",
      status: DocumentStatus.DRAFT,
      accessMode: DocumentAccessMode.PAID,
      provisions: [],
      editor: {
        name: currentUser?.firstName + " " + currentUser?.lastName,
      },
      qas: [
        {
          question: "",
          shortTitle: "",
          answer: "",
          arguments: "",
          dissentingOpinions: [],
          editorialOpinions: [],
          relatedJudgementActs: [],
          topics: [],
        },
      ],
    },
  });

  const { setValue } = methods;
  const judgementActId = useWatch({ control: methods.control, name: "relatedJudgementAct.id" });
  const judgementAct = useJudgementAct(judgementActId);
  const otherSummaries = judgementAct?.summaries.filter((s) => s.id !== serverData?.id);

  useEffect(() => {
    async function fetchData() {
      !serverData &&
        judgementActId &&
        setValue("summaryType", await fetchSummaryType(judgementActId));
    }
    fetchData();
  }, [judgementActId, setValue, serverData]);

  const summaryFormData = useWatch({
    control: methods.control,
  });

  return (
    <FormProvider {...methods}>
      <SummaryEditContextProvider>
        <SummaryContextProvider summary={summaryFormData}>
          <DocumentPageLayout
            title={serverData?.relatedJudgementAct.title}
            defaultTitle={intl.formatMessage({ id: "summary-edit.page.add-new-title" })}
            mode="edit"
            className="summary-page"
            menu={<SummaryEditPageActions />}
            navigation={<SummaryPageNavigation mode="edit" />}
          >
            <Form className="edit-form" autoComplete="off">
              <SummaryEditMainSection
                isCreateNew={!summary}
                otherSummaries={otherSummaries}
                judgementAct={judgementAct}
              />
              <SummaryQaTabs />
            </Form>
          </DocumentPageLayout>
        </SummaryContextProvider>
      </SummaryEditContextProvider>
    </FormProvider>
  );
};

const ProtectedSummaryEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_SUMMARIES)} fallback={NotAuthorized}>
      <SummaryEditPage />
    </Visible>
  );
};

export default ProtectedSummaryEditPage;
