import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { NewsArticle } from "../../models/NewsArticle.model";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";

interface NewsArticleQuickViewPageActionsProps {
  article: EntityModel<NewsArticle>;
  onClose?: () => void;
}

const NewsArticleQuickViewPageActions: FunctionComponent<NewsArticleQuickViewPageActionsProps> = ({
  article,
  onClose,
}) => {
  return (
    <DocumentActionsContainer
      document={{
        id: article.id,
        title: article.title,
      }}
      quickview
    >
      <DocumentCloseAction handler={onClose} />
    </DocumentActionsContainer>
  );
};

export default NewsArticleQuickViewPageActions;
