import ReferencedJudgementAct from "./ReferencedJudgementAct.model";

type JudgementActLink = {
  id: string;
  type: JudgementActLinkType;
  act: ReferencedJudgementAct;
};

export enum JudgementActLinkType {
  CITATION = "CITATION",
  SIMILARITY = "SIMILARITY",
  ADDENDUM = "ADDENDUM",
  CONTRADICTION = "CONTRADICTION",
  RULES = "RULES",
  NONE = "NONE",
}

export default JudgementActLink;
