import Card from "react-bootstrap/Card";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { InlineLinkButton } from "../../components/Button/button";
import EditorInfo from "../../components/EditorInfo";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { useSummaryContext } from "../../contexts/summary.context";
import DocumentStatus, { isPublishedStatus } from "../../models/DocumentStatus.enum";
import { judgementActHref } from "../../services/judgement-act-services";
import { shortenUrl } from "../../services/url-services";

const MainInfoCard = () => {
  const { summary } = useSummaryContext();
  const { setSelectedActId } = useSelectedActContext();
  const intl = useIntl();

  const navigate = useNavigate();

  const dissOpinionCount = [...summary.qas.map((qa, idx) => qa.dissentingOpinions.length)].reduce(
    (a, b) => a + b,
    0
  );

  return (
    <Card className="info-card">
      <Card.Body>
        <LabelValue
          label={intl.formatMessage({ id: "view-summary.labels.reporter" })}
          show={!!summary?.reporters.length}
        >
          {summary.reporters?.map((reporter, i) => {
            return (
              <>
                <InlineLinkButton
                  onClick={async () => {
                    const params = new URLSearchParams();
                    params.append("reporter", reporter);
                    const q = await shortenUrl(params.toString());
                    navigate(`/search/summaries?q=${q}`);
                  }}
                >
                  {reporter}
                </InlineLinkButton>
                {i > summary.reporters.length - 1 && ", "}
              </>
            );
          })}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.proceedingBy" })}
          show={!!summary?.relatedJudgementAct?.proceeding?.name}
        >
          {summary?.relatedJudgementAct?.proceeding?.name}
        </LabelValue>
        <LabelValue label={intl.formatMessage({ id: "view-summary.labels.dissentingOpinions" })}>
          {dissOpinionCount || (
            <FormattedMessage id="view-summary.labels.dissentingOpinions.none" />
          )}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-summary.labels.relatedAct" })}
          show={!summary.caseRelatedJudgementActs.length}
          className="d-flex flex-column"
        >
          {summary.relatedJudgementAct.status === DocumentStatus.GENERATED ||
          summary.relatedJudgementAct.status === DocumentStatus.DELETED ? (
            summary.relatedJudgementAct.title
          ) : (
            <InlineLink
              to={judgementActHref(summary.relatedJudgementAct.id)}
              onClick={(ev) => {
                if (!ev.ctrlKey && !ev.metaKey) {
                  ev.preventDefault();
                  setSelectedActId(summary.relatedJudgementAct.id);
                }
              }}
            >
              {summary.relatedJudgementAct.title}
            </InlineLink>
          )}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-summary.labels.caseRelatedActs" })}
          show={!!summary.caseRelatedJudgementActs.length}
          className="d-flex flex-column info-card-case-related-act"
        >
          <ul>
            {summary.caseRelatedJudgementActs
              .filter(
                (relatedAct) =>
                  relatedAct.status !== DocumentStatus.GENERATED &&
                  relatedAct.status !== DocumentStatus.DELETED
              )
              .map((act) => (
                <li key={act.id}>
                  {!isPublishedStatus(act.status) ? (
                    <div className="inactive-case-act-link">{act.title}</div>
                  ) : (
                    <>
                      <InlineLink
                        to={judgementActHref(act.id)}
                        onClick={(ev) => {
                          if (!ev.ctrlKey && !ev.metaKey) {
                            ev.preventDefault();
                            setSelectedActId(act.id);
                          }
                        }}
                        className={summary.relatedJudgementAct.id === act.id ? "fw-bold" : ""}
                      >
                        {act.title}
                      </InlineLink>
                      &nbsp;
                      <InlineLinkButton
                        onClick={() => window.open(`/judgement-acts/${act.id}`, "_blank")}
                      >
                        <FaExternalLinkAlt size={12} title="Отвори акта в нов таб" />
                      </InlineLinkButton>
                    </>
                  )}
                </li>
              ))}
          </ul>
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-summary.labels.editedBy" })}
          show={!!summary?.editor}
        >
          <EditorInfo editor={summary?.editor} />
        </LabelValue>
      </Card.Body>
    </Card>
  );
};

export default MainInfoCard;
