import { Option } from "react-multi-select-component/dist/types/lib/interfaces";
import SimpleSelect from ".";
import { SourcedOption } from "./simple-select";

interface SelectMultipleProps<T> {
  getOption?: (option: T) => Option;
  options?: T[];
  value: T[];
  onChange: (selection: T[]) => void;
  overrideStrings?: {
    [key: string]: string;
  };
  placeholder: string;
  hint?: string;
  ItemRenderer?: any;
  defaultIsOpen?: boolean;
  filterOnEmpty?: boolean;
  isDisabled?: boolean;
  onMenuToggle?: (expanded: boolean) => any;
  filterOptions?: ((options: Option[], filter: string) => Option[] | Promise<Option[]>) | undefined;
  closeOnSelection?: boolean;
  hasSelectAll?: boolean;
}

const SelectMultiple = <T extends unknown>({
  options = [],
  onChange = () => {},
  getOption = (option) => option as Option,
  defaultIsOpen = false,
  onMenuToggle,
  filterOptions,
  filterOnEmpty = false,
  isDisabled = false,
  value,
  placeholder,
  hint,
  ItemRenderer,
  closeOnSelection,
  hasSelectAll,
}: SelectMultipleProps<T>) => {
  return (
    <SimpleSelect
      options={options}
      getOption={getOption}
      value={value ? value : []}
      onChange={(selection) => onChange(selection)}
      isClearable={true}
      isDisabled={isDisabled}
      disableSearch={false}
      isMulti={true}
      defaultIsOpen={defaultIsOpen}
      onMenuToggle={onMenuToggle}
      closeOnSelection={closeOnSelection}
      filterOptions={
        filterOptions
          ? async (options, input) => {
              if (!input && !filterOnEmpty) {
                return [];
              }
              const filtered = await filterOptions(options, input);
              if (filtered.length > 1 && filtered.length <= 15) {
                filtered.unshift({
                  value: filtered.map((option) => option.value).join("!#!"),
                  label: "Избери всички",
                  disabled: false,
                } as any);
              }
              if (filtered.length === 100) {
                filtered.unshift({
                  value: "##",
                  label: "Визуализирани са първите 100 резултата",
                  disabled: true,
                  className: "no-options",
                } as any);
              }
              if (filtered.length === 0) {
                filtered.push({
                  value: "##",
                  label: "Няма резултати",
                  disabled: true,
                  className: "no-options",
                } as any);
              }
              return filtered;
            }
          : undefined
      }
      valueRenderer={(selected, options) =>
        selected.length
          ? selected.map((option) => (
              <div className="multi-value" key={option.value}>
                <div className="multi-value-label">{option.label.replace(/\s\(\d+\+?\)/i, "")}</div>
                <div
                  className="multi-value-remove"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(
                      (selected as SourcedOption<T>[])
                        .filter((sel) => sel.value !== option.value)
                        .map((sel) => sel.source)
                    );
                  }}
                >
                  <svg
                    height="14"
                    width="14"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                  </svg>
                </div>
              </div>
            ))
          : undefined
      }
      ItemRenderer={ItemRenderer}
      overrideStrings={{
        selectSomeItems: placeholder,
        search: "Търсене",
        selectAllFiltered: "Избери всички",
        noOptions: hint || "Няма резултати",
      }}
    />
  );
};

export default SelectMultiple;
