import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import { FaGlobe, FaLock } from "react-icons/fa";
import { useAuth } from "../../contexts/auth.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import "./access-indicator.scss";

const AccessIndicator: React.FC<{
  mode?: DocumentAccessMode;
  showAlways?: boolean;
  placement?: Placement;
}> = ({ mode, showAlways = false, placement = "bottom" }) => {
  const { isLoginSessionActive } = useAuth();
  return !isLoginSessionActive() || showAlways ? (
    mode === DocumentAccessMode.PAID ? (
      <OverlayTrigger
        placement={placement}
        overlay={(props) => (
          <Tooltip id="access-indicator-tooltip" {...props}>
            Изисква абонамент
          </Tooltip>
        )}
      >
        <span>
          <FaLock className="access-indicator" />
        </span>
      </OverlayTrigger>
    ) : (
      <OverlayTrigger
        placement={placement}
        overlay={(props) => (
          <Tooltip id="access-indicator-tooltip" {...props}>
            Публичен достъп
          </Tooltip>
        )}
      >
        <span>
          <FaGlobe className="access-indicator" />
        </span>
      </OverlayTrigger>
    )
  ) : null;
};

export default AccessIndicator;
