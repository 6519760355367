enum DocumentStatus {
  FOCUSED = "FOCUSED",
  PUBLIC = "PUBLIC",
  REVIEW = "REVIEW",
  DRAFT = "DRAFT",
  GENERATED = "GENERATED",
  DELETED = "DELETED",
}

export const isPublishedStatus = (status: DocumentStatus) =>
  status === DocumentStatus.PUBLIC || status === DocumentStatus.FOCUSED;

export default DocumentStatus;
