import "./carousel.css";

type CarouselPreviewProps = {
  imageSrc: string;
  title: string;
  subTitle: string;
  details?: string;
};

const CarouselPreview: React.FC<CarouselPreviewProps> = ({
  imageSrc,
  title,
  subTitle,
  details,
}) => {
  return (
    <div className="carousel">
      <img src={imageSrc} alt="" className="highlighted-article-image" />
      <div className="legend">
        <h3>{title}</h3>
        <div className="article-details">{subTitle}</div>
        {details && <div className="article-details2 mt-3">{details}</div>}
      </div>
    </div>
  );
};

export default CarouselPreview;
