/* eslint-disable no-underscore-dangle */
import { EntityModel } from "hateoas-hal-types";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { InlineLinkButton } from "../../components/Button/button";
import RichTextEditor from "../../components/RichTextEditor";
import Select, { RefDataSelect } from "../../components/Select/select";
import SelectMultiple from "../../components/SimpleSelect/select-multiple";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { ConnectUser, hasPrivilege, not, Privileges } from "../../contexts/auth.context";
import { useSummaryEditContext } from "../../contexts/summary-edit.context";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { JudgementAct } from "../../models/JudgementAct.model";
import { LinkedSummary } from "../../models/ReferencedDocument";
import { Summary } from "../../models/Summary.model";
import { DocumentStatusBadge } from "../../services/document-services";
import { fetchJudgementActs } from "../../services/judgement-act-services";
import { useDocumentSets, useSummaryTypes } from "../../services/ref-data-services";
import { useUserNames } from "../../services/user-services";
import ProvisionsSection from "./summary-edit-page-provisions";
const SummaryEditMainSection = ({
  isCreateNew,
  otherSummaries,
  judgementAct,
}: {
  isCreateNew: boolean;
  otherSummaries?: LinkedSummary[];
  judgementAct?: EntityModel<JudgementAct>;
}) => {
  const { control } = useFormContext<EntityModel<Summary>>();
  const intl = useIntl();
  const { data: summaryTypes } = useSummaryTypes();
  const relatedJudgementAct = useWatch({ control, name: "relatedJudgementAct" }) as any;

  const { setModeAddProvisionModal } = useSummaryEditContext();

  const lexebraEditors = useUserNames();

  useHotkeys(
    "ctrl+shift+q,command+shift+q",
    (e) => {
      e.preventDefault();
      setModeAddProvisionModal("select", window.getSelection()?.toString());
    },
    { enableOnContentEditable: true, enableOnTags: ["INPUT", "TEXTAREA"] }
  );

  const { data: documentSets } = useDocumentSets();

  return (
    <>
      {otherSummaries && otherSummaries.length > 0 && (
        <Alert variant="warning">
          Към избраният акт вече съществува резюме:{" "}
          {otherSummaries.map((s) => (
            <Link to={`/summaries/${s.id}`}>{s.shortTitle || "Кратко заглавие"}</Link>
          ))}
        </Alert>
      )}
      <Row>
        <Col md="8">
          <Controller
            name="relatedJudgementAct"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.Group controlId="relatedJudgementAct">
                <Form.Label>
                  <FormattedMessage id="edit-summary.form.related-act" />{" "}
                  {!!value && (
                    <InlineLinkButton
                      onClick={() => window.open(`/judgement-acts/${value.id}`, "_blank")}
                    >
                      <FaExternalLinkAlt title="Отвори акта в нов таб" />
                    </InlineLinkButton>
                  )}
                </Form.Label>
                <Select
                  onChange={onChange}
                  components={{
                    DropdownIndicator: null,
                  }}
                  inputStyles={{ singleValue: { width: "98%", textOverflow: "ellipsis" } }}
                  loadOptions={fetchJudgementActs}
                  getOptionValue={(value: any) => value.text || value.title}
                  getOptionLabel={(value: any) => (
                    <div title={value.text || value.title}>
                      {value.text || value.title}
                      <span style={{ position: "absolute", right: "5px" }}>
                        <DocumentStatusBadge status={value.status} />
                      </span>
                    </div>
                  )}
                  value={[value]}
                  isDisabled={isCreateNew}
                  shadowed
                />
              </Form.Group>
            )}
          />
        </Col>
        <Col md="2">
          <Form.Group controlId="status">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.status" />
            </Form.Label>
            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ConnectUser>
                  {({ currentUser }) => (
                    <SelectSingle
                      options={Object.keys(DocumentStatus)}
                      getOption={(status) => ({
                        label: intl.formatMessage({ id: `document.status.${status}` }),
                        value: status,
                        disabled: !(
                          hasPrivilege(Privileges.PROTECT_SUMMARIES)(currentUser) ||
                          status === DocumentStatus.DRAFT ||
                          status === DocumentStatus.REVIEW
                        ),
                      })}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </ConnectUser>
              )}
            />
          </Form.Group>
        </Col>
        <Col md="2">
          <Form.Group controlId="accessMode">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.access-mode" />
            </Form.Label>
            <Controller
              name="accessMode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ConnectUser>
                  {({ currentUser }) => (
                    <SelectSingle
                      options={Object.keys(DocumentAccessMode)}
                      getOption={(accessMode) => ({
                        label: intl.formatMessage({ id: `document.access-mode.${accessMode}` }),
                        value: accessMode,
                      })}
                      value={value}
                      onChange={onChange}
                      isDisabled={!hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)(currentUser)}
                    />
                  )}
                </ConnectUser>
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form.Group controlId="shortTitle">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.short-title" />
            </Form.Label>
            <Controller
              name="shortTitle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor
                  id="shortTitle"
                  defaultValue={value}
                  singleLine
                  cleanupPastedContent
                  headless
                  plainText
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Group controlId="summaryType">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.summaryType" />
            </Form.Label>
            <Controller
              name="summaryType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RefDataSelect
                  onChange={onChange}
                  value={[value]}
                  isClearable
                  shadowed
                  options={summaryTypes}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="editor">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.editor" />
            </Form.Label>
            <ConnectUser>
              {({ currentUser }) => (
                <Controller
                  name="editor"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={lexebraEditors}
                      getOption={(lexebraEditor) => ({
                        label: lexebraEditor.name,
                        value: lexebraEditor,
                      })}
                      value={value}
                      onChange={onChange}
                      isDisabled={not(hasPrivilege(Privileges.EDIT_ALL_SUMMARIES))(currentUser)}
                    />
                  )}
                />
              )}
            </ConnectUser>
          </Form.Group>
        </Col>
        <Col md="2">
          <Form.Group controlId="linkWithEuLegsilation" className="checkbox">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.link-eu-legislation" />
            </Form.Label>
            <div>
              <Controller
                name="linkWithEuLegsilation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectSingle
                    options={["true", "false"]}
                    getOption={(option) => ({
                      label: intl.formatMessage({ id: `boolean.${option}` }),
                      value: option,
                    })}
                    value={value + ""}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form.Group controlId="documentSets">
            <Form.Label>
              <FormattedMessage id="edit-news-article.form.documentSets" />
            </Form.Label>
            <Controller
              name="documentSets"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectMultiple
                  options={documentSets}
                  getOption={(documentSet) => ({
                    label: documentSet.shortName,
                    value: documentSet.id,
                  })}
                  value={value}
                  onChange={onChange}
                  placeholder="Изберете..."
                  isDisabled
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col md="6">
          <ProvisionsSection summaryTitle={relatedJudgementAct.text || relatedJudgementAct.title} />
        </Col>
        <Col md={6}>
          <Form.Group controlId="comments">
            <Form.Label>
              <FormattedMessage id="edit-summary.form.comments" />
            </Form.Label>
            <Controller
              name="comments"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor
                  id="comments"
                  defaultValue={value}
                  cleanupPastedContent
                  headless
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      {judgementAct && judgementAct.proceeding && (
        <Row>
          <Col md="6">&nbsp;</Col>
          <Col md={6}>
            <FormattedMessage id="edit-judgement.form.proceeding" />:{" "}
            {judgementAct?.proceeding?.name}
          </Col>
        </Row>
      )}
    </>
  );
};

export default SummaryEditMainSection;
