import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import ReactQuill from "react-quill";
import { v4 as uuid } from "uuid";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { scrollToTargetAdjusted } from "../../pages/JudgementPage/judgmenet-view-page-metadata";
import Heading from "./Heading";
import "./table-of-contents.scss";

const fetchActs = (activeId?: Heading) => {
  const elements = Array.from(document.querySelectorAll(".act-title a, .summary-title a")).map(
    (elem) => {
      if (!elem.id) {
        elem.id = "_" + uuid();
      }
      return {
        id: elem.id,
        text: (elem as HTMLAnchorElement).innerText as string,
        href: (elem as HTMLAnchorElement).href,
        className: activeId
          ? elem.closest(".main-section")?.querySelector(`#${activeId.id}`)
            ? ""
            : "h-0"
          : "",
      };
    }
  );
  return elements;
};

export const TableOfContentActs: React.FC<{
  activeId?: Heading;
}> = ({ activeId }) => {
  const [actLinks, setActLinks] = useState<
    { text: string; id: string; href: string; className: string }[]
  >([]);

  useEffect(() => {
    setActLinks(fetchActs(activeId));
  }, [activeId]);

  useEffect(() => {
    const quillContainer = document.querySelector("#editor-collection > .ql-container")!;
    if (quillContainer) {
      let quill = ReactQuill.Quill.find(quillContainer);
      if (quill) {
        const handler = () => {
          setActLinks(fetchActs());
        };
        quill.on("text-change", handler);
        quill.scroll.emitter.on("blot-load", handler);
        return () => {
          quill.off("text-change", handler);
          quill.scroll.emitter.off("blot-load", handler);
        };
      }
    }
  }, []);

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { setSelectedActId } = useSelectedActContext();
  return (
    <div className="section">
      <ul className={`collection-acts ${actLinks.length >= 100 ? "large" : ""}`}>
        {actLinks.map((link) => (
          <li key={link.id} className={link.className}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id={`tooltip-${link.id}`}>{link.text}</Tooltip>}
            >
              <a
                className={`link inline-link ${
                  !isMobile ? "text-overflow-ellipsis" : ""
                } d-inline-block`}
                href={link.href}
                onClick={(e) => {
                  e.preventDefault();
                  const editor = document.querySelector(".ql-editor");
                  if (editor) {
                    if (link.href.indexOf("acts") > -1) {
                      setSelectedActId(link.href.substring(link.href.lastIndexOf("/") + 1));
                    } else {
                      setSelectedActId(
                        "summary:" + link.href.substring(link.href.lastIndexOf("/") + 1)
                      );
                    }
                  } else {
                    scrollToTargetAdjusted(document.querySelector(`#${link.id}`)!);
                  }
                  return false;
                }}
              >
                {link.text}
              </a>
            </OverlayTrigger>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContentActs;
